import React from 'react'
import '../style.sass'

import logo from '../../../image/logo-start.png'
import Inputs from '../../../Components/Inputs'
import Partners from '../../../Components/LeftSideBar/Partners'
import SiteFooter from '../../../Components/SiteFooter'

const TYPE_REGISTRATION = 'registration';
const TYPE_AUTH = 'auth';
const TYPE_FORGOT = 'forgot';

export default class Admin extends React.Component{

    state = {
        type: TYPE_REGISTRATION, // registration || auth || forgot
        email: '',
        emailError: false,
        login: '',
        loginError: false,
        password: '',
        passwordError: false,
    }
    

    changeType = type => {
        this.setState({
            type,
            emailError: false,
            loginError: false,
            passwordError: false,

        })
    }

    updateState = (type, value) => {
        // eslint-disable-next-line
        switch (type) {
            case 'email' : {
                this.setState({
                    email: value,
                    emailError: false
                });
                break;
            }
            case 'login' : {
                this.setState({
                    login: value,
                    loginError: false
                })
                break;
            }
            case 'password' : {
                this.setState({
                    password: value,
                    passwordError: false
                })
                break;
            }
        }
    }

    sendDate = () => {
        const {type, email, login, password} = this.state
        if(type === TYPE_REGISTRATION){
            if(!this.valid_email(email)){
                this.setState({emailError: true})
            }else if(!login.length){
                this.setState({loginError: true})
            }else if(!password.length){
                this.setState({passwordError: true})
            }else{
                this.props.changeType()
            }
        }
        if(type === TYPE_AUTH){
            if(!login.length){
                this.setState({loginError: true})
            }else if(!password.length){
                this.setState({passwordError: true})
            }else{
                this.props.changeType()
            }
        }
        if(type === TYPE_FORGOT){
            if(!this.valid_email(email)){
                this.setState({emailError: true})
            }else{
                alert('valid')
            }
        }

    }

    valid_email = (el) => {
        let reg = /^[a-z-._0-9]+@[a-z-_0-9]+\.[a-z0-9]{2,10}$/i;
        return reg.test(el)
    }

    render() {
        const {type, emailError, loginError, passwordError} = this.state
        return (
            <div className="Entrance">
                <div className="wrp">
                    <form action="" className="form">
                        <div className="logo">
                            <img src={logo} alt=""/>
                        </div>
                        {type === TYPE_REGISTRATION ?
                            <div className="head">
                                Приветствуем Вас, Администратор! <br/>
                                <span onClick={() => {this.changeType(TYPE_AUTH)}}>Войдите</span> или для регистрации в обучающей программе <br/>
                                заполните, пожалуйста, поля:
                            </div>
                            :
                            type === TYPE_FORGOT ?
                                <div className="head">
                                    Для востановления паролья, <br/>  пожалуйста, введите свой E-mail:
                                </div>
                                :
                                <div className="head">
                                    Приветствуем Вас, Администратор! <br/>
                                    Для входа в обучающую программу заполните, <br/>
                                    пожалуйста, поля или <span onClick={() => {this.changeType(TYPE_REGISTRATION)}}>зарегистрируйтесь</span>.
                                </div>

                        }
                        <Inputs
                            name={'email'}
                            placeholder={'E-mail'}
                            onChange={this.updateState}
                            type={'email'}
                            className={emailError && 'error'}
                        />
                        {type !== TYPE_FORGOT &&
                            <Inputs
                                name={'login'}
                                placeholder={'Логин'}
                                onChange={this.updateState}
                                className={loginError && 'error'}

                            />
                        }
                        {type === TYPE_AUTH &&
                            <div className="forgot">
                                <span onClick={() => {this.changeType('forgot')}}>Забыли пароль?</span>
                            </div>
                        }
                        {type === TYPE_FORGOT &&
                            <div className="forgot">
                                <span onClick={() => {this.changeType('auth')}}>Вернуться</span>
                            </div>
                        }
                        {type === TYPE_REGISTRATION &&
                            <Inputs
                                name={'password'}
                                placeholder={'Придумайте пароль'}
                                type={'password'}
                                onChange={this.updateState}
                                className={passwordError && 'error'}

                            />
                        }
                        <div className="buttons">
                                <button 
                                    type='button'
                                    className="btn btn_white"
                                    onClick={() => {
                                        this.sendDate()
                                    }}
                                >
                                    {type === TYPE_REGISTRATION ? 'зарегистрироваться' : 'Войти' }
                                </button>
                        </div>
                        <Partners brStyle={{display: 'none'}}/>
                    </form>
                </div>
                <SiteFooter style={{backgroundColor: '#00559C'}}/>
            </div>
        )
    }

}
