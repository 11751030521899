import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from "../../../image/lessons/lesson6/step29-1.png";
import img2 from "../../../image/lessons/lesson6/step29-2.png";
import img3 from "../../../image/lessons/lesson6/step29-3.png";
import img4 from "../../../image/lessons/lesson6/step29-4.png";


export default class step29 extends React.Component {

    render() {
        return (
            <div className="step29">
                <div className="head">
                    Хвост
                </div>
                <TextBlock
                    text={`<b>Помимо вербальной коммуникации, кошку можно понять и по движениям ее хвоста.</b>`}
                />
                <div className="flex">
                    <div className="flex_el">
                        <div className="flex_el_text">
                            Хвост поднят вверх. Это признак доверия и хорошего настроения.
                        </div>
                        <div className="flex_el_img-box">
                            <img src={img1} alt="" className="flex_el_img"/>
                        </div>
                    </div>
                    <div className="flex_el">
                        <div className="flex_el_text">
                            Энергичное виляние хвостом из стороны в сторону. Вероятнее всего, питомец нервничает или раздражен.
                        </div>
                        <div className="flex_el_img-box">
                            <img src={img2} alt="" className="flex_el_img"/>
                        </div>
                    </div>
                    <div className="flex_el">
                        <div className="flex_el_text">
                            Хвост поджат, находится между задними лапами. Эта поза говорит о том, что кошка напугана.
                        </div>
                        <div className="flex_el_img-box">
                            <img src={img3} alt="" className="flex_el_img"/>
                        </div>
                    </div>
                    <div className="flex_el">
                        <div className="flex_el_text">
                            Распушенный хвост. Такой жест тоже свидетельствует о страхе, но также он сигнализирует о готовности атаковать.
                        </div>
                        <div className="flex_el_img-box">
                            <img src={img4} alt="" className="flex_el_img"/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
