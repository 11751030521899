import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from "../../../image/lessons/lesson6/step27-1.png";


export default class step27 extends React.Component {

    render() {
        return (
            <div className="step27">
                <div className="head">
                    Мяуканье
                </div>
                <TextBlock
                    text={`<b>Считается, что кошки редко общаются с себе подобными с помощью мяуканья</b>. Исключение – котята, которые таким образом разговаривают с мамой. Взрослая же кошка, мяукая, скорее всего, хочет привлечь внимание хозяина.
                    
                    Кстати, разговорчивость животного часто зависит от породы. Своей привычкой поболтать с хозяином <b>славятся сиамские, ориентальные и тайские кошки.</b>`}
                />
                <img src={img1} alt="" className="imgCenter"/>
            </div>
        )
    }
}
