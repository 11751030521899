import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import Creator from '../../../Components/LessonAndTestComponents/Creator';
import UsedMaterials from '../../../Components/LessonAndTestComponents/UsedMaterials';

import img1 from "../../../image/lessons/lesson6/step1-1.png";
import img2 from "../../../image/Lecturers/namk.png";

export default class step1 extends React.Component {

    render() {
        return (
            <div className="step1">
                <Creator
                    img={img2}
                    name={'Национальной ассоциацией медицины кошек'}
                />
                <hr className="string"/>
                <div className="flex">
                    <div className="flex_el">
                        <div className="flex_head">
                            Как понять кошку?
                        </div>
                        <TextBlock
                            text={`В этом уроке мы подготовили материалы, которые помогут вам обучать владельцев правильному обращению с кошкой. Как распознать эмоции кошек, каковы их скрытые инстинкты и социальные привычки, на что важно обращать внимание.
                        
                        Профессиональная консультация по вопросам поведения кошек, призванная сформировать ответственное отношение владельца к своему питомцу – важный элемент заботы ветеринарного врача о своих пациентах.`}
                        />
                    </div>
                    <img src={img1} alt="" className="flex_img"/>
                </div>
                <UsedMaterials
                    head={'Использованные материалы:'}
                    text={'1) Kessler M.R., Turner D.C. Stress and adaptation of cats (felis silvestris catus) housed singly, in pairs and in groups in boarding catteries. Animal Welfare 1997; 6; 234-254 pp. 2) Beaver B.V. Feline Behaviour:  A guide for veterinarians. St. Louis: Elsevier Science, 2003. p.349 3) UFAW Animal Welfare Research Report No.8; An ethnogram for behavioural studies of domestic cats, 1995 4) Understanding Cats. The Ohio State University: College of Veterinary Medicine.'}
                />
            </div>
        )
    }
}
