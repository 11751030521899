import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import Popup from "../../../Components/Popup";

import img1 from '../../../image/lessons/lesson11/step4-1.png'

export default class step4 extends React.Component {

    state = {
        windowWidth: window.innerWidth,
        pointActive: 0,
        showPopup: false,
        points: [],
    };

    //Отлов события ресайз
    handlerResize = () => {
        this.setState({windowWidth: window.innerWidth})
    }

    componentDidMount() {
        window.addEventListener('resize', this.handlerResize)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handlerResize);
    }

    showPopup = (pointActive) => {
        const {points, showPopup, windowWidth} = this.state;
        if (points.indexOf(pointActive) === -1) {
            points.push(pointActive)
        }

        if (windowWidth < 768) {
            if (!showPopup) {
                this.setState({
                    pointActive: pointActive,
                    showPopup: true,
                })
            } else if (pointActive >= 4) {
                this.setState({showPopup: false, pointActive: 0})
            } else {
                this.setState({
                    pointActive: pointActive +1,
                    showPopup: true,
                })
            }
        } else {
            this.setState({points: points, pointActive: pointActive})
        }
        if (points.length === 4) {
            this.props.showNextBtn();
        }
    }

    closePopup = () => {
        this.setState({showPopup: false})
    }

    popupData = {
        0: {
            text: '',
        },
        1: {

            head: `Ротовая полость`,
            text: `В слюне кошки отсутствует фермент амилаза, который необходим травоядным животным для переваривания растительной пищи уже в процессе жевания.`,
        },
        2: {
            head: `Желудок`,
            text: `Желудок кошек <b>предназначен</b> для многократного приёма небольших порций пищи в течение дня. <b>Уровень pH</b> желудочного сока кошек более кислый, чем у человека. Это способствует перевариванию костей и уничтожению патогенных бактерий.`,
        },
        3: {
            head: `Тонкий кишечник`,
            text: `Активность фермента амилазы, которую вырабатывает поджелудочная железа для переваривания растительной пищи, у кошек приблизительно в три раза ниже, чем у собак.`,
        },
        4: {
            head: `Толстый кишечник`,
            text: `У кошек короткий кишечник. А время кишечного транзита составляет 12–24 часов, что значительно меньше по сравнению с аналогичными показателями у человека (от 30 часов до 5 дней).`,
        },
    }

    render() {
        const {showPopup, pointActive, points} = this.state
        return (
            <div className="step4">
                <InfoBlock
                    text={`Нажмите на ключевые элементы, чтобы больше узнать о них .`}
                />
                <div className="box">
                    <div className="box_img">
                        <div
                            className={'box_point ' + (points.indexOf(1) >= 0 && 'active ') + (pointActive === 1 && 'heigher')}
                            onClick={() => {this.showPopup(1)}}
                        >
                            1
                        </div>
                        <div
                            className={'box_point ' + (points.indexOf(2) >= 0 && 'active ') + (pointActive === 2 && 'heigher')}
                            onClick={() => {this.showPopup(2)}}
                        >
                            2
                        </div>
                        <div
                            className={'box_point ' + (points.indexOf(3) >= 0 && 'active ') + (pointActive === 3 && 'heigher')}
                            onClick={() => {this.showPopup(3)}}
                        >
                            3
                        </div>
                        <div
                            className={'box_point ' + (points.indexOf(4) >= 0 && 'active ') + (pointActive === 4 && 'heigher')}
                            onClick={() => {this.showPopup(4)}}
                        >
                            4
                        </div>
                        <div className={'block red ' + (pointActive === 1 && 'active')}>
                            <div className="block_head">
                                {this.popupData[1].head}
                            </div>
                            <TextBlock
                                text={this.popupData[1].text}
                            />
                        </div>
                        <div className={'block green ' + (pointActive === 2 && 'active')}>
                            <div className="block_head">
                                {this.popupData[2].head}
                            </div>
                            <TextBlock
                                text={this.popupData[2].text}
                            />
                        </div>
                        <div className={'block ' + (pointActive === 3 && 'active')}>
                            <div className="block_head">
                                {this.popupData[3].head}
                            </div>
                            <TextBlock
                                text={this.popupData[3].text}
                            />
                        </div>
                        <div className={'block ' + (pointActive === 4 && 'active')}>
                            <div className="block_head">
                                {this.popupData[4].head}
                            </div>
                            <TextBlock
                                text={this.popupData[4].text}
                            />
                        </div>
                        <img src={img1} className="imgCenter" alt=""/>
                    </div>
                </div>
                {showPopup &&
                <Popup
                    closePopup={this.closePopup}
                >
                    <div className={'info'}>
                        <div className="info_icon">
                            {pointActive}
                        </div>
                        <div className="info_head">
                            {this.popupData[pointActive].head}
                        </div>
                        <TextBlock
                            text={this.popupData[pointActive].text}
                        />
                    </div>
                    <button
                        className="btn btn_violet btn_center"
                        onClick={() => {this.showPopup(pointActive)}}
                    >
                        Продолжить
                    </button>
                </Popup>
                }
            </div>
        )
    }
}
