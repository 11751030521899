import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import PlanInFirstStep from '../../../Components/LessonAndTestComponents/PlanInFirstStep';

export default class step4 extends React.Component {

    planData = [
        `Организация сна`,
        `Повышение активности кошки`,
        `Скрытый инстинкт`,
        `Социальные привычки`,
        `Пищевые привычки и рацион`,
        `Территория`,
        `Уход за шерстью и температура`,
        `Самые опасные хищники для кошек`,
        `Эмоции кошек`,
    ]

    render() {
        return (
            <div className="step4">
                <TextBlock
                    text={`<b>Мы можем использовать наше понимание поведения кошек для улучшения окружающей обстановки и домашней среды, в которой пребывают живущие с нами кошки.</b>`}
                />
                <div className="head">План урока</div>
                <PlanInFirstStep
                    planData={this.planData}
                    type={'violet'}
                />
            </div>
        )
    }
}
