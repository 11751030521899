import React from 'react'
import './style.sass'

export default (props) => {
    return (
        <div className={'organizer'}>
            <div className="organizer_box-img">
                <img src={props.img} alt="" className="organizer_img"/>
            </div>
            <div className="organizer_name">
                {props.name}
            </div>
            <div className="organizer_description">
                {props.description}
            </div>
        </div>
    )
}
