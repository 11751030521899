import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
// import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import OnClickTest from '../../../Components/LessonAndTestComponents/OnClickTest';

export default class step14 extends React.Component {

    state = {
        answersData: [
            `У собак`,
            `У кошек`,
        ],
    }

    showNextBtn = () => {
        this.props.showNextBtn()
    }

    render() {
        return (
            <div className="step14">
                <TextBlock
                    text={`У каких пациентов чаще встречаются анестезиологические осложнения?`}
                />
                <OnClickTest
                    ansewrsData={this.state.answersData}
                    type={'line'}
                    rightAnswers={[2]}
                    showNextBtn={this.showNextBtn}
                    correctText={``}
                />
            </div>
        )
    }
}
