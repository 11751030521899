import React from 'react'
import './style.sass'
// import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

export default class BlockTurnsIntoPopup extends React.Component {

    state = {
        windowWidth: window.innerWidth,
    }

    handlerResize = () => {
        this.setState({windowWidth: window.innerWidth})
    }

    componentDidMount() {
        const {windowWidth} = this.state
        window.addEventListener('resize', this.handlerResize)
        if (windowWidth <768) {
            document.body.scrollIntoView( true);
            document.body.style.overflow = "hidden";
        }
    }

    componentWillUnmount() {
        const {windowWidth} = this.state
        if (windowWidth < 768) {
            document.body.style.overflow = "";
        }
        window.removeEventListener('resize', this.handlerResize);
    }

    stopClosePopup = (e) => {
        e.stopPropagation()
    }

    render() {
        return (
            // Пример использования lesson 33, step 5
            <div className={'BlockTurnsIntoPopup ' + this.props.className} onClick={() => {this.props.closePopup()}}>
                <div className="BlockTurnsIntoPopup_wrp" onClick={(e) => {this.stopClosePopup(e)}}>
                    <div
                        className="BlockTurnsIntoPopup_closed"
                        onClick={() => {this.props.closePopup()}}
                    />
                    <div className="BlockTurnsIntoPopup_content">
                        {this.props.children}
                        <button
                            className="btn btn_violet btn_center"
                            onClick={() => {this.props.changePoint()}}
                        >
                            {this.props.popupBtnText ? this.props.popupBtnText : 'продолжить'}
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}
