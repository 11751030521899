import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from "../../../image/lessons/lesson6/step22-1.png";


export default class step22 extends React.Component {

    render() {
        return (
            <div className="step22">
                <TextBlock
                    text={`<b>Большинство кошек настолько хорошо ухаживают за своей шерстью</b>, что им даже не нужно принимать ванну, чтобы оставаться чистыми.
                    
                    Длинношерстные (с густой шерстью), страдающие ожирением или больные кошки могут нуждаться в дополнительном расчесывании и уходе за шерстью.
                    
                    Несмотря на то, что кошки в условиях дикой природы не имеют такой «роскоши», вы можете помочь своей кошке всегда оставаться здоровой и чистой, если видите, что она изо всех сил пытается сама безуспешно ухаживать за своей шерстью.`}
                />
                <img src={img1} alt="" className="imgCenter"/>
            </div>
        )
    }
}
