import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';

import img1 from '../../../image/lessons/lesson11/step5-1.png';

export default class step5 extends React.Component {

    state = {
        windowWidth: window.innerWidth,
        flexActive: 0,
        flexs: [0],
        showPopup: false
    };

    //Отлов события ресайз
    handlerResize = () => {
        this.setState({windowWidth: window.innerWidth})
    }

    componentDidMount() {
        window.addEventListener('resize', this.handlerResize)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handlerResize);
    }

    changeFlex = flexActive => {
        const {flexs, windowWidth, showPopup} = this.state
        if (flexs.indexOf(flexActive) === -1) {
            flexs.push(flexActive)
        }

        if (windowWidth < 768) {
            if (!showPopup) {
                this.setState({
                    flexActive: flexActive,
                    showPopup: true,
                })
                document.body.scrollIntoView( true);
                document.body.style.overflow = "hidden";
            } else if (flexActive >= 7) {
                this.setState({showPopup: false, flexActive: 0})
                document.body.style.overflow = "";
            } else {
                this.setState({
                    flexActive: flexActive +1,
                    showPopup: true,
                })
            }
        } else {
            this.setState({flexActive: flexActive, flexs: flexs, showPopup: true})
        }


        if (flexs.length === 8) {
            this.props.showNextBtn()
        }
    }

    closeImgDiv = () => {
        this.setState({flexActive: 0, showPopup: false})
        document.body.style.overflow = "";
    }

    imgDivData = {
        0: {
            head: '',
            class: '',
            text: '',
        },
        1: {
            head: 'Белки',
            class: 'first',
            text: `<b>Описание</b>:
        
        Потребности кошки в белке выше, чем у собаки или человека. Это связано с неспособностью к регулированию процесса распада белков, даже при потреблении низкобелкового рациона. Поэтому кошки нуждаются в рационе с высоким общим объёмом белка.
        
        <b>Источники</b>:
        
        Продукты животного происхождения (мясо, субпродукты, рыба)`,
        },

        2: {
            head: 'Аргинин',
            class: 'second',
            text: `<b>Описание</b>:
        
        Аргинин — аминокислота, наличие которой чрезвычайно важно в рационе кошек. 
        
        <b>Функции</b>:
        
        В результате распада белков образуется аммиак, поэтому чем выше содержание белка в рационе, тем выше потребность кошки в аргинине, ведь он необходим для синтеза мочевины из аммиака, а также способствует регулированию тонуса кровеносных сосудов и выработке некоторых гормонов.
        
        <b>Источники</b>:
        
        Содержится аргинин в продуктах животного происхождения: мясе и субпродуктах.
        
        <span class="text_red">Дефицит и избыток</span>:
        
        Недостаток аргинина в их организме очень скоро приводит к появлению клинических симптомов интоксикации аммиаком: рвоты, повышенного слюноотделения и нарушения со стороны нервной системы. Дефицит аргинина может привести к летальному исходу в течение нескольких часов.`,
        },

        3: {
            head: 'Таурин',
            class: 'third',
            text: `<b>Описание</b>:
        
        Таурин —  аминокислота, содержащаяся в большинстве животных тканей и являющаяся ещё одним жизненно необходимым элементом рациона кошек. В их организме, в отличие от собак, таурин не синтезируется, поэтому они нуждаются в постоянном поступлении этой аминокислоты с пищей.
        
        <b>Функции</b>:
        
        Таурин необходим кошкам для нормальной работы сердца, хорошего зрения и слуха, кроме того, он является важным антиоксидантом и обеспечивает защиту организма от негативных факторов внешней среды.
        
        <b>Источники</b>:
        
        Таурин содержится лишь в продуктах животного происхождения. Основными его источниками являются субпродукты (например, сердце, почки, печень).
        
        <span class="text_red">Дефицит и избыток</span>:
        
        Дефицит таурина может привести к дегенерации сетчатки у кошек и последующей слепоте, развитию заболеваний сердца, неадекватной иммунной реакции, замедленному росту и нарушению репродуктивной функции, включая рождение нежизнеспособного потомства или врождённые пороки развития.
        `,
        },

        4: {
            head: 'Жиры',
            class: 'fourth',
            text: `<b>Описание</b>:
        
        Особое значение для кошек играет наличие в рационе животных жиров, которые содержат арахидоновую жирную кислоту, относящуюся к группе омега-6 жирных кислот. Кошки не способны её синтезировать и должны получать с рационом.
       
        <b>Источники</b>:
        
        Арахидоновая кислота содержится в животных жирах, например в говяжьем жире и подкожном жире домашней птицы.
        
        <span class="text_red">Дефицит и избыток</span>:
        
        Важно придерживаться разумного сочетания животных и растительных жиров в её рационе. Дефицит омега-6 жирных кислот может привести к нарушению репродуктивной функции, ухудшению состояния кожи и шерсти — сухости, раздражению и шелушению кожи, тусклости шёрстного покрова.
        `,
        },

        5: {
            head: 'Углеводы',
            class: 'fifth',
            text: `<b>Описание</b>:
        
        Кошки обладают меньшей, по сравнению с собаками, способностью переваривать углеводы вследствие низкой активности ферментов в тонком кишечнике — в частности, как уже говорилось выше, фермента амилазы, который вырабатывает поджелудочная железа. Однако это не значит, что кошка может обходиться совсем без углеводов.
        
        <b>Функции</b>:
        
        Важным компонентом рациона кошки является клетчатка, необходимая для обеспечения нормального процесса пищеварения.
        
        <span class="text_red">Дефицит и избыток</span>:
        
        Использование исключительно мясного рациона чревато для кошки нарушением работы пищеварительного тракта.
        `,
        },

        6: {
            head: 'Витамин А',
            class: 'sixth',
            text: `<b>Описание</b>:
        
        Провитамином А является бета-каротин, который содержится в растительных продуктах. Его молекула состоит по существу из двух соединённых вместе витамин-А-подобных молекул, которые организм большинства животных способен превращать в две молекулы активного витамина А. Доказано, что организм кошки не может превращать бета-каротин в витамин А, поэтому кошки должны получать с пищей готовые источники витамина А, которыми являются только продукты животного происхождения, в растениях готового витамина А нет.
       
        <b>Источники</b>:
        
        Хорошими источниками витамина А являются печень, рыба и яйца.
        
        <span class="text_red">Дефицит и избыток</span>:
        
        Дефицит витамина А может привести к нарушению зрения, сухости кожи, репродуктивным нарушениям, лёгочной патологии и повышенной восприимчивости к инфекциям. Но помните: опасен не только недостаток витамина А в рационе кошки, но и его избыток, поэтому количество этого вещества в рационе должно строго контролироваться.
        `,
        },

        7: {
            head: 'Витамин B3',
            class: 'seventh',
            text: `<b>Описание</b>:
        
        Кошки способны синтезировать ниацин очень ограниченно, потому его также необходимо включать в их рацион.
        
        <b>Функции</b>:
        
        Наряду с другими витаминами группы В ниацин помогает защищать кожу, способствуя синтезу кожного жира, особенно церамидов, чтобы ограничить обезвоживание кожи.
        
        <b>Источники</b>:
        
        Ниацин содержится во многих продуктах, его много в мясе, рыбе и злаках.
        `,
        },

    }

    render() {
        const {flexActive, flexs, showPopup, windowWidth} = this.state
        return (
            <div className="step5">
                <TextBlock
                    text={`В отличие от других животных, в том числе и собак, целый ряд жизненно важных веществ кошки могут получать исключительно из продуктов животного происхождения.`}
                />
                <InfoBlock
                    text={`Нажмите на ключевые элементы, чтобы больше узнать о них .`}
                />
                <div className="wrp">
                    <div className={'flex'}>
                        <div className={'flex_el ' + (flexs.indexOf(1) >= 0 && 'active')} onClick={() => {
                            this.changeFlex(1)
                        }}>Белки
                        </div>
                        <div className={'flex_el ' + (flexs.indexOf(2) >= 0 && 'active')} onClick={() => {
                            this.changeFlex(2)
                        }}>Аргинин
                        </div>
                        <div className={'flex_el ' + (flexs.indexOf(3) >= 0 && 'active')} onClick={() => {
                            this.changeFlex(3)
                        }}>Таурин
                        </div>
                        <div className={'flex_el ' + (flexs.indexOf(4) >= 0 && 'active')} onClick={() => {
                            this.changeFlex(4)
                        }}>Жиры
                        </div>
                    </div>
                    {/*{flexActive === 0 &&
                    <img src={img1} alt=""/>
                    }*/}
                    {showPopup &&
                    <div className={'imgDiv ' + (this.imgDivData[flexActive].class)}>
                        <div className="imgDiv_wrap">
                            {windowWidth < 768 &&
                            <div className="imgDiv_head">
                                {this.imgDivData[flexActive].head}
                            </div>
                            }
                            <div
                                className="imgDiv_closed"
                                onClick={this.closeImgDiv}
                            />
                            <TextBlock
                                text={this.imgDivData[flexActive].text}
                            />
                            {windowWidth < 768 &&
                            <button
                                className="btn btn_violet btn_center"
                                onClick={() => {this.changeFlex(flexActive)}}
                            >
                                Продолжить
                            </button>
                            }
                        </div>

                    </div>
                    }
                    <div className={'flex'}>
                        <div className={'flex_el ' + (flexs.indexOf(5) >= 0 && 'active')} onClick={() => {
                            this.changeFlex(5)
                        }}>Углеводы
                        </div>
                        <div className={'flex_el ' + (flexs.indexOf(6) >= 0 && 'active')} onClick={() => {
                            this.changeFlex(6)
                        }}>Витамин А
                        </div>
                        <div className={'flex_el ' + (flexs.indexOf(7) >= 0 && 'active')} onClick={() => {
                            this.changeFlex(7)
                        }}>Витамин B3
                        </div>
                    </div>
                </div>
                <img src={img1} className="imgCenter" alt=""/>
            </div>
        )
    }
}
