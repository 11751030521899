import React from 'react'

export default class step16 extends React.Component {

    render() {
        return (
            <div className="step16">
                <ul className="list">
                    <li className="list_item">
                        Агрессия - одна из наиболее часто регистрируемых проблем кошачьего поведения в клинике, вызываемая страхом и болью от процедур. Агрессия может привести к нападениям на ветеринарный персонал, которые обычно включают в себя укусы или царапины. Уровень инфицирования при необработанных ранах после нападения кошек колеблется в пределах <b>30-50%</b>. Раны на руках особенно подвержены прогрессированию инфекции. <b>Улучшение опыта госпитализации для «кошачьего» пациента уменьшает агрессию, основанную на страхе, а лучшее распознавание и умение управлять болевыми симптомами уменьшает агрессию, основанную на боли.</b>
                    </li>
                    <li className="list_item">
                        Если «кошачьи» пациенты демонстрируют признаки улучшения самочувствия, лучшие исходы заболевания и меньшее негативное поведение, вызванное стрессом, то ветеринарные врачи тоже будут испытывать удовлетворенность лечением в большей степени.
                    </li>
                    <li className="list_item">
                        Наконец, сокращение времени, необходимого для обращения с капризными и неконтактными кошками, повышает эффективность лечения и результативность работы в процессе ветеринарной терапии.
                    </li>
                </ul>
            </div>
        )
    }
}
