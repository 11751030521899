import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import OnClickBlock from '../../../Components/LessonAndTestComponents/OnClickBlock';

import img1 from "../../../image/lessons/lesson11/step18-1.png";
import img2 from "../../../image/lessons/lesson11/step18-2.png";
import img3 from "../../../image/lessons/lesson11/step18-3.png";

export default class step18 extends React.Component {

    state = {
        flexData: ['Потребление энергии', 'Пищеварение'],
        trianglePosition: ['first', 'second'],
        flexHidden: false
    }

    hideElement = () => {
        this.setState({flexHidden: true})
    }

    showNextBtn = () => {
        this.props.showNextBtn()
    }

    render() {
        const {flexData, flexHidden, trianglePosition} = this.state
        return (
            <div className="step18">
                <InfoBlock
                    text={`Нажимайте на ключевые элементы, чтобы узнать результаты исследования питания пожилых кошек.`}
                />
                <TextBlock
                    text={`В научно-исследовательском институте Waltham проводились исследования поведения пожилых кошек. Изучалась их физическая активность, аппетит, особенности пищеварения.`}
                />
                <OnClickBlock
                    flexData={flexData}
                    trianglePosition={trianglePosition}
                    hideElement={this.hideElement}
                    showNextBtn={this.showNextBtn}
                >
                    <div className="imgDiv_wrap">
                        <div className="imgDiv_wrap_flex">
                            <TextBlock
                                text={`Исследование WALTHAM™ Research показало, что в отличие от людей и собак, у кошек в процессе старения не уменьшаются энергетические потребности и мышечная масса. Это связано с тем, что уровень активности взрослых и пожилых кошек примерно одинаковый, поэтому потребление энергии не изменяется. Однако этот эффект зависит от индивидуальных особенностей каждой кошки.
                                
                                В пожилом возрасте необходимо продолжать следить за упитанностью кошки и подбирать рацион, исходя из индивидуальных потребностей.`}
                            />
                            <img src={img2} alt="" className="imgDiv_wrap_flex_img"/>
                        </div>
                    </div>
                    <div className="imgDiv_wrap">
                        <div className="imgDiv_wrap_flex">
                            <TextBlock
                                text={`Другое исследование показало, что многие пожилые кошки испытывают <b>проблемы с перевариванием жиров и белков</b>. Кошки, относящиеся к очень пожилой возрастной группе (11+ лет) больше склонны к потере веса и мышечной массы из-за ухудшения усвояемости питательных веществ.
                                
                                Кошкам с пищеварительными проблемами увеличивают суточную норму потребления пищи для компенсации этого эффекта. `}
                            />
                            <img src={img3} alt="" className="imgDiv_wrap_flex_img"/>
                        </div>
                    </div>
                </OnClickBlock>
                <img src={img1} alt="" className={'imgCenter ' + (flexHidden && 'hidden')}/>
            </div>
        )
    }
}
