import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson7/step9-1.png'


export default class step9 extends React.Component{

    render() {
        return (
            <div className="step9">
                <div className="flex">
                    <TextBlock
                        text={`Те, кто хотят научиться понимать свою кошку, могут ознакомиться с монографией From the Cat’s Point of View за авторством Гвен Боненкамп (Gwen Bohnenkamp).`}
                    />
                    <div className="flex_img-box">
                        <img src={img1} alt="" className="flex_img"/>
                    </div>
                </div>
            </div>
        )
    }
}
