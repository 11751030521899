import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import PlanInFirstStep from '../../../Components/LessonAndTestComponents/PlanInFirstStep';
import Lecturer from '../../../Components/LessonAndTestComponents/Lecturer';

import img1 from "../../../image/Lecturers/seregina.png";
import img2 from "../../../image/Lecturers/seregina-icon.png";

export default class step1 extends React.Component {

    planData = [
        `Видеолекция: Бронхиальная астма у кошек`,
        `Тест`,
    ]

    render() {
        return (
            <div className="step1">
                <Lecturer
                    img={img1}
                    icon={img2}
                    surname={'Сережина'}
                    name={'Людмила Абрамовна'}
                    text={'Ветеринарный врач клиники экспериментальной терапии ФГБУ НМИЦ онкологии им. Н.Н.Блохина, ведущий терапевт, руководитель отделения терапии клиники "Биоконтроль", г. Москва.'}
                />

                <hr className="string"/>

                <div className="head">План урока</div>

                <TextBlock
                    text={`В этом уроке вы узнаете:`}
                />

                <PlanInFirstStep
                    planData={this.planData}
                    type={'violet'}
                />

            </div>
        )
    }
}
