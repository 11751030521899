import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import PlanInFirstStep from '../../../Components/LessonAndTestComponents/PlanInFirstStep';
import Creator from "../../../Components/LessonAndTestComponents/Creator";
import img1 from "../../../image/lessons/lesson6/step1-1.svg";
import UsedMaterials from "../../../Components/LessonAndTestComponents/UsedMaterials";

export default class step1 extends React.Component {

    planData = [
        `Шкала оценивания эмоций кошки`,
        `Характеристика эмоций кошки`,
        `Дружелюбное и конфликтное поведение кошек`,
        `Тест`,
    ]

    render() {
        return (
            <div className="step1">
                <Creator
                    img={img1}
                    name={'Национальной ассоциацией медицины кошек'}
                />
                <hr className="string"/>
                <div className="text_top">
                    Кошки используют различные позы для передачи своих эмоций. В этом уроке приведены некоторые типичные позы, которые вы можете наблюдать у кошки. Наблюдая за кошкой, постарайтесь составить представление о ее обычном поведении, когда она одна и находится в контакте с другими животными, в том числе с людьми.
                </div>
                <div className="head">План урока</div>

                <TextBlock
                    text={`В этом уроке мы рассмотрим следующие темы:`}
                />

                <PlanInFirstStep
                    planData={this.planData}
                    type={'violet'}
                />
                <UsedMaterials
                    head={'Использованные материалы:'}
                    text={'Kessler MR, Turner DC. Stress and adaptation of cats (felis silvestris catus) housed singly, in pairs and in groups in boarding catteries. Animal Welfare 1997;6:243-254, Beaver BV. Feline Behavior: A guide for veterinarians. St. Louis: Elsevier Science, 2003:349, and UFAW Animal Welfare Research Report No. 8; An ethogram for behavioural studies of domestic cats, 1995.'}
                />
            </div>
        )
    }
}
