import React from 'react'
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";

export default class step2 extends React.Component {

    render() {
        return (
            <div className="step2">
                <TextBlock
                    text={`Чтобы получить знак «Здесь комфортно кошкам», клиника должна отвечать требованиям как по квалификации персонала, так и по наличию необходимого оборудования.`}
                />
            </div>
        )
    }
}