import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock'

import img1 from '../../../image/lessons/lesson34/step2-1.png'
import img2 from '../../../image/lessons/lesson34/step2-2.png'

export default class step2 extends React.Component {

    render() {
        return (
            <div className="step2">
                <div className="flex">
                    <div className="flex_el">
                        <TextBlock
                            text={`В этой части урока Вас ждет знакомство с нашим научно-исследовательским институтом Waltham, его основными исследованиями и открытиями.
                                
                                После ознакомления с научными фактами о научно-исследовательском институте Waltham Вас будет ждать небольшое задание на внимательность.`}
                        />
                    </div>
                    <div className="flex_el">
                        <div className="flex_el_box-img">
                            <img src={img1} alt="" className="flex_el_img"/>
                        </div>
                    </div>
                </div>
                <img src={img2} alt="" className="imgCenter"/>
            </div>
        )
    }
}
