import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from "../../../image/lessons/lesson8/step21-1.jpg";

export default class step21 extends React.Component {

    render() {
        return (
            <div className="step21">
                <div className="head">
                    Транспортировка кошачьей переноски в машине
                </div>
                <TextBlock
                    text={`Потренируйтесь класть переноску на сиденье автомобиля и вытаскивать ее из машины: сначала без кошки, а затем с кошкой внутри.
                    
                    Во время транспортировки закрепите переноску.
                    
                    Размещение мягкого полотенца в кошачьей переноске может предотвратить визуальное возбуждение кошки.`}
                />
                <img src={img1} alt="" className="imgCenter"/>
                <div className="center-text">
                    Кошачья переноска закреплена на сиденье ремнем безопасности.
                </div>
            </div>
        )
    }
}