import React from 'react'

import ResumeNew from "../../../Components/LessonAndTestComponents/ResumeNew";

export default class step11 extends React.Component {

    state = {
        resumeData: [
            `Ветеринарные врачи смогут обеспечить более эффективный уход, который облегчит процесс лечения и выздоровления их «кошачьих» пациентов`,
            `Владельцы смогут высоко оценить и принять к сведению разработанный подход, который подчеркивает важность безопасности и защищенности для кошки`,
            `Ветеринарные врачи заработают лояльность владельцев, и их штат пополнится продуктивными и счастливыми сотрудниками, ставящими безопасность животного во главу угла`,
        ]
    }

    render() {
        return (
            <div className="step9">
                <ResumeNew
                    text={`Ветеринарные клиники, которые заботятся о своих «кошачьих» пациентах, учитывают их особенности и потребности, смогут успешно зарекомендовать себя в сфере предоставления ветеринарных услуг и оставить довольными своих клиентов:`}
                    resumeData={this.state.resumeData}
                    text2={`<b>Данные рекомендации носят всеобъемлющий характер и могут с первого взгляда показаться чрезмерными и обременительными. Тем не менее, даже небольшие улучшения и постепенный прогресс в области ухода за кошками могут незамедлительно оказать благоприятный эффект для всей ветеринарии и положить начало созданию культуры квалифицированного и сострадательного отношения к уходу за кошками.</b>`}
                />
            </div>
        )
    }
}
