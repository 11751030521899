import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from "../../../image/lessons/lesson33/step22-1.png";
import img1xs from "../../../image/lessons/lesson33/step22-1xs.png";
import img2 from "../../../image/lessons/lesson33/step22-2.png";


export default class step22 extends React.Component {

    render() {
        return (
            <div className="step22">
                <TextBlock
                    text={`Согласно исследованиям, <b>86% владельцев</b> кошек регулярно угощают их чем-либо. Но в основном это еда со стола, которая не только не полезна для кошек, но и может быть <b>небезопасна.</b>`}
                />
                <picture>
                    <source srcSet={img1xs} media="(max-width: 767px)"/>
                    <img src={img1} alt="" className="imgCenter"/>
                </picture>
                {/* <div className="discover">
                    <TextBlock
                        text={`Однако сырое мясо и сырая рыба, которыми владельцы угощают своих питомцев могут содержать в себе бактерии или паразитов, а еда со стола как правило <b>чрезмерно калорийна для кошек.</b>`}
                    />
                    <div className="discover_flex">
                        <img src={img2} alt="" className="discover_flex_img"/>
                        <div className="discover_flex_el">
                            <TextBlock
                                text={`Сколько ккал содержится в одной порции еды со стола и в одной подушечке <b>WHISKAS® DUO TREATS?</b>`}
                            />
                            <a href="https://cat-friendly.ru/" className="btn btn_violet" target="_blank" rel="noopener noreferrer">
                                Нажмите и узнайте
                            </a>
                        </div>
                    </div>
                </div> */}
            </div>
        )
    }
}
