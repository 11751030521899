import React from 'react'
import VideoBlock from "../../../Components/LessonAndTestComponents/VideoBlock";
import Lecturer from "../../../Components/LessonAndTestComponents/Lecturer";
import img1 from "../../../image/Lecturers/kadochnikova.png";
import img2 from "../../../image/Lecturers/kadochnikova-icon.png";
import video1 from "../../../image/lessons/lesson44/step2-1.mp4";

export default class step2 extends React.Component {

    render() {
        return (
            <div className="step5">
                Шаг 5
            </div>
        )
    }
}

