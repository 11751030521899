import React from 'react'
import OnClickBlock from "../../../Components/LessonAndTestComponents/OnClickBlock";
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";
import InfoBlock from "../../../Components/LessonAndTestComponents/InfoBlock";

import img1 from "../../../image/lessons/lesson33/step15-1.png";
import img2 from "../../../image/lessons/lesson33/step15-2.png";
import img3 from "../../../image/lessons/lesson33/step15-3.png";
import img4 from "../../../image/lessons/lesson33/step15-4.png";

export default class step15 extends React.Component {

    state = {
        flexData: [
            'Поддержка долгой и здоровой жизни',
            'Поддержка жизненной энергии',
            'Поддержка аппетита'
        ],
        flexHidden: false,
    }

    hideElement = () => {
        this.setState({flexHidden: !this.state.flexHidden})
    }

    showNextBtn = () => {
        this.props.showNextBtn()
    }

    render() {
        const {flexData} = this.state
        return (
            <div className="step15">
                <InfoBlock
                    text={`Нажмите на ключевые потребности кошек старше 7 лет в питании и узнайте больше о каждой из них, а также Вы узнаете решения, которые предлагают рационы WHISKAS®.`}
                />
                <div className="header">
                    <div className="header_text">
                        Ключевые потребности кошек старше 7 лет в питании
                    </div>
                    <img src={img1} alt="" className="header_img"/>
                </div>
                <OnClickBlock
                    flexData={flexData}
                    hideElement={this.hideElement}
                    showNextBtn={this.showNextBtn}
                >
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`<b>Поддержка долгой и здоровой жизни</b>
                           
                            У кошки после 7 лет уже могут появляться <b>признаки старения</b>, хотя владельцы нередко не воспринимают таких кошек как пожилых.
                            
                            <b>Решение WHISKAS®</b>

                            Повышение качества и продолжительности жизни <b>за счет оптимального для стареющих кошек баланса белков и жиров.</b>`}
                        />
                        <img className="imgDiv_wrap_img" src={img2} alt=""/>
                    </div>
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`<b>Поддержка жизненной энергии</b>
                           
                            После 7 лет у кошки снижается общая активность и способность к усвоению пищи.
                            
                            <b>Решение WHISKAS®</b>

                            Отборные ингредиенты и специальный витаминно-минеральный комплекс, поддерживают жизненную энергию кошек.`}
                        />
                        <img src={img3} alt=""/>
                    </div>
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`<b>Поддержка аппетита</b>
                           
                            У кошек старше 7 лет нередко пропадает интерес к пище. Они отказываются от привычной еды, либо едят неохотно и с трудом.`}
                        />
                        <div className="imgDiv_wrap_flex">
                            <div className="imgDiv_wrap_flex_el">
                                <TextBlock
                                    text={`<b>Решение WHISKAS®</b>`}
                                />
                                <ul className="list">
                                    <li className="list_item">Высокая вкусовая привлекательность;</li>
                                    <li className="list_item">Разнообразие текстур и вкусов.</li>
                                </ul>
                            </div>
                            <div className="imgDiv_wrap_flex_el">
                                <img className="imgDiv_wrap_flex_img" src={img4} alt=""/>
                            </div>
                        </div>
                    </div>
                </OnClickBlock>
            </div>
        )
    }
}
