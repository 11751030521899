import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import PopupInStep from "../../../Components/LessonAndTestComponents/PopupInStep";

import img1 from "../../../image/lessons/lesson7/step21-1.png";
import img2 from "../../../image/lessons/lesson7/step21-2.png";
import img3 from "../../../image/lessons/lesson7/step21-3.png";
import img4 from "../../../image/lessons/lesson7/step21-4.png";
import img5 from "../../../image/lessons/lesson7/step21-5.png";

export default class step21 extends React.Component {

    state = {
        popupActive: 0,
        showPopup: false,
        points: [],
    }

    showPopup = (popupActive) => {
        const {points, showPopup} = this.state;
        if (points.indexOf(popupActive) === -1) {
            points.push(popupActive)
        }

        if (!showPopup) {
            this.setState({
                popupActive: popupActive,
                showPopup: true,
                points: points,
            })
        } else if (popupActive >= 4) {
            this.setState({showPopup: false, popupActive: 0})
        } else {
            this.setState({
                popupActive: popupActive +1,
                showPopup: true,
                points: points,
            })
        }

        if (points.length === 4) {
            this.props.showNextBtn();
        }
    }

    closePopup = () => {
        this.setState({showPopup: false})
    }

    popupData = {
        1: {
            img: img1,
            head: `Мышка`,
            text: `Первой игрушкой для кошки может стать пушистая мышь, которая издает шум и движется.`,
        },
        2: {
            img: img2,
            head: `Палочка`,
            text: `Кошки любят палочки, веревки или шнурки с болтающейся на конце игрушкой. Они заставляют их чувствовать, что они используют свою естественную быстроту и ловкость, чтобы поймать добычу. Важно убедиться, что кошка не может откусить или отгрызть кусочек игрушки, которым она может подавиться.`,
        },
        3: {
            img: img3,
            head: `Мячики и шарики`,
            text: `Шарики или мячики позволяют вашей кошке не только преследовать их, но и догнать, как если бы кошка догнала свою добычу. Кошки также любят игрушки, которые они могут поймать, поднять, затем подбросить в воздух и ловить.
            
            Вы можете наполнить шар или мячик изнутри едой или вкусными лакомствами, чтобы побудить кошку к игре.`,
        },
        4: {
            img: img4,
            head: `Простые вещи`,
            text: `Кошки также любят играть с простыми вещами, например, со скомканными бумажными шариками, пластиковыми крышками, бумажными полотенцами и т. д.`,
        },
        /*5: {
            img: img5,
            // head: `Простые вещи`,
            text: `Предпочтения у кошек разные, поэтому необходимо предложить кошке несколько игрушек на выбор. Можно использовать игрушки в разных последовательностях, чтобы старые казались новыми и захватывающими.

            Иногда кошка может полюбить игрушку настолько сильно, что начнет повсюду носить ее за собой и спать рядом с ней.

            Из-за охотничьего инстинкта кошки любят визуальную стимуляцию. Если нет подходящего окна для наблюдения за происходящим на улице, можно установить видеозапись со звуками природы и других животных. Кошки особенно любят наблюдать за птицами, рыбками, грызунами и насекомыми.`,
        },*/
    }

    render() {
        const {showPopup, popupActive} = this.state
        return (
            <div className="step21">
                <TextBlock
                    text={`У домашних кошек сохраняется охотничий инстинкт, поэтому они любят игрушки, которые издают звуки, раскачиваются из стороны в сторону или вибрируют. Это напоминает им о движущейся добыче и побуждает их к соответствующему взаимодействию.
                    
                    Существует множество различных видов игрушек:`}
                />
                <InfoBlock
                    text={`Нажмите на активные элементы, чтобы узнать больше о видах и типах игрушек для кошек.`}
                />
                <div className="flex">
                    <div className="flex_el" onClick={() => {this.showPopup(1)}}>
                        <div className="flex_el_img-box">
                            <img src={this.popupData[1].img} alt="" className="flex_el_img"/>
                        </div>
                    </div>
                    <div className="flex_el" onClick={() => {this.showPopup(2)}}>
                        <div className="flex_el_img-box">
                            <img src={this.popupData[2].img} alt="" className="flex_el_img"/>
                        </div>
                    </div>
                    <div className="flex_el" onClick={() => {this.showPopup(3)}}>
                        <div className="flex_el_img-box">
                            <img src={this.popupData[3].img} alt="" className="flex_el_img"/>
                        </div>
                    </div>
                    <div className="flex_el" onClick={() => {this.showPopup(4)}}>
                        <div className="flex_el_img-box">
                            <img src={this.popupData[4].img} alt="" className="flex_el_img"/>
                        </div>
                    </div>
                    {/*<div className="flex_el" onClick={() => {this.showPopup(5)}}>*/}
                    {/*    <div className="flex_el_img-box">*/}
                    {/*        <img src={this.popupData[5].img} alt="" className="flex_el_img"/>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
                <TextBlock
                    text={`Предпочтения у кошек разные, поэтому необходимо предложить кошке несколько игрушек на выбор. Можно использовать игрушки в разных последовательностях, чтобы старые казались новыми и захватывающими.
            
                    Иногда кошка может полюбить игрушку настолько сильно, что начнет повсюду носить ее за собой и спать рядом с ней.
                    
                    Из-за охотничьего инстинкта кошки любят визуальную стимуляцию. Если нет подходящего окна для наблюдения за происходящим на улице, можно установить видеозапись со звуками природы и других животных. Кошки особенно любят наблюдать за птицами, рыбками, грызунами и насекомыми.`}
                />
                {showPopup &&
                <PopupInStep
                    closePopup={this.closePopup}
                    imgHead={this.popupData[popupActive].img}
                    head={this.popupData[popupActive].head}
                    changePoint={() => {this.showPopup(popupActive)}}
                >
                    <TextBlock
                        text={this.popupData[popupActive].text}
                    />
                </PopupInStep>
                }
            </div>
        )
    }
}
