import React from 'react'
// import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';


export default class step27 extends React.Component {

    render() {
        return (
            <div className="step13">
                <div className="text-top">
                    <ul>
                        <li>
                            Главное не торопиться. Разрешите кошкам вступать в визуальный контакт, но вводите его постепенно, оставив в дверном проеме лишь маленькую щелочку. Приоткройте дверь, чтобы кошки могли смотреть друг на друга, но не могли зайти внутрь. Обычно кошки сначала шипят друг на друга, но если они становятся агрессивными или крайне враждебными, закройте дверь и оставьте их одних на несколько часов. Приоткройте дверь снова, когда кошки расслабятся и станут менее возбужденными.
                        </li>
                        <li>
                            Когда кошки будут смотреть друг на друга спокойно, откройте дверь пошире. После того, как кошки смогут смотреть друг на друга без шипения или проявления признаков тревоги и возбуждения, открыйте дверь и позвольте им встретиться. Если возникнут какие-либо проблемы, поместите новую кошку в ее комнату и закройте дверь. Постепенно увеличивайте интервал, в течение которого дверь открыта, пока кошки не почувствуют себя комфортно рядом друг с другом. Держите комнату открытой для новой кошки, чтобы в случае угрозы или враждебности со стороны другой кошки у неё было безопасное место для отступления, если она почувствует угрозу или враждебность со стороны другой кошки.
                        </li>

                    </ul>
                </div>
            </div>
        )
    }
}
