import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import PlanInFirstStep from '../../../Components/LessonAndTestComponents/PlanInFirstStep';
import Lecturer from '../../../Components/LessonAndTestComponents/Lecturer';

import img1 from '../../../image/Lecturers/vorontsov.png'
import img2 from '../../../image/Lecturers/vorontsov-icon.png'

export default class step1 extends React.Component {

    planData = [
        `Видеолекция: Идиопатический пио и хилоторакс у кошек`,
        `Тест`,
    ]

    render() {
        return (
            <div className="step1">
                <Lecturer
                    img={img1}
                    icon={img2}
                    surname={'Воронцов'}
                    name={'Александр Алексеевич'}
                    text={'Ветеринарный врач-хирург, кандидат ветеринарных наук. Главный врач "Ветеринарного центра хирургии и онкологии доктора Воронцова", г. Москва'}
                />

                <hr className="string"/>

                <div className="head">План урока</div>

                <TextBlock
                    text={`В этом уроке вы узнаете:`}
                />

                <PlanInFirstStep
                    planData={this.planData}
                    type={'violet'}
                />

            </div>
        )
    }
}
