import React, {useState} from 'react'
import './style.sass'
import {Link} from "react-router-dom";
// import Partners from "../../Components/LeftSideBar/Partners";

export default function SiteFooter(props) {
    const [infoBlock, setInfoBlock] = useState(false)

    const rout = window.location.href
    const start = rout.indexOf('/startPage')
    return (
        <footer className={'footer ' + props.className} style={(props.style && props.style)}>
            {/*<Partners className="partners_phone"/>*/}
            <div className="footer__box">
                <div className="footer__rights">
                    © 2020 Марс или аффилированные лица. Все права защищены.<br/>
                    {/** С любовью к кошкам*/}
                </div>
                <div className="footer__policy">
                    <div className="footer__policy__box">
                        <div className="footer__item">
                            <a href="https://www.mars.com/privacy-policy-russia" className="footer__link" target="_blank" rel="noopener noreferrer">Положение о конфиденциальности</a>
                        </div>
                        <div className="footer__item">
                            <a href="https://www.mars.com/legal-russia" className="footer__link" target="_blank" rel="noopener noreferrer">Правила использования сайта</a>
                        </div>
                        <div className="footer__item">
                            <div className={"footer__info-block " + (infoBlock && 'active')}>ООО "МАРС" ИНН: 5045016560 </div>
                            <div className="footer__link" onClick={() => {setInfoBlock(!infoBlock)}}>Рекламодатель</div>
                        </div>
                    </div>
                    <div className="footer__policy__box">
                        <div className="footer__item">
                            <a href="https://www.mars.com/global/policies/note-to-parents/np-russian" className="footer__link" target="_blank" rel="noopener noreferrer">Уведомление для родителей</a>
                        </div>
                        <div className="footer__item">
                            <a href="https://www.mars.com/cookies-russia" className="footer__link" target="_blank" rel="noopener noreferrer">Использование cookies</a>
                        </div>
                    </div>
                    <div className="footer__policy__box">
                        <div className="footer__item">
                            <a href="https://www.mars.com/accessibility-russian" className="footer__link" target="_blank" rel="noopener noreferrer">Доступность</a>
                        </div>
                        <div className="footer__item">
                            <Link
                                className='footer__link'
                                to={'/coociesform'}
                            >
                                Связаться с нами
                            </Link>
                        </div>
                        <button id="ot-sdk-btn" className="ot-sdk-show-settings">Настройки файлов cookie</button>
                    </div>
                </div>
            </div>
        </footer>
    )
}
