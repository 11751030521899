import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import ListNumeral from "../../../Components/LessonAndTestComponents/ListNumeral";

import img1 from "../../../image/lessons/lesson8/step33-1.jpg";
import img2 from "../../../image/lessons/lesson8/step33-2.jpg";


export default class step33 extends React.Component {

    data = [
        // `Госпитализируйте кошку, чтобы провести дополнительные процедуры.`,
        // `Используйте искусственное расслабление с помощью феромонов.`,
        // `Перенесите осмотр на другое время, когда у вас появится более эффективная стратегия.`,
        `Проявите гибкость с каждым конкретным животным.`,
        `Положите под кошку полотенце или подстилку из переноски.`,
        `Осмотрите кошку, пока она сидит на коленях.`,
        `Старайтесь поддерживать кошку руками.`,
        `Чтобы успокоить кошку произведите массирующие движения.`,
        `Заверните кошку в полотенце или накройте голову кошки одеялом.`,
        `Выполняйте процедуры в комнате для осмотра.`,
        `Если присутствие владельца усугубляет состояние кошки, вежливо попросите владельца выйти.`,
    ]

    render() {
        return (
            <div className="step33">
                <div className="head">
                    Проведение обследования и обращение с кошкой во время процедуры
                </div>
                {/* <TextBlock
                    text={`Проявите гибкость с каждым конкретным животным.
                    Попробуйте следующие приемы:
                    Положите под кошку полотенце или подстилку из переноски.
                    Осмотрите кошку, пока она сидит на коленях. Старайтесь поддерживать кошку руками.
                    Чтобы успокоить кошку произведите массирующие движения.
                    
                    Заверните кошку в полотенце или накройте голову кошки одеялом.
                    Выполняйте процедуры в комнате для осмотра.
                    Если присутствие владельца усугубляет состояние кошки, вежливо попросите владельца выйти.`}
                /> */}
                <ListNumeral
                    data={this.data}
                />
                <div className="flex">
                    <div className="flex_el">
                        <img src={img1} alt="" className="flex_el_img"/>
                        <TextBlock
                            text={`Массаж или поглаживание верхней части головы может помочь кошке расслабиться во время выполнения таких процедур, как измерение артериального давления.`}
                        />
                    </div>
                    <div className="flex_el">
                        <img src={img2} alt="" className="flex_el_img"/>
                        <TextBlock
                            text={`Кошки могут чувствовать себя более защищенными, слегка завернувшись в полотенце. Если вы аккуратно завернете кошку в полотенце, это может оказать расслабляющий эффект и придать кошке чувство контроля над происходящим.`}
                        />
                    </div>
                </div>
            </div>
        )
    }
}
