import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import PlanInFirstStep from '../../../Components/LessonAndTestComponents/PlanInFirstStep';
import Lecturer from "../../../Components/LessonAndTestComponents/Lecturer";

import img1 from "../../../image/Lecturers/kadochnikova.png";
import img2 from "../../../image/Lecturers/kadochnikova-icon.png";

export default class step1 extends React.Component {

    planData = [
        `Видеолекция: 10 вопросов про кошек к врачу репродуктологу`,
        'Тест'
    ]

    render() {
        return (
            <div className="step1">
                <Lecturer
                    img={img1}
                    icon={img2}
                    surname={'Кадочникова'}
                    name={'Елена Андреевна'}
                    text={'Ветеринарный врач - репродуктолог. Директор ветеринарного центра «Колибри», г. Долгопрудный'}
                />
                <hr className="string"/>
                <div className="head">План урока</div>

                <TextBlock
                    text={`В этом уроке Вас ждёт:`}
                />

                <PlanInFirstStep
                    planData={this.planData}
                    type={'violet'}
                />

            </div>
        )
    }
}
