import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import Popup from "../../../Components/Popup";

import img1 from '../../../image/lessons/lesson7/step15-1.png'
import img2 from '../../../image/lessons/lesson7/step15-2.png'
import img3 from '../../../image/lessons/lesson7/step15-3.png'
import img4 from '../../../image/lessons/lesson7/step15-4.png'

export default class step15 extends React.Component {

    state = {
        windowWidth: window.innerWidth,
        pointActive: 0,
        showPopup: false,
        points: [],
    };

    //Отлов события ресайз
    handlerResize = () => {
        this.setState({windowWidth: window.innerWidth})
    }

    componentDidMount() {
        window.addEventListener('resize', this.handlerResize)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handlerResize);
    }

    showPopup = (pointActive) => {
        const {points, showPopup, windowWidth} = this.state;
        if (points.indexOf(pointActive) === -1) {
            points.push(pointActive)
        }

        if (windowWidth < 768) {
            if (!showPopup) {
                this.setState({
                    pointActive: pointActive,
                    showPopup: true,
                })
            } else if (pointActive >= 4) {
                this.setState({showPopup: false, pointActive: 0})
            } else {
                this.setState({
                    pointActive: pointActive +1,
                    showPopup: true,
                })
            }
        } else {
            this.setState({points: points, pointActive: pointActive})
        }
        if (points.length === 4) {
            this.props.showNextBtn();
        }
    }

    closePopup = () => {
        this.setState({showPopup: false})
    }

    popupData = {
        0: {
            text: '',
        },
        1: {
            img: img1,
            text: `Возьмите несколько бумажных полотенец и промокните ими ковер. Накройте это место полотенцем или стопкой бумажных полотенец.`,
        },
        2: {
            img: img2,
            text: `Когда полотенца впитаются, нанесите чистящее средство для ковров или используйте несколько капель моющего средства для посуды, смешанного с водой. Не смывайте в течение часа или двух. Не стоит чистить ковер щеткой.`,
        },
        3: {
            img: img3,
            text: `Промойте область ковра и аккуратно промокните ее. Смочите губку водой и снова промойте область. Замочите область содой и оставьте на десять минут.`,
        },
        4: {
            text: `Промокните соду и положите на участок ковра свежие бумажные полотенца или любое другое свежее полотенце. Положите сверху на полотенца что-нибудь тяжелое и оставьте на ночь. Утром опрыскайте область с помощью ферментативного очистителя.
            
            Никогда не используйте на ковре аммиак или продукты на его основе. Запах может привлечь кошку и побудить ее мочиться в этой области.
            
            Держите кошку подальше от этого места. Если запах еще не выветрился, и кошка продолжает использовать эту область несмотря на очистку, очистите её ещё раз.`,
        },
    }

    render() {
        const {showPopup, pointActive, points} = this.state
        return (
            <div className="step15">
                <div className="head">
                    Ковер
                </div>
                <InfoBlock
                    text={`Нажмите на активные элементы, чтобы узнать больше о правилах уборки.`}
                />
                <div className="box">
                    <div className="box_img">
                        {Object.keys(this.popupData).slice(1, 7).map(item => {
                            return (
                                <div
                                    className={'box_point ' + (points.indexOf(Number(item)) >= 0 && 'active ') + (pointActive === Number(item) && 'heigher')}
                                    onClick={() => {this.showPopup(Number(item))}}
                                >
                                    <div className="box_point_inner"/>
                                </div>
                            )
                        })}
                        {Object.keys(this.popupData).slice(1, 7).map(item => {
                            return (
                                <div
                                    key={item}
                                    className={'block red ' + (pointActive === Number(item) && 'active')}
                                >
                                    <TextBlock
                                        text={this.popupData[item].text}
                                    />
                                    <img src={this.popupData[item].img} className="imgCenter" alt=""/>
                                </div>
                            )
                        })}
                        <img src={img4} className="imgCenter" alt=""/>
                    </div>
                </div>
                {showPopup &&
                <Popup
                    closePopup={this.closePopup}
                >
                    <div className={'info'}>
                        <img src={this.popupData[pointActive].img} alt="" className="info_img"/>
                        <TextBlock
                            text={this.popupData[pointActive].text}
                        />
                    </div>
                    <button
                        className="btn btn_violet btn_center"
                        onClick={() => {this.showPopup(pointActive)}}
                    >
                        Продолжить
                    </button>
                </Popup>
                }
            </div>
        )
    }
}
