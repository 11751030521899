import React from 'react'
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";

import img1 from '../../../image/lessons/lesson7/step54-1.png'


export default class step54 extends React.Component{

    render() {
        return (
            <div className="step54">
                <TextBlock
                    text={`Приучение к переноске с возможностью свободного доступа – это альтернативный способ обеспечить убежище для кошки.
                    
                    Для кошек переноска должна быть достаточно просторной. 
                    
                    Поместить кошачью переноску необходимо в тихое, безопасное и привлекательное для кошки место в доме, желательно на возвышенности.
                    
                    Важно не спешить с кошками, которые уже ассоциируют переноску или клетку со страшными переживаниями, например, поездкой в машине или визитом к ветеринарному врачу.`}
                />
                <img src={img1} alt="" className="imgCenter"/>
            </div>
        )
    }
}
