import React from 'react'
import './style.sass'

export default class Creator extends React.Component {

    // Пример использования lesson 6 step1
    render() {
        return (
            <div className="Creator">
                <div className="Creator_info">
                    <div className="Creator_info_img-block">
                        <img src={this.props.img} alt="" className="Lecturer_info_img"/>
                    </div>
                    <div className="Creator_info_text">
                        <div className="Creator_info_status">
                            {this.props.status ? this.props.status : 'Подготовлено'}
                        </div>
                        <div className="Creator_info_fio">
                            <div className="Creator_info_name">
                                {this.props.name}
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="Lecturer_description"
                    dangerouslySetInnerHTML={{ __html: this.props.text }}
                />
            </div>
        )
    }
}
