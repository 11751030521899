import React from 'react'
import './style.sass'
import video1 from '../../image/Cat_vet_delivery_v11.mp4'

export default props => {
    const scrollHeandler = () => {
        let newLesson = document.getElementById(props.lessonId)
        console.log(newLesson)
        newLesson.scrollIntoView({block: "center", behavior: "smooth"});
    }

    return (
        <div className="new__lesson--container">
            <div className="new__lesson">
                <div className="new__lesson--left-side">
                    <img className="new__lesson--image" src={props.imageSrc} alt={props.lessonName}/>
                </div>
                <div className="new__lesson--right-side">
                    <h4 className="new__lesson--header">{props.lessonName}</h4>
                    <p className="new__lesson--text">{props.text}</p>
                    <div className="new__lesson--buttons">
                        <a href={video1} download className="new__lesson--link">Скачать видео</a>
                        <button type="button" className="new__lesson--button" onClick={props.onClick}>Смотреть видео</button>
                    </div>
                </div>
            </div>
        </div>
    )
}