import React from 'react'
import VideoBlock from "../../../Components/LessonAndTestComponents/VideoBlock";
import Lecturer from "../../../Components/LessonAndTestComponents/Lecturer";
import img1 from "../../../image/Lecturers/spirina.png";
import img2 from "../../../image/Lecturers/spirina-icon.png";

export default class step2 extends React.Component {

    render() {
        return (
            <div className="step2">
                <Lecturer
                    img={img1}
                    icon={img2}
                    surname={'Спирина'}
                    name={'Анна Сергеевна'}
                    text={'Ветеринарный врач, кандидат ветеринарных наук, президент Национального ветеринарного стоматологического союза, руководитель отделения «Стоматология и челюстно-лицевая хирургия» ветеринарных клиник «Денталвет», старший преподаватель Учебного Центра «Денталвет», резидент Европейской высшей школы ветеринарной стоматологии (EVDC).'}
                />

                <hr className="string"/>
                <VideoBlock
                    src={'https://www.youtube.com/embed/_n_y25Ao0W4'}
                    title={'https://www.youtube.com/watch?v=_n_y25Ao0W4&feature=youtu.be'}
                />
            </div>
        )
    }
}
