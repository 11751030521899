import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import PlanInFirstStep from '../../../Components/LessonAndTestComponents/PlanInFirstStep';
import Creator from "../../../Components/LessonAndTestComponents/Creator";

import img1 from "../../../image/Lecturers/namk-mars.png";

export default class step1 extends React.Component {

    planData = [
        `Физиология питания кошек`,
        `Питание кошек на разных стадиях жизни`,
        `Питание во время беременности и лактации`,
        `Питание котят в период роста`,
        `Питание взрослой и пожилой кошки`,
        `Практические рекомендации по кормлению кошек`,
        `Расчет суточной нормы кормления`,
        `Правильный выбор лакомств`,
        `Поддержка оптимального веса`,
        `Тест`,
    ]

    render() {
        return (
            <div className="step1">
                <Creator
                    img={img1}
                    name={'Национальной ассоциацией медицины кошек и отделом научной коммуникации компании «МАРС»'}
                    text={'Использованы материалы научно-исследовательского института Waltham. <a class="text_link" href="https://www.waltham.com" target="_blank" rel="noopener noreferrer">https://www.waltham.com</a>'}
                />
                <hr className="string"/>
                <div className="head_first_step">План урока</div>

                <TextBlock
                    text={`В этом уроке вы узнаете:`}
                />

                <PlanInFirstStep
                    planData={this.planData}
                    type={'violet'}
                />
            </div>
        )
    }
}
