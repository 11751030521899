import React from 'react'
// import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson10/step12-1.png'
import img2 from '../../../image/lessons/lesson10/step12-2.png'

export default class step12 extends React.Component {

    render() {
        return (
            <div className="step12">
                <div className="flex">
                    <div className="flex_el">
                        <img src={img1} alt="" className="flex_img"/>
                        <div className="flex_text">
                            Для венепункции хорошо подходит медиальная подкожная вена тазовой конечности (V. saphena medialis).
                        </div>
                    </div>
                    <div className="flex_el">
                        <img src={img2} alt="" className="flex_img"/>
                        <div className="flex_text">
                            Для кошек лучше применять иглы и внутривенные канюли небольшого диаметра (на фото внутривенные канюли 22G, 24G, 26G), а для сбора проб крови пробирки малого объема.
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
