import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import OnClickTest from "../../../Components/LessonAndTestComponents/OnClickTest";

import img1 from "../../../image/lessons/lesson7/step35-1.png";


export default class step35 extends React.Component {

    state = {
        answersData:
            [
                `Да`,
                `Нет`,
            ]
    }

    showNextBtn = () => {
        this.props.showNextBtn()
    }

    render() {
        return (
            <div className="step35">
                <TextBlock
                    text={`Определите, иллюстрируют ли следующие картинки конфликт между кошками:`}
                />
                <img src={img1} alt="" className="imgCenter"/>
                <OnClickTest
                    ansewrsData={this.state.answersData}
                    type={'block'}
                    rightAnswers={[2]}
                    showNextBtn={this.showNextBtn}
                    quantity={50}
                    correctText={`Данная картинка иллюстрирует дружелюбное поведение кошек. Кошки переворачиваются на спину и выставляют свои животы, когда хотят играть. Плюс самки могут демонстрировать такое поведение во время спаривания.`}
                />
            </div>
        )
    }
}
