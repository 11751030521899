import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import InfoBlock from "../../../Components/LessonAndTestComponents/InfoBlock";

import img1 from "../../../image/lessons/lesson2/step10-1.png";


export default class step10 extends React.Component {

    render() {
        return (
            <div className="step10">
                <TextBlock
                    text={`Кошка обладает великолепным слухом. Она слышит намного лучше по сравнению не только с человеком, но и с собакой.
                    
                    Слух помогает кошке охотиться на мелких грызунов, ведь эта хищница никогда не стремится преследовать добычу на большие расстояния. Вместо этого она, прислушиваясь к малейшим шорохам и движениям, выслеживает жертву, находящуюся неподалёку, и ждёт подходящего момента, чтобы наброситься на неё.`}
                />
                <InfoBlock
                    text={`Внимательно изучите строение уха кошки.`}
                />
                <img src={img1} alt="" className="imgCenter"/>
            </div>
        )
    }
}
