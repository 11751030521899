import React from 'react'
import VideoBlock from "../../../Components/LessonAndTestComponents/VideoBlock";
import Lecturer from "../../../Components/LessonAndTestComponents/Lecturer";

import img1 from '../../../image/Lecturers/mendosa-kuznecova.png'
import img2 from '../../../image/Lecturers/mendosa-kuznecova-icon.png'

export default class step2 extends React.Component {

    render() {
        return (
            <div className="step2">
                <Lecturer
                    img={img1}
                    icon={img2}
                    surname={'Мендоса-Кузнецова'}
                    name={'Екатерина Сергеевна'}
                    text={'Ветеринарный врач, дипломант Европейского Колледжа (коллегии) Ветеринарной Дерматологии. Дерматолог и преподаватель (assistant professor) ветеринарной школы Тафтского университета штата Массачусетс, США.'}
                />

                <hr className="string"/>

                <VideoBlock
                    src={'https://www.youtube.com/embed/wGgrEFYkmGg'}
                    title={'https://www.youtube.com/watch?v=wGgrEFYkmGg&feature=youtu.be'}
                />
            </div>
        )
    }
}
