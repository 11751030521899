import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson11/step17-1.png'

export default class step17 extends React.Component {

    render() {
        return (
            <div className="step17">
                <TextBlock
                    text={`Кошка начинает считаться пожилой в возрасте примерно 7 лет, когда она достигает последней трети предпологаемой продолжительности жизни. Цель ухода за пожилой кошкой — предотвратить или замедлить изменения, появляющиеся в процессе старения.`}
                />
                <div className="flex">
                    <div className="flex_el">
                        <TextBlock
                            text={`В этом возрасте у кошек могут развиться болезни, такие как:`}
                        />
                        <ul className="list">
                            <li className="list_item"><b>почечная недостаточность;</b></li>
                            <li className="list_item"><b>гипертиреоз;</b></li>
                            <li className="list_item"><b>болезни зубов.</b></li>
                        </ul>
                        <TextBlock
                            text={`Для предотвращения этих заболеваний необходимо следить за здоровьем кошки.`}
                        />
                    </div>
                    <img src={img1} alt="" className="flex_img"/>
                </div>
            </div>
        )
    }
}
