import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import PopupInStep from "../../../Components/LessonAndTestComponents/PopupInStep";

import img1 from "../../../image/lessons/lesson13/step5-1.png";
import img2 from "../../../image/lessons/lesson13/step5-2.png";
import img3 from "../../../image/lessons/lesson13/step5-3.png";
import img4 from "../../../image/lessons/lesson13/step5-4.png";
import img5 from "../../../image/lessons/lesson13/step5-5.png";
import img6 from "../../../image/lessons/lesson13/step5-6.png";
import img7 from "../../../image/lessons/lesson13/step5-7.png";
export default class step5 extends React.Component {

    state = {
        popupActive: 0,
        showPopup: false,
        points: [],
    }

    showPopup = (popupActive) => {
        const {points, showPopup} = this.state;
        if (points.indexOf(popupActive) === -1) {
            points.push(popupActive)
        }

        if (!showPopup) {
            this.setState({
                popupActive: popupActive,
                showPopup: true,
                points: points,
            })
        } else if (popupActive >= 2) {
            this.setState({showPopup: false, popupActive: 0})
        } else {
            this.setState({
                popupActive: popupActive +1,
                showPopup: true,
                points: points,
            })
        }

        if (points.length === 2) {
            this.props.showNextBtn();
        }
    }

    closePopup = () => {
        this.setState({showPopup: false})
    }

    popupData = {
        1: {
            img: img6,
            head: ``,
            text: `Необходимо создать <span style="color:#4147DA;">убежище*</span> со всеми необходимыми кошке ресурсами: мисками для еды и воды, насестом (желательно рядом с окном, чтобы кошка могла выглянуть наружу или смотреть в окно), местом для сна, местом, где она могла бы спрятаться (например, коричневые бумажные пакеты или картонные коробки), когтеточкой и игрушками. Это поможет кошке привыкнуть к вам и опыту пребывания в помещении. Предоставленное кошке убежище на некоторое время станет для нее настоящим домом. Если в доме нет других домашних животных, можно оставить дверь в убежище открытой. Пусть кошка отважится выйти из убежища, когда будет полностью к этому готова.   
            <span style="font-size: 13px; margin-top:15px">* Убежище – это то место, куда кошка может направиться, когда ей необходима тишина, и нужно провести время в одиночестве. Кошка должна быть в состоянии беспрепятственно приходить и уходить из своего убежища. Когда кошка находится в своем убежище, другим членам семьи, включая людей и домашних животных, не следует беспокоить ее.</span>`,
        },
        2: {
            img: img7,
            head: ``,
            text: `Следует убедиться, что кошку навещают часто или радио или телевизор остаются включенными, пока кошка находится одна в комнате. Это может помочь приглушить нежелательные звуки, которые кошка слышит, но не может идентифицировать. 
   <br/>   <br/>   Радио или телевизор также будут служить источником человеческого голоса для  кошки, которые она будет ассоциировать с безопасностью. 
`,
        },
    }

    render() {
        const {showPopup, popupActive} = this.state
        return (
            <div className="step5">
                <InfoBlock
                    text={`Нажмите на активные элементы, чтобы узнать больше о видах и типах игрушек для кошек.`}
                />
                <div className="content">
                <div className="flex">
                    <div className="flex_el" onClick={() => {this.showPopup(1)}}>
                        <div className="flex_el_img-box">
                            <img src={this.popupData[1].img} alt="" className="flex_el_img"/>
                        </div>
                    </div>
                    <div className="flex_el" onClick={() => {this.showPopup(2)}}>
                        <div className="flex_el_img-box">
                            <img src={this.popupData[2].img} alt="" className="flex_el_img"/>
                        </div>
                    </div>
                </div>
                <div className="imgBlock">
                    <div><img src={img3} alt=""/></div>
                    <div><img src={img1} alt=""/></div>
                    <div><img src={img2} alt=""/></div>
                    <div><img src={img4} alt=""/></div>
                    <div><img src={img5} alt=""/></div>
                </div>
                </div>

                {showPopup &&
                <PopupInStep
                    closePopup={this.closePopup}
                    imgHead={this.popupData[popupActive].img}
                    head={this.popupData[popupActive].head}
                    changePoint={() => {this.showPopup(popupActive)}}
                >
                    <TextBlock
                        text={this.popupData[popupActive].text}
                    />
                </PopupInStep>
                }
            </div>
        )
    }
}
