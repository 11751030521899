import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

export default class step3 extends React.Component {

    render() {
        return (
            <div className="step3">
                <div className="head">
                    Научная основа
                </div>
                <TextBlock
                    text={`Наука использует научные доказательства и эмпирические данные, в то время как искусство исцеления опирается на клинический опыт, наблюдение, обратную связь с животным или клиентом и способность интерпретировать психологический настрой животного, находящегося на ветеринарном лечении.
                    
                    Поведенческая реакция кошачьего «пациента» на лечение занимает центральное место в опыте ухода за больной кошкой.
                    
                    <b>Чтобы обеспечить оптимальную поддержку здоровой, больной, травмированной или выздоравливающей кошке, опыт ухода за больными кошками так же важен, как и ветеринарная наука.</b>`}
                />
            </div>
        )
    }
}
