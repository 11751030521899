import React from 'react'
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";

import img1 from '../../../image/lessons/lesson31/step18-1.png'

export default class step18 extends React.Component {


    render() {
        return (
            <div className="step18">
                <div className="head">
                    Классификация по классу
                </div>
                <TextBlock
                    text={`Третий критерий, который отличает корма для домашних животных — <b>их специализация,</b> то есть наличие внутри ассортимента разнообразных рецептур для различных групп животных.
                    
                    <b>Чем выше класс корма – тем выше специализация под конкретную группу животных,</b> например:`}
                />
                <div className="flex">
                    {/*<div className="flex_el">*/}
                    {/*    <ul>*/}
                    {/*        <li>корм супер-премиум класса имеет ещё большую специализацию, он учитывает возраст кошки, её образ жизни, особенности пищеварения, а также может учитывать породные и другие особенности;</li>*/}
                    {/*        <li>корм премиум класса Whiskas предлагает корм с учётом возраста животного и его образа жизни (корм для котят, взрослых кошек от 1 года до 7 лет, для кошек старше 7 лет и для стерилизованных кошек);</li>*/}
                    {/*        <li>корм эконом класса kitekat — это корм для взрослых кошек без учёта образа жизни животного и других особенностей.</li>*/}
                    {/*    </ul>*/}
                    {/*</div>*/}
                    <div className="flex_el">
                        <img className="flex_img" src={img1} alt=""/>
                    </div>
                </div>
            </div>
        )
    }
}
