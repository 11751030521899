import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock'

import img1 from '../../../image/lessons/lesson32/step2-1.png'
import img2 from '../../../image/lessons/lesson32/step2-2.png'
import img3 from '../../../image/lessons/lesson32/step2-3.png'
import img4 from '../../../image/lessons/lesson32/step2-4.png'

export default class step2 extends React.Component {

    state = {
    }

    render() {
        return (
            <div className="step2">
                <TextBlock
                    text={`Создание кормов делится на несколько этапов. Каждый этап имеет важное значение в производственном процессе.`}
                />
                <div className="flex">
                    <div className="flex_el">
                        <div className="flex_wrp">
                            <img src={img1} alt=""/>
                            <p className="flex_text">
                                научная разработка продукта
                            </p>
                        </div>
                    </div>
                    <div className="flex_el arrow">
                        <div className="flex_el_arrow-box">
                            <svg width="26" height="16" viewBox="0 0 26 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 7C0.447715 7 0 7.44772 0 8C0 8.55228 0.447715 9 1 9V7ZM25.7071 8.70711C26.0976 8.31658 26.0976 7.68342 25.7071 7.29289L19.3431 0.928932C18.9526 0.538408 18.3195 0.538408 17.9289 0.928932C17.5384 1.31946 17.5384 1.95262 17.9289 2.34315L23.5858 8L17.9289 13.6569C17.5384 14.0474 17.5384 14.6805 17.9289 15.0711C18.3195 15.4616 18.9526 15.4616 19.3431 15.0711L25.7071 8.70711ZM1 9H25V7H1V9Z" fill="#A7268E"/>
                            </svg>
                        </div>
                    </div>
                    <div className="flex_el">
                        <div className="flex_wrp">
                            <img src={img2} alt=""/>
                            <p className="flex_text">
                                отбор и&nbsp;анализ сырья
                            </p>
                        </div>
                    </div>
                    <div className="flex_el arrow">
                        <div className="flex_el_arrow-box">
                            <svg width="26" height="16" viewBox="0 0 26 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 7C0.447715 7 0 7.44772 0 8C0 8.55228 0.447715 9 1 9V7ZM25.7071 8.70711C26.0976 8.31658 26.0976 7.68342 25.7071 7.29289L19.3431 0.928932C18.9526 0.538408 18.3195 0.538408 17.9289 0.928932C17.5384 1.31946 17.5384 1.95262 17.9289 2.34315L23.5858 8L17.9289 13.6569C17.5384 14.0474 17.5384 14.6805 17.9289 15.0711C18.3195 15.4616 18.9526 15.4616 19.3431 15.0711L25.7071 8.70711ZM1 9H25V7H1V9Z" fill="#A7268E"/>
                            </svg>
                        </div>
                    </div>
                    <div className="flex_el">
                        <div className="flex_wrp">
                            <img src={img3} alt=""/>
                            <p className="flex_text">
                                производство продукта
                            </p>
                        </div>
                    </div>
                    <div className="flex_el arrow">
                        <div className="flex_el_arrow-box">
                            <svg width="26" height="16" viewBox="0 0 26 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 7C0.447715 7 0 7.44772 0 8C0 8.55228 0.447715 9 1 9V7ZM25.7071 8.70711C26.0976 8.31658 26.0976 7.68342 25.7071 7.29289L19.3431 0.928932C18.9526 0.538408 18.3195 0.538408 17.9289 0.928932C17.5384 1.31946 17.5384 1.95262 17.9289 2.34315L23.5858 8L17.9289 13.6569C17.5384 14.0474 17.5384 14.6805 17.9289 15.0711C18.3195 15.4616 18.9526 15.4616 19.3431 15.0711L25.7071 8.70711ZM1 9H25V7H1V9Z" fill="#A7268E"/>
                            </svg>
                        </div>
                    </div>
                    <div className="flex_el">
                        <div className="flex_wrp">
                            <img src={img4} alt=""/>
                            <p className="flex_text">
                                хранение готовых кормов
                            </p>
                        </div>
                    </div>
                </div>
                <TextBlock
                    text={`Далее вы рассмотрите каждый из этапов подробнее, чтобы лучше понимать процесс создания рационов для домашних животных.`}
                />
            </div>
        )
    }
}
