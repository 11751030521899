import React from 'react'
// import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

export default class step13 extends React.Component {

    render() {
        return (
            <div className="step13">
                <div className="head">
                    Рекомендации владельцу
                </div>
                <ol className="numeral">
                    <li className="numeral_item">
                        <span className="numeral_item_number">2.</span><span>Отрепетируйте процедуру обследования в ветеринарной клинике и научитесь делать необходимые процедуры дома, используя для этого похвалу в спокойном тоне и сочетая эти процедуры с положительным восприятием с помощью предложения лакомств или другого поощрения.</span>
                        <p className={'numeral_item_paragraph'}>(например, предложите кошке игры или кошачью мяту, сделайте массаж шеи или подбородка).
                            Аккуратно выполняйте следующие процедуры в соответствии с указаниями ветеринарного врача:
                        </p>
                    </li>
                </ol>
            </div>
        )
    }
}
