import React from 'react'
// import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from "../../../image/lessons/lesson13/step3-1.png";

export default class step8 extends React.Component {

    render() {
        return (
            <div className="step8">
                <div className="text-top">
                    <p>
                        Большинство кошек предпочитают охотиться на конкретных существ. Например, некоторые кошки любят погоняться за мышами, в то время как другие кошки предпочитают ловить птиц или насекомых. Если правильно определить «любимую добычу» кошки, можно купить или самим сделать игрушки,
                        с которыми кошка будет охотно играть и не будет их игнорировать.
                    </p>
                    <p>
                        Можно определить предпочтения кошки в любимой добыче, уделяя пристальное внимание тому,
                        как она реагирует на игрушки с определенными качествами:
                    </p>

                    <ul>
                        <li>
                            Купить несколько игрушек или предметов, которые по своему виду напоминают птиц, например, перья, мягкие игрушки, покрытые перьями, предметы, подвешенные в воздухе, а также игрушки, издающие чирикающие звуки.
                        </li>
                        <li>
                            Начать играть с кошкой, подбрасывая игрушки вверх, заставляя их летать по воздуху.
                        </li>
                        <li>
                            Кошка внимательно следит за объектом?
                        </li>
                        <li>
                            Следует ли она за всеми объектами или игнорирует большинство из них? Это очень важная информация для определения игрушек
                        </li>
                    </ul>
                    <p>
                        Затем следует убрать эти предметы из поля зрения кошки и попробовать поиграть с ней предметами, напоминающими мышей, например, с кусочком меха, мягкими игрушками, пушистыми мышками и мячиками.
                    </p>
                    <p>
                        Можно попробовать одновременно поиграть с кошкой в игрушки в форме птиц и мышей, чередуя их примерно каждые 20 секунд.
                    </p>
                    <p>
                        Следует быть изобретательными и мыслить, как кошка. Когда идет поиск потенциальной игрушки для кошки, нужно представить, на какое животное такая игрушка может походить. В конце концов, ведь бумажный шарик – это не просто бумажный шарик... Это полноценная добыча!
                    </p>


                </div>

            </div>
        )
    }
}
