import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson7/step30-1.png'


export default class step30 extends React.Component{

    render() {
        return (
            <div className="step9">
                <TextBlock
                    text={`Дополнительные признаки скрытых конфликтов`}
                />
                <ul className="list">
                    <li className="list_item">
                        Скрытый конфликт может включать в себя нечистоплотное поведение, когда кошки метят свою территорию или же мочатся в неположенных местах (например при идиопатическом цистите).
                    </li>
                    <li className="list_item">
                        Маркировать мочой в результате конфликта между кошками может как «агрессор», так и «жертва», окружая себя своим же запахом, пытаясь создать для привычную и безопасную среду.
                    </li>
                    <li className="list_item">
                        Иногда напуганные самцы даже брызгают кровавой мочой!
                    </li>
                    <li className="list_item">
                        Кошки могут мочиться на приподнятые поверхности или открытые места, если их доступу к лотку угрожает другая кошка или лоток расположен таким образом, что быстрый побег от другой кошки будет невозможен.
                    </li>
                    {/*<li className="list_item">*/}
                    {/*    Если пугливая кошка попытается использовать этот ресурс позднее, то одного присутствия уверенной кошки может быть вполне достаточно, чтобы заставить пугливую кошку убежать.*/}
                    {/*</li>*/}
                    <li className="list_item">
                        Пугливые кошки часто проводят больше времени вдали от семьи, в тех помещениях дома, которые другие члены семьи не используют, либо играют и проводят время со своими владельцами только тогда, когда более уверенная кошка находится в другом месте.
                    </li>
                </ul>
                <img src={img1} alt="" className="imgCenter"/>
            </div>
        )
    }
}
