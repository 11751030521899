import React from 'react'

export default class step11 extends React.Component {

    render() {
        return (
            <div className="step10">
                <ul className="list">
                    <li className="list_item">
                        <b>Место проведения</b>. Выберите тихое место для проведения диагностических или лечебных процедур.
                    </li>
                    <li className="list_item">
                        <b>Правильное позиционирование животного</b>. Поместите кошку на мягкую, нескользящую поверхность и в наиболее естественное положение для планируемой процедуры. Например, необходимо выполнять пункцию мочевого пузыря (цистоцентез) в том положении, которое наиболее удобно для кошки. Например, для кошки это может быть положение стоя или на боку вместо лежачего положения с согнутыми лапами.
                    </li>
                    <li className="list_item">
                        <b>Кошачий лицевой феромон</b>. Использование синтетического лицевого феромона (FFP) в клетках, на медицинских кушетках или одеялах в процедурных кабинетах и кошачьих отделениях за 10-15 минут до процедуры оказывает успокаивающее действие.
                    </li>
                    <li className="list_item">
                        <b>Венепункция</b>. Яремные, головные или медиальные подкожные вены являются подходящим выбором для сбора крови. Медиальное размещение катетера подкожной вены является хорошим вариантом для коротких процедур и взятия крови. Позиционирование кошки для венепункции медиальной подкожной вены обычно оказывает успокаивающий эффект и, возможно, является наиболее комфортным для многих кошек. Бритье шерсти обычно не требуется, что делает процедуру более быстрой и устраняет этап, который может встревожить или расстроить кошку.
                    </li>
                    <li className="list_item">
                        <b>Установка внутривенного катетера</b>. Избегайте множественных венепункций, устанавливая внутривенный катетер для повторного забора проб крови, инфузионной терапии и/или внутривенного лечения, а также в случае необходимости экстренного доступа.
                    </li>
                </ul>
            </div>
        )
    }
}
