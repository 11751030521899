import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from "../../../image/lessons/lesson6/step21-1.png";


export default class step21 extends React.Component {

    render() {
        return (
            <div className="step21">
                <TextBlock
                    text={`Иногда кошки играют слишком агрессивно и могут поцарапать или даже укусить вас. Вы можете научить свою кошку подавлять такое неподобающее игровое поведение, покинув игровую зону, если видите, что ваша кошка проявляет признаки агрессии во время игры.`}
                />
                <img src={img1} alt="" className="imgCenter"/>
            </div>
        )
    }
}
