import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import PlanInFirstStep from '../../../Components/LessonAndTestComponents/PlanInFirstStep';
import Creator from "../../../Components/LessonAndTestComponents/Creator";

import img1 from "../../../image/Lecturers/namk.png";
import UsedMaterials from "../../../Components/LessonAndTestComponents/UsedMaterials";

export default class step1 extends React.Component {

    planData = [
        `Рекомендации по уходу за кошками в каждой конкретной зоне ветеринарной клиники`,
        `Рекомендации для ветеринарных врачей`,
        `Практические рекомендации по снижению стресса у кошек в ветеринарной клинике`,
        `Оборудование и принадлежности для снижения стресса у кошек`,
        `Кормление больной кошки`,
    ]

    render() {
        return (
            <div className="step1">
                <Creator
                    img={img1}
                    name={'Национальной ассоциацией медицины кошек'}
                />
                <hr className="string"/>
                <div className="head">План урока</div>

                <TextBlock
                    text={`В этом уроке вы получите практические рекомендации по снижению стресса у кошек во время проведения диагностики и лечения в различных зонах ветеринарной клиники.`}
                />

                <PlanInFirstStep
                    planData={this.planData}
                    type={'violet'}
                />
                <UsedMaterials
                    head={'Использованные материалы:'}
                    text={'AAFP and ISFM Feline-Friendly Nursing Care Guidelines//Journal of Feline Medicine and Surgery (2012) 14, 337–350'}
                />
            </div>
        )
    }
}
