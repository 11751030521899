import React from 'react'
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";

export default class step20 extends React.Component {

    render() {
        return (
            <div className="step20">
                {/*<TextBlock*/}
                {/*    text={`Следующее оборудование и принадлежности помогут снизить стресс у кошек, находящихся на ветеринарном лечении во время госпитализации:`}*/}
                {/*/>*/}
                <ul className="list">
                    <li className="list_item">
                        Кормите кошку ее обычным рационом во время госпитализации. Попросите владельца кошки принести ее любимые лакомства, если это уместно.
                    </li>
                    <li className="list_item">
                        При необходимости пропишите лечебную диету, когда кошка вернется домой, и к ней вернется ее нормальный аппетит.
                    </li>
                    <li className="list_item">
                        Плоские емкости для пищи, такие как маленькие бумажные тарелки и низкие поилки, будут способствовать улучшению процесса потребления пищи, сделав его более доступным для кошки.
                    </li>
                    <li className="list_item">
                        Разогрейте влажный корм до температуры тела кошки, поместив упаковку в резервуар с теплой водой.
                    </li>
                    <li className="list_item">
                        Не оставляйте пищу в клетках кошек, которые проявляют пищевое отвращение (слюнотечение, облизывание губ, игнорирование миски с едой, рвота).
                    </li>
                    <li className="list_item">
                        Пища всегда должна быть свежей, подаваться небольшими порциями и пополняться по мере необходимости.
                    </li>
                    <li className="list_item">
                        Стимуляторы аппетита могут быть полезны в отдельных случаях в течение короткого периода времени (2 дня) в сочетании с описанными выше методами.
                    </li>
                </ul>
            </div>
        )
    }
}
