import React from 'react'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import ArrowBack from '../../../Components/LessonAndTestComponents/ArrowBack';
import List from "../../../Components/LessonAndTestComponents/List";

import img1 from '../../../image/lessons/lesson32/step6-1.png';
import img3 from '../../../image/lessons/lesson32/step6-3.png';
import img4 from '../../../image/lessons/lesson32/step6-4.png';
import img5 from '../../../image/lessons/lesson32/step6-5.png';


export default class step6 extends React.Component {
    state = {
        flexActive: 0,
        flexs: [1],
        flexHidden: false,
    };

    changeFlex = flexActive => {
        const {flexs} = this.state
        if (flexs.indexOf(flexActive) === -1) {
            flexs.push(flexActive)
        }
        this.setState({flexActive, flexs, flexHidden: true})
        if (flexs.length === 3) {
            this.props.showNextBtn()
        }
    }

    closeImgDiv = () => {this.setState({flexHidden: false, flexActive: 0})}

    ListData = [
        'усвояемость продукта;',
        'вкусовая привлекательность;',
        'отдельно у кошек — способность продукта помогать профилактике мочекаменной болезни.'
    ]

    render() {
        const {flexActive, flexHidden} = this.state
        return (
            <div className="step6">
                <TextBlock
                    text={`В данной теме мы расскажем вам, как происходит процесс производства кормов на примере компании Mars. Первый этап — <b>это научная разработка кормов,</b> которая <b>проводится в несколько этапов.</b> Без них невозможно создание гарантированно полезного и безопасного продукта.`}
                    hidden={flexHidden === true ? ' hidden' : ''}
                />
                <InfoBlock
                    text={`Нажмите на кнопки, чтобы узнать подробнее об этапах.`}
                    hidden={flexHidden === true ? ' hidden' : ''}
                />

                <div className={'flex ' + (flexHidden === true && 'hidden')}>
                    <div className={'flex_el ' + (flexActive === 1 && 'active')} onClick={() => {
                        this.changeFlex(1)
                    }}>создание рецептуры на основании научных данных
                        <span className="flex_arrow">
                            <svg width="26" height="16" viewBox="0 0 26 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 7C0.447715 7 0 7.44772 0 8C0 8.55228 0.447715 9 1 9V7ZM25.7071 8.70711C26.0976 8.31658 26.0976 7.68342 25.7071 7.29289L19.3431 0.928932C18.9526 0.538408 18.3195 0.538408 17.9289 0.928932C17.5384 1.31946 17.5384 1.95262 17.9289 2.34315L23.5858 8L17.9289 13.6569C17.5384 14.0474 17.5384 14.6805 17.9289 15.0711C18.3195 15.4616 18.9526 15.4616 19.3431 15.0711L25.7071 8.70711ZM1 9H25V7H1V9Z" fill="#4147DA"/>
                            </svg>
                        </span>
                    </div>
                    <div className={'flex_el ' + (flexActive === 2 && 'active')} onClick={() => {
                        this.changeFlex(2)
                    }}>проверка рецептуры
                        <span className="flex_arrow">
                           <svg width="26" height="16" viewBox="0 0 26 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                               <path d="M1 7C0.447715 7 0 7.44772 0 8C0 8.55228 0.447715 9 1 9V7ZM25.7071 8.70711C26.0976 8.31658 26.0976 7.68342 25.7071 7.29289L19.3431 0.928932C18.9526 0.538408 18.3195 0.538408 17.9289 0.928932C17.5384 1.31946 17.5384 1.95262 17.9289 2.34315L23.5858 8L17.9289 13.6569C17.5384 14.0474 17.5384 14.6805 17.9289 15.0711C18.3195 15.4616 18.9526 15.4616 19.3431 15.0711L25.7071 8.70711ZM1 9H25V7H1V9Z" fill="#4147DA"/>
                           </svg>
                        </span>
                    </div>
                    <div className={'flex_el ' + (flexActive === 3 && 'active')} onClick={() => {
                        this.changeFlex(3)
                    }}>исследование предпочтений владельцев домашних животных
                    </div>
                </div>
                {flexActive !== 0 &&
                <div className={'imgDiv ' + (flexActive === 2 ? 'center' : flexActive === 3 ? 'right' : '')}>
                    <ArrowBack
                        returnMenu={this.closeImgDiv}
                    />
                    {flexActive === 1 &&
                    <div className="imgDiv_wrap">
                        <div className="imgPhone">
                            <img className="imgPhoneBg" src={img3} alt=""/>
                        </div>
                        <div className="imgDiv_flex">
                            <img src={img3} alt=""/>
                            <TextBlock
                                text={`На основании научных данных создаётся рецептура, учитывающая потребности животных, а также спецификацию нового корма.`}
                            />
                        </div>
                    </div>
                    }
                    {flexActive === 2 &&
                    <div className="imgDiv_wrap">
                        <div className="imgPhone">
                            <img className="imgPhoneBg" src={img4} alt=""/>
                        </div>
                        <div className="imgDiv_flex">
                            <div>
                                <TextBlock
                                    text={`После создания рецептуры производится <b>пробная</b> партия продукта.
                                
                                <b>До запуска</b> массового производства необходимо убедиться, что продукт <b>благотворно влияет на здоровье</b> животного.
                                
                                Для этого исследуются следующие характеристики:`}
                                />
                                <List
                                    ListData={this.ListData}
                                />
                            </div>
                            <img src={img4} alt=""/>
                        </div>
                    </div>
                    }
                    {flexActive === 3 &&
                    <div className="imgDiv_wrap">
                        <div className="imgPhone">
                            <img className="imgPhoneBg imgWidth" src={img5} alt=""/>
                        </div>
                        <div className="imgDiv_flex">
                            <TextBlock
                                text={`Чтобы продукт пользовался успехом на рынке, важно изучить ожидания владельцев домашних животных.
                                
                                Перед массовым запуском производства проводится большая работа по изучению мнения владельцев домашних животных. Оцениваются такие показатели <b>как привлекательность внешнего вида продукта, его запах, цвет, удобство использования упаковки, привлекательность её дизайна</b> и другие показатели.`}
                            />
                        </div>
                        <img className="img imgDesktop" src={img5} alt=""/>
                    </div>
                    }
                </div>
                }
                <img className={'img img_bottom ' +  (flexHidden === true ? ' hidden' : '')} src={img1} alt=""/>
            </div>
        )
    }
}
