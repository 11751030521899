import React from 'react'
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";

import img1 from '../../../image/lessons/lesson7/step52-1.png'


export default class step52 extends React.Component{

    render() {
        return (
            <div className="step52">
                <TextBlock
                    text={`Кошки любят следовать своим привычкам, поэтому при необходимости сменить корм, наполнитель, миски, лоток и т.д., перемены не должны быть внезапными.`}
                />
                <ul className="list">
                    <li className="list_item">
                        Можно предложить кошке новый корм (наполнитель) в обычной миске (контейнере), которые будут стоять рядом со старой миской (лотком).
                    </li>
                    <li className="list_item">
                        Можно насыпать новый корм в привычную для кошки миску и попробовать предложить его, когда кошка проголодается. Когда же она cъест немного новой пищи, предложить ей её старый корм.
                    </li>
                    <li className="list_item">
                        Когда кошка привыкнет к новому корму, постепенно уменьшайте порцию старого на ¼ миски до полного перехода на новый рацион.
                    </li>
                </ul>
                <img src={img1} alt="" className="imgCenter"/>
            </div>
        )
    }
}
