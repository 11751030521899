import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import PopupInStep from "../../../Components/LessonAndTestComponents/PopupInStep";

import img1 from "../../../image/lessons/lesson33/step11-1.png";
import img2 from "../../../image/lessons/lesson33/step11-2.png";
import img3 from "../../../image/lessons/lesson33/step16-1.png";
import img4 from "../../../image/lessons/lesson33/step16-2.png";

export default class step16 extends React.Component {

    state = {
        popupActive: 0,
        showPopup: false,
        points: [],
    }

    showPopup = (popupActive) => {
        const {points} = this.state;
        if (points.indexOf(popupActive) === -1) {
            points.push(popupActive)
        }

        this.setState({
            popupActive: popupActive,
            showPopup: true,
            points: points
        })

        if (points.length === 2) {
            this.props.showNextBtn();
        }
    }

    closePopup = () => {
        this.setState({showPopup: false})
    }

    render() {
        const {showPopup, popupActive} = this.state
        return (
            <div className="step16">
                <div className="head">
                    Ключевые потребности кошек старше 7 лет в питании
                </div>
                <TextBlock
                    text={`Теперь Вам предстоит знакомство с рационами WHISKAS® для кошек старше 7 лет, а также с индивидуальными особенностями каждого продукта.`}
                />
                <InfoBlock
                    text={`Нажмите на иконки с кормом и узнайте о них больше.`}
                />
                <div className="flex">
                    <div className="flex_el" onClick={() => {this.showPopup(1)}}>
                        <img src={img1} alt="" className="flex_el_img"/>
                        <p className="flex_el_name">
                            Сухой корм
                        </p>
                    </div>
                    <div className="flex_el" onClick={() => {this.showPopup(2)}}>
                        <img src={img2} alt="" className="flex_el_img"/>
                        <p className="flex_el_name">
                            Рагу
                        </p>
                    </div>
                </div>
                {showPopup &&
                <PopupInStep
                    closePopup={this.closePopup}
                    head={'Разнообразие в питании –\n' +
                    'репертуар вкусов и текстур'}
                >
                    <div className="box">
                        {popupActive === 1
                            ?
                            <div className="box_el">
                                <div className="box_el_head">
                                    Сухой полнорационный корм WHISKAS® для кошек старше 7-ми лет «Вкусные подушечки с нежным паштетом. Аппетитное ассорти с курицей и индейкой»
                                </div>
                                <div className="box_el_flex">
                                    <div className="box_el_flex_wrap">
                                        <div className="box_el_flex_head low">
                                            Cодержит все необходимое, чтобы еда Вашей кошки была не только
                                            вкусной, но и полезной:
                                        </div>
                                        <ul className="list">
                                            <li className="list_item">Витамин Е для поддержания иммунитета</li>
                                            <li className="list_item">Высокоусваиваемые ингредиенты для здорового пищеварения</li>
                                            <li className="list_item">Таурин для здоровья сердца</li>
                                            <li className="list_item">Жирные кислоты Омега-6 и цинк для здоровья кожи и шерсти</li>
                                            <li className="list_item">Источник глюкозамина для здоровья суставов</li>
                                        </ul>
                                    </div>
                                    <img src={img3} alt="" className="box_el_flex_img"/>
                                </div>
                            </div>
                            : popupActive === 2 ?
                                <div className="box_el">
                                    <div className="box_el_head">
                                        Рагу WHISKAS® для кошек старше 7 лет
                                    </div>
                                    <div className="box_el_flex">
                                        <div className="box_el_flex_wrap">
                                            <div className="box_el_flex_head">
                                                Cодержит:
                                            </div>
                                            <ul className="list">
                                                <li className="list_item">Оптимальный баланс белков и жиров для жизненной энергии;</li>
                                                <li className="list_item">Витамин Е для поддержания иммунитета;</li>
                                                <li className="list_item">Высокоусваиваемые ингредиенты для здорового пищеварения;</li>
                                                <li className="list_item">Источник глюкозамина для здоровья суставов;</li>
                                                <li className="list_item">Жирные кислоты Омега-6 и цинк для здоровья кожи и шерсти.</li>
                                            </ul>
                                        </div>
                                        <img src={img4} alt="" className="box_el_flex_img"/>
                                    </div>
                                </div>
                                :
                                null
                        }
                    </div>
                </PopupInStep>
                }
            </div>
        )
    }
}
