import React from 'react'

import img1 from '../../../image/lessons/lesson6/step6-1.png'

export default class step6 extends React.Component {


    render() {
        return (
            <div className="step6">
                <div className="head">
                    Зачем играть с кошкой?
                </div>
                <div className="flex">
                    <div className="flex_el">
                        Игры способствуют установлению и развитию связи с вашей кошкой.
                    </div>
                    <div className="flex_el">
                        Игры повышают качество жизни вашей кошки.
                    </div>
                    <div className="flex_el">
                        Игры способствуют улучшению состояния и самочувствия вашей кошки, снижению риска и частоты ожирения, а также предотвращают возможные проблемы со здоровьем в будущем.
                    </div>
                </div>
                <img src={img1} alt="" className="imgCenter"/>
            </div>
        )
    }
}
