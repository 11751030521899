import React from 'react'
// import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson7/step14-1.png'

export default class step14 extends React.Component {

    render() {
        return (
            <div className="step14">
                <ul className="list">
                    <li className="list_item">Кошки нуждаются в тишине и уединении при использовании своего лотка.</li>
                    <li className="list_item">Расположение и путь до кошачьего лотка должны быть легко доступными. Не стоит устанавливать кошачий лоток в узких местах или проходах, например, под раковинами, туалетным столиком или под низко расположенными столами.</li>
                    <li className="list_item">Подумайте, по какому пути кошка будет добираться до кошачьего лотка. Например, не стоит размещать кошачий лоток рядом со спальным местом собаки или позади дивана.</li>
                    <li className="list_item">В многоэтажных домах кошачьи лотки должны быть размещены на каждом этаже дома. Кошки не любят далеко ходить, чтобы добраться до ближайшего лотка.</li>
                </ul>
                <div className="info">
                    Золотое правило гласит: «один кошачий лоток на кошку, плюс один дополнительный».
                </div>
                <img src={img1} alt="" className="imgCenter"/>
            </div>
        )
    }
}
