import React from 'react'
import VideoBlock from "../../../Components/LessonAndTestComponents/VideoBlock";
import Lecturer from "../../../Components/LessonAndTestComponents/Lecturer";

import img1 from '../../../image/Lecturers/bokarev.png'
import img2 from '../../../image/Lecturers/bokarev-icon.png'

export default class step2 extends React.Component {

    render() {
        return (
            <div className="step2">
                <Lecturer
                    img={img1}
                    icon={img2}
                    surname={'Бокарёв'}
                    name={'Алексей Николаевич'}
                    text={'Ветеринарный врач-кардиолог клиник "Одинвет" и "Белый клык". Лектор Российского Кардиологического Ветеринарного Общества.'}
                />

                <hr className="string"/>

                <VideoBlock
                    src={'https://www.youtube.com/embed/2ahbp2RApmc'}
                    title={'https://www.youtube.com/watch?v=2ahbp2RApmc&feature=youtu.be'}
                />
            </div>
        )
    }
}
