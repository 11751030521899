import React from 'react'

export default class step6 extends React.Component {

    render() {
        return (
            <div className="step6">
                <ul className="list">
                    <li className="list_item">
                        Успешные результаты лечения больных кошек зависят от способности их владельцев обеспечить непрерывный уход
                    </li>
                    <li className="list_item">
                        При наличии соответствующего обучения клиента, руководства и постоянной поддержки со стороны ветеринарного врача кошки могут быть выписаны раньше, что в конечном итоге будет благоприятным исходом для всех
                    </li>
                    <li className="list_item">
                        Оптимальный домашний уход не является интуитивно понятным для большинства владельцев, поэтому обучение клиента является ключевым аспектом
                    </li>
                    <li className="list_item">
                        Проактивное последующее общение с владельцем подтвердит успешное лечение или выявит неожиданные проблемы
                    </li>
                    <li className="list_item">
                        Дайте инструкции и рекомендации по уходу в устной или письменной форме либо с использованием практических демонстраций и/или других мультимедийных ресурсов, когда это представляется возможным
                    </li>
                </ul>
            </div>
        )
    }
}
