import React from 'react'
// import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

export default class step19 extends React.Component {

    render() {
        return (
            <div className="step13">
                <div className="text-top">
                    <ul>
                        <li>
                            Когда идет ремонт в доме, кошка подвергается воздействию новой обстановки, а также множества новых звуков и даже запахов. Громкие электроинструменты, краски, химикаты и строительные материалы могут пугать кошку. За несколько дней до того, как  начнется ремонт, нужно подговить необходимое убежище для кошки. Оставить радиоприемник или телевизор включенными рядом с убежищем, чтобы заглушить нежелательные звуки строительных работ
                            и обеспечить успокаивающий «белый шум» для  кошки.
                        </li>
                        <li>
                            Когда закончатся ремонтные работы в доме, можно использовать аналог кошачьего лицевого феромона в том месте, где делали ремонт.
                        </li>
                        <li>
                            Каждая кошка приспосабливается к новой обстановке в своем темпе, поэтому главное терпение. Может пройти некоторое время, прежде чем  кошка привыкнет к новой обстановке после ремонта. Следует позволить кошке самой решить, когда для нее наступит этот момент.
                        </li>
                    </ul>
                </div>

            </div>
        )
    }
}
