import React from 'react'
import VideoBlock from "../../../Components/LessonAndTestComponents/VideoBlock";
import Lecturer from "../../../Components/LessonAndTestComponents/Lecturer";

import img1 from '../../../image/Lecturers/ilina.png'
import img2 from '../../../image/Lecturers/ilina-icon.png'

export default class step2 extends React.Component {

    render() {
        return (
            <div className="step2">
                <Lecturer
                    img={img1}
                    icon={img2}
                    surname={'Ильина'}
                    name={'Юлия Алексеевна '}
                    text={'Руководитель Центра репродукции и неонатологии Европейского ветеринарного центра (EVC), ведущий врач-репродуктолог, врач УЗ-диагностики EVC , г.Москва'}
                />

                <hr className="string"/>

                <VideoBlock
                    src={'https://www.youtube.com/embed/7SHUbE7X3uA'}
                    title={'https://www.youtube.com/watch?v=7SHUbE7X3uA&feature=youtu.be'}
                />
            </div>
        )
    }
}
