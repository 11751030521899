import React from 'react'
// import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from "../../../image/lessons/lesson13/step13-1.png";

export default class step13 extends React.Component {

    render() {
        return (
            <div className="step13">
                <div className="text-top">
                    <ul>
                        <li>
                            Кошкам, как и людям, необходимо время, чтобы привыкнуть к потере. Они проходят через те же стадии горя, что и люди. Кошка может испытывать потерю аппетита, стать тревожной или подавленной, вести себя необычно и издавать более громкие звуки, чем в обычной ситуации. Не нужно наказывать  кошку за изменения в ее поведении во время адаптации. Распорядок дня и убежище – главные помощники в этот период. Кроме того, увеличение количества игр, ласки и ухода по отношению к кошке поможет снизить уровень её тревоги.
                        </li>
                        <li>
                            Если покойный был владельцем кошки, в убежище кошки может быть помещен предмет одежды, принадлежащий этому человеку. В идеале новым владельцем должен стать тот человек, с кем кошка хорошо знакома. Если это невозможно и кошка нуждается в передержке или новом доме, необходимо следовать рекомендациям по адаптации кошки к новому дому и новым владельцам
                        </li>

                    </ul>
                </div>

                <div className="infoImage">
                    <img src={img1} alt=""/>
                </div>

            </div>
        )
    }
}
