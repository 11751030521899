import React from "react";
import Api from '../../Service/Api'
import VideoBlock from '../../Components/LessonAndTestComponents/VideoBlock';
import Buttons from '../../Components/Buttons';
import Popup from '../../Components/Popup';
import VideoSectionButton from '../../Components/VideoSectionButton'
import VideoSectionModal from '../../Components/VideoSectionModalToVideoFrame'
import Overlay from '../../Components/Overlay'
import "./style.sass";

export default class Video extends React.Component {

    state = {
        video: {},
        activeVideo: '0',
        activeTab: '1',
        overlay: false,
        mainVideoFrame: false,
    }

    Api = new Api()

    componentDidMount() {
        this.Api.getVideoLecture()
            .then((res) => {
                if (res !== undefined) {
                    this.setState({video: res})
                    // console.log(this.state.video)
                }
            })
        this.getNeededVideo()
    }

    getNeededVideo = () => {
        const needTab = window.location.pathname.slice(7, -2)
        const needVideo = window.location.pathname.slice(9)
        if(needVideo != ''){
            this.setState({activeVideo: needVideo})
        }
        if(needTab != ''){
            this.setState({activeTab: needTab})
        }
    }

    showAboutLector = () => {
        let modalToVideoFrame = document.querySelector('.Popup_video');
        modalToVideoFrame.querySelectorAll('.lecture').forEach(element => {
            element.classList.toggle('hide');
        });
        modalToVideoFrame.classList.toggle('active');
        this.setState({overlay: true});
        setTimeout(function(){
            document.querySelector('.Overlay').style.opacity = '75%';
        }, 50);
        document.body.style.overflowY = 'hidden';
        document.querySelector('.video-frame').scrollIntoView({
            behavior: 'smooth',
            block: 'start'
        });
    }

    showAboutLecture = () => {
        let modalToVideoFrame = document.querySelector('.Popup_video');
        modalToVideoFrame.querySelectorAll('.lectore').forEach(element => {
            element.classList.toggle('hide');
        });
        modalToVideoFrame.classList.toggle('active');
        this.setState({overlay: true});
        setTimeout(function(){
            document.querySelector('.Overlay').style.opacity = '75%';
        }, 50);
        document.body.style.overflowY = 'hidden';
        document.querySelector('.video-frame').scrollIntoView({
            behavior: 'smooth',
            block: 'start'
        });
    }

    closeModal = () => {
        let modalToVideoFrame = document.querySelector('.Popup_video');
        modalToVideoFrame.classList.toggle('active');
        setTimeout(function(){modalToVideoFrame.querySelectorAll('p').forEach(element => {
                element.classList.remove('hide');
        })}, 500);
        // document.querySelector('.Overlay').style.opacity = '0';
        // setTimeout(() => {
            this.setState({overlay: false});
        // }, 1000)
        document.body.style.overflowY = 'auto';
        document.querySelector('.simpleButton').scrollIntoView({
            behavior: 'smooth',
            block: 'start'
        });
    }

    startVideo = () => {
        let videoFrame = document.querySelector('.video-frame');
        videoFrame.querySelector('.video_frame-cover').style.opacity = '0'
        videoFrame.querySelector('.video_frame-button').style.opacity = '0'
        setTimeout(function (){
            videoFrame.querySelector('.video_video').style.zIndex = '10';
        }, 500);
        let videoLink = videoFrame.querySelector('.video_video').firstElementChild.getAttribute('src');
        videoFrame.querySelector('.video_video').firstElementChild.setAttribute('src', videoLink + '?autoplay=1');
    }

    videoCardHandler = (e) => {
        this.setState({mainVideoFrame: true});
        this.setState({activeVideo: e.target.closest('.video_card').getAttribute('id')});
        Object.entries(document.querySelectorAll('.video_card')).map(([key, value]) => {
            value.classList.remove('video_active')
        })
        e.target.closest('.video_card').classList.toggle('video_active')
        setTimeout((parent) => {
            let videoFrame = document.querySelector('.video-frame');
            videoFrame.querySelector('.video_frame-cover').style.opacity = '1'
            videoFrame.querySelector('.video_frame-button').style.opacity = '1'
            videoFrame.querySelector('.video_video').style.zIndex = '-1'
            document.querySelector('.simpleButton').scrollIntoView({
                behavior: 'smooth',
                block: 'start'
            });
            window.history.replaceState("", "", "/video/" + this.state.activeTab + '/' + this.state.activeVideo);
        }, 100)

    }

    tabHandler = (value) => {
        this.setState({mainVideoFrame: false, activeTab: value, activeVideo: 0});
        Object.entries(document.querySelectorAll('.video_card')).map(([key, value]) => {
            value.classList.remove('video_active')
        })
    }

    render() {
        return (
            <div className="video">
                {this.state.overlay ? <Overlay onClick={this.closeModal}/> : null}
                <div className="video_page">
                    <div className="video_page-header">
                        <div className="video_page-header-text">
                            <svg width="40" height="28" viewBox="0 0 40 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M12.2917 9.19568C12.2917 7.21843 14.4104 5.93242 16.2112 6.81668L24.1511 10.7155C26.1473 11.6957 26.1473 14.4933 24.1511 15.4735L16.2112 19.3723C14.4104 20.2566 12.2917 18.9706 12.2917 16.9933V9.19568ZM15.2795 8.64668C14.8639 8.44262 14.375 8.73939 14.375 9.19568V16.9933C14.375 17.4496 14.8639 17.7464 15.2795 17.5423L23.2194 13.6435C23.68 13.4173 23.68 12.7717 23.2194 12.5455L15.2795 8.64668Z" fill="#4147DA"/>
                                <path d="M29.9251 25.2458C30.575 24.6141 30.4674 23.4727 29.685 22.6964C28.9025 21.9201 27.7414 21.8029 27.0916 22.4346C26.4418 23.0663 26.5493 24.2078 27.3317 24.9841C28.1142 25.7604 29.2753 25.8776 29.9251 25.2458Z" fill="#4147DA"/>
                                <path d="M31.7842 21.8191C32.6489 21.527 33.0565 20.4529 32.6947 19.42C32.3328 18.3871 31.3386 17.7866 30.474 18.0787C29.6094 18.3708 29.2018 19.445 29.5636 20.4778C29.9254 21.5107 30.9196 22.1112 31.7842 21.8191Z" fill="#4147DA"/>
                                <path d="M39.3349 24.9851C40.1174 24.2088 40.2249 23.0674 39.5751 22.4356C38.9252 21.8039 37.7641 21.9211 36.9817 22.6974C36.1992 23.4737 36.0917 24.6151 36.7416 25.2469C37.3914 25.8786 38.5525 25.7614 39.3349 24.9851Z" fill="#4147DA"/>
                                <path d="M37.1026 20.4781C37.4644 19.4452 37.0568 18.3711 36.1922 18.079C35.3276 17.7869 34.3333 18.3874 33.9715 19.4203C33.6097 20.4532 34.0173 21.5273 34.882 21.8194C35.7466 22.1115 36.7408 21.511 37.1026 20.4781Z" fill="#4147DA"/>
                                <path d="M36.6949 26.2442C36.4055 25.4567 35.8378 25.3173 35.2733 24.3827C34.9237 23.8039 34.695 22.2302 33.333 22.224C31.9712 22.23 31.7424 23.8039 31.3928 24.3827C30.8283 25.3173 30.2606 25.4567 29.9712 26.2442C29.8123 26.6767 29.9466 27.0612 30.1281 27.3578C30.4415 27.8701 31.3299 28.1851 32.3234 27.8805C32.8127 27.7304 33.2693 27.7121 33.333 27.7103C33.3969 27.7121 33.8536 27.7304 34.3427 27.8805C35.3362 28.1851 36.2245 27.8701 36.538 27.3578C36.7195 27.0611 36.8538 26.6767 36.6949 26.2442Z" fill="#4147DA"/>
                                <path d="M13.3333 2.04601H23.3333C25.7013 2.04601 27.3519 2.0476 28.637 2.15071C29.8978 2.25188 30.6221 2.44047 31.1708 2.71501C32.3468 3.30349 33.3029 4.24249 33.9021 5.39743C34.1817 5.93626 34.3737 6.64763 34.4767 7.88581C34.5817 9.14787 34.5833 10.7689 34.5833 13.0945C34.5833 14.4851 34.5828 15.6238 34.5598 16.5846C35.1861 16.3341 35.8982 16.2724 36.6455 16.5026C36.6667 15.5264 36.6667 14.4039 36.6667 13.0945C36.6667 8.51097 36.6667 6.21922 35.7584 4.46856C34.9594 2.92864 33.6846 1.67664 32.1166 0.892006C30.334 0 28.0004 0 23.3333 0H13.3333C8.66623 0 6.33268 0 4.55008 0.892006C2.98206 1.67664 1.70722 2.92864 0.908279 4.46856C0 6.21922 0 8.51097 0 13.0945C0 17.6779 0 19.9697 0.908279 21.7204C1.70722 23.2603 2.98206 24.5123 4.55008 25.2969C6.33268 26.1889 8.66623 26.1889 13.3333 26.1889H23.3333C24.4008 26.1889 25.3463 26.1889 26.1909 26.1782C26.1762 26.1641 26.1616 26.1499 26.1472 26.1356C25.6215 25.614 25.2235 24.9009 25.0674 24.1407C24.5352 24.1428 23.9593 24.1429 23.3333 24.1429H13.3333C10.9654 24.1429 9.31475 24.1413 8.02967 24.0382C6.7689 23.937 6.04455 23.7484 5.49589 23.4739C4.31988 22.8854 3.36375 21.9464 2.76454 20.7915C2.48499 20.2527 2.29296 19.5413 2.18995 18.3031C2.08495 17.041 2.08333 15.42 2.08333 13.0945C2.08333 10.7689 2.08495 9.14787 2.18995 7.88581C2.29296 6.64763 2.48499 5.93626 2.76454 5.39743C3.36375 4.24249 4.31988 3.30349 5.49589 2.71501C6.04455 2.44047 6.7689 2.25188 8.02967 2.15071C9.31475 2.0476 10.9654 2.04601 13.3333 2.04601Z" fill="#4147DA"/>
                            </svg>
                            Видеолекции
                        </div>
                        <span></span>
                        <div className="video_page-header-description">
                            <p className="video_page-description">В данном разделе вы можете ознакомиться с видеолекциями, которые помогут вам ещё лучше понять кошек и найти к ним правильный подход.</p>
                        </div>
                    </div>

                    {this.state.mainVideoFrame
                        ?
                        <React.Fragment>
                            <div className="video_buttons">
                                <VideoSectionButton
                                    class={'simpleButton'}
                                    name={'О лекторе'}
                                    svg={<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5 23.7512H2.5C2.5 19.609 5.85786 16.2512 10 16.2512C14.1421 16.2512 17.5 19.609 17.5 23.7512H15C15 20.9897 12.7614 18.7512 10 18.7512C7.23858 18.7512 5 20.9897 5 23.7512ZM22.955 19.2049L21.1875 17.4374C22.8286 15.7965 23.7506 13.5707 23.7506 11.2499C23.7506 8.92912 22.8286 6.70339 21.1875 5.06242L22.955 3.29492C27.3476 7.68819 27.3476 14.8104 22.955 19.2037V19.2049ZM19.4187 15.6699L17.6512 13.8999C19.1135 12.4358 19.1135 10.064 17.6512 8.59992L19.4187 6.82867C21.8595 9.26944 21.8595 13.2267 19.4187 15.6674V15.6699ZM10 14.9999C7.23858 14.9999 5 12.7613 5 9.99992C5 7.2385 7.23858 4.99992 10 4.99992C12.7614 4.99992 15 7.2385 15 9.99992C15 11.326 14.4732 12.5978 13.5355 13.5355C12.5979 14.4731 11.3261 14.9999 10 14.9999ZM10 7.49992C8.63424 7.50131 7.52236 8.59848 7.50279 9.9641C7.48322 11.3297 8.56321 12.4583 9.92836 12.4988C11.2935 12.5393 12.4385 11.4768 12.5 10.1124V10.6124V9.99992C12.5 8.61921 11.3807 7.49992 10 7.49992Z" fill="white"/></svg>}
                                    onClick={this.showAboutLector}
                                />
                                <VideoSectionButton
                                    class={'simpleButton'}
                                    name={'О лекции'}
                                    svg={<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.9875 27.5C8.08557 27.4931 2.49541 21.8936 2.5 14.9917C2.50461 8.08974 8.10223 2.4977 15.0042 2.5C21.9061 2.5023 27.5 8.09806 27.5 15C27.4959 21.9067 21.8942 27.5028 14.9875 27.5ZM5 15.215C5.05915 20.7165 9.55139 25.1369 15.0532 25.1075C20.555 25.0778 24.9994 20.6094 24.9994 15.1075C24.9994 9.60563 20.555 5.13721 15.0532 5.10749C9.55139 5.07807 5.05915 9.49844 5 15V15.215ZM16.25 21.25H13.75V18.75H16.25V21.25ZM16.25 16.25H13.75V8.74999H16.25V16.25Z" fill="white"/></svg>}
                                    onClick={this.showAboutLecture}/>
                            </div>
                            <div className="video_wrapper">
                                {Object.keys(this.state.video).length !== 0
                                    ?
                                    <div className="video_window">
                                        <div className="video-frame">
                                            <img className="video_frame-cover"
                                                 src={this.state.video[this.state.activeTab].video_lecture[this.state.activeVideo].image}
                                                 alt={this.state.video[this.state.activeTab].video_lecture[this.state.activeVideo].token}
                                                 onClick={this.startVideo}/>
                                            <VideoBlock
                                                src={'https://www.youtube.com/embed/' + this.state.video[this.state.activeTab].video_lecture[this.state.activeVideo].token}
                                                title={'https://www.youtube.com/watch?v=' + this.state.video[this.state.activeTab].video_lecture[this.state.activeVideo].token}
                                                modify={'video_video'}
                                                id={this.state.video[this.state.activeTab].video_lecture[this.state.activeVideo].token}
                                            />
                                            <button className="video_frame-button" aria-label="Запустить видео"
                                                    onClick={this.startVideo}>
                                                <svg width="90" height="60" viewBox="0 0 90 60" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <rect className="video_button-shape" width="90" height="60"
                                                          rx="15"/>
                                                    <path className="video_button-i"
                                                          d="M37 18.75L57 30L37 41.25V18.75Z"/>
                                                </svg>
                                            </button>
                                            <VideoSectionModal
                                                lector={this.state.video[this.state.activeTab].video_lecture[this.state.activeVideo].lector}
                                                lectorProfile={this.state.video[this.state.activeTab].video_lecture[this.state.activeVideo].lector_profile}
                                                title={this.state.video[this.state.activeTab].video_lecture[this.state.activeVideo].title}
                                                description={this.state.video[this.state.activeTab].video_lecture[this.state.activeVideo].description}
                                                onClick={this.closeModal}
                                            />
                                        </div>
                                    </div>
                                    :
                                    <svg className="video_loading" width="252px" height="69px" viewBox="0 0 128 35">
                                        <g>
                                            <circle fill="#4147da" cx="17.5" cy="17.5" r="17.5"/>
                                            <animate attributeName="opacity" dur="2700ms" begin="0s"
                                                     repeatCount="indefinite" keyTimes="0;0.167;0.5;0.668;1"
                                                     values="0.3;1;1;0.3;0.3"/>
                                        </g>
                                        <g>
                                            <circle fill="#4147da" cx="110.5" cy="17.5" r="17.5"/>
                                            <animate attributeName="opacity" dur="2700ms" begin="0s"
                                                     repeatCount="indefinite" keyTimes="0;0.334;0.5;0.835;1"
                                                     values="0.3;0.3;1;1;0.3"/>
                                        </g>
                                        <g>
                                            <circle fill="#4147da" cx="64" cy="17.5" r="17.5"/>
                                            <animate attributeName="opacity" dur="2700ms" begin="0s"
                                                     repeatCount="indefinite" keyTimes="0;0.167;0.334;0.668;0.835;1"
                                                     values="0.3;0.3;1;1;0.3;0.3"/>
                                        </g>
                                    </svg>
                                }
                            </div>
                        </React.Fragment>
                        :
                        null
                    }

                    <h2 className="video_subtitle">Список видеолекций</h2>
                    <div className="video_buttons">
                        {Object.entries(this.state.video).map(([key, value]) =>{
                            return (
                                <button className={`video_button ${this.state.activeTab === key ? 'active' : ''}`} onClick={() => this.tabHandler(key)} key={key}>
                                    {value.attribute_topic.topic}
                                    {value.attribute_topic.is_new === 1 && <div className="new-video">новое</div>}
                                    {value.attribute_topic.is_new === 1 && <div className="triangle-video"></div>}
                                </button>
                            )
                        })}
                    </div>
                    {Object.keys(this.state.video).length !== 0
                        ?
                        <div className="video_gallery">
                            {this.state.video[this.state.activeTab].video_lecture.map((item, index) => {
                                return (
                                    <div className={"video_card"} id={index} onClick={this.videoCardHandler} key={index}>
                                        <img className="video_cover" src={item.image} alt={item.title} />
                                        {/*<VideoBlock src={'https://www.youtube.com/embed/' + item.url} title={'https://www.youtube.com/watch?v=' + item.url} modify={'video_video'}/>*/}
                                        <div>
                                            <h6 className="video_name">{item.title}</h6>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        :
                        <svg className="video_loading" width="252px" height="69px" viewBox="0 0 128 35">
                            <g>
                                <circle fill="#4147da" cx="17.5" cy="17.5" r="17.5"/>
                                <animate attributeName="opacity" dur="2700ms" begin="0s" repeatCount="indefinite" keyTimes="0;0.167;0.5;0.668;1" values="0.3;1;1;0.3;0.3"/>
                            </g>
                            <g>
                                <circle fill="#4147da" cx="110.5" cy="17.5" r="17.5"/>
                                <animate attributeName="opacity" dur="2700ms" begin="0s" repeatCount="indefinite" keyTimes="0;0.334;0.5;0.835;1" values="0.3;0.3;1;1;0.3"/>
                            </g>
                            <g>
                                <circle fill="#4147da" cx="64" cy="17.5" r="17.5"/>
                                <animate attributeName="opacity" dur="2700ms" begin="0s" repeatCount="indefinite" keyTimes="0;0.167;0.334;0.668;0.835;1" values="0.3;0.3;1;1;0.3;0.3"/>
                            </g>
                        </svg>
                    }
                </div>
            </div>
        )
    }
}
