import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock'

import img1 from '../../../image/lessons/lesson34/step12-1.png'

export default class step12 extends React.Component {

    render() {
        return (
            <div className="step12">
                <div className="flex">
                    <div className="flex_el">
                        <TextBlock
                            text={`Вы изучили функциональные особенности рационов PERFECT FIT™ для кошек.
                            
                            Теперь Вам предстоит выполнить финальное задание и пройти тест по этой теме.`}
                        />
                    </div>
                    <div className="flex_el">
                        <div className="flex_el_box-img">
                            <img src={img1} alt="" className="flex_el_img"/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
