import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import VideoBlock from "../../../Components/LessonAndTestComponents/VideoBlock";
import video1 from "../../../image/lessons/lesson43/step5-1.mp4";

export default class step5 extends React.Component {


    render() {
        return (
            <div className="step5">
                <hr className="string"/>
                <div className="head">Как появилась идея создания музыки для кошек</div>

                <TextBlock
                    text={`Посмотрите фильм о том, как создавалась музыка для кошек и как она каждый день помогает кошкам избавиться от стресса в ветеринарных клиниках и на приеме у специалистов.`}
                />

                <VideoBlock
                    src={video1}
                    title={''}
                />

            </div>
        )
    }
}
