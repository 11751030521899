import React from 'react'
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";
import Answer from './dnd/Answer';
import Questions from './dnd/Questions';

import img1 from "../../../image/lessons/lesson43/step2-1.png";
import img2 from "../../../image/lessons/lesson43/step2-2.png";

export default class step2 extends React.Component {

    state = {
        answers: [],
        questions: [],
        correct: '',
        answerCorrect: []
    }

    setAnswer = (answer_el, question_el) => {
        const {answers} = this.state

        if (answers.indexOf(question_el) === -1) {
            let answer = []
            answers[answer_el] = question_el
            answers.push(answer)
        }
        this.setState({answers})

        let questions = [];
        // eslint-disable-next-line
        answers.map(answer => {

            if (answer.length) {
                questions.push(answer)
            }

        });
        this.setState({questions})
    };

    checkAnswer = () => {
        const {answerCorrect, questions} = this.state
        console.log(answerCorrect)
        console.log(questions)
        // eslint-disable-next-line
        questions.map((answer, index) => {
            if (answer === String(index + 1)) {
                answerCorrect.push('correct')
            } else {
                answerCorrect.push('inCorrect')
            }
        })
        this.setState({
            answerCorrect: answerCorrect,
        })
        if (questions.length === 2 && answerCorrect.indexOf('inCorrect') === -1) {
            this.setState({correct: '1'})
        } else {
            this.setState({correct: '0'})
        }
        this.props.showNextBtn()
    };

    render() {
        const {answerCorrect, correct, questions} = this.state
        return (
            <div className="step2">
                <hr className="string"/>
                <div className="head">Что такое музыка для кошек</div>
                <TextBlock
                    text={`Это музыка, которая технически состоит из двух слоёв: низкие частоты для людей и набор высокочастотных звуков для кошек, основанный на принципе воспроизведения звуков и строения их мозга. И низкие, и высокие частоты звучат одновременно.`}
                />
                <div className="block">
                    <h4 className="block__header">Для кого какие частоты предназначены?</h4>
                    <div className="block__info answers">
                        <img className="block__info__img" src={img1} alt=""/>
                        <h4 className="block__info__title">Люди</h4>
                        <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'2'} text={'?'} count={'?'} answerCorrect={answerCorrect[0]}/>
                    </div>
                    <div className="block__info answers">
                        <img className="block__info__img" src={img2} alt=""/>
                        <h4 className="block__info__title">Кошки</h4>
                        <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'1'} text={'1'} count={'?'} answerCorrect={answerCorrect[1]}/>
                    </div>
                    <div className="questions">
                        <div className={'question_el ' + (questions.indexOf('1') >= 0 && 'hidden')}>
                            <Questions targetKey="1" question_el={'1'} label={'Высокие частоты'} text={'Высокие частоты'}/>
                        </div>
                        <div className={'question_el ' + (questions.indexOf('2') >= 0 && 'hidden')}>
                            <Questions targetKey="1" question_el={'2'} label={'Низкие частоты'} text={'Низкие частоты'}/>
                        </div>
                    </div>
                </div>
                {correct !== '' ?
                    <div className={'info ' + (correct === '0' && 'error')}>
                        <div className="info_head">
                            {correct === '1' ? 'все верно!' : 'К сожалению, ваш ответ не верен.'}
                        </div>
                        <div className="info_text">
                            {correct === '1' ? 'Вы правильно определили предназначение частот' : 'Вы не правильно определили предназначение частот'}
                        </div>
                    </div>
                    :
                    <button className="btn btn_violet" onClick={() => this.checkAnswer()}>
                        ПРОВЕРИТЬ
                    </button>
                }
            </div>
        )
    }
}