import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import PlanInFirstStep from '../../../Components/LessonAndTestComponents/PlanInFirstStep';
import Lecturer from '../../../Components/LessonAndTestComponents/Lecturer';

import img1 from '../../../image/Lecturers/spirina.png'
import img2 from '../../../image/Lecturers/spirina-icon.png'

export default class step1 extends React.Component {

    planData = [
        `Видеолекция: Стоматология кошек. От педиатрии до гериатрии. Просто о сложном.`,
        `Тест`,
    ]

    render() {
        return (
            <div className="step1">
                <Lecturer
                    img={img1}
                    icon={img2}
                    surname={'Спирина'}
                    name={'Анна Сергеевна'}
                    text={'Ветеринарный врач, кандидат ветеринарных наук, президент Национального ветеринарного стоматологического союза, руководитель отделения «Стоматология и челюстно-лицевая хирургия» ветеринарных клиник «Денталвет», старший преподаватель Учебного Центра «Денталвет», резидент Европейской высшей школы ветеринарной стоматологии (EVDC).'}
                />

                <hr className="string"/>

                <div className="head">План урока</div>

                <TextBlock
                    text={`В этом уроке вы узнаете:`}
                />

                <PlanInFirstStep
                    planData={this.planData}
                    type={'violet'}
                />

            </div>
        )
    }
}
