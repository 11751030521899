import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock'

import img1 from "../../../image/lessons/lesson8/step27-1.png";
// import img2 from "../../../image/lessons/lesson8/step27-2.png";

export default class step27 extends React.Component {

    render() {
        return (
            <div className="step27">
                <div className="head">
                    Комната для осмотра / смотровой кабинет
                </div>
                <TextBlock
                    text={`Постарайтесь выделить хотя бы одну комнату исключительно для кошек.
                    Проведите обследование в том месте, где кошка чувствует себя наиболее комфортно (как на фото).
                    
                    Для удаления запахов используйте дезинфицирующие мыла или стиральные порошки, которые удаляют как белок, так и жир (которые являются частью состава феромонов).
                    
                    Имейте в наличии различные лакомства, одноразовые или многоразовые игрушки и/или полотенца, обработанные кошачьей мятой, чтобы заинтересовать кошку.`}
                />
                <img src={img1} alt="" className="imgCenter"/>
            </div>
        )
    }
}
