import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

export default class step15 extends React.Component {

    render() {
        return (
            <div className="step15">
                <TextBlock
                    text={`<b>Окружающая среда</b>`}
                />
                <ul className="list">
                    <li className="list_item">
                        По возможности, обеспечьте наличие кошачьих и собачьих отделений в ветеринарной клинике, изолированных друг от друга.
                    </li>
                    <li className="list_item">
                        Не позволяйте животным попадать в поле зрения друг друга.
                    </li>
                    <li className="list_item">
                        Поддерживайте низкий уровень освещенности, чтобы успокоить встревоженных кошек и способствовать их отдыху.
                    </li>
                    <li className="list_item">
                        Вместо использования универсального подхода создайте такую среду в клетке, которая предназначена для удовлетворения потребностей и предпочтений каждой отдельной кошки.
                    </li>
                    <li className="list_item">
                        Используйте клетки достаточно большого размера, чтобы обеспечить необходимое место для кошачьего укрытия, а также расположите кошачий лоток отдельно от пищи, подстилки и питья. Размер и высота кошачьего лотка должны соответствовать размеру и степени подвижности кошки. По возможности, используйте кошачий лоток, который уже знаком кошке.
                    </li>
                    <li className="list_item">
                        Положите в клетку знакомую кошке подстилку, игрушки, пищу и кошачий лоток, которые были взяты владельцем кошки из дома; меняйте подстилку исключительно в том случае, когда она испачкалась.
                    </li>
                    <li className="list_item">
                        Подберите необходимый микроклимат в каждой клетке в соответствии с индивидуальными потребностями кошки; помните, что больные и пожилые кошки, а также кошки, проходящие лечение с использованием успокоительных средств, имеющих седативный эффект, и кошки, находящиеся в отделении педиатрии, нуждаются в более высокой температуре окружающей среды.
                    </li>
                    <li className="list_item">
                        Для поддержания необходимого уровня тепла в теле кошки обеспечьте в клетке удобную подстилку, например, толстые полотенца или полотенца с густым ворсом, ортопедическую подстилку или коврики для йоги.
                    </li>
                    <li className="list_item">
                        Обеспечьте надежное укрытие для кошки внутри клетки, например, прочный бумажный пакет, картонную коробку, кошачью подстилку или переноску.
                    </li>
                </ul>
            </div>
        )
    }
}