import React from 'react'
import VideoBlock from "../../../Components/LessonAndTestComponents/VideoBlock";
import Lecturer from "../../../Components/LessonAndTestComponents/Lecturer";

import img1 from '../../../image/Lecturers/belenson.png'
import img2 from '../../../image/Lecturers/belenson-icon.png'

export default class step2 extends React.Component {

    render() {
        return (
            <div className="step2">
                <Lecturer
                    img={img1}
                    icon={img2}
                    surname={'Беленсон'}
                    name={'Максим Михайлович'}
                    text={'Ветеринарный врач – эндокринолог. Сеть ветеринарных клиник «Белый клык»'}
                />

                <hr className="string"/>

                <VideoBlock
                    src={'https://www.youtube.com/embed/HU0X-Xi0icY'}
                    title={'https://www.youtube.com/watch?v=HU0X-Xi0icY&feature=youtu.be'}
                />
            </div>
        )
    }
}
