import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import PlanInFirstStep from '../../../Components/LessonAndTestComponents/PlanInFirstStep';
import Lecturer from "../../../Components/LessonAndTestComponents/Lecturer";
import OnClickBlock from "../../../Components/LessonAndTestComponents/OnClickBlock";
import List from "../../../Components/LessonAndTestComponents/List";
import ListNumeral from "../../../Components/LessonAndTestComponents/ListNumeral";

import img1 from "../../../image/lessons/lesson43/step8-1.png";
import img2 from "../../../image/lessons/lesson43/step8-2.png";
import img3 from "../../../image/lessons/lesson43/step8-3.png";
import img4 from "../../../image/lessons/lesson43/step8-4.png";
import img5 from "../../../image/lessons/lesson43/step8-5.png";
import img6 from "../../../image/lessons/lesson43/step8-6.png";
import img7 from "../../../image/lessons/lesson43/step8-7.png";
import img8 from "../../../image/lessons/lesson43/step8-8.png";
import img9 from "../../../image/lessons/lesson43/step8-9.png";
import img10 from "../../../image/lessons/lesson43/step8-10.png";
import img11 from "../../../image/lessons/lesson43/step8-11.png";
import InfoBlock from "../../../Components/LessonAndTestComponents/InfoBlock";
import Slider from "react-slick";

export default class step8 extends React.Component {

    state = {
        flexData: [
            'Введение',
            'Процесс',
            'Результаты'
        ],
        flexHidden: false,
    }

    hideElement = () => {
        this.setState({flexHidden: !this.state.flexHidden})
        document.querySelector('.main__image').style.display = 'none'
    }

    showNextBtn = () => {
        this.props.showNextBtn()
    }

    listData = [
        'Ориентация головы в сторону говорящего',
        'Движение в сторону говорящего',
        'Трение о говорящего' ,
        'Обнюхивание говорящего'
    ]

    listData2 = [
        'Музыка, написанная для кошек, вызывает у них более <strong>положительную реакцию,</strong> чем музыка, написанная для людей (рис. 1, верхний график).',
        '<strong>Латентность реакции была</strong> значительно короче на музыку, написанную для кошек, чем на человеческую музыку (рис. 1, нижний график).',
        '<strong>Пятнадцать кошек стали спокойнее</strong> после прослушивания кошачьей музыки и только две кошки стали спокойнее после прослушивания человеческой музыки.' ,
        'Реакция на музыку кошки <strong>отрицательно коррелировала</strong> с возрастом, а латентность реакции на музыку кошки положительно коррелировала с возрастом (рис. 2).'
    ]

    changeSlide = (next) => {
        if(next === 4){
            this.props.showNextBtn();
        }
        console.log(111, next)
    }

    render() {
        const {flexData} = this.state
        let settings = {
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 2,
            adaptiveHeight: true,
            beforeChange: (current, next) => {
                this.changeSlide(next)
            },
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        dots: true,
                        arrows: false,
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                },
            ]
        };
        return (
            <div className="step8">
                <hr className="string"/>
                <div className="head">Почему музыка для кошек важна в рамках подхода Cat Friendly – С любовью к кошкам</div>

                <InfoBlock
                    text={`Нажмимайте на кнопки с разделами ниже, для того, чтобы открылась  информация`}
                />

                <OnClickBlock
                    flexData={flexData}
                    hideElement={this.hideElement}
                    showNextBtn={this.showNextBtn}
                >
                    <div className="imgDiv_wrap">
                        {/*<div className="wrap__container">*/}
                        {/*    <div className="wrap__block">*/}
                        {/*        <img src={img1} alt="" className="wrap__image"/>*/}
                        {/*        <h4 className="wrap__header">Участники</h4>*/}
                        {/*        <p className="wrap__text">47 кошек разных пород в возрасте от 5 месяцев до 19 лет</p>*/}
                        {/*    </div>*/}
                        {/*    <div className="wrap__block">*/}
                        {/*        <img src={img2} alt="" className="wrap__image"/>*/}
                        {/*        <h4 className="wrap__header">Предмет изучения</h4>*/}
                        {/*        <p className="wrap__text">Влияние композиций, написанных специально для кошек, а также влияние музыкальных произведений, написанных для людей, на кошек</p>*/}
                        {/*    </div>*/}
                        {/*    <div className="wrap__block">*/}
                        {/*        <img src={img3} alt="" className="wrap__image"/>*/}
                        {/*        <h4 className="wrap__header">Особенности музыки для кошек</h4>*/}
                        {/*        <p className="wrap__text">Средняя высота тона кошачьих композиций (1,34 кГц) была примерно на 2 октавы выше, чем у произведений, разработанных для человека (335 Гц), и была на октаву выше верхнего диапазона основной частоты вокализации кошек</p>*/}
                        {/*    </div>*/}
                        {/*    <div className="wrap__block">*/}
                        {/*        <img src={img4} alt="" className="wrap__image"/>*/}
                        {/*        <h4 className="wrap__header">Желаемый эффект</h4>*/}
                        {/*        <p className="wrap__text">Музыка была написана таким образом, чтобы оказывать успокаивающее воздействие на кошек</p>*/}
                        {/*    </div>*/}
                        {/*    <div className="wrap__block">*/}
                        {/*        <img src={img5} alt="" className="wrap__image"/>*/}
                        {/*        <h4 className="wrap__header">Музыка для человека</h4>*/}
                        {/*        <p className="wrap__text">В качестве композиций для человека были использованы «Воздух на струне соль» Баха и «Элегия» Форе</p>*/}
                        {/*    </div>*/}
                        {/*</div>*/}

                        <Slider {...settings}>
                            <div className="flex_el">
                                <div className="wrap__block">
                                    <img src={img1} alt="" className="wrap__image"/>
                                    <h4 className="wrap__header">Участники</h4>
                                    <p className="wrap__text">47 кошек разных пород в возрасте от 5 месяцев до 19 лет</p>
                                </div>
                            </div>
                            <div className="flex_el">
                                <div className="wrap__block">
                                    <img src={img2} alt="" className="wrap__image"/>
                                    <h4 className="wrap__header">Предмет изучения</h4>
                                    <p className="wrap__text">Влияние композиций, написанных специально для кошек, а также влияние музыкальных произведений, написанных для людей, на кошек</p>
                                </div>
                            </div>
                            <div className="flex_el">
                                <div className="wrap__block">
                                    <img src={img3} alt="" className="wrap__image"/>
                                    <h4 className="wrap__header">Особенности музыки для кошек</h4>
                                    <p className="wrap__text">Средняя высота тона кошачьих композиций (1,34 кГц) была примерно на 2 октавы выше, чем у произведений, разработанных для человека (335 Гц), и была на октаву выше верхнего диапазона основной частоты вокализации кошек</p>
                                </div>
                            </div>
                            <div className="flex_el">
                                <div className="wrap__block">
                                    <img src={img4} alt="" className="wrap__image"/>
                                    <h4 className="wrap__header">Желаемый эффект</h4>
                                    <p className="wrap__text">Музыка была написана таким образом, чтобы оказывать успокаивающее воздействие на кошек</p>
                                </div>
                            </div>
                            <div className="flex_el">
                                <div className="wrap__block">
                                    <img src={img5} alt="" className="wrap__image"/>
                                    <h4 className="wrap__header">Музыка для человека</h4>
                                    <p className="wrap__text">В качестве композиций для человека были использованы «Воздух на струне соль» Баха и «Элегия» Форе</p>
                                </div>
                            </div>
                        </Slider>

                    </div>
                    <div className="imgDiv_wrap">
                        <ul className="list">
                            <li className="list__item">
                                <img src={img6} alt="" className="list__item__img"/>
                                <p className="list__item__text">Две колонки были установлены в домах владельцев кошек на расстоянии 3 метров друг от друга. В каждой паре стимулов через один динамик воспроизводилась музыка для кошки, а через другой –  человеческая музыка.</p>
                            </li>
                            <li className="list__item">
                                <img src={img7} alt="" className="list__item__img"/>
                                <p className="list__item__text">Исследователи выбрали четыре типа поведения, которые должны были указывать на интерес к воспроизводимой музыке:
                                    <List
                                        ListData={this.listData}
                                    />
                                </p>
                            </li>
                            <li className="list__item">
                                <img src={img8} alt="" className="list__item__img"/>
                                <p className="list__item__text">Кроме того, была измерена задержка от начала музыки до начала любого из видов поведения, вызывающего интерес, и записывалась активность кошки до и во время каждого стимула, чтобы определить, возбуждала или успокаивала кошку музыка.</p>
                            </li>
                        </ul>
                    </div>
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`Результаты исследования показали, что:`}
                        />
                        <ListNumeral
                            data={this.listData2}
                        />
                        <div className="wrap__images">
                            <img src={img9} alt=""/>
                            <img src={img10} alt=""/>
                        </div>
                    </div>
                </OnClickBlock>
                <img className="main__image" src={img11} alt=""/>
                <p className="footnote">T. S. Charles, Teie David, Savage Megan. —Cats Prefer Ecologically Appropriate Music//Applied Animal Behaviour Science. — 2015. — № 166. — С. 106-111.</p>
            </div>
        )
    }
}
