import React from 'react'

import Lesson1 from "./Lesson1";
import Lesson2 from "./Lesson2";
import Lesson3 from "./Lesson3";
import Lesson4 from "./Lesson4";
import Lesson5 from "./Lesson5";
import Lesson6 from "./Lesson6";
import Lesson7 from "./Lesson7";
import Lesson8 from "./Lesson8";
import Lesson9 from "./Lesson9";
import Lesson10 from "./Lesson10";
import Lesson11 from "./Lesson11";
import Lesson12 from "./Lesson12";
import Lesson13 from "./Lesson13";
import Lesson15 from "./Lesson15";
import Lesson16 from "./Lesson16";
import Lesson17 from "./Lesson17";
import Lesson18 from "./Lesson18";
import Lesson19 from "./Lesson19";
import Lesson20 from "./Lesson20";
import Lesson22 from "./Lesson22";
import Lesson23 from "./Lesson23";
import Lesson25 from "./Lesson25";
import Lesson26 from "./Lesson26";
import Lesson27 from "./Lesson27";
import Lesson28 from "./Lesson28";
import Lesson30 from "./Lesson30";
import Lesson31 from "./Lesson31";
import Lesson32 from "./Lesson32";
import Lesson33 from "./Lesson33";
import Lesson34 from "./Lesson34";
import Lesson35 from "./Lesson35";
import Lesson37 from "./Lesson37";
import Lesson43 from "./Lesson43";
import Lesson44 from "./Lesson44";
import Lesson45 from "./Lesson45";
import Lesson46 from "./Lesson46";
import Lesson47 from "./Lesson47";
import Lesson48 from "./Lesson48";
import Lesson49 from "./Lesson49";
import Lesson50 from "./Lesson50";
import Lesson51 from "./Lesson51";
import Lesson52 from "./Lesson52";
import Lesson53 from "./Lesson53";
import Lesson54 from "./Lesson54";
import Lesson55 from "./Lesson55";
import Lesson56 from "./Lesson56";
import Lesson57 from "./Lesson57";
import Lesson58 from "./Lesson58";

export default class lessons extends React.Component {

    componentDidMount() {
    }

    _renderLesson = () => {
        let lesson = this.props.location.state
        switch (lesson.attributes.url) {
            case '1' : {
                return <Lesson1 lesson={lesson}/>
            }
            case '2' : {
                return <Lesson2 lesson={lesson}/>
            }
            case '3' : {
                return <Lesson3 lesson={lesson}/>
            }
            case '4' : {
                return <Lesson4 lesson={lesson}/>
            }
            case '5' : {
                return <Lesson5 lesson={lesson}/>
            }
            case '6' : {
                return <Lesson6 lesson={lesson}/>
            }
            case '7' : {
                return <Lesson7 lesson={lesson}/>
            }
            case '8' : {
                return <Lesson8 lesson={lesson}/>
            }
            case '9' : {
                return <Lesson9 lesson={lesson}/>
            }
            case '10' : {
                return <Lesson10 lesson={lesson}/>
            }
            case '11' : {
                return <Lesson11 lesson={lesson}/>
            }
            case '12' : {
                return <Lesson12 lesson={lesson}/>
            }
            case '13' : {
                return <Lesson13 lesson={lesson}/>
            }
            case '15' : {
                return <Lesson15 lesson={lesson}/>
            }
            case '16' : {
                return <Lesson16 lesson={lesson}/>
            }
            case '17' : {
                return <Lesson17 lesson={lesson}/>
            }
            case '18' : {
                return <Lesson18 lesson={lesson}/>
            }
            case '19' : {
                return <Lesson19 lesson={lesson}/>
            }
            case '20' : {
                return <Lesson20 lesson={lesson}/>
            }
            case '22' : {
                return <Lesson22 lesson={lesson}/>
            }
            case '23' : {
                return <Lesson23 lesson={lesson}/>
            }
            case '25' : {
                return <Lesson25 lesson={lesson}/>
            }
            case '26' : {
                return <Lesson26 lesson={lesson}/>
            }
            case '27' : {
                return <Lesson27 lesson={lesson}/>
            }
            case '28' : {
                return <Lesson28 lesson={lesson}/>
            }
            case '30' : {
                return <Lesson30 lesson={lesson}/>
            }
            case '31' : {
                return <Lesson31 lesson={lesson}/>
            }
            case '32' : {
                return <Lesson32 lesson={lesson}/>
            }
            case '33' : {
                return <Lesson33 lesson={lesson}/>
            }
            case '34' : {
                return <Lesson34 lesson={lesson}/>
            }
            case '35' : {
                return <Lesson35 lesson={lesson}/>
            }
            case '37' : {
                return <Lesson37 lesson={lesson}/>
            }
            case '43' : {
                return <Lesson43 lesson={lesson}/>
            }
            case '44' : {
                return <Lesson44 lesson={lesson}/>
            }
            case '45' : {
                return <Lesson45 lesson={lesson}/>
            }
            case '46' : {
                return <Lesson46 lesson={lesson}/>
            }
            case '47' : {
                return <Lesson47 lesson={lesson}/>
            }
            case '48' : {
                return <Lesson48 lesson={lesson}/>
            }
            case '49' : {
                return <Lesson49 lesson={lesson}/>
            }
            case '50' : {
                return <Lesson50 lesson={lesson}/>
            }
            case '51' : {
                return <Lesson51 lesson={lesson}/>
            }
            case '52' : {
                return <Lesson52 lesson={lesson}/>
            }
            case '53' : {
                return <Lesson53 lesson={lesson}/>
            }
            case '54' : {
                return <Lesson54 lesson={lesson}/>
            }
            case '55' : {
                return <Lesson55 lesson={lesson}/>
            }
            case '56' : {
                return <Lesson56 lesson={lesson}/>
            }
            case '57' : {
                return <Lesson57 lesson={lesson}/>
            }
            case '58' : {
                return <Lesson58 lesson={lesson}/>
            }
            default : {
                return false
            }
        }
    }

    render() {
        return (
            this._renderLesson()
        )
    }
}
