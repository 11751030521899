import React from 'react'
import './style.sass'

export default class PanelStudy extends React.Component{
    state = {
        team: '',
        progress: ''
    };

    componentDidMount() {
        this.setTeam();
    }

    setTeam = () => {
        const {lesson} = this.props;
        let progress = Math.round((lesson.steps / lesson.attributes.steps) * 100)
        let team = {
            baseBgColor: '#fff',
            baseTextColor: '#333333',
            btnClass: 'btn_violet',
            starLineColor: '#333333',
            border: '2px solid #fff'
        }
        if(progress !== 100 && progress !== 0) {
            team = {
                baseBgColor: '#4147DA',
                baseTextColor: '#fff',
                btnClass: 'btn_default_whiteBr',
                starLineColor: '#fff',
                border: '2px solid #4147DA'
            }
        }

        if (lesson.attributes.steps === 0) {
            team = {
                baseBgColor: '#EFEFEF',
                baseTextColor: '#77777B',
                btnClass: 'btn_soon',
                starLineColor: '#DEDEDE',
                border: '2px solid #DEDEDE'
            }
        }
        this.setState({
            team,
            progress
        })

    }

    render() {
        const {team, progress} = this.state;
        const {lesson} = this.props;

        return (
            <div
                className={'PanelStudy'}
                style={{backgroundColor: team.baseBgColor, border: team.border}}
                id={lesson.attributes.id}
            >
                {progress !== 0 && lesson.attributes.steps > 0
                    &&
                    <div className="done">
                    {progress === 100 ?
                        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" >
                            <circle cx="15" cy="15" r="15" fill="#4147DA"/>
                            <line x1="8.12132" y1="15" x2="13" y2="19.8787" stroke="white" strokeWidth="3" strokeLinecap="square" strokeLinejoin="round"/>
                            <line x1="22" y1="11.1213" x2="13.1213" y2="20" stroke="white" strokeWidth="3" strokeLinecap="square" strokeLinejoin="round"/>
                        </svg>
                        :
                        <span>
                            пройдено {progress}%
                        </span>
                    }
                </div>
                }
                <div className="name" style={{color: team.baseTextColor}}>{lesson.attributes.name}</div>
                <div className="desc">
                    <div className="img">
                        <img src={this.props.img} alt=""/>
                    </div>
                    <div className="text">
                        <span style={{color: team.baseTextColor}} dangerouslySetInnerHTML={{ __html: lesson.attributes.info_text }}/>
                        {lesson.attributes.steps === 0 ?
                            <div className="button">
                                <button
                                    className={'btn ' + team.btnClass}
                                    onClick={this.props.showSoon}
                                >
                                    скоро
                                </button>
                            </div>
                            :
                            <div className="button">
                                <button
                                    className={'btn ' + team.btnClass}
                                    onClick={()=>{this.props.onClick(lesson)}}
                                >
                                    {progress === 0 ? 'Начать урок' : progress === 100 ? 'пройти снова' : 'продолжить'}
                                </button>
                            </div>
                        }
                    </div>
                </div>
                <div className="star" style={{color: team.baseTextColor}}>
                    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill={team.baseTextColor} fillRule="evenodd" clipRule="evenodd" d="M10.4853 0L14.1566 6L21 7.64706L16.4476 13L16.9762 20L10.4853 17.3235L3.99441 20L4.55245 13L0 7.64706L6.81399 6L10.4853 0ZM10.4853 2.67647L7.69511 7.20588L2.55524 8.47059L6.02098 12.5294L5.60979 17.8235L10.5147 15.7941L15.4196 17.8235L15.0084 12.5294L18.4448 8.47059L13.2755 7.23529L10.4853 2.67647Z" />
                    </svg>
                    {lesson.attributes.points} баллов
                    <div className="line" style={{backgroundColor: team.starLineColor}}/>
                </div>
                {this.props.isNew === 1 && <div className="new">новое</div>}
                {this.props.isNew === 1 && <div className="triangle"></div>}
            </div>
        )
    }
}
