import React from 'react'
import './style.sass'
import InputMask from 'react-input-mask';
import Inputs from "../Inputs";

export default class InputSelect extends React.Component {

    state = {
        data: [],
        showOption: false,
        input_value: '',
    }

    componentDidMount() {
        this.setState({data: this.props.data})
    }

    showData = () => {
        const {showOption} = this.state
        this.setState({showOption: !showOption})
    }

    onChangeValue = (name, value, index) => {
        this.props.onChange(name, value, index)
        this.showData()
        this.setState({input_value: value})
    }

    render() {
        const {data, showOption, input_value} = this.state
        return (
            <div className={'inputBlock ' + (this.props.option_show && 'show ') + (this.props.required && ' required')}>
                {this.props.label &&
                    <label htmlFor={this.props.id}>{this.props.label}</label>
                }
                <span className="star">&#42;</span>
                <InputMask
                    type={this.props.type ? this.props.type : 'text'}
                    className={'input ' + this.props.className}
                    name={this.props.name}
                    value={input_value}
                    placeholder={this.props.placeholder}
                    onClick={this.showData}
                    readOnly={true}
                    required={this.props.required}
                    disabled={this.props.disabled}
                    mask={this.props.mask}
                />
                <svg className="inputBlock_select-arrow" width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.70345 5.30376C5.31378 5.68944 4.68622 5.68944 4.29655 5.30376L0.666294 1.71074C0.031322 1.08228 0.476354 -1.00743e-06 1.36975 -9.29326e-07L8.63026 -2.94592e-07C9.52365 -2.1649e-07 9.96868 1.08228 9.33371 1.71074L5.70345 5.30376Z" fill="#4147DA"/>
                </svg>
                {this.props.errorText &&
                    <div className="errorText">{this.props.errorText}</div>
                }
                <div className={'inputBlock_option ' + (showOption && 'show')}>
                    <Inputs
                        name={'inputBlock_option'}
                        placeholder={'Выберите должность'}
                        type={'text'}
                        required={'required'}
                        key={'contact_phone'}
                        value={input_value}
                        disabled={true}
                    />
                    <svg className="inputBlock_option_icon" width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.70345 0.696238C5.31378 0.310561 4.68622 0.310561 4.29655 0.696238L0.666294 4.28926C0.031322 4.91772 0.476354 6 1.36975 6L8.63026 6C9.52365 6 9.96868 4.91772 9.33371 4.28926L5.70345 0.696238Z" fill="#4147DA"/>
                    </svg>
                    <div className="inputBlock_option_list">
                        {data.map((item, index) => {
                            return (
                                <div
                                    key={index}
                                    className="inputBlock_option_item"
                                    onClick={() => {this.onChangeValue(this.props.name, item)}}
                                >
                                    {item}
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        )
    }
}
// .filter(function(item, pos) {
//     return dataSort.indexOf(item.name) === pos;
// })
