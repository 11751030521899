import React from 'react'
// import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

export default class step12 extends React.Component {

    render() {
        return (
            <div className="step12">
               <div className="block red">
                   <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                       <circle cx="16" cy="16" r="16" fill="#CC3333"/>
                       <path fillRule="evenodd" clipRule="evenodd" d="M9.32372 9.32372C9.75536 8.89209 10.4552 8.89209 10.8868 9.32372L16 14.4369L21.1132 9.32372C21.5448 8.89209 22.2446 8.89209 22.6763 9.32372C23.1079 9.75536 23.1079 10.4552 22.6763 10.8868L17.5631 16L22.6763 21.1132C23.1079 21.5448 23.1079 22.2446 22.6763 22.6763C22.2446 23.1079 21.5448 23.1079 21.1132 22.6763L16 17.5631L10.8868 22.6763C10.4552 23.1079 9.75536 23.1079 9.32372 22.6763C8.89209 22.2446 8.89209 21.5448 9.32372 21.1132L14.4369 16L9.32372 10.8868C8.89209 10.4552 8.89209 9.75536 9.32372 9.32372Z" fill="white"/>
                   </svg>
                   <div className="block_text">
                       Очень важно избегать чрезмерной замкнутости у испуганной кошки. Грубые силовые методы воздействия на кошку, скорее всего, усилят беспокойство кошки и сделают обращение с ней еще более трудным. Практикующий врач и владелец кошки должны сохранять спокойствие и терпение.
                   </div>
               </div>
                <div className="block green">
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="16" cy="16" r="16" fill="#4DD06A"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M22.7976 10.3035C23.2928 10.744 23.3371 11.5025 22.8966 11.9976L14.3559 21.5976C14.1279 21.854 13.801 22.0004 13.4579 22C13.1148 21.9996 12.7882 21.8523 12.5608 21.5953L8.30144 16.7831C7.86219 16.2868 7.90841 15.5284 8.40468 15.0892C8.90095 14.6499 9.65934 14.6961 10.0986 15.1924L13.4617 18.992L21.1034 10.4024C21.544 9.90724 22.3025 9.86295 22.7976 10.3035Z" fill="white"/>
                    </svg>
                    <div className="block_text">
                        Персонал должен использовать альтернативный подход к достижению целей визита, если кошка боится и сопротивляется лечению. Такие меры могут включать в себя применение седативного средства или временное прекращение медицинской активности, чтобы позволить кошке успокоиться. Если поведение кошки предполагает наличие боли, пусть даже незначительной, необходимо обеспечить достаточное обезболивание и повторно оценить реакцию кошки.
                    </div>
                </div>
            </div>
        )
    }
}
