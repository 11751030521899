import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import PopupInStep from "../../../Components/LessonAndTestComponents/PopupInStep";
import List from "../../../Components/LessonAndTestComponents/List";

import img1 from '../../../image/lessons/lesson32/step13-1.png'
import img2 from '../../../image/lessons/lesson32/step13-2.png'
import img3 from '../../../image/lessons/lesson32/step13-3.png'
import img4 from '../../../image/lessons/lesson32/step13-4.png'
import img5 from '../../../image/lessons/lesson32/step13-5.png'



export default class step13 extends React.Component {

    state = {
        popupActive: 0,
        popupShow: false,
        flexHidden: false,
        points: [],
    };

    showPopup = popupActive => {
        const {points} = this.state;
        if (points.indexOf(popupActive) === -1) {
            points.push(popupActive)
        }

        if (points.length === 5) {
            this.setState({popupActive: 0, popupShow: false})
        }

        this.setState({
            popupShow: true,
            popupActive: popupActive,
            points: points
        })

        if (popupActive > 5) {
            this.setState({popupActive: 0, popupShow: false})
        }

        if (points.length === 5) {
            this.props.showNextBtn();
        }
    }

    closePopup = () => {
        this.setState({popupShow: false})
    }

    popupWrp = {
        1: {
            img_head: img1,
            head: 'Сырьё',
            text: `Прежде чем поступить в производственный процесс, сырье для сухого корма подвергается высушиванию. Т.е., из курицы производится куриная мука, из говядины — говяжья мука и т.д.
            
            Каждая партия сырья проверяется на качество и безопасность по нескольким показателям, а также определяются его окислительные характеристики с целью выяснить способность сырья к длительному хранению.`,
        },
        2: {
            img_head: img2,
            head: 'Перемешивание и измельчение',
            text: `Первый этап производства сухого корма — соединение всех сырьевых компонентов согласно рецептуре (мясные муки, растительные муки, масла и жиры, витамины и минералы), их перемешивание и измельчение.
            
            Этот этап очень важен, так как чем лучше будет проведено измельчение сырья, тем лучше корм будет усваиваться в желудочно-кишечном тракте животного.`,
        },
        3: {
            img_head: img3,
            head: 'Формирование гранул методом экструзии',
            text: `Экструзия — это процесс, при котором сырье из сухого муко-образного состояния превращается в пористые гранулы под воздействием высокой температуры и давления.
            
            В процессе такого воздействия происходит расщепление сложных углеводов на более простые, что обеспечивает существенное улучшение усвояемости корма.
            
            Преимущества этого метода:`,
            ListData: [
                `создается продукт с заданными внешними свойствами;`,
                `обеспечивается микробиологическая безопасность продукта;`,
                `сохраняется постоянство характеристик продукта;`,
                `повышается усвояемость продукта за счет изменения структуры сложных углеводов и повышения пористости гранул.`
            ],
        },
        4: {
            img_head: img4,
            head: 'Высушивание гранул',
            text: `Результатом экструзии является формирование пористой гранулы. Однако создание гранулы еще не означает, что продукт готов.
            
            Очень важно высушить гранулы после экструзии, то есть под действием температуры убрать из корма лишнюю влагу.
            
            Важно сделать так, чтобы в готовом сухом корме содержание влаги не превышало 10%, так как такое высушивание позволяет защитить продукт от воздействия микроорганизмов в окружающей среде и обеспечить ему большой срок годности без дополнительных консервантов.`,
        },
        5: {
            img_head: img5,
            head: 'Покрытие гранул бульоном',
            text: `Наконец, финальный этап производства сухого корма — покрытие сухой гранулы специальным бульоном или спреем. Такой бульон варится из субпродуктов (например, печени), а также содержит масла и жиры.
            
             Гранулы корма покрываются бульоном для того, чтобы корм стал более привлекательным для кошек, ведь они являются хищниками, а значит, им очень нравится вкус  и запах мясных ингредиентов, богатых белком и жиром.
             
             Еще одна важная задача бульона — быть «защитной оболочкой» для гранулы, то есть защищать ее от проникновения внутрь влаги.`,
            img: false
        },
    }

    render() {
        const {popupShow, popupActive} = this.state
        return (
            <div className="step13">
                <TextBlock
                    text={`После проверки и контроля качества сырья запускается производственный процесс. На схеме ниже показан процесс производства сухого корма.`}
                />
                <InfoBlock
                    text={`Нажмите на вопросительные знаки, чтобы посмотреть описания производственных процессов.`}
                />
                <div className="schema">
                    <div className="flex_left">
                        <div className="flex_raw">
                            Сырье
                            <span className="point" onClick={() => {this.showPopup(1)}}>?</span>
                        </div>
                        <div className="flex_wrp">
                            <div className="flex_wrp_left">
                                <div className="flex_wrp_el">
                                    Перемешивание и измельчение
                                    <svg className="arrow_down" width="16" height="26" viewBox="0 0 16 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7 1C7 0.447715 7.44772 7.68068e-08 8 0C8.55228 -7.68068e-08 9 0.447715 9 1L7 1ZM8.70711 25.7071C8.31659 26.0976 7.68342 26.0976 7.2929 25.7071L0.928935 19.3431C0.53841 18.9526 0.53841 18.3195 0.928935 17.9289C1.31946 17.5384 1.95262 17.5384 2.34315 17.9289L8 23.5858L13.6569 17.9289C14.0474 17.5384 14.6805 17.5384 15.0711 17.9289C15.4616 18.3195 15.4616 18.9526 15.0711 19.3431L8.70711 25.7071ZM9 1L9 25L7 25L7 1L9 1Z" fill="#4147DA"/>
                                    </svg>
                                    <span className="point" onClick={() => {this.showPopup(2)}}>?</span>
                                </div>
                                <div className="flex_wrp_el">
                                    Экструзия
                                    <svg className="arrow_down" width="16" height="26" viewBox="0 0 16 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7 1C7 0.447715 7.44772 7.68068e-08 8 0C8.55228 -7.68068e-08 9 0.447715 9 1L7 1ZM8.70711 25.7071C8.31659 26.0976 7.68342 26.0976 7.2929 25.7071L0.928935 19.3431C0.53841 18.9526 0.53841 18.3195 0.928935 17.9289C1.31946 17.5384 1.95262 17.5384 2.34315 17.9289L8 23.5858L13.6569 17.9289C14.0474 17.5384 14.6805 17.5384 15.0711 17.9289C15.4616 18.3195 15.4616 18.9526 15.0711 19.3431L8.70711 25.7071ZM9 1L9 25L7 25L7 1L9 1Z" fill="#4147DA"/>
                                    </svg>
                                    <span className="point" onClick={() => {this.showPopup(3)}}>?</span>
                                </div>
                                <div className="flex_wrp_el">
                                    Высушивание гранул
                                    <svg className="arrow_down" width="16" height="26" viewBox="0 0 16 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7 1C7 0.447715 7.44772 7.68068e-08 8 0C8.55228 -7.68068e-08 9 0.447715 9 1L7 1ZM8.70711 25.7071C8.31659 26.0976 7.68342 26.0976 7.2929 25.7071L0.928935 19.3431C0.53841 18.9526 0.53841 18.3195 0.928935 17.9289C1.31946 17.5384 1.95262 17.5384 2.34315 17.9289L8 23.5858L13.6569 17.9289C14.0474 17.5384 14.6805 17.5384 15.0711 17.9289C15.4616 18.3195 15.4616 18.9526 15.0711 19.3431L8.70711 25.7071ZM9 1L9 25L7 25L7 1L9 1Z" fill="#4147DA"/>
                                    </svg>
                                    <svg className="arrow_right" width="26" height="16" viewBox="0 0 26 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 9C0.447715 9 0 8.55228 0 8C0 7.44772 0.447715 7 1 7L1 9ZM25.7071 7.29289C26.0976 7.68342 26.0976 8.31658 25.7071 8.70711L19.3431 15.0711C18.9526 15.4616 18.3195 15.4616 17.9289 15.0711C17.5384 14.6805 17.5384 14.0474 17.9289 13.6569L23.5858 8L17.9289 2.34315C17.5384 1.95262 17.5384 1.31946 17.9289 0.928932C18.3195 0.538408 18.9526 0.538408 19.3431 0.928932L25.7071 7.29289ZM1 7L25 7V9L1 9L1 7Z" fill="#4147DA"/>
                                    </svg>
                                    <span className="point" onClick={() => {this.showPopup(4)}}>?</span>
                                </div>
                            </div>
                            <div className="flex_wrp_right">
                                <div className="flex_wrp_el">
                                    Приготовление и варка бульона
                                    <svg className="arrow_down" width="16" height="26" viewBox="0 0 16 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7 1C7 0.447715 7.44772 7.68068e-08 8 0C8.55228 -7.68068e-08 9 0.447715 9 1L7 1ZM8.70711 25.7071C8.31659 26.0976 7.68342 26.0976 7.2929 25.7071L0.928935 19.3431C0.53841 18.9526 0.53841 18.3195 0.928935 17.9289C1.31946 17.5384 1.95262 17.5384 2.34315 17.9289L8 23.5858L13.6569 17.9289C14.0474 17.5384 14.6805 17.5384 15.0711 17.9289C15.4616 18.3195 15.4616 18.9526 15.0711 19.3431L8.70711 25.7071ZM9 1L9 25L7 25L7 1L9 1Z" fill="#4147DA"/>
                                    </svg>
                                </div>
                                <div className="flex_wrp_el hidden">
                                    <div className="answer"/>
                                </div>
                                <div className="flex_wrp_el">
                                    Покрытие гранул бульоном
                                    <svg className="arrow_down arrow_down--big" width="16" height="126" viewBox="0 0 16 126" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9 1C9 0.447715 8.55228 0 8 0C7.44772 0 7 0.447715 7 1L9 1ZM7.29289 125.707C7.68342 126.098 8.31658 126.098 8.70711 125.707L15.0711 119.343C15.4616 118.953 15.4616 118.319 15.0711 117.929C14.6805 117.538 14.0474 117.538 13.6569 117.929L8 123.586L2.34315 117.929C1.95262 117.538 1.31946 117.538 0.928932 117.929C0.538408 118.319 0.538408 118.953 0.928932 119.343L7.29289 125.707ZM7 1L7 125H9L9 1L7 1Z" fill="#4147DA"/>
                                    </svg>
                                    <svg className="arrow_right" width="26" height="16" viewBox="0 0 26 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 9C0.447715 9 0 8.55228 0 8C0 7.44772 0.447715 7 1 7L1 9ZM25.7071 7.29289C26.0976 7.68342 26.0976 8.31658 25.7071 8.70711L19.3431 15.0711C18.9526 15.4616 18.3195 15.4616 17.9289 15.0711C17.5384 14.6805 17.5384 14.0474 17.9289 13.6569L23.5858 8L17.9289 2.34315C17.5384 1.95262 17.5384 1.31946 17.9289 0.928932C18.3195 0.538408 18.9526 0.538408 19.3431 0.928932L25.7071 7.29289ZM1 7L25 7V9L1 9L1 7Z" fill="#4147DA"/>
                                    </svg>
                                    <span className="point" onClick={() => {this.showPopup(5)}}>?</span>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="flex_right">
                        <div className="flex_wrp_el">
                            Упаковка
                            <svg className="arrow_up" width="16" height="26" viewBox="0 0 16 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9 25C9 25.5523 8.55228 26 8 26C7.44772 26 7 25.5523 7 25L9 25ZM7.29289 0.292892C7.68342 -0.0976314 8.31658 -0.0976315 8.70711 0.292892L15.0711 6.65685C15.4616 7.04738 15.4616 7.68054 15.0711 8.07107C14.6805 8.46159 14.0474 8.46159 13.6569 8.07107L8 2.41421L2.34315 8.07107C1.95262 8.46159 1.31946 8.46159 0.928931 8.07107C0.538407 7.68054 0.538407 7.04738 0.928931 6.65685L7.29289 0.292892ZM7 25L7 1L9 1L9 25L7 25Z" fill="#4147DA"/>
                            </svg>
                        </div>
                        <div className="flex_wrp_el gray">
                            Готовый продукт
                        </div>
                    </div>
                </div>
                {popupShow &&
                <PopupInStep
                    closePopup={this.closePopup}
                    imgHead={this.popupWrp[popupActive].img_head}
                    head={this.popupWrp[popupActive].head}
                    changePoint={() => this.showPopup(popupActive + 1)}
                >
                    <TextBlock
                        text={this.popupWrp[popupActive].text}
                    />
                    {this.popupWrp[popupActive].ListData &&
                    <List ListData={this.popupWrp[popupActive].ListData}/>
                    }
                </PopupInStep>
                }
            </div>
        )
    }
}
