import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson11/step10-1.png'
import img1xs from '../../../image/lessons/lesson11/step10-1xs.png'

export default class step10 extends React.Component {

    render() {
        return (
            <div className="step10">
                <TextBlock
                    text={`После отучения от материнского молока энергетические потребности котят превышают потребности взрослых кошек в 3–4 раза и <b>достигают пика в 10-недельном возрасте котенка</b>. Затем энергетические потребности начинают постепенно понижаться, хотя остаются относительно высокими.`}
                />
                <picture>
                    <source srcSet={img1xs} media="(max-width: 767px)"/>
                    <img src={img1} alt="" className="imgCenter"/>
                </picture>
                <TextBlock
                    text={`На графике видно, что котята мужского пола растут быстрее, и в 6-недельном возрасте их вес значительно больше, чем у котят женского пола. В годовалом возрасте коты могут весить <b>на 45% больше кошек</b>, при прочих равных условиях.`}
                />
            </div>
        )
    }
}
