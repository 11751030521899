import React from 'react'
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";

export default class step42 extends React.Component{

    render() {
        return (
            <div className="step42">
                <div className="head">
                    Что делать, когда агрессия является потенциальной проблемой
                </div>
                <div className="subheader">
                    Рекомендации владельцу
                </div>
                <TextBlock
                    text={`Поместите вернувшуюся из клиники кошку в безопасную, тихую комнату (со всеми необходимыми ресурсами), <b>по крайней мере, на 24 часа</b>.
                    
                     <b>Если в течение 3 дней проблемы продолжаются</b>, рекомендуется обратиться за ветеринарной консультацией.
                     
                     Зачастую в таких ситуациях будет целесообразным сделать так, чтобы запах клиники исходил от всех кошек в равной степени.`}
                />
            </div>
        )
    }
}
