import React from 'react'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import Popup from '../../../Components/Popup';

// import img1 from "../../../image/lessons/lesson2/step8-1.png";

export default class step8 extends React.Component {

    state = {
        windowWidth: window.innerWidth,
        bitter: [],
        salty: [],
        sour: [],
        sweet: [],
        check: false,
        popupActive: 0,
        showPopup: false,
    }

    //Отлов события ресайз
    handlerResize = () => {
        this.setState({windowWidth: window.innerWidth})
    }

    componentDidMount() {
        window.addEventListener('resize', this.handlerResize)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handlerResize);
    }

    chooseAnswers = (number, type) => {
        let answers = [];
        let answersArray = [1, 2, 3, 4]
        answers = answers.concat(answersArray.splice(0, number))

        switch (type) {
            case 'bitter' : {
                this.setState({bitter: answers})
                break;
            }
            case 'salty' : {
                this.setState({salty: answers})
                break;
            }
            case 'sour' : {
                this.setState({sour: answers})
                break;
            }
            case 'sweet' : {
                this.setState({sweet: answers})
                break;
            }
            default : {
                return false
            }
        }
    }

    showPopup = (popupActive) => {
        const {showPopup} = this.state
        if (showPopup && popupActive >= 4) {
            this.setState({popupActive: 0, showPopup: false})
        } else if (showPopup) {
            this.setState({popupActive: popupActive + 1})
        } else {
            this.setState({popupActive: popupActive, showPopup: true})
        }
    }

    closePopup = () => {
        this.setState({showPopup: false})
    }

    checkAnswers = () => {
        this.setState({check: true})
        this.props.showNextBtn()
    }

    popupData = {
        1: {
            head: `Горький`,
            text: `<b>Кошки чрезвычайно чувствительны к горькому вкусу</b>. Присутствие в корме горьких компонентов быстро вызывает у кошек отвращение к еде. Кошки более чувствительны к горькому вкусу, чем собаки, и определяют его в более низкой концентрации. Такая способность помогает кошкам избежать отравления различными ядовитыми веществами, которые часто очень горькие на вкус.`,
        },
        2: {
            head: `Соленый`,
            text: `<b>Кошкам нравится солёный вкус</b>. Пища животного происхождения содержит оптимальный уровень хлорида натрия. Поэтому чувствительность к соли у кошек развита хуже, чем у всеядных животных. При этом к солёному вкусу кошки относятся положительно. Добавление соли в пищу способствует более активному потреблению как самого корма, так и воды.`,
        },
        3: {
            head: `Кислый`,
            text: `<b>Кислый вкус особенно привлекателен для кошек</b>. Ярко выраженный кислый вкус очень привлекателен для кошек. Однако потребление корма с высоким содержанием кислот может привести к нарушению работы почек.И это обязательно необходимо учитывать.`,
        },
        4: {
            head: `Сладкий`,
            text: `<b>Кошки не распознают сладкий вкус</b>. У кошек отсутствуют рецепторы сладкого вкуса. Они не реагируют не только на сахар, но и на заменители сахара. Однако низкая чувствительность к сладкому не является, по-видимому, проблемой для кошек, так как в природе они не нуждаются в потреблении фруктов в своём ежедневном рационе.`,
        },
    }

    render() {
        const {bitter, salty, sour, sweet, check, popupActive, showPopup, windowWidth} = this.state
        return (
            <div className="step8">
                <InfoBlock
                    text={`Попробуйте определить степень восприятия каждого вкуса кошкой.`}
                />
                <div className="flex">
                    <div className="flex_el">
                        <div
                            className={'flex_el_test ' + (bitter.indexOf(4) >= 0 && 'active ') + (check && ' inCorrect')}
                            onClick={() => {this.chooseAnswers(4, 'bitter')}}
                        />
                        <div
                            className={'flex_el_test ' + (bitter.indexOf(3) >= 0 && 'active ') + (check && ' correct')}
                            onClick={() => {this.chooseAnswers(3, 'bitter')}}
                        />
                        <div
                            className={'flex_el_test ' + (bitter.indexOf(2) >= 0 && 'active ') + (check && ' correct')}
                            onClick={() => {this.chooseAnswers(2, 'bitter')}}
                        />
                        <div
                            className={'flex_el_test ' + (bitter.indexOf(1) >= 0 && 'active ') + (check && ' correct')}
                            onClick={() => {this.chooseAnswers(1, 'bitter')}}
                        />
                        <div
                            className="flex_el_type"
                            onClick={() => {this.showPopup(1)}}
                        >
                            {windowWidth <= 767 ? '1' : 'Горький'}
                        </div>
                    </div>
                    <div className="flex_el">
                        <div
                            className={'flex_el_test ' + (salty.indexOf(4) >= 0 && 'active ') + (check && ' inCorrect')}
                            onClick={() => {this.chooseAnswers(4, 'salty')}}
                        />
                        <div
                            className={'flex_el_test ' + (salty.indexOf(3) >= 0 && 'active ') + (check && ' inCorrect')}
                            onClick={() => {this.chooseAnswers(3, 'salty')}}
                        />
                        <div
                            className={'flex_el_test ' + (salty.indexOf(2) >= 0 && 'active ') + (check && ' correct')}
                            onClick={() => {this.chooseAnswers(2, 'salty')}}
                        />
                        <div
                            className={'flex_el_test ' + (salty.indexOf(1) >= 0 && 'active ') + (check && ' correct')}
                            onClick={() => {this.chooseAnswers(1, 'salty')}}
                        />
                        <div
                            className="flex_el_type"
                            onClick={() => {this.showPopup(1)}}
                        >
                            {windowWidth <= 767 ? '2' : 'Соленый'}
                        </div>
                    </div>
                    <div className="flex_el">
                        <div
                            className={'flex_el_test ' + (sour.indexOf(4) >= 0 && 'active ') + (check && ' correct')}
                            onClick={() => {this.chooseAnswers(4, 'sour')}}
                        />
                        <div
                            className={'flex_el_test ' + (sour.indexOf(3) >= 0 && 'active ') + (check && ' correct')}
                            onClick={() => {this.chooseAnswers(3, 'sour')}}
                        />
                        <div
                            className={'flex_el_test ' + (sour.indexOf(2) >= 0 && 'active ') + (check && ' correct')}
                            onClick={() => {this.chooseAnswers(2, 'sour')}}
                        />
                        <div
                            className={'flex_el_test ' + (sour.indexOf(1) >= 0 && 'active ') + (check && ' correct')}
                            onClick={() => {this.chooseAnswers(1, 'sour')}}
                        />
                        <div
                            className="flex_el_type"
                            onClick={() => {this.showPopup(1)}}
                        >
                            {windowWidth <= 767 ? '3' : 'Кислый'}
                        </div>
                    </div>
                    <div className="flex_el">
                        <div
                            className={'flex_el_test ' + (sweet.indexOf(4) >= 0 && 'active ') + (check && ' inCorrect')}
                            onClick={() => {this.chooseAnswers(4, 'sweet')}}
                        />
                        <div
                            className={'flex_el_test ' + (sweet.indexOf(3) >= 0 && 'active ') + (check && ' inCorrect')}
                            onClick={() => {this.chooseAnswers(3, 'sweet')}}
                        />
                        <div
                            className={'flex_el_test ' + (sweet.indexOf(2) >= 0 && 'active ') + (check && ' inCorrect')}
                            onClick={() => {this.chooseAnswers(2, 'sweet')}}
                        />
                        <div
                            className={'flex_el_test ' + (sweet.indexOf(1) >= 0 && 'active ') + (check && ' correct')}
                            onClick={() => {this.chooseAnswers(1, 'sweet')}}
                        />
                        <div
                            className="flex_el_type"
                            onClick={() => {this.showPopup(1)}}
                        >
                            {windowWidth <= 767 ? '4' : 'Сладкий'}
                        </div>
                    </div>
                </div>
                {windowWidth <= 767 &&
                    <div className="description">
                        <p className="description_item">
                            1. Горький
                        </p>
                        <p className="description_item">
                            2. Соленый
                        </p>
                        <p className="description_item">
                            3. Кислый
                        </p>
                        <p className="description_item">
                            4. Сладкий
                        </p>
                    </div>
                }
                {!check &&
                <button
                    className="btn btn_violet btn_center"
                    onClick={this.checkAnswers}
                >
                    проверить
                </button>
                }
                {showPopup &&
                <Popup
                    closePopup={this.closePopup}
                >
                    <div className="Popup_head">
                        {this.popupData[popupActive].head}
                    </div>
                    <TextBlock
                        text={this.popupData[popupActive].text}
                    />
                    <button
                        className="btn btn_violet btn_center"
                        onClick={() => {this.showPopup(popupActive)}}
                    >
                        Продолжить
                    </button>
                </Popup>
                }
            </div>
        )
    }
}
