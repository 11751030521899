import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

// import img1 from '../../../image/lessons/lesson11/step10-1.png'

export default class step10 extends React.Component {

    render() {
        return (
            <div className="step10">
                <TextBlock
                    text={`Чтобы обучить кошку любым вещам или отучить ее от нежелательных привычек, необходимо:`}
                />
                <div className="listNum">
                    <span className="listNum_number">1.</span>
                    Прекратить кричать и наказывать кошку.
                    <ul className="list">
                        <li className="list_item">
                            Кошки чрезмерно активные, когда им скучно, из-за чего их поведение может иметь разрушительные последствия для квартиры, а характер любимца показаться «вредным». Ежедневные игры и расслабляющий массаж тела кошки, аккуратное расчёсывание шерсти помогут кошке успокоиться.
                        </li>
                        <li className="list_item">
                            Если выделить время на регулярные, разнообразные и развивающие игры, а также уделить кошке необходимое ей внимание, то можно свести на нет деструктивное поведение, например, нежелание пользоваться кошачьим лотком.
                        </li>
                    </ul>
                </div>
                <div className="listNum">
                    <span className="listNum_number">2.</span>
                    Настроить кошку на успех в ежедневном проявлении желаемых форм поведения, не забывая о соответствующей награде.
                </div>
                <div className="listNum">
                    <span className="listNum_number">3.</span>
                    Создать среду обитания кошки так, чтобы она знала, что не получит вознаграждение за плохое или нежелательное поведение.
                </div>
                <TextBlock
                    text={`Пример: царапание мебели.
                    
                    Размещая дома когтеточку, необходимо придумать способ, чтобы мебель в глазах кошки перестала быть привлекательным предметом для обточки когтей. Пусть само расположение мебели намекнет ей об этом. В противном случае когтеточка станет простым предметом интерьера.`}
                />
            </div>
        )
    }
}
