import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';

import img1 from "../../../image/lessons/lesson11/step34-1.png";
import img1xs from "../../../image/lessons/lesson11/step34-1xs.png";


export default class step34 extends React.Component {

    render() {
        return (
            <div className="step34">
                <TextBlock
                    text={`У кошек, как и у людей, ожирение принято связывать с угрозой здоровью, и, в большинстве случаев, оно действительно представляет такую угрозу – от элементарных неудобств до тяжелых патологических состояний, сопряженных с риском для жизни.`}
                />
                <InfoBlock
                    text={`Рассмотрите схему. На ней показаны риски, появляющиеся у животных в связи с ожирением.`}
                />
                <picture>
                    <source srcSet={img1xs} media="(max-width: 767px)"/>
                    <img src={img1} alt="" className="imgCenter"/>
                </picture>
            </div>
        )
    }
}
