import React from 'react'
import './style.sass'

import img1 from "../../../../image/lessons/lesson43/modalAfterStep1-1.png";

export default class popapAfterTest extends React.Component {

    componentDidMount() {
        document.body.scrollIntoView( true);
        document.body.style.overflow = "hidden";
    }

    componentWillUnmount() {
        document.body.style.overflow = "";
    }

    render() {
        console.log(this.props)
        return (

            <div className="popapAfterTest">
                <div className="popapAfterTest_wrp">
                    <img className="popapAfterTest_image" src={img1} alt=""/>
                    <div
                        className="popapAfterTest_close"
                        onClick={() => {
                            this.props.closeEndPopap !== undefined ? this.props.closeEndPopap() : this.props.history.push('/MyTraining', {showPopapEnd: false})
                        }}
                    />
                    <div className="popapAfterTest_head">
                        <strong>Поздравляем!</strong>
                    </div>
                    <div className="popapAfterTest_subhead">
                        Вы прошли урок «Музыка для кошек»
                    </div>
                    <div className="popapAfterTest_text">
                        Теперь файлы с музыкой для кошек<br/>доступны для скачивания<br/>в личном кабинете.
                    </div>
                    <div className="popapAfterTest_btn">
                        <button
                            className="btn btn_violet"
                            onClick={() => {
                                this.props.closeEndPopap !== undefined ? this.props.closeEndPopap() : this.props.history.push('/profileCard', {showPopapEnd: false})
                            }}
                        >перейти в личный кабинет
                        </button>
                    </div>
                </div>
            </div>

        )
    }
}
