import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson7/step26-1.png'
import PopupInStep from "../../../Components/LessonAndTestComponents/PopupInStep";


export default class step26 extends React.Component {

    state = {
        pointActive: 0,
        showPopup: false,
        points: [],
    }

    showPopup = (pointActive) => {
        const {points, showPopup} = this.state;
        if (points.indexOf(pointActive) === -1) {
            points.push(pointActive)
        }

        if (!showPopup) {
            this.setState({
                pointActive: pointActive,
                showPopup: true,
            })
        } else if (pointActive >= 1) {
            this.setState({showPopup: false, pointActive: 0})
        } else {
            this.setState({
                pointActive: pointActive +1,
                showPopup: true,
            })
        }

        if (points.length === 1) {
            this.props.showNextBtn();
        }
    }

    closePopup = () => {
        this.setState({showPopup: false})
    }

    render() {
        const {showPopup, pointActive} = this.state
        return (
            <div className="step26">
                <TextBlock
                    text={`Убежищем для кошки является наименее посещаемая или отдаленная часть дома, где кошка имеет доступ к пище, воде, кошачьему лотку, когтеточке, насесту и игрушкам.
                    
                    Кошка может направиться туда, когда ей нужно провести время в одиночестве.
                    
                    Основные рекомендации к обустройству убежища:`}
                />
                <ul className="list">
                    <li className="list_item">
                        Кошка должна иметь возможность беспрепятственно приходить в свое убежище и покидать его.
                    </li>
                    <li className="list_item">
                        Когда кошка находится в своем убежище, другим членам семьи (людям и домашним животным) не следует беспокоить ее.
                    </li>
                    <li className="list_item">
                        Можно оставить в месте убежища включенными радио или телевизор. Это может помочь приглушить нежелательные звуки, а также послужить источником человеческого голоса, который кошка будет ассоциировать с безопасностью.
                    </li>
                    <li className="list_item">
                        Использование специальных кошачьих феромонов в виде спрея или диффузора в убежище может сделать его более безопасным и привлекательным для кошки.
                    </li>
                </ul>
                <img src={img1} alt="" className="imgCenter" onClick={() => {this.showPopup(1)}}/>
                {showPopup &&
                <PopupInStep
                    closePopup={this.closePopup}
                    changePoint={() => {this.showPopup(pointActive)}}
                >
                    <div className={'info'}>
                        <TextBlock
                            text={`
                            
                            Если же у кошки остаются какие-либо проблемы после создания для нее комфортного окружения и установления ежедневного времени для игр, необходимо исключить проблемы со здоровьем.`}
                        />
                    </div>
                </PopupInStep>
                }
            </div>
        )
    }
}
