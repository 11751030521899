import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from "../../../image/lessons/lesson7/step5-1.png";

export default class step5 extends React.Component {

    render() {
        return (
            <div className="step5">
                <TextBlock
                    text={`Необходимо предоставить кошке предмет для царапанья, иначе она начнет повреждать мебель и другие вещи.
                    
                    Важно хвалить кошку каждый раз, когда она использует когтеточку. Это дает ей понять, что она может использовать этот предмет в любой момент, когда ей захочется поточить когти.
                    
                    Также необходимо организовать соответствующие места для лазания и насест, сидя на котором кошка сможет смотреть в окно.`}
                />
                <img src={img1} alt="" className="imgCenter"/>
            </div>
        )
    }
}
