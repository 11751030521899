import React from 'react'
// import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from "../../../image/lessons/lesson13/step17-1.png";

export default class step17 extends React.Component {

    render() {
        return (
            <div className="step13">
                <div className="text-top">
                    <ul>
                        <li>
                            Перестановка мебели в доме изменяет привычную планировку окружающей среды кошки. Перед тем, как планировать  перестановку мебели, обеспечьте кошку необходимым <b>убежищем</b>.
                        </li>
                        <li>
                            Оставить радиоприемник или телевизор включенными рядом с убежищем. Как правило, «белый шум» оказывает на кошку успокаивающее действие. Когда будет закончена перестановка, можно использовать <b>аналог кошачьего лицевого феромона</b> в том месте, где была перестановка мебели.
                        </li>
                        <li>
                            Позвольте кошке исследовать новую планировку в своем собственном темпе.
                        </li>
                    </ul>
                </div>

                <div className="infoImage">
                    <img src={img1} alt=""/>
                </div>

            </div>
        )
    }
}
