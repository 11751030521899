import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from "../../../image/lessons/lesson6/step26-1.png";


export default class step26 extends React.Component {

    render() {
        return (
            <div className="step26">
                <div className="head">
                    Мурлыканье
                </div>
                <TextBlock
                    text={`С помощью этого сигнала кошка:`}
                />
                <div className="block">
                    <ul className="list">
                        <li className="list_item"><b>Демонстрирует удовольствие</b>. Чаще всего кошки мурчат именно от удовольствия, когда их ласкают и гладят.</li>
                        <li className="list_item"><b>Привлекает к себе внимание</b>. Если кошка мурчит и трется о ноги, можно не сомневаться — скорее всего, она хочет, чтобы вы покормили ее или погладили.</li>
                        <li className="list_item"><b>Пытается успокоиться</b>. Установлено, что урчание провоцирует у кошек выработку особенного гормона, который оказывает успокаивающий, расслабляющий и обезболивающий эффект. Так что мурчать кошка может даже при болезни или в страхе.</li>
                    </ul>
                    <img src={img1} alt="" className="block_img"/>
                </div>
            </div>
        )
    }
}
