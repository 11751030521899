import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

export default class step35 extends React.Component {

    render() {
        return (
            <div className="step35">
                <div className="head">
                    Техника работы с пугливыми или агрессивными кошками
                </div>
                <TextBlock
                    text={`Научитесь распознавать ранние признаки проявления страха и принимать соответствующие меры. Тщательно оцените ситуацию, чтобы выбрать уместный подход к пациенту.
                    
                    <b>Что необходимо сделать перед началом приема</b>`}
                />
                <ul className="list">
                    <li className="list_item">
                        Назначая прием, спросите владельца о поведении кошки (дома и в клинике).
                    </li>
                    <li className="list_item">
                        Запишите подходы и методики, которые показали эффективность при лечении, а также с негативным эффектом.
                    </li>
                    <li className="list_item">
                        Минимизируйте проявления страха.
                    </li>
                    <li className="list_item">
                        Чтобы помочь кошке справиться с чувством страха и тревожности, можно использовать лекарственные средства, которые возможно дать внутрь. Используйте пероральный бензодиазепин (например, алпразолам) в качестве анксиолитика и возможного амнезивного средства (важно помнить, что бензодиазепины могут провоцировать агрессию в некоторых случаях).Наиболее доступным и эффективным  препаратом для успокоения кошек в ветеринарных клиниках и домашних условиях является габапентин. Используйте противорвотные препараты (например, маропитант) для страдающих тошнотой и рвотой кошек.
                    </li>
                    <li className="list_item">
                        Избегайте использования ацепромазина, поскольку это седативное, а не анксиолитическое средство. Ацепромазин ограничивает двигательные реакции, повышает чувствительность к шумам.
                    </li>
                </ul>
                <TextBlock
                    text={`Для получения дополнительной информации проконсультируйтесь с анестезиологом.`}
                />
            </div>
        )
    }
}
