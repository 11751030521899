import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

export default class step15 extends React.Component {

    render() {
        return (
            <div className="step15">
                <TextBlock
                    text={`<b>Кошачьи позы также могут показывать их эмоции, выраженные по отношению к другим животным.</b>
                    
                    Эти позы часто выражают либо дружелюбие, либо конфликт, в зависимости от конкретной ситуации. Поведение, в котором поза кошки выражает конфликт, часто бывает трудноуловимой и менее заметной, чем показано на иллюстрациях далее. Такую позу можно заметить только тогда, когда человек знает, что искать, и на что обращать внимание. Например, вы также можете наблюдать, как одна кошка выглядит напряженной или встревоженной, а другая (более доминирующая) кошка блокирует доступ к пище, кошачьим лоткам или другим ресурсам в доме.
                    
                    <b>Поведение кошки может быть довольно сложным, на эту тему написано множество книг. Иллюстративное описание основных поз приводится исключительно в качестве вводной информации, благодаря которой вы сможете различить эмоции вашей кошки.</b>`}
                />
            </div>
        )
    }
}
