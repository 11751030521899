import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from "../../../image/lessons/lesson8/step5-1.png";

export default class step5 extends React.Component {

    render() {
        return (
            <div className="step5">
                <div className="head">Концепции кошачьего поведения</div>
                <div className="flex">
                    <TextBlock
                        text={`Кошачья смелость в совокупности с принятием новизны и взаимодействия варьируются в зависимости от генетической предрасположенности и окружающей среды.
                        
                        Однако, есть несколько способов, как можно уменьшить стресс от посещения клиники для многих кошек. Далее мы расскажем об основных концепциях кошачьего поведения и о том, как на практике применить знания о них.`}
                    />
                    <img src={img1} alt="" className="flex_img"/>
                </div>
            </div>
        )
    }
}
