import React from 'react'

export default class step6 extends React.Component {

    render() {
        return (
            <div className="step6">
                <ul className="list">
                    <li className="list_item">
                        <div className="number">1.</div>
                        Должен полностью закрываться на замок для обеспечения конфиденциальности и безопасности кошки и ее владельца.
                    </li>
                    <li className="list_item">
                        <div className="number">2.</div>
                        Стены кабинета не должны быть прозрачными (возможно использование специальных пленок для стекол).
                    </li>
                </ul>
            </div>
        )
    }
}