import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import PlanInFirstStep from '../../../Components/LessonAndTestComponents/PlanInFirstStep';
import Creator from "../../../Components/LessonAndTestComponents/Creator";
import img1 from "../../../image/lessons/lesson6/step1-1.svg";
import UsedMaterials from "../../../Components/LessonAndTestComponents/UsedMaterials";

export default class step1 extends React.Component {

    planData = [
        `Адаптация кошки к жизни в доме`,
        `Правила подбора игрушек`,
        `Расставание кошки с хозяином`,
        `Взаимодействие кошки с членами семьи и гостями`,
        `Кошки и изменения внутри и снаружи дома`,
        `Знакомство кошки с новыми животными`,
        `Поход в ветеринарную клинику`,
        `Путешествие`,
    ]

    render() {
        return (
            <div className="step1">
                <Creator
                    img={img1}
                    name={'Национальной ассоциацией медицины кошек'}
                />
                <hr className="string"/>
                <div className="text_top">
                </div>
                <div className="head">План урока</div>

                <TextBlock
                    text={`В рамках данного урока мы рассмотрим факторы стресса у кошек, связанные со следующими темами:`}
                />
                <PlanInFirstStep
                    planData={this.planData}
                    type={'violet'}
                />
                <UsedMaterials
                    head={'Использованные материалы:'}
                    text={'Life Stressors of Cats: How to Make Your Cat More Comfortable When Stress Occurs. The Ohio State University: College of Veterinary Medicine'}
                />
            </div>
        )
    }
}
