import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

export default class step9 extends React.Component {

    render() {
        return (
            <div className="step9">
                <TextBlock
                    text={`Выработка соответствующего комплекса мероприятий представляет собой серию шагов, направленных на достижение вашей цели, в данном случае – на реализацию представленных принципов в вашей ветеринарной клинике. Эффективный план действий основывается на <b>SMART-критериях</b> и является конкретным, измеримым, достижимым, релевантным и своевременным. Разработайте отдельный план действий для каждой инициативы, на которой вы хотите сосредоточиться. План действий должен включать следующие элементы:`}
                />
                <ul className="list">
                    <li className="list_item">Заявленная цель</li>
                    <li className="list_item">Конкретные задачи для достижения цели</li>
                    <li className="list_item">Лицо, ответственное за реализацию каждой задачи</li>
                    <li className="list_item">Срок исполнения конкретной задачи</li>
                    <li className="list_item">Ожидаемые результаты</li>
                </ul>
                <div className="head">Цель SMART</div>
                <div className="table">
                    <div className="table_row">
                        <div className="table_row_el el1">S</div>
                        <div className="table_row_el"><p>Specific</p></div>
                        <div className="table_row_el"><p>Конкретная</p></div>
                        <div className="table_row_el"><p>Каких результатов необходимо достичь? Каковы качественные показатели и характеристики ожидаемых результатов?</p></div>
                    </div>
                    <div className="table_row">
                        <div className="table_row_el el1">M</div>
                        <div className="table_row_el"><p>Measurable</p></div>
                        <div className="table_row_el"><p>Измеримая</p></div>
                        <div className="table_row_el"><p>Каковы количественные показатели? Каковы их описания/размерность? Что даст мне возможность судить о достижении цели?</p></div>
                    </div>
                    <div className="table_row">
                        <div className="table_row_el el1">A</div>
                        <div className="table_row_el"><p>Achievable</p></div>
                        <div className="table_row_el"><p>Достижимая</p></div>
                        <div className="table_row_el"><p>Какие дествия необходимо предпринять? Какова должна быть последовательность действий, каковы приоритеты? Какие усилия потребуются для достижения цели? Какие ресурсы необходимы для достижения цели?</p></div>
                    </div>
                    <div className="table_row">
                        <div className="table_row_el el1">R</div>
                        <div className="table_row_el"><p>Relevant Realistic</p></div>
                        <div className="table_row_el"><p>Актуальная Реалистичная</p></div>
                        <div className="table_row_el"><p>Является ли поставленная цель важной для достижения общего успеха? Может ли исполнитель достичь цели?</p></div>
                    </div>
                    <div className="table_row">
                        <div className="table_row_el el1">T</div>
                        <div className="table_row_el"><p>Time-framed</p></div>
                        <div className="table_row_el"><p>Определённая во времени</p></div>
                        <div className="table_row_el"><p>Когда, к какому моменту времени необходимо достичь поставленной цели?</p></div>
                    </div>


                </div>


            </div>
        )
    }
}
