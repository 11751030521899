import React from 'react'
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";

import img1 from '../../../image/lessons/lesson7/step49-1.png'


export default class step49 extends React.Component{

    render() {
        return (
            <div className="step49">
                <TextBlock
                    text={`Кошки наиболее уязвимы во время сна, поэтому они предпочитают отдыхать в тех местах, где они могут чувствовать себя максимально в безопасности, например, на шкафу или под кроватью.
                    
                    Чтобы кошка держалась подальше от кровати и других запрещённых для нее мест, необходимо предложить ей отдохнуть в другом спокойном и безопасном месте.
                    
                    Где бы кошка ни решила отдохнуть, важно не беспокоить ее в этот момент. Тогда, когда она проснется, она будет готова к играм и другим совместным активностям.`}
                />
                <img src={img1} alt="" className="imgCenter"/>
            </div>
        )
    }
}
