import React from 'react'
import VideoBlock from "../../../Components/LessonAndTestComponents/VideoBlock";
import Lecturer from "../../../Components/LessonAndTestComponents/Lecturer";
import img1 from "../../../image/Lecturers/kadochnikova.png";
import img2 from "../../../image/Lecturers/kadochnikova-icon.png";
import video1 from "../../../image/lessons/lesson44/step2-1.mp4";

export default class step2 extends React.Component {

    render() {
        return (
            <div className="step2">
                <Lecturer
                    img={img1}
                    icon={img2}
                    surname={'Кадочникова'}
                    name={'Елена Андреевна'}
                    text={'Ветеринарный врач - репродуктолог. Директор ветеринарного центра «Колибри», г. Долгопрудный'}
                />
                <hr className="string"/>
                <VideoBlock
                    src={'https://www.youtube.com/embed/AWEEnJLHvYk'}
                    title={'https://www.youtube.com/watch?v=AWEEnJLHvYk&feature=youtu.be'}
                />
            </div>
        )
    }
}

