import React from 'react'
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";
import img3 from "../../../image/lessons/lesson43/step3-2.png";
import ListNumeral from "../../../Components/LessonAndTestComponents/ListNumeral";

export default class step3 extends React.Component {

    listData2 = [
        'Мурчание',
        'Мяукание',
        'Звук грудного вскармливания' ,
        'Звуки кормления',
        'Сердцебиение'
    ]

    render() {
        return (
            <div className="step3">
                <hr className="string"/>
                <div className="head">Что такое музыка для кошек</div>
                <TextBlock
                    text={`В музыке используются звуки, которые близки кошкам:`}
                />
                <ListNumeral
                    data={this.listData2}
                />
                <TextBlock
                    text={`Использование особых частот и привычных кошкам звуков снижает уровень стресса у кошек и делает их <span>жизнь замурррчательной!</span>`}
                />
                <img className="main__image" src={img3} alt=""/>
            </div>
        )
    }
}
