import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import Popup from "../../../Components/Popup";

import img1 from '../../../image/lessons/lesson6/step3-1.png'

export default class step3 extends React.Component {

    state = {
        windowWidth: window.innerWidth,
        pointActive: 0,
        showPopup: false,
        points: [],
    };

    //Отлов события ресайз
    handlerResize = () => {
        this.setState({windowWidth: window.innerWidth})
    }

    componentDidMount() {
        window.addEventListener('resize', this.handlerResize)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handlerResize);
    }

    showPopup = (pointActive) => {
        const {points, showPopup, windowWidth} = this.state;
        if (points.indexOf(pointActive) === -1) {
            points.push(pointActive)
        }

        if (windowWidth < 768) {
            if (!showPopup) {
                this.setState({
                    pointActive: pointActive,
                    showPopup: true,
                })
            } else if (pointActive >= 2) {
                this.setState({showPopup: false, pointActive: 0})
            } else {
                this.setState({
                    pointActive: pointActive +1,
                    showPopup: true,
                })
            }
        } else {
            this.setState({points: points, pointActive: pointActive})
        }
        if (points.length === 2) {
            this.props.showNextBtn();
        }
    }

    closePopup = () => {
        this.setState({showPopup: false})
    }

    popupData = {
        0: {
            svg: '',
            text: '',
        },
        1: {
            svg: `
                <svg width="44" height="28" viewBox="0 0 44 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M40.9001 3.6C40.5001 1.85 38.9001 0.5 37.0001 0.5H34.2501L33.7001 3.7C33.6501 4 33.6501 4.35 33.8001 4.65C34.0001 5.1 34.4501 5.4 34.9001 5.5C35.4001 5.6 35.9001 5.45 36.2501 5.05C36.4501 4.8 36.6001 4.5 36.6501 4.2L37.0001 2.3C37.0501 2.05 37.3001 1.85 37.6001 1.9C37.8501 1.95 38.0501 2.2 38.0001 2.5L37.7001 4.35C37.6001 5.05 37.2001 5.7 36.6001 6.1C36.2001 6.4 35.7001 6.5 35.2001 6.5C35.0501 6.5 34.9001 6.5 34.7501 6.45C34.0001 6.3 33.4001 5.9 33.0001 5.25C32.7001 4.7 32.6001 4.1 32.7001 3.5L33.1501 0.85L29.6501 4.7C28.9501 5.5 27.8501 6 26.7501 6H12.5001C11.2001 6 10.0001 6.5 9.10005 7.35C7.40005 7.05 4.55005 6.15 2.85005 3.3C2.45005 2.6 1.55005 2.3 0.850054 2.65C0.0500542 3.05 -0.199946 4 0.250054 4.75C2.25005 8.15 5.35005 9.5 7.65005 10.1C7.55005 10.4 7.50005 10.7 7.50005 11V25.95C7.50005 26.7 8.00005 27.35 8.70005 27.5C9.65005 27.7 10.5001 26.95 10.5001 26.05V16.5H17.3001C18.3501 17.4 20.2501 18 22.5001 18C22.5001 18 23.3501 18 24.5001 18V25.9C24.5001 26.7 25.1001 27.45 25.9001 27.5C26.7501 27.55 27.5001 26.85 27.5001 26V18H29.5001V25.95C29.5001 26.7 30.0001 27.35 30.7001 27.5C31.6501 27.7 32.5001 26.95 32.5001 26.05V16.75C33.7001 15.25 34.5001 13 34.5001 13L36.5001 8H41.0001C42.6501 8 44.0001 6.65 44.0001 5L40.9001 3.6Z"/>
                </svg>
            `,
            text: `Большинство домашних социальных хищников, таких как собаки или даже люди, обычно являются стайными охотниками на крупную добычу, в то время как травоядные животные, например, крупный рогатый скот и лошади, развивают социальные группы для самозащиты`,
        },
        2: {
            svg: `
                <svg width="31" height="41" viewBox="0 0 31 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M30.7 9.09968L26.5 2.99968V1.19968C26.5 0.749679 25.95 0.549679 25.65 0.849679L18.75 7.29968C17.95 8.04968 17.5 9.09968 17.5 10.1997V10.4997C17.5 14.7497 6 16.4997 6 29.9997C6 31.7997 6.1 33.3497 6.35 34.6497C6.25 34.5497 6.15 34.4497 6 34.3497C4.85 33.2497 3.5 30.7997 3.5 25.4997C3.5 23.6497 4.4 21.7497 5.3 19.9497C6.7 17.0497 8.3 13.7497 5.55 10.9497C4.95 10.3497 4 10.3497 3.45 10.9497C2.85 11.5497 2.85 12.4997 3.45 13.0497C4.6 14.1997 4.1 15.5497 2.65 18.5997C1.6 20.6497 0.5 22.9997 0.5 25.4997C0.5 30.6497 1.7 34.3997 4 36.5997C5.2 37.7497 6.5 38.1997 7.4 38.3997C8.35 40.3997 9.5 40.9997 10 40.9997H16C16.85 40.9997 17.5 40.3497 17.5 39.4997C17.5 38.7497 16.95 38.0997 16.2 37.9997H14.5C16.4 36.9497 18 34.7997 18 30.9997C18 27.6997 17.35 26.3497 15.15 25.1497C14.8 24.9497 14.65 24.4997 14.85 24.1497C15.05 23.7997 15.5 23.6497 15.85 23.8497C18.9 25.4497 19.5 27.6997 19.5 30.9997C19.5 32.7997 19.15 34.3997 18.5 35.7497V39.4997C18.5 40.3497 19.15 40.9997 20 40.9997C20.85 40.9997 21.5 40.3497 21.5 39.4997V34.8997C21.85 34.4997 22.2 34.0497 22.5 33.5497V39.4997C22.5 40.3497 23.15 40.9997 24 40.9997C24.85 40.9997 25.5 40.3497 25.5 39.4997V26.1997C25.85 24.6997 26 23.3997 26 22.4997C26 18.5497 25 18.1497 25 15.9997C25 14.8997 25.9 13.9997 27 13.9997C29.2 13.9997 31 12.1997 31 9.99968C31 9.64968 30.9 9.34968 30.7 9.09968Z"/>
                </svg>
            `,
            text: `Кошки, напротив, являются охотниками-одиночками, и их цель – мелкая добыча. Данная стратегия кошек действует иначе, чем у многих других видов, что может быть неприятно для их владельцев, которые не понимают их привычек и особенностей поведения.`,
        },
    }

    render() {
        const {showPopup, pointActive, points} = this.state
        return (
            <div className="step3">
                <TextBlock
                    text={`<b>Кошки имеют иную социальную структуру, нежели собаки.</b>`}
                />
                <InfoBlock
                    text={`Нажмите на активные элементы, чтобы узнать, чем поведение кошек отличается от собак`}
                />
                <div className="box">
                    <div className="box_img">
                        <div
                            className={'box_point ' + (points.indexOf(1) >= 0 && 'active ') + (pointActive === 1 && 'heigher')}
                            onClick={() => {this.showPopup(1)}}
                        >
                            <svg width="44" height="28" viewBox="0 0 44 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M40.9001 3.6C40.5001 1.85 38.9001 0.5 37.0001 0.5H34.2501L33.7001 3.7C33.6501 4 33.6501 4.35 33.8001 4.65C34.0001 5.1 34.4501 5.4 34.9001 5.5C35.4001 5.6 35.9001 5.45 36.2501 5.05C36.4501 4.8 36.6001 4.5 36.6501 4.2L37.0001 2.3C37.0501 2.05 37.3001 1.85 37.6001 1.9C37.8501 1.95 38.0501 2.2 38.0001 2.5L37.7001 4.35C37.6001 5.05 37.2001 5.7 36.6001 6.1C36.2001 6.4 35.7001 6.5 35.2001 6.5C35.0501 6.5 34.9001 6.5 34.7501 6.45C34.0001 6.3 33.4001 5.9 33.0001 5.25C32.7001 4.7 32.6001 4.1 32.7001 3.5L33.1501 0.85L29.6501 4.7C28.9501 5.5 27.8501 6 26.7501 6H12.5001C11.2001 6 10.0001 6.5 9.10005 7.35C7.40005 7.05 4.55005 6.15 2.85005 3.3C2.45005 2.6 1.55005 2.3 0.850054 2.65C0.0500542 3.05 -0.199946 4 0.250054 4.75C2.25005 8.15 5.35005 9.5 7.65005 10.1C7.55005 10.4 7.50005 10.7 7.50005 11V25.95C7.50005 26.7 8.00005 27.35 8.70005 27.5C9.65005 27.7 10.5001 26.95 10.5001 26.05V16.5H17.3001C18.3501 17.4 20.2501 18 22.5001 18C22.5001 18 23.3501 18 24.5001 18V25.9C24.5001 26.7 25.1001 27.45 25.9001 27.5C26.7501 27.55 27.5001 26.85 27.5001 26V18H29.5001V25.95C29.5001 26.7 30.0001 27.35 30.7001 27.5C31.6501 27.7 32.5001 26.95 32.5001 26.05V16.75C33.7001 15.25 34.5001 13 34.5001 13L36.5001 8H41.0001C42.6501 8 44.0001 6.65 44.0001 5L40.9001 3.6Z"/>
                            </svg>
                        </div>
                        <div
                            className={'box_point ' + (points.indexOf(2) >= 0 && 'active ') + (pointActive === 2 && 'heigher')}
                            onClick={() => {this.showPopup(2)}}
                        >
                            <svg width="31" height="41" viewBox="0 0 31 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M30.7 9.09968L26.5 2.99968V1.19968C26.5 0.749679 25.95 0.549679 25.65 0.849679L18.75 7.29968C17.95 8.04968 17.5 9.09968 17.5 10.1997V10.4997C17.5 14.7497 6 16.4997 6 29.9997C6 31.7997 6.1 33.3497 6.35 34.6497C6.25 34.5497 6.15 34.4497 6 34.3497C4.85 33.2497 3.5 30.7997 3.5 25.4997C3.5 23.6497 4.4 21.7497 5.3 19.9497C6.7 17.0497 8.3 13.7497 5.55 10.9497C4.95 10.3497 4 10.3497 3.45 10.9497C2.85 11.5497 2.85 12.4997 3.45 13.0497C4.6 14.1997 4.1 15.5497 2.65 18.5997C1.6 20.6497 0.5 22.9997 0.5 25.4997C0.5 30.6497 1.7 34.3997 4 36.5997C5.2 37.7497 6.5 38.1997 7.4 38.3997C8.35 40.3997 9.5 40.9997 10 40.9997H16C16.85 40.9997 17.5 40.3497 17.5 39.4997C17.5 38.7497 16.95 38.0997 16.2 37.9997H14.5C16.4 36.9497 18 34.7997 18 30.9997C18 27.6997 17.35 26.3497 15.15 25.1497C14.8 24.9497 14.65 24.4997 14.85 24.1497C15.05 23.7997 15.5 23.6497 15.85 23.8497C18.9 25.4497 19.5 27.6997 19.5 30.9997C19.5 32.7997 19.15 34.3997 18.5 35.7497V39.4997C18.5 40.3497 19.15 40.9997 20 40.9997C20.85 40.9997 21.5 40.3497 21.5 39.4997V34.8997C21.85 34.4997 22.2 34.0497 22.5 33.5497V39.4997C22.5 40.3497 23.15 40.9997 24 40.9997C24.85 40.9997 25.5 40.3497 25.5 39.4997V26.1997C25.85 24.6997 26 23.3997 26 22.4997C26 18.5497 25 18.1497 25 15.9997C25 14.8997 25.9 13.9997 27 13.9997C29.2 13.9997 31 12.1997 31 9.99968C31 9.64968 30.9 9.34968 30.7 9.09968Z"/>
                            </svg>
                        </div>
                        <div className={'block red ' + (points.indexOf(1) >= 0 && 'active')}>
                            <div className="block_head">
                                {this.popupData[1].head}
                            </div>
                            <TextBlock
                                text={this.popupData[1].text}
                            />
                        </div>
                        <div className={'block green ' + (points.indexOf(2) >= 0 && 'active')}>
                            <div className="block_head">
                                {this.popupData[2].head}
                            </div>
                            <TextBlock
                                text={this.popupData[2].text}
                            />
                        </div>
                        <img src={img1} className="imgCenter" alt=""/>
                    </div>
                </div>
                {showPopup &&
                <Popup
                    closePopup={this.closePopup}
                >
                    <div className={'info'}>
                        <div className="info_img-box">
                            <div className="info_img"
                                 dangerouslySetInnerHTML={{ __html: this.popupData[pointActive].svg }}
                            />
                        </div>

                        <TextBlock
                            text={this.popupData[pointActive].text}
                        />
                    </div>
                    <button
                        className="btn btn_violet"
                        onClick={() => {this.showPopup(pointActive)}}
                    >
                        Продолжить
                    </button>
                </Popup>
                }
            </div>
        )
    }
}
