import React from 'react'

export default class step7 extends React.Component {

    render() {
        return (
            <div className="step7">
                <ul className="list">
                    <li className="list_item">
                        Если требуется елизаветинский воротник, используйте его в том случае, когда он сделан из мягкого материала вместо жесткого пластика.
                    </li>
                    <li className="list_item">
                        Кошки не любят тугие или ограничительные повязки и бинты. Кошки любят потягиваться и свободно двигаться. Эластичные, самоклеящиеся и не ограничивающие движения повязки хорошо переносятся кошками. Большинство кошек предпочитают трубчатый чулок вместо белой ленты из оксида цинка или нерасширяющихся марлевых повязок.
                    </li>
                    <li className="list_item">
                        Многие кошки отрицательно реагируют на спиртосодержащие антисептики для очистки кожи или «смачивания» области перед венепункцией, поскольку им не нравится запах или внезапное ощущение холода при испарении алкоголя. В качестве альтернативы используйте теплый стерильный физиологический раствор, воду или бесспиртовые антисептики.
                    </li>
                    <li className="list_item">
                        Кошки метят свою территорию с помощью лицевых феромонов и будут демонстрировать такое поведение в ветеринарной клетке. Они могут тереться мордочкой о подстилку, коробки, стенки и дверь клетки. Избегайте очистки соответствующих областей в клетке, помеченных кошкой.
                    </li>
                    <li className="list_item">
                        Кошки хорошо известны своей чистоплотностью и вниманием к уходу за собой. Больные или ослабленные кошки, как правило, в меньшей степени способны ухаживать за собой и нуждаются в стороннем уходе. Удалите кровь или лекарственные растворы с кожи или волос кошки, чтобы минимизировать позывы к личной гигиене, которую должна выполнять кошка. Удалите все вещества, которые могут быть неприятны на вкус кошке, когда она ухаживает за собой.
                    </li>
                </ul>

            </div>
        )
    }
}
