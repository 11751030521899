import React from 'react'
// import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from "../../../image/lessons/lesson8/step4-1.png";

export default class step4 extends React.Component {

    render() {
        return (
            <div className="step4">
                <div className="flex">
                    <ul className="list">
                        <li className="list_item">Люди часто неверно интерпретируют поведение кошек и то, как они справляются со стрессом и конфликтами.</li>
                        <li className="list_item"><b>Ветеринарные врачи могут помочь владельцам кошек сформировать реалистичные ожидания относительно поведения кошек и способов решения сопутствующих проблем.</b></li>
                        <li className="list_item">Начните с обучения вашего ветеринарного персонала и ваших клиентов, расскажите им об уникальных социальных и поведенческих характеристиках кошки. Помогите им научиться интерпретировать поведение с точки зрения животного, то есть научите их в буквальном смысле «думать как кошка».</li>
                    </ul>
                    <img src={img1} alt="" className="flex_img"/>
                </div>
            </div>
        )
    }
}
