import React from 'react'
import VideoBlock from "../../../Components/LessonAndTestComponents/VideoBlock";
import Lecturer from "../../../Components/LessonAndTestComponents/Lecturer";

import img1 from '../../../image/Lecturers/pozharskaya.png'
import img2 from '../../../image/Lecturers/pozharskaya-icon.png'

export default class step2 extends React.Component {

    render() {
        return (
            <div className="step2">
                <Lecturer
                    img={img1}
                    icon={img2}
                    surname={'Пожарская'}
                    name={'Дарья Сергеевна'}
                    text={'Ветеринарный врач дерматолог-эндокринолог, научный руководитель отделения дерматологии сети в/к «Свой Доктор», ИВЦ МВА, г. Москва.'}
                />

                <hr className="string"/>

                <VideoBlock
                    src={'https://www.youtube.com/embed/siAqAhHPa7w'}
                    title={'https://www.youtube.com/watch?v=siAqAhHPa7w&feature=youtu.be'}
                />
            </div>
        )
    }
}
