import React from 'react'
import {withRouter} from 'react-router-dom';
import Api from '../../../Service/Api'

import LessonPlan from "../../../Components/LessonAndTestComponents/LessonPlan";
import MainButton from "../../../Components/LessonAndTestComponents/MainButton";
import MainHeader from "../../../Components/LessonAndTestComponents/MainHeader";
import ModalAfterStep from "../../../Components/LessonAndTestComponents/ModalAfterStep";

import './style.sass'
import Step1 from './step1';
import Step2 from './step2';
import Step3 from './step3';
import Step4 from './step4';
import Step5 from './step5';
import Step6 from './step6';
import Step7 from './step7';
import Step8 from "./step8";
import Step9 from "./step9";
import Step10 from "./step10";
import Step11 from "./step11";
import Step12 from "./step12";
import Step13 from "./step13";
import Step14 from "./step14";
import Step15 from "./step15";
import Step16 from "./step16";
import Step17 from "./step17";
import Step18 from "./step18";
import Step19 from "./step19";
import Step20 from "./step20";
import Step21 from "./step21";
import Step22 from "./step22";
import Step23 from "./step23";
import Step24 from "./step24";
import Step25 from "./step25";
import Step26 from "./step26";
import Step27 from "./step27";
import Step28 from "./step28";
import Step29 from "./step29";
import Step30 from "./step30";

class Lesson6 extends React.Component {
    Api = new Api();

    state = {
        lessonStepsActive: 1, // Пункт меню справа
        stepActive: 1, // Текуший шаг урока
        MainHeader_header: 'Урок 2', // Первая линия заголовка шага
        MainHeader_subheader: 'Особенности поведения кошек', // Вторая линия заголовка шага
        MainHeader_class: 'bigHeader', // Большой или маленький Заголовок
        MainHeader_text: '', //Текст под заголовком
        typeMainBtn: 'take a lesson', // тип кнопок (take a lesson - Пройти урок, '' - стандартно медлу щагами)
        showNextBtn: true, // Показывать кнопку продолжить?
        showPrevBtn: true, // Показывать кнопку вернуться?
        showPopap: false, // Показ попапа на шаге
        modalImg: '', // Картинка на верху модалки
        modalImg2: '', // Картинка в теле модалки
        modalHead: '', // шапка в модалке
        modalText: '', // текст в модалке
        arrayStepsPlan: [], //массив шагов плана урока (lessonStepsActive)
    }

    componentDidMount() {
        const {arrayStepsPlan} = this.state
        const {lesson} = this.props;
        let stepActive = lesson.steps
        stepActive = stepActive === lesson.attributes.steps ? 1 : stepActive + 1
        this.stepActiveStateUpdate(stepActive)
        // this.stepActiveStateUpdate(28)
        if (lesson.steps === lesson.attributes.steps) {
            // eslint-disable-next-line
            Object.values(this.settingStepsLesson).map((item) => {
                arrayStepsPlan.push(item.state.lessonStepsActive)
                this.setState({arrayStepsPlan: arrayStepsPlan})
            })
        }
    }

    // Переключалка шагов
    _renderStep = () => {
        const {stepActive} = this.state;
        const {lesson} = this.props;

        if (this.settingStepsLesson[stepActive] !== undefined) {
            return this.settingStepsLesson[stepActive].view;
        } else {
            lesson.plan = lesson.lessonPlan;
            lesson.MainHeader_header = 'Урок 2';
            lesson.MainHeader_subheader = 'Особенности поведения кошек';
            this.props.history.push('/test', lesson);

        }
    }

    // _renderStep = () => {
    //     const {stepActive} = this.state;
    //     const {lesson} = this.props;
    //
    //     if (this.settingStepsLesson[stepActive] !== undefined) {
    //         return this.settingStepsLesson[stepActive].view;
    //     } else {
    //         this.Api.getActualStep(lesson)
    //             .then((res) => {
    //                 if (res !== undefined) {
    //                     let actualStep = res[0].course.lessons[1].steps;
    //                     console.log(this.props);
    //                     if(lesson.steps !== lesson.attributes.steps) {
    //                         console.log('Что-то пошло не так')
    //                         this.props.history.push('/myTraining');
    //                     }
    //                     else {
    //                         lesson.plan = lesson.lessonPlan;
    //                         lesson.MainHeader_header = 'Урок 2';
    //                         lesson.MainHeader_subheader = 'Особенности поведения кошек';
    //                         this.props.history.push('/test', lesson);
    //                     }
    //                 }
    //             })
    //     }
    // }

    // Действие по кнопке продолжить
    showNextStep = () => {
        const {lesson} = this.props;
        let {stepActive} = this.state;
        this.Api.setLessonStats(lesson.attributes.id, stepActive)
        stepActive++;
        this.setStateBtnSteps(stepActive)
    }
    // Действие по кнопке вернутся
    showPrevStep = () => {
        let {stepActive} = this.state;
        stepActive--;
        this.setStateBtnSteps(stepActive)
    }
    // установка стайтов для смены шага
    setStateBtnSteps = stepActive => {
        this.setState({
            stepActive,
            showPopap: false
        });
        this.stepActiveStateUpdate(stepActive)
    }
    // показать попап на шаге.
    showPopap = () => {
        this.setState({
            showPopap: true
        });
    }

    closePopap = () => {
        this.setState({
            showPopap: false,
        })
    }

    // Состояния заголовков, кнопок, оглавления на текшем шаге
    stepActiveStateUpdate = stepActive => {
        this.setState({stepActive}, () => {
            if (this.settingStepsLesson[stepActive] !== undefined) {
                return this.setState({
                    MainHeader_header: this.settingStepsLesson[stepActive].state['MainHeader_header'],
                    MainHeader_subheader: this.settingStepsLesson[stepActive].state['MainHeader_subheader'],
                    MainHeader_class: this.settingStepsLesson[stepActive].state['MainHeader_class'],
                    MainHeader_text: this.settingStepsLesson[stepActive].state['MainHeader_text'],
                    typeMainBtn: this.settingStepsLesson[stepActive].state['typeMainBtn'],
                    showNextBtn: this.settingStepsLesson[stepActive].state['showNextBtn'],
                    lessonStepsActive: this.settingStepsLesson[stepActive].state['lessonStepsActive'],
                    modalImg: this.settingStepsLesson[stepActive].state['modalImg'],
                    modalImg2: this.settingStepsLesson[stepActive].state['modalImg2'],
                    modalHead: this.settingStepsLesson[stepActive].state['modalHead'],
                    modalText: this.settingStepsLesson[stepActive].state['modalText'],
                });
            }
        })
    }

    // Вызвать чтобы показать кнопку продолжить
    showNextBtn = () => {
        this.setState({
            showNextBtn: true
        })
    };

    render() {
        const {
            lessonStepsActive,
            typeMainBtn,
            showNextBtn,
            MainHeader_header,
            MainHeader_subheader,
            MainHeader_class,
            MainHeader_text,
            modalImg,
            modalImg2,
            modalHead,
            modalText,
            showPopap
        } = this.state;
        return (

            <div className="lesson">
                <div className="content_lesson">
                    <div className="step lesson6">
                        {/* Основной заголовок шага */}
                        <MainHeader
                            header={MainHeader_header}
                            subheader={MainHeader_subheader}
                            headerClass={MainHeader_class}
                            text={MainHeader_text}
                        />
                        {/* Вывод Шага урока */}
                        {this._renderStep()}
                    </div>
                    {/* Вывод Кнопок урока */}
                    <MainButton
                        type={typeMainBtn}
                        showPopap={this.showPopap}
                        showNextStep={this.showNextStep}
                        showPrevStep={this.showPrevStep}
                        showNextBtn={showNextBtn}
                        customBtnText={'Продолжить'}
                    />
                </div>
                {/* Модальное окно после шага */}
                {showPopap &&
                <ModalAfterStep
                    closePopap={this.closePopap}
                    modalImg={modalImg}
                    modalImg2={modalImg2}
                    modalHead={modalHead}
                    modalText={modalText}
                    type={'whiskas'}
                    showNextStep={this.showNextStep}
                />
                }
                {/* Вывод Оглавление урока */}
                <LessonPlan
                    lessonImg={this.props.lesson.attributes.image} // Картинка урока
                    lessonNumeral={parseInt(this.props.lesson.attributes.name.match(/\d+/), 10)} //Номер урока для планшета
                    lessonName={'Особенности поведения кошек'} // Название урока
                    lessonSteps={this.props.lesson.lessonPlan} // Шаги урока
                    lessonStepsActive={lessonStepsActive - 1} // Активный шаг урока
                    onClick={this.setStateBtnSteps}
                    arrayStepsPlan={this.state.arrayStepsPlan}
                />
            </div>

        )
    }

    settingStepsLesson = {
        1: {
            view: <Step1 showNextBtn={this.showNextBtn}/>,
            state: {
                MainHeader_header: 'Урок 2',
                MainHeader_subheader: 'Особенности поведения кошек',
                MainHeader_class: 'bigHeader',
                typeMainBtn: 'custom',
                showNextBtn: true,
                lessonStepsActive: 1
            }
        },
        2: {
            view: <Step2 showNextBtn={this.showNextBtn}/>,
            state: {
                MainHeader_header: 'Как понять кошку?',
                MainHeader_subheader: '',
                MainHeader_class: '',
                typeMainBtn: 'next step',
                showNextBtn: true,
                lessonStepsActive: 1
            }
        },
        3: {
            view: <Step3 showNextBtn={this.showNextBtn}/>,
            state: {
                MainHeader_header: 'Чем поведение кошек отличается от собак?',
                MainHeader_subheader: '',
                MainHeader_class: '',
                typeMainBtn: 'next step',
                showNextBtn: false,
                lessonStepsActive: 1
            }
        },
        4: {
            view: <Step4/>,
            state: {
                typeMainBtn: 'take a lesson',
                MainHeader_header: 'Особенности поведения кошек',
                MainHeader_subheader: '',
                MainHeader_class: '',
                lessonStepsActive: 1,
                MainHeader_text: ''
            }
        },
        5: {
            view: <Step5 showNextBtn={this.showNextBtn}/>,
            state: {
                MainHeader_header: 'Организация сна',
                MainHeader_subheader: '',
                MainHeader_class: '',
                typeMainBtn: 'next step',
                showNextBtn: false,
                lessonStepsActive: 2,
            }
        },
        6: {
            view: <Step6 showNextBtn={this.showNextBtn}/>,
            state: {
                MainHeader_header: 'Повышение активности кошки',
                MainHeader_subheader: '',
                MainHeader_class: '',
                typeMainBtn: 'next step',
                showNextBtn: true,
                lessonStepsActive: 3,
            }
        },
        7: {
            view: <Step7 showNextBtn={this.showNextBtn}/>,
            state: {
                MainHeader_header: 'Повышение активности кошки',
                MainHeader_subheader: '',
                MainHeader_class: '',
                typeMainBtn: 'next step',
                showNextBtn: true,
                lessonStepsActive: 3,
            }
        },
        8: {
            view: <Step8 showNextBtn={this.showNextBtn}/>,
            state: {
                MainHeader_header: 'Повышение активности кошки',
                MainHeader_subheader: '',
                MainHeader_class: '',
                typeMainBtn: 'next step',
                showNextBtn: false,
                lessonStepsActive: 3,
            }
        },
        9: {
            view: <Step9 showNextBtn={this.showNextBtn}/>,
            state: {
                MainHeader_header: 'Повышение активности кошки',
                MainHeader_subheader: '',
                MainHeader_class: '',
                typeMainBtn: 'show popap',
                showNextBtn: false,
                lessonStepsActive: 3,
                modalText: `<span class="text_violet">Используйте в своих интересах любой предмет, с которым ваша кошка любит играть, заставляя ее больше двигаться.</span>
                
                Какая бы игрушка или игра ни понравилась вашей кошке, старайтесь не использовать все игрушки сразу, чтобы не портить удовольствие. Не поддавайтесь искушению оставить вашей кошке ее любимые игрушки на целый день. Вместо этого оставьте кошке несколько игрушек в течение дня, а самые любимые игрушки приносите ей один раз в день или даже раз в неделю. Если вы последуете этому совету, ваша кошка будет оставаться увлеченной гораздо дольше!`
            }
        },
        10: {
            view: <Step10 showNextBtn={this.showNextBtn}/>,
            state: {
                MainHeader_header: 'Скрытый инстинкт',
                MainHeader_subheader: '',
                MainHeader_class: '',
                typeMainBtn: 'next step',
                showNextBtn: true,
                lessonStepsActive: 4,
            }
        },
        11: {
            view: <Step11 showNextBtn={this.showNextBtn}/>,
            state: {
                MainHeader_header: 'Скрытый инстинкт',
                MainHeader_subheader: '',
                MainHeader_class: '',
                typeMainBtn: 'next step',
                showNextBtn: true,
                lessonStepsActive: 4,
            }
        },
        12: {
            view: <Step12 showNextBtn={this.showNextBtn}/>,
            state: {
                MainHeader_header: 'Скрытый инстинкт',
                MainHeader_subheader: '',
                MainHeader_class: '',
                typeMainBtn: 'next step',
                showNextBtn: true,
                lessonStepsActive: 4,
            }
        },
        13: {
            view: <Step13 showNextBtn={this.showNextBtn}/>,
            state: {
                MainHeader_header: 'Скрытый инстинкт',
                MainHeader_subheader: '',
                MainHeader_class: '',
                typeMainBtn: 'next step',
                showNextBtn: true,
                lessonStepsActive: 4,
            }
        },
        14: {
            view: <Step14 showNextBtn={this.showNextBtn}/>,
            state: {
                MainHeader_header: 'Скрытый инстинкт',
                MainHeader_subheader: '',
                MainHeader_class: '',
                typeMainBtn: 'next step',
                showNextBtn: true,
                lessonStepsActive: 4,
            }
        },
        15: {
            view: <Step15 showNextBtn={this.showNextBtn}/>,
            state: {
                MainHeader_header: 'Скрытый инстинкт',
                MainHeader_subheader: '',
                MainHeader_class: '',
                typeMainBtn: 'show popap',
                showNextBtn: true,
                lessonStepsActive: 4,
                modalText: `<b>При перестановке мебели или переезде учитывайте, сможет ли ваша кошка иметь необходимый ей угол обзора, если вы поменяете местами мебель в вашем доме.</b>`
            }
        },
        16: {
            view: <Step16 showNextBtn={this.showNextBtn}/>,
            state: {
                MainHeader_header: 'Социальные привычки',
                MainHeader_subheader: '',
                MainHeader_class: '',
                typeMainBtn: 'next step',
                showNextBtn: false,
                lessonStepsActive: 5,
            }
        },
        17: {
            view: <Step17 showNextBtn={this.showNextBtn}/>,
            state: {
                MainHeader_header: 'Пищевые привычки и рацион',
                MainHeader_subheader: '',
                MainHeader_class: '',
                typeMainBtn: 'next step',
                showNextBtn: true,
                lessonStepsActive: 6,
            }
        },
        18: {
            view: <Step18 showNextBtn={this.showNextBtn}/>,
            state: {
                MainHeader_header: 'Территория',
                MainHeader_subheader: '',
                MainHeader_class: '',
                typeMainBtn: 'next step',
                showNextBtn: true,
                lessonStepsActive: 7,
            }
        },
        19: {
            view: <Step19 showNextBtn={this.showNextBtn}/>,
            state: {
                MainHeader_header: 'Территория',
                MainHeader_subheader: '',
                MainHeader_class: '',
                typeMainBtn: 'next step',
                showNextBtn: true,
                lessonStepsActive: 7,
            }
        },
        20: {
            view: <Step20 showNextBtn={this.showNextBtn}/>,
            state: {
                MainHeader_header: 'Территория',
                MainHeader_subheader: '',
                MainHeader_class: '',
                typeMainBtn: 'next step',
                showNextBtn: true,
                lessonStepsActive: 7,
            }
        },
        21: {
            view: <Step21 showNextBtn={this.showNextBtn}/>,
            state: {
                MainHeader_header: 'Территория',
                MainHeader_subheader: '',
                MainHeader_class: '',
                typeMainBtn: 'next step',
                showNextBtn: true,
                lessonStepsActive: 7,
            }
        },
        22: {
            view: <Step22 showNextBtn={this.showNextBtn}/>,
            state: {
                MainHeader_header: 'Уход за шерстью и температура',
                MainHeader_subheader: '',
                MainHeader_class: '',
                typeMainBtn: 'next step',
                showNextBtn: true,
                lessonStepsActive: 8,
            }
        },
        23: {
            view: <Step23 showNextBtn={this.showNextBtn}/>,
            state: {
                MainHeader_header: 'Уход за шерстью и температура',
                MainHeader_subheader: '',
                MainHeader_class: '',
                typeMainBtn: 'next step',
                showNextBtn: true,
                lessonStepsActive: 8,
            }
        },
        24: {
            view: <Step24 showNextBtn={this.showNextBtn}/>,
            state: {
                MainHeader_header: 'Самые опасные хищники для кошек',
                MainHeader_subheader: '',
                MainHeader_class: '',
                typeMainBtn: 'next step',
                showNextBtn: true,
                lessonStepsActive: 9,
            }
        },
        25: {
            view: <Step25 showNextBtn={this.showNextBtn}/>,
            state: {
                MainHeader_header: 'Эмоции кошек',
                MainHeader_subheader: '',
                MainHeader_class: '',
                typeMainBtn: 'next step',
                showNextBtn: true,
                lessonStepsActive: 10,
            }
        },
        26: {
            view: <Step26 showNextBtn={this.showNextBtn}/>,
            state: {
                MainHeader_header: 'Эмоции кошек',
                MainHeader_subheader: '',
                MainHeader_class: '',
                typeMainBtn: 'next step',
                showNextBtn: true,
                lessonStepsActive: 10,
            }
        },
        27: {
            view: <Step27 showNextBtn={this.showNextBtn}/>,
            state: {
                MainHeader_header: 'Эмоции кошек',
                MainHeader_subheader: '',
                MainHeader_class: '',
                typeMainBtn: 'next step',
                showNextBtn: true,
                lessonStepsActive: 10,
            }
        },
        28: {
            view: <Step28 showNextBtn={this.showNextBtn}/>,
            state: {
                MainHeader_header: 'Эмоции кошек',
                MainHeader_subheader: '',
                MainHeader_class: '',
                typeMainBtn: 'next step',
                showNextBtn: true,
                lessonStepsActive: 10,
            }
        },
        29: {
            view: <Step29 showNextBtn={this.showNextBtn}/>,
            state: {
                MainHeader_header: 'Эмоции кошек',
                MainHeader_subheader: '',
                MainHeader_class: '',
                typeMainBtn: 'next step',
                showNextBtn: true,
                lessonStepsActive: 10,
            }
        },
        30: {
            view: <Step30 showNextBtn={this.showNextBtn}/>,
            state: {
                MainHeader_header: 'Эмоции кошек',
                MainHeader_subheader: '',
                MainHeader_class: '',
                typeMainBtn: 'next step',
                showNextBtn: true,
                lessonStepsActive: 10,
            }
        },

    }

}

export default withRouter(Lesson6);
