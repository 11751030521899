import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock'

import img1 from '../../../image/lessons/lesson11/step23-1.png'
import img2 from '../../../image/lessons/lesson11/step23-2.png'


export default class step23 extends React.Component {


    render() {
        return (
            <div className="step23">
                <TextBlock
                    text={`Корм должен соответствовать жизненной стадии питомца и предлагаться в достаточном количестве. Тогда организм животного будет обеспечен необходимым уровнем энергии и питательных веществ.`}
                />
                <InfoBlock
                    text={`Сравните энергетическую ценность сухого и влажного корма.`}
                />

                <div className="flex">
                    <div className="flex_el">
                        <span>СУХОЙ КОРМ</span>
                        330-420 ккал / 100 г
                        <img src={img1} alt=""/>
                    </div>
                    <div className="flex_el">
                        <span>ВЛАЖНЫЙ КОРМ</span>
                        60-100 ккал / 100 г
                        <img src={img2} alt=""/>
                    </div>
                </div>
            </div>
        )
    }
}
