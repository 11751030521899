import React from "react";
import "./style.sass"
import DropDownPanel from "../../Components/DropDownPanel"

const faq = [
    {
        question: "Как начисляются баллы?",
        answer: "За прохождение каждого урока вы получаете определенную сумму баллов. Информацию об этом вы можете найти во вкладке «Личный кабинет». Прохождение тестов также дает вам дополнительные баллы, которые зависят от количества правильных ответов. Для получения сертификата нужно пройти обучение на образовательной платформе Cat Friendly по всем темам раздела Общий курс Cat Friendly и 30% тем в разделе Медицина кошек, ответив правильно на 70% вопросов тестов в каждом уроке."
    },
    {
        question: "Что такое статус и для чего он нужен?",
        answer: "Статус показывает ваш прогресс в прохождении обучающего курса и зависит от количества набранных баллов. Посмотреть свой статус можно во вкладке «Программа обучения ветеринарных врачей» вверху страницы."
    },
    {
        question: "Что делать, если у меня возникли вопросы?",
        answer: "Напишите свои вопросы и пожелания администраторам системы по электронному адресу: maria.baldina@effem.com. \n" +
            "Ответ придет максимально оперативно. "
    },
    {
        question: "Могу ли я проходить уроки в произвольном порядке?",
        answer: "Да, вы можете проходить курс в удобном для вас порядке."
    },
    {
        question: "Как присваиваются статусы?",
        answer: [`Статус присваивается в соответствии с заработанными в процессе обучения баллами и, в зависимости от их количества, пользователю присваивается один из следующих статусов: `,<br/>,
            <table>
                <tr>
                    <th>Статус</th>
                    <th>Количество баллов</th>
                </tr>
                <tr>
                    <td>Новичок</td>
                    <td>0 баллов</td>
                </tr>
                <tr>
                    <td>Специалист</td>
                    <td>50 баллов</td>
                </tr>
                <tr>
                    <td>Профессионал</td>
                    <td>100 баллов</td>
                </tr>
                <tr>
                    <td>Мастер</td>
                    <td>200 баллов</td>
                </tr>
                <tr>
                    <td>Знаток</td>
                    <td>500 баллов</td>
                </tr>
                <tr>
                    <td>Мудрец</td>
                    <td>800 баллов</td>
                </tr>
                <tr>
                    <td>Гуру</td>
                    <td>1200 баллов</td>
                </tr>
            </table>, <br/>,
            `Обращаем ваше внимание, что статус не отображается в сертификате.`]
    },
]

export default function Faq() {

    return (
        <div className="faq">
            <div className="faq_head">
                Вопросы и ответы
            </div>
            <div className="faq_subhead">
                Вопросы, наиболее часто задаваемые специалистам.
            </div>
            {faq.map((item, index) => {
                return (
                    <DropDownPanel
                        key={index}
                        number={index + 1}
                        question={item.question}
                        answer={item.answer}
                    />
                )
            })}
        </div>
    )
}

