import React from 'react'
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";

import img1 from '../../../image/lessons/lesson32/step9-1.png'
import img2 from '../../../image/lessons/lesson32/step9-2.png'
import img3 from '../../../image/lessons/lesson32/step9-3.png'

export default class step9 extends React.Component {


    render() {
        return (
            <div className="step9">
                <TextBlock
                    text={`Сухой продукт в основном состоит из мяса и субпродуктов в дегидратированном (то есть высушенном) виде. Рассмотрите таблицу, где описаны основные источники сырья в сухом продукте.`}
                />
                <div className="flex flex_header">
                    <div className="flex_el_header">
                        <div className="attention">
                            Тип сырья
                        </div>
                    </div>
                    <div className="flex_el_header">
                        <div className="attention">
                            Сырой материал
                        </div>
                    </div>
                    <div className="flex_el_header">
                        <div className="attention">
                            Функция
                        </div>
                    </div>
                </div>
                <div className="flex">
                    <div className="flex_el">
                        <h3 className="flex_head">
                            Тип сырья:
                        </h3>
                        <div className="flex_wrp">
                            <div className="flex_wrp_head">
                                мясо
                            </div>
                            <img className="img" src={img1} alt=""/>
                        </div>
                    </div>
                    <div className="flex_el">
                        <h3 className="flex_head">
                            Сырой материал:
                        </h3>
                        <div className="flex_wrp">
                            <TextBlock
                                text={`мука из птицы
                            говяжья мука
                            куриные субпродукты
                            мука из тунца
                            мука из ягненка
                            мука из кролика
                            мука из лосося
                            мука из креветок`}
                            />
                        </div>
                    </div>
                    <div className="flex_el">
                        <h3 className="flex_head">
                            Функция:
                        </h3>
                        <div className="flex_wrp">
                            <TextBlock
                                text={`источник животного белка и незаменимых аминокислот`}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex">
                    <div className="flex_el">
                        <h3 className="flex_head">
                            Тип сырья:
                        </h3>
                        <div className="flex_wrp">
                            <div className="flex_wrp_head">
                                злаки
                            </div>
                            <img className="img" src={img2} alt=""/>
                        </div>
                    </div>
                    <div className="flex_el">
                        <h3 className="flex_head">
                            Сырой материал:
                        </h3>
                        <div className="flex_wrp">
                            <TextBlock
                                text={`мука из пшеницы
                            мука из риса
                            мука из кукурузы`}
                            />
                        </div>
                    </div>
                    <div className="flex_el">
                        <h3 className="flex_head">
                            Функция:
                        </h3>
                        <div className="flex_wrp">
                            <TextBlock
                                text={`источники легко усвояемых углеводов и клетчатки`}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex">
                    <div className="flex_el">
                        <h3 className="flex_head">
                            Тип сырья:
                        </h3>
                        <div className="flex_wrp">
                            <div className="flex_wrp_head">
                                масла и жиры
                            </div>
                            <img className="img" src={img3} alt=""/>
                        </div>
                    </div>
                    <div className="flex_el">
                        <h3 className="flex_head">
                            Сырой материал:
                        </h3>
                        <div className="flex_wrp">
                            <TextBlock
                                text={`подсолнечное масло
                            оливковое масло
                            рыбий жир
                            животный жир`}
                            />
                        </div>
                    </div>
                    <div className="flex_el">
                        <h3 className="flex_head">
                            Функция:
                        </h3>
                        <div className="flex_wrp">
                            <TextBlock
                                text={`источник энергии, жирных кислот, жирорастворимых витаминов`}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
