import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import PlanInFirstStep from '../../../Components/LessonAndTestComponents/PlanInFirstStep';
import Lecturer from '../../../Components/LessonAndTestComponents/Lecturer';

import img1 from '../../../image/Lecturers/voiteha.png'
import img2 from '../../../image/Lecturers/voiteha-icon.png'
import img3 from '../../../image/Lecturers/shilkin.png'
import img4 from '../../../image/Lecturers/shilkin-icon.png'
import img5 from '../../../image/Lecturers/barinina.png'
import img6 from '../../../image/Lecturers/barinina-icon.png'

export default class step1 extends React.Component {

    planData = [
        `Видеолекция: Сложные патологии роговицы и радужной оболочки у кошек. Как выбрать правильный подход к лечению?`,
        `Тест`,
    ]

    render() {
        return (
            <div className="step1">
                <Lecturer
                    img={img1}
                    icon={img2}
                    surname={'Войтеха'}
                    name={'Мария Александровна'}
                    text={'Ветеринарный врач-офтальмолог, кандидат биологических наук, микрохирург Центра ветеринарной офтальмологии доктора Шилкина А.Г., г. Москва.'}
                />

                <Lecturer
                    img={img3}
                    icon={img4}
                    surname={'Шилкин'}
                    name={'Алексей Германович'}
                    text={'Ветеринарный врач-офтальмолог, кандидат медицинских наук, доцент, руководитель Центра ветеринарной офтальмологии доктора Шилкина А.Г., г. Москва.'}
                />

                <Lecturer
                    img={img5}
                    icon={img6}
                    surname={'Барынина'}
                    name={'Юлия Сергеевна'}
                    text={'Ветеринарный врач Центра ветеринарной офтальмологии доктора Шилкина А.Г., г. Москва.'}
                />

                <hr className="string"/>

                <div className="head">План урока</div>

                <TextBlock
                    text={`В этом уроке вы узнаете:`}
                />

                <PlanInFirstStep
                    planData={this.planData}
                    type={'violet'}
                />

            </div>
        )
    }
}
