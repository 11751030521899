import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import OnClickTest from "../../../Components/LessonAndTestComponents/OnClickTest";

import img1 from "../../../image/lessons/lesson7/step36-1.png";


export default class step34 extends React.Component {

    state = {
        answersData:
            [
                `Да`,
                `Нет`,
            ]
    }

    showNextBtn = () => {
        this.props.showNextBtn()
    }

    render() {
        return (
            <div className="step34">
                <TextBlock
                    text={`Определите, иллюстрируют ли следующие картинки конфликт между кошками:`}
                />
                <img src={img1} alt="" className="imgCenter"/>
                <OnClickTest
                    ansewrsData={this.state.answersData}
                    type={'block'}
                    rightAnswers={[1]}
                    showNextBtn={this.showNextBtn}
                    quantity={50}
                    correctText={`Данная картинка иллюстрирует конфликтное поведение кошек. Когда угроза близка, живот и лапы обороняющейся кошки (в данном случае − это кошка слева) могут касаться пола. Уши и бакенбарды будут прижаты к голове, а зубы могут быть обнажены. Если во время конфликта кошки оказываются на спине или  переворачиваются на спину целенаправленно, то лишь для того, чтобы  яростно отразить нападение, защищаясь всеми четырьмя лапами. В то время как собаки при конфликтной ситуации демонстрируют живот, чтобы избежать драки.`}
                />
            </div>
        )
    }
}
