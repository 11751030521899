import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock'
import SwipeBlock from "../../../Components/LessonAndTestComponents/SwipeBlock";
import Slider from "react-slick";

import img1 from '../../../image/lessons/lesson32/step5-1.png';
import img2 from '../../../image/lessons/lesson32/step5-2.png';
import img3 from '../../../image/lessons/lesson32/step5-3.png';
import img4 from '../../../image/lessons/lesson32/step5-4.png';
import img5 from '../../../image/lessons/lesson32/step5-5.png';
import img6 from '../../../image/lessons/lesson32/step5-6.png';


export default class step5 extends React.Component{

    changeSlide = (next) => {
        if(next === 4){
            this.props.showNextBtn();
        }
        console.log(111, next)
    }

    render() {
        let settings = {
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: 2,
            slidesToScroll: 2,
            adaptiveHeight: true,
            beforeChange: (current, next) => {
                this.changeSlide(next)
            },
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        dots: true,
                        arrows: false,
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                },
            ]
        };
        return (
            <div className="step5">
                <div className="head">
                    Открытия научно-исследовательского института Waltham
                </div>
                <TextBlock
                    text={`В научно-исследовательском институте Waltham были сделаны важные научные открытия, некоторые из которых в корне изменили индустрию производства кормов для животных.`}
                />
                <InfoBlock
                    text={`Используйте стрелки, чтобы узнать о ключевых научных открытиях.`}
                />
                <SwipeBlock
                    text={`<p class="swiper-block__tablet">Используйте свайп для перемещения между элементами.</p><p class="swiper-block__mobile">Используйте свайп для ознакомления с каждой характеристикой.</p>`}
                />
                <Slider {...settings}>
                    <div className="flex_el">
                        <div className="img">
                            <img src={img1} alt=""/>
                        </div>
                        <TextBlock
                            text={`<b>1991 ГОД</b>
Определен необходимый уровень таурина в сухих и влажных рационах для кошек.`}
                        />
                    </div>
                    <div className="flex_el">
                        <div className="img">
                            <img src={img2} alt=""/>
                        </div>
                        <TextBlock
                            text={`<b>1995 ГОД</b>
Разработана первая диета, помогающая контролировать образование струвитных и кальций-оксалатных камней у кошек.`}
                        />
                    </div>
                    <div className="flex_el">
                        <div className="img">
                            <img src={img3} alt=""/>
                        </div>
                        <TextBlock
                            text={`<b>1997 ГОД</b>
Запатентован уровень цинка и линолевой кислоты, определяющей оптимальное состояние кожи и шерсти.`}
                        />
                    </div>
                    <div className="flex_el">
                        <div className="img">
                            <img src={img4} alt=""/>
                        </div>
                        <TextBlock
                            text={`<b>2003 ГОД</b>
Определена роль макронутриентного профиля (оптимального сочетания белков, жиров и углеводов) в пищевом предпочтении кошек.`}
                        />
                    </div>
                    <div className="flex_el">
                        <div className="img">
                            <img src={img5} alt=""/>
                        </div>
                        <TextBlock
                            text={`<b>2005 ГОД</b>
Было установлено, что у кошек нет рецепторов, распознающих сладкий вкус сахаров.`}
                        />
                    </div>
                    {/* <div className="flex_el">
                        <div className="img">
                            <img src={img6} alt=""/>
                        </div>
                        <TextBlock
                            text={`<b>2007 ГОД</b>
Открыт ген, обуславливающий размер собак.`}
                        />
                    </div> */}
                </Slider>
                <p className="attention">
                    Многие из перечисленных научных открытий являются фундаментальными не только для компании Mars и ее продукции, но и для всей индустрии!
                </p>
            </div>
        )
    }
}
