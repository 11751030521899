import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';

import img1 from '../../../image/lessons/lesson11/step19-1.png'


export default class step19 extends React.Component {

    state = {
        pointActive: 0,
        points: [],
    };

    showPoint = pointActive => {
        const {points} = this.state;
        if (points.indexOf(pointActive) === -1) {
            points.push(pointActive)
        }

        this.setState({
            pointActive: pointActive,
            points: points
        })

        if (points.length === 3) {
            this.props.showNextBtn();
        }
    }

    render() {
        const {points} = this.state
        return (
            <div className="step19">
                <TextBlock
                    text={`Несмотря на то, что пожилые кошки сами контролируют потребление энергии, их организм часто испытывает <b>проблемы с извлечением энергии из пищи</b>. Поэтому кошкам на этой стадии жизни необходимо особое кормление.`}
                />
                <InfoBlock
                    text={`Нажимайте на вопросительные знаки, чтобы узнать больше об особенностях кормления пожилых кошек.`}
                />
                <div className="imgDiv">
                    <div
                        className={'point ' + (points.indexOf(1) >= 0 ? 'point_out' : '')}
                        onClick={() => {this.showPoint(1)}}
                    >
                        ?
                    </div>
                    <div
                        className={'point ' + (points.indexOf(2) >= 0 ? 'point_out' : '')}
                        onClick={() => {this.showPoint(2)}}
                    >
                        ?
                    </div>
                    <div
                        className={'point ' + (points.indexOf(3) >= 0 ? 'point_out' : '')}
                        onClick={() => {this.showPoint(3)}}
                    >
                        ?
                    </div>
                    <img src={img1} alt="" className="imgCenter"/>
                    <div className="imgDiv_answers">
                        <div
                            className={'answer ' + (points.indexOf(1) >= 0 ? 'answer_in' : '')}
                        >
                            <p className="answer_text">Кошкам необходимо достаточное количество <b>витамина Е</b> для поддержания иммунитета.</p>
                        </div>
                        <div
                            className={'answer ' + (points.indexOf(2) >= 0 ? 'answer_in' : '')}
                        >
                            <p className="answer_text">Энергетическая ценность корма для пожилых кошек должна быть примерно <b>равна</b> энергетической ценности корма для взрослых кошек.</p>
                        </div>
                        <div
                            className={'answer ' + (points.indexOf(3) >= 0 ? 'answer_in' : '')}
                        >
                            <p className="answer_text">Нужно следить за аппетитом кошки и предлагать ей корма <b>с высокой вкусовой привлекательностью.</b></p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
