import React from 'react'
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";

// import img1 from '../../../image/lessons/lesson8/step40-1.png'


export default class step40 extends React.Component{

    render() {
        return (
            <div className="step40">
                <TextBlock
                    text={`После выписки на кошках могут присутствовать незнакомые материалы или запахи.
                    Другие кошки, живущие в доме, могут не узнать ее и напасть.
                    Чтобы минимизировать осложнения, спросите владельцев кошки, возникали ли ранее проблемы.`}
                />
                <div className="head">
                    Общие рекомендации для уменьшения признаков агрессии со стороны других кошек в домашней обстановке.
                </div>
                <TextBlock
                    text={`Попросите владельцев принести любой предмет из дома, от которого будет исходить знакомый кошке запах.
                     Рассмотрите возможность использования синтетического аналога кошачьего лицевого феромона в кошачьей переноске и в домашних условиях.
                     Рекомендуйте владельцам не поощрять и не принуждать к взаимодействию с другими кошками и исключить совместное кормление.`}
                />
                {/*<img src={img1} alt="" className="imgCenter"/>*/}
                {/*<TextBlock*/}
                {/*    text={`Кошка на фото прибыла в ветеринарную клинику со своей собственной знакомой ей подстилкой.`}*/}
                {/*/>*/}
            </div>
        )
    }
}
