import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import Popup from "../../../Components/Popup";

import img1 from "../../../image/lessons/lesson7/step22-1.png";
import img2 from "../../../image/lessons/lesson7/step22-2.png";
import img3 from "../../../image/lessons/lesson7/step22-3.png";
import img4 from "../../../image/lessons/lesson7/step22-4.png";


export default class step22 extends React.Component {

    state = {
        windowWidth: window.innerWidth,
        pointActive: 0,
        showPopup: false,
        points: [],
    };

    //Отлов события ресайз
    handlerResize = () => {
        this.setState({windowWidth: window.innerWidth})
    }

    componentDidMount() {
        window.addEventListener('resize', this.handlerResize)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handlerResize);
    }

    showPopup = (pointActive) => {
        const {points, showPopup, windowWidth} = this.state;
        if (points.indexOf(pointActive) === -1) {
            points.push(pointActive)
        }

        if (windowWidth < 768) {
            if (!showPopup) {
                this.setState({
                    pointActive: pointActive,
                    showPopup: true,
                })
            } else if (pointActive >= 4) {
                this.setState({showPopup: false, pointActive: 0})
            } else {
                this.setState({
                    pointActive: pointActive +1,
                    showPopup: true,
                })
            }
        } else {
            this.setState({points: points, pointActive: pointActive})
        }
        if (points.length === 4) {
            this.props.showNextBtn();
        }
    }

    closePopup = () => {
        this.setState({showPopup: false})
    }

    popupData = {
        0: {
            text: '',
        },
        1: {

            img: img1,
            text: `Купите несколько игрушек или предметов, которые по своему виду напоминают птиц, например, перья, мягкие игрушки, покрытые перьями, а также игрушки, издающие чирикающие звуки. Начните играть с вашей кошкой.`,
        },
        2: {
            img: img2,
            text: `Затем уберите эти предметы из поля зрения вашей кошки и попробуйте поиграть с ней предметами, напоминающими мышей, например, игрушками с кусочками меха, пушистыми мышками и мячиками. 
            
            Внимательное наблюдайте за реакцией кошки на каждый предмет.`,
        },
        3: {
            img: img3,
            text: `Попробуйте одновременно поиграть с кошкой в игрушки в форме птиц и мышей, чередуя их примерно каждые 20 секунд, чтобы понять, какую кошка предпочитает больше.`,
        },
        4: {
            img: img4,
            text: `После возьмите игрушки, напоминающие жуков, например, лазерную указку, веревку с узлом на конце, сухие лакомства, разбросанные по полу.`,
        },
    }

    render() {
        const {points, pointActive, showPopup} = this.state
        return (
            <div className="step22">
                <TextBlock
                    text={`У большинства кошек есть предпочтения по добыче.`}
                />
                <div className="prey">
                    <div className="prey_string"/>
                    <div className="prey_item">
                        <div
                            className={'prey_point ' + (points.indexOf(1) >= 0 && 'active')}
                            onClick={() => {this.showPopup(1)}}
                        >
                            <div className="prey_point_inner"/>
                        </div>
                        <div className={'prey_info ' + (pointActive === 1 && 'active')}>
                            <div className="prey_info_text">
                                {this.popupData[1].text}
                            </div>
                            <img src={this.popupData[1].img} alt="" className="prey_info_img"/>
                        </div>
                    </div>
                    <div className="prey_item">
                        <div
                            className={'prey_point ' + (points.indexOf(2) >= 0 && 'active')}
                            onClick={() => {this.showPopup(2)}}
                        >
                            <div className="prey_point_inner"/>
                        </div>
                        <div className={'prey_info ' + (pointActive === 2 && 'active')}>
                            <div className="prey_info_text">
                                {this.popupData[2].text}
                            </div>
                            <img src={this.popupData[2].img} alt="" className="prey_info_img"/>
                        </div>
                    </div>
                    <div className="prey_item">
                        <div
                            className={'prey_point ' + (points.indexOf(3) >= 0 && 'active')}
                            onClick={() => {this.showPopup(3)}}
                        >
                            <div className="prey_point_inner"/>
                        </div>
                        <div className={'prey_info ' + (pointActive === 3 && 'active')}>
                            <div className="prey_info_text">
                                {this.popupData[3].text}
                            </div>
                            <img src={this.popupData[3].img} alt="" className="prey_info_img"/>
                        </div>
                    </div>
                    <div className="prey_item">
                        <div
                            className={'prey_point ' + (points.indexOf(4) >= 0 && 'active')}
                            onClick={() => {this.showPopup(4)}}
                        >
                            <div className="prey_point_inner"/>
                        </div>
                        <div className={'prey_info ' + (pointActive === 4 && 'active')}>
                            <div className="prey_info_text">
                                {this.popupData[4].text}
                            </div>
                            <img src={this.popupData[4].img} alt="" className="prey_info_img"/>
                        </div>
                    </div>
                </div>
                {showPopup &&
                <Popup
                    closePopup={this.closePopup}
                >
                    <div className={'info'}>
                        <img src={this.popupData[pointActive].img} alt="" className="info_img"/>
                        <TextBlock
                            text={this.popupData[pointActive].text}
                        />
                    </div>
                    <button
                        className="btn btn_violet btn_center"
                        onClick={() => {this.showPopup(pointActive)}}
                    >
                        Продолжить
                    </button>
                </Popup>
                }
            </div>
        )
    }
}
