import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import PlanInFirstStep from '../../../Components/LessonAndTestComponents/PlanInFirstStep';
import Lecturer from '../../../Components/LessonAndTestComponents/Lecturer';

import img1 from '../../../image/Lecturers/sengmani.png'
import img2 from '../../../image/Lecturers/sengmani-icon.png'
import img3 from '../../../image/Lecturers/lisickaya.png'
import img4 from '../../../image/Lecturers/pozharskaya-icon.png'
import img5 from '../../../image/Lecturers/antanasova.png'
import img6 from '../../../image/Lecturers/antanasova-icon.png'

export default class step1 extends React.Component {

    planData = [
        `Видеолекция: Бронхоальвеолярный лаваж: взгляд хирурга, цитолога и анестезиолога»`,
        `Тест`,
    ]

    render() {
        return (
            <div className="step1">
                <Lecturer
                    img={img1}
                    icon={img2}
                    surname={'Cенгмани'}
                    name={'Марина Шамировна '}
                    text={'Ветеринарный врач, хирург, эндоскопист, ВОНЦ «Биоконтроль»,  г.Москва'}
                />

                <Lecturer
                    img={img3}
                    surname={'Лисицкая'}
                    name={'Ксения Валерьевна'}
                    text={'К.б.н., морфолог, патолог ветеринарной лаборатории Лабоклин Россия'}
                />

                <Lecturer
                    img={img5}
                    icon={img6}
                    surname={'Атанасова'}
                    name={'Снежана Георгиевна'}
                    text={'Президент Национальной ассоциации медицины кошек, ветеринарный врач, анестезиолог-реаниматолог, научный руководитель в/к «Клиника Кошек», г. Москва.'}
                />

                <hr className="string"/>

                <div className="head">План урока</div>

                <TextBlock
                    text={`В этом уроке вы узнаете:`}
                />

                <PlanInFirstStep
                    planData={this.planData}
                    type={'violet'}
                />

            </div>
        )
    }
}
