import React from 'react'
// import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import ListNumeral from "../../../Components/LessonAndTestComponents/ListNumeral";

import img1 from "../../../image/lessons/lesson8/step3-1.png";

export default class step3 extends React.Component {

    state = {
        data: [
            `Уменьшить страх и боль у кошки.`,
            `Укрепить связь между ветеринарным врачом, владельцем и его кошкой, установить доверие и внушить уверенность. А значит, кошка получит наиболее эффективную медицинскую помощь и лечение.`,
            `Повысить эффективность, результативность и удовлетворенность работой со стороны ветеринарных врачей.`,
            `Повысить соответствие требованиям клиентов, вести своевременную отчетность и достичь раннего выявления медицинских и поведенческих проблем.`,
            `Уменьшить количество травм, которые может получить владелец кошки и ветеринарный врач при обследовании и лечении кошки.`,
            `Снизить тревожность и обеспокоенность клиента.`,
        ]
    }

    render() {
        return (
            <div className="step3">
                <div className="flex">
                    <ListNumeral
                        data={this.state.data}
                    />
                    <img src={img1} alt="" className="flex_img"/>
                </div>
                <div className="info">
                    Лечение наиболее успешно в тех случаях, когда ветеринарный врач адаптирует соответствующий подход к каждой отдельной кошке в зависимости от конкретной ситуации.
                </div>
            </div>
        )
    }
}
