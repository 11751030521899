import React from 'react';
import './style.sass';
import { animateScroll as scroll } from "react-scroll";
import Api from '../../Service/Api'

export default class AccreditatedClinics extends React.Component {

    state = {
        accClinics: [],
        clinicSearch: '',
    };

    Api = new Api()

    componentDidMount() {
        window.addEventListener('scroll', this.toggleVisibility);
        window.addEventListener('keydown', this.enterSearch);

        scroll.scrollToTop()

        this.Api.getAccreditatedClinics()
            .then((res) => {
                if (res !== undefined) {
                    this.setState({accClinics: res})
                }
            })
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.enterSearch);
    }

    changeInputValue = (clinicSearch) => {
        this.setState({clinicSearch: clinicSearch}, () => {
            if (!clinicSearch.length) {
                this.searchAccreditatedClinics();
            }
        });
    }

    enterSearch = (e) => {
        if (e.keyCode === 13) {
            this.searchAccreditatedClinics();
        }
    }

    searchAccreditatedClinics = () => {
        const {clinicSearch} = this.state

        this.Api.searchAccreditatedClinics(clinicSearch)
            .then((res) => {
                if (res !== undefined) {
                    this.setState({accClinics: res})
                }
            })
    }

    //Отлов события scroll
    handlerScroll = () => {
        this.setState({scrollTop: window.pageYOffset})
    }

    render() {
        const {accClinics, clinicSearch} = this.state
        return (
            <div className="AboutProject">
                <div className="AboutProject_content">
                    <div className="StartPage_container_clinics">
                        <div className="StartPage_content_inner">
                            <div
                                className="StartPage_content_item StartPage_content_item-full StartPage_page StartPage_container_toKnow">

                                <div className="StartPage_clinic_top ">
                                    <h2 className="StartPage_article_head">Список клиник</h2>
                                    <div className="StartPage_clinic_search">
                                        <input
                                            value={clinicSearch}
                                            onChange={(e) => (this.changeInputValue(e.target.value))}
                                            className="StartPage_clinic_search_input"
                                            type="text"
                                            placeholder="БЫСТРЫЙ ПОИСК КЛИНИКИ/ГОРОДА"
                                        />
                                        <i
                                            className="StartPage_search_icon"
                                            onKeyDown={() => {this.enterSearch()}}
                                            onClick={() => {this.searchAccreditatedClinics()}}
                                        >
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M9.25529 9.15956C10.9564 7.47609 10.9564 4.74665 9.25529 3.06318C7.55423 1.37971 4.79626 1.37971 3.0952 3.06318C1.39413 4.74665 1.39413 7.47609 3.0952 9.15956C4.79626 10.843 7.55423 10.843 9.25529 9.15956ZM10.5418 10.4328C12.9534 8.04612 12.9534 4.17662 10.5418 1.78998C8.13022 -0.59666 4.22027 -0.59666 1.80869 1.78998C-0.602896 4.17662 -0.602896 8.04612 1.80869 10.4328C4.22027 12.8194 8.13022 12.8194 10.5418 10.4328Z" fill="#77777B"/>
                                                <path fillRule="evenodd" clipRule="evenodd" d="M9.91148 9.97446C10.2667 9.62287 10.8427 9.62287 11.198 9.97446L15.7336 14.4631C16.0888 14.8147 16.0888 15.3847 15.7336 15.7363C15.3783 16.0879 14.8023 16.0879 14.447 15.7363L9.91148 11.2477C9.55622 10.8961 9.55622 10.326 9.91148 9.97446Z" fill="#77777B"/>
                                            </svg>
                                        </i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="StartPage_content_inner">
                            <div className="StartPage_content_item StartPage_content_item-full">
                                <div className="StartPage_clinic_list">
                                    <div className="StartPage_clinic_list_head">
                                        <div className="StartPage_clinic_head_item StartPage_clinic_city">

                                            Город
                                            {/*<span className="StartPage_sort_icon">
                                                        <img src={img28} alt=""/>
                                                    </span>*/}
                                        </div>
                                        <div className="StartPage_clinic_head_item StartPage_clinic_name">
                                            Клиника
                                        </div>
                                        <div className="StartPage_clinic_head_item StartPage_clinic_address">
                                            Адрес
                                        </div>
                                        <div className="StartPage_clinic_head_item StartPage_clinic_phone">
                                            Телефон
                                        </div>
                                    </div>
                                    {accClinics.map((item, index) => (
                                        <div className="StartPage_clinic_list_line" key={index}>
                                            <div className="StartPage_clinic_item StartPage_clinic_city_item">
                                                {item.clinic_city}
                                            </div>
                                            <div className="StartPage_clinic_item StartPage_clinic_name_item">
                                                <span className="StartPage_clinic_text">{item.clinic_name}</span>
                                                {/*<a href={item.clinic_site}*/}
                                                {/*   className="StartPage_clinic_name_link" target="_blank" rel="noopener noreferrer">{item.clinic_site}*/}
                                                {/*</a>*/}
                                            </div>
                                            <div className="StartPage_clinic_item StartPage_clinic_address_item">
                                                <span className="StartPage_clinic_text">{item.clinic_address}</span>
                                                <span className="StartPage_clinic_address2">{item.clinic_city}</span>
                                            </div>
                                            <div className="StartPage_clinic_item StartPage_clinic_phone_item">
                                                <span className="StartPage_clinic_text">{item.clinic_phone}</span>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

