import React from 'react'
import Resume from '../../../Components/LessonAndTestComponents/Resume'

import img1 from '../../../image/lessons/lesson11/step21-1.png'


export default class step21 extends React.Component{

    state = {
        resumeData: [
            `Кошка считается пожилой, когда она достигает <b>последней трети предполагаемой</b> продолжительности жизни.`,
            `В отличие от собак, у кошек <b>не снижаются</b> энергетические потребности в старости.`,
            `Многие пожилые кошки испытывают трудности в переваривании белков и жиров, поэтому <b>рекомендуется кормить их высокоусвояемым рационом.</b>`,
        ]
    }

    render() {
        return (
            <div className="step21">
                <Resume
                    title={'Выводы'}
                    resumeData={this.state.resumeData}
                    img={img1}
                />
            </div>
        )
    }
}
