import React from 'react'

export default class step3 extends React.Component {

    render() {
        return (
            <div className="step3">
                <ul className="list">
                    <li className="list_item">
                        Ветеринарный персонал должен иметь общую заинтересованность в отработке безопасных и эффективных методов обращения с кошкой, обмене знаниями для поддержания высокого уровня предоставления ветеринарных услуг, а также в создании культуры постоянного совершенствования процедуры лечения.
                    </li>
                    <li className="list_item">
                        <b>Каждый сотрудник ветеринарной клиники играет определенную роль в лечении кошки с момента ее появления на пороге ветеринарной клиники и до выхода. Даже после выписки ветеринарный персонал обязан принимать участие в наблюдении за уходом.</b>
                    </li>
                </ul>
            </div>
        )
    }
}
