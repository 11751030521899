import React from 'react'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import OnClickBlock from '../../../Components/LessonAndTestComponents/OnClickBlock';

import img1 from "../../../image/lessons/lesson33/step7-1.png";
import img2 from "../../../image/lessons/lesson33/step7-2.png";
import img3 from "../../../image/lessons/lesson33/step7-3.png";
import img4 from "../../../image/lessons/lesson33/step7-4.png";
import img5 from "../../../image/lessons/lesson33/step7-5.png";
import img6 from "../../../image/lessons/lesson33/step7-6.png";
import img7 from "../../../image/lessons/lesson33/step7-7.png";

export default class step7 extends React.Component {

    state = {
        flexData: [
            'Обеспечение здорового роста и правильного развития',
            'поддержка иммуной системы',
            'Удобный размер и текстура корма'
        ],
        flexHidden: false
    }

    hideElement = () => {
        this.setState({flexHidden: !this.state.flexHidden})
    }

    showNextBtn = () => {
        this.props.showNextBtn()
    }

    render() {
        const {flexData} = this.state
        return (
            <div className="step7">
                <InfoBlock
                    text={`Нажмите на ключевые потребности котят в питании и узнайте больше о каждой из них, а также Вы узнаете решения, которые предлагают рационы WHISKAS® для котят`}
                />
                <div className="header">
                    <div className="header_text">
                        Ключевые потребности котят в питании
                    </div>
                    <img src={img1} alt="" className="header_img"/>
                </div>
                <OnClickBlock
                    flexData={flexData}
                    hideElement={this.hideElement}
                    showNextBtn={this.showNextBtn}
                >
                    <div className="imgDiv_wrap">
                        <div className="imgDiv_wrap_flex">
                            <img src={img2} alt="" className="imgDiv_wrap_flex_img"/>
                            <p className="imgDiv_wrap_flex_text">
                                Котята растут в 18 раз быстрее, чем дети
                            </p>
                            <img src={img3} alt="" className="imgDiv_wrap_flex_img"/>
                        </div>
                        <div className="imgDiv_wrap_head">
                            Решение WHISKAS®
                        </div>
                        <ul className="list">
                            <li className="list_item">WHISKAS® предлагает <b>повышенную энергетическую плотность</b> кормов для котят.
                            </li>
                            <li className="list_item">WHISKAS® поддерживает оптимальное развитие котенка благодаря набору питательных веществ и комплексу витаминов и минералов.</li>
                        </ul>
                        <img className="imgDiv_wrap_img" src={img4} alt=""/>
                    </div>
                    <div className="imgDiv_wrap">
                        <div className="imgDiv_wrap_head">
                            Поддержка иммунной системы
                        </div>
                        <div className="imgDiv_wrap_flex">
                            <TextBlock
                                text={`Иммунная система котенка очень уязвима. Поэтому в начале жизни очень важно поддержать его иммунитет`}
                            />
                            <img src={img5} alt="" className="imgDiv_wrap_flex_img element"/>
                        </div>
                        <div className="imgDiv_wrap_head">
                            Решение WHISKAS®
                        </div>
                        <div className="imgDiv_wrap_flex">
                            <img src={img6} alt="" className="imgDiv_wrap_flex_img element"/>
                            <TextBlock
                                text={`Рационы WHISKAS® для котят содержат витамин Е, необходимый для поддержки нормальной работы иммунной системы.`}
                            />
                        </div>
                    </div>
                    <div className="imgDiv_wrap">
                        <div className="imgDiv_wrap_head">
                            Удобная текстура корма и разнообразие вкусов
                        </div>
                        <ul className="list">
                            <li className="list_item">
                                Котята только учатся есть твердый корм, и их десны еще слишком слабы.
                            </li>
                            <li className="list_item">
                                У котят хорошо развиты инстинкт поиска разнообразия в питании и потребность пробовать новое.
                            </li>
                        </ul>
                        <div className="imgDiv_wrap_head">
                            Решение WHISKAS®
                        </div>
                        <TextBlock
                            text={`WHISKAS® для котят – это мягкая консистенция и мелкий размер кусочков и гранул (по сравнению с кормом для взрослых кошек), а также большой ассортимент вкусов.`}
                        />
                        <img className="imgDiv_wrap_img" src={img7} alt=""/>
                    </div>
                </OnClickBlock>
            </div>
        )
    }
}
