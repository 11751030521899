import React from 'react'

export default class step10 extends React.Component {

    render() {
        return (
            <div className="step10">
                <ul className="list">
                    <li className="list_item">Вовлеките всех ветеринарных врачей в подготовку плана действий или, по крайней мере, тех лиц, которые будут отвечать за решение соответствующих задач</li>
                    <li className="list_item">Уточните цель, связанную с фактором успеха, и составьте приоритетный список задач для достижения этой цели</li>
                    <li className="list_item">Определите любые барьеры или ограничения, которые будут мешать вам достичь цели, и способы преодоления этих барьеров</li>
                    <li className="list_item">Перечислите любые ресурсы, которые имеют решающее значение для достижения вашей цели</li>
                </ul>
            </div>
        )
    }
}
