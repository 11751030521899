import React from 'react'
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";

export default class step6 extends React.Component {

    render() {
        return (
            <div className="step6">
               <div className="head">Зачем кошкам орган Якобсона?</div>
                <TextBlock
                    text={`Часть обонятельной области носа у кошек обособилась в так называемый орган Якобсона. Вы можете понять, что кошка использует его, по характерной позе: она останавливается как вкопанная, открывает пасть и вдыхает воздух, втягивая губы и собирая кожу на голове в своеобразную «насмешливую» гримасу (улыбку Флемена). Существуют три основных гипотезы о его назначении:`}
                />
                <ul className="list">
                    <li className="list_item__withoutDot">
                        Роль информатора, специализирующегося на восприятии феромонов и других социальных запахов.
                    </li>
                    <li className="list_item__withoutDot">
                        Орган воспринимает запах пищи, поступившей в ротовую полость, как бы дополняя информацию о ней, полученную органом обоняния. Сторонники этой гипотезы считают, что животные таким образом используют ротовое обоняние.
                    </li>
                    <li className="list_item__withoutDot">
                        Сторонники третьей гипотезы называют этот орган «шестым чувством», позволяющим кошкам предсказывать катастрофические события (извержение вулкана, землетрясение, приближение лесного пожара и т.д.), благодаря анализу воздушных потоков атмосферы, отражающих незначительные изменения химического состава воздуха.
                    </li>
                </ul>
            </div>
        )
    }
}
