import React from 'react'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson2/step4-1.png'
import img2 from '../../../image/lessons/lesson2/step4-2.png'
import img3 from '../../../image/lessons/lesson2/step4-3.png'


export default class step4 extends React.Component {
    state = {
        flexActive: 0,
        flexs: [1],
        answer: false
    };

    changeFlex = flexActive => {
        const {flexs} = this.state
        if (flexs.indexOf(flexActive) === -1) {
            flexs.push(flexActive)
        }
        this.setState({flexActive, flexs})
    }

    showAnswer = () => {
        this.setState({answer: true})
        this.props.showNextBtn()
    }

    closeImgDiv = () => {this.setState({flexActive: 0})}


    render() {
        const {flexActive, answer} = this.state
        return (
            <div className="step4">
                <div className="head">
                    Цветовое восприятие мира
                </div>
                <InfoBlock
                    text={`Выберите правильный вариант цветового восприятия кошек.`}
                />

                <div className={'flex'}>
                    <div className={'flex_el ' + (flexActive === 1 && 'active ') + (answer && ' error')} onClick={() => {
                        this.changeFlex(1)
                    }}>
                        <img src={img1} className="flex_el_img" alt=""/>
                    </div>
                    <div className="flex_el">
                        <img src={img3} className="flex_img" alt=""/>
                    </div>
                    <div className={'flex_el ' + (flexActive === 2 && 'active ') + (answer && ' right')} onClick={() => {
                        this.changeFlex(2)
                    }}>
                        <img src={img2} className="flex_el_img" alt=""/>
                    </div>
                </div>
                {flexActive !== 0 && answer &&
                <div className={'info ' + (flexActive === 1 && 'error')}>
                    {flexActive === 1 &&
                    <div className="info_wrp">
                        <div className="info_head">
                            К сожалению, ваш ответ неверен.
                        </div>
                        <TextBlock
                            text={`Так  мир  видит человек.

                            Фоторецепторы различаются по форме и делятся на два типа: палочки и колбочки. В палочках содержится только один вид пигмента, они обеспечивают сумеречное бесцветное зрение. В колбочках два вида пигмента, и они составляют основу двухцветного цветового дневного зрения кошек.

                            У человека в колбочках — три вида пигмента, и поэтому зрение трёхцветное. У кошки, как у сумеречного животного, на сетчатке глаза преобладают палочки, и только в центральной части сконцентрированы колбочки; это область острого зрения. Соотношение палочек и колбочек у кошек 25:1, а у человека — 4:1.

                            Кошки хуже человека различают оттенки цвета. Они отчётливо видят только в синих и зелено-жёлтых частях спектра. Но при этом кошки способны различать большое количество оттенков серого цвета. Такую особенность зрения можно объяснить окраской основных жертв кошек — мышей, цвет которых варьируется от светло-серого до буровато-серого.`}
                        />
                    </div>
                    }
                    {flexActive === 2 &&
                    <div className="info_wrp">
                        <div className="info_head">
                            Все верно!
                        </div>
                        <TextBlock
                            text={`Так мир видит кошка. 
                            
                            Восприятие оттенков цвета кошками отличается от человеческого восприятия. Они отчётливо видят только в синих и зелено-жёлтых частях спектра, но при этом кошки способны различать большое количество оттенков серого цвета. Такую особенность зрения можно объяснить окраской основных жертв кошек — грызунов, цвет которых варьируется от светло-серого до буровато-серого. 
                            
                            Фоторецепторы различаются по форме и делятся на два типа: палочки и колбочки. В палочках содержится только один вид пигмента, они обеспечивают сумеречное бесцветное зрение. В колбочках два вида пигмента, и они составляют основу двухцветного цветового дневного зрения кошек. 
                            
                            У человека в колбочках — три вида пигмента, и поэтому зрение трёхцветное. У кошки, как у сумеречного животного, на сетчатке глаза преобладают палочки, и только в центральной части сконцентрированы колбочки; это область острого зрения. Соотношение палочек и колбочек у кошек 25:1, а у человека — 4:1.`}
                        />
                    </div>
                    }
                </div>
                }
                { !answer &&
                <button
                    className="btn btn_violet btn_center"
                    onClick={this.showAnswer}
                >
                    Проверить
                </button>
                }
            </div>
        )
    }
}
