import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson8/step30-1.jpg'
import img2 from '../../../image/lessons/lesson8/step30-2.jpg'


export default class step30 extends React.Component{

    render() {
        return (
            <div className="step30">
                <div className="head">
                    Как правильно открыть кошачью переноску и достать кошку
                </div>
                <TextBlock
                    text={` Откройте дверцу, позволив кошке выйти самостоятельно.
                    Кошки охотнее выходят из переноски, находящейся на полу.
                    
                    Если кошка не выходит, спокойно снимите верхнюю часть и дверцу кошачьей переноски. Так можно провести большую часть обследования.
                    
                    Если кошка боится, накиньте полотенце вместо крышки переноски. Так кошка будет в укрытии, а вы получите к ней доступ (пример на фото)`}
                />
                <div className="flex">
                    <img src={img1} alt="" className="flex_img"/>
                    <img src={img2} alt="" className="flex_img"/>
                </div>
            </div>
        )
    }
}
