import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from "../../../image/lessons/lesson2/step2-1.png";

export default class step2 extends React.Component {

    render() {
        return (
            <div className="step2">
                <div className="flex">
                    <TextBlock
                        text={`Как у типичных ночных животных, у кошек <b>особенно развиты</b> те органы чувств, которые помогают охотиться и спасают от внешних врагов.
                        
                        Кошки зорко видят в темноте и способны воспринимать широкий звуковой диапазон. 
                        
                        И хотя большинство кошек живут сегодня в безопасности и домашнем уюте, <b>они сохранили физиологические особенности</b> своих диких предков.`}
                    />
                    <img src={img1} alt="" className="flex_img"/>
                </div>
            </div>
        )
    }
}
