import React from 'react'
import VideoBlock from "../../../Components/LessonAndTestComponents/VideoBlock";
import Lecturer from "../../../Components/LessonAndTestComponents/Lecturer";

import img1 from '../../../image/Lecturers/renzshin.png'
import img2 from '../../../image/Lecturers/renzshin-icon.png'

export default class step2 extends React.Component {

    render() {
        return (
            <div className="step2">
                <Lecturer
                    img={img1}
                    icon={img2}
                    surname={'Ренжин'}
                    name={'Иван Александрович'}
                    text={'Врач невропатолог, руководитель отделения методов лучевой диагностики и неврологии, ИВЦ МВА, г. Москва'}
                />

                <hr className="string"/>

                <VideoBlock
                    src={'https://www.youtube.com/embed/SxAetq6VIlo'}
                    title={'https://www.youtube.com/watch?v=SxAetq6VIlo&feature=youtu.be'}
                />
            </div>
        )
    }
}
