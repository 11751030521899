import React from 'react'
// import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";
// import InfoBlock from "../../../Components/LessonAndTestComponents/InfoBlock";

import img1 from "../../../image/lessons/lesson33/step10-1.png";
import img1xs from "../../../image/lessons/lesson33/step10-1xs.png";

export default class step10 extends React.Component {

    render() {
        return (
            <div className="step10">
                <div className="head">
                    Очень важно кормить кошку с учётом стадии её жизни.
                </div>
                <picture>
                    <source srcSet={img1xs} media="(max-width: 767px)"/>
                    <img src={img1} alt="" className="imgCenter"/>
                </picture>
            </div>
        )
    }
}
