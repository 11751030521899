import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock'

import img1 from "../../../image/lessons/lesson7/step27-1.png";

export default class step27 extends React.Component {

    render() {
        return (
            <div className="step27">
                <TextBlock
                    text={`Кошки остро реагируют, когда их чувство безопасности оказывается под угрозой. Некоторые из них становятся агрессивными, другие – наоборот, замкнутыми. Иногда кошка может даже заболеть из-за стресса.
                    
                    Наличие конфликтных ситуаций – одна из распространенных причин определенных проблем со здоровьем домашних кошек.
                    
                    Цель – свести нездоровый конфликт к минимуму и сделать его более управляемым для вовлеченных в него кошек.`}
                />
                <img src={img1} alt="" className="imgCenter"/>
            </div>
        )
    }
}
