import React from 'react'
import './style.sass'
import VideoSectionButton from "../VideoSectionButton";

export default class VideoSectionModal extends React.Component{
    render() {

        let keys = this.props.lector;
        let values = this.props.lectorProfile;

        let result = {};
        keys.forEach((key, i) => result[key] = values[i]);

        return (
            <div className="Popup_video">
                <p className="popup_video-title lectore">О лекторе</p>
                <p className="popup_video-title lecture">О лекции</p>
                {Object.entries(result).map(([key, value], index) => {
                    return(
                        <React.Fragment>
                            <p className="popup_video-subtitle lectore">{key}</p>
                            <p className="popup_video-text lectore">{value}</p>
                        </React.Fragment>
                        )
                })}
                <p className="popup_video-subtitle lecture">{this.props.title}</p>
                <p className="popup_video-text lecture">{this.props.description}</p>
                <VideoSectionButton class={'closeButton'} svg={<svg width="100%" height="100%" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={this.props.onClick}>
                    <circle cx="20" cy="20" r="20"/>
                    <path d="M26.3535 14.7275L26.7068 14.3743L26.3538 14.0207L25.9804 13.6467L25.6269 13.2926L25.2731 13.6464L20 18.9189L14.7273 13.6464L14.3737 13.2928L14.0201 13.6465L13.6464 14.0205L13.293 14.3741L13.6465 14.7275L18.9191 19.9999L13.6465 25.2723L13.2931 25.6257L13.6463 25.9792L14.0201 26.3534L14.3736 26.7073L14.7274 26.3535L20 21.0804L25.273 26.3535L25.6269 26.7075L25.9805 26.3532L26.3539 25.9791L26.7068 25.6255L26.3535 25.2723L21.0809 19.9999L26.3535 14.7275Z"/>
                </svg>}/>
            </div>
        )
    }
}