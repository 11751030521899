import React from 'react'
// import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

export default class step28 extends React.Component {

    render() {
        return (
            <div className="step28">
                <div className="flex">
                    <div className="flex_el gray">
                        <div className="flex_el_head">
                            Уверенная кошка
                        </div>
                        <ul className="list">
                            <li className="list_item">Никогда не отступает и не убегает от других кошек</li>
                            <li className="list_item">Уверенно смотрит на других кошек</li>
                            <li className="list_item">Лишает других кошек доступа к ресурсам</li>
                            <li className="list_item">Трется щеками, головой, подбородком и хвостом о людей, дверные проемы и мебель, расположенную на уровне кошки.</li>
                        </ul>
                    </div>
                    <div className="flex_el gray">
                        <div className="flex_el_head">
                            Пугливая кошка
                        </div>
                        <ul className="list">
                            <li className="list_item">Проводит большое количество времени, прячась или находясь вдали от семьи</li>
                            <li className="list_item">Избегает зрительного контакта с другими кошками</li>
                            <li className="list_item">Позволяет другим кошкам забирать ресурсы</li>
                        </ul>
                    </div>
                </div>
                <div className="head">Когда уверенная кошка видит пугливую кошку:</div>
                <div className="flex">
                    <div className="flex_el pink">
                        <div className="flex_el_head">
                            Уверенная кошка
                        </div>
                        <ul className="list">
                            <li className="list_item">Опускает голову и шею, одновременно поднимая заднюю часть туловища, и преследует другую кошку.</li>
                            <li className="list_item">Распускает шерсть дыбом на спине, взъерошивает шерсть на хвосте и у основания хвоста</li>
                            <li className="list_item">Рычит</li>
                            <li className="list_item">Может метить территорию</li>
                        </ul>
                    </div>
                    <div className="flex_el pink">
                        <div className="flex_el_head">
                            Пугливая кошка
                        </div>
                        <ul className="list">
                            <li className="list_item">Пригибается, а затем может убежать</li>
                            <li className="list_item">Не издает звуков</li>
                            <li className="list_item">Может метить территорию</li>
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}
