import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

export default class step4 extends React.Component {

    render() {
        return (
            <div className="step4">
                <TextBlock
                    text={`В практической деятельности персонал клиники <span class="text-blue">должен следовать</span> следующим принципам:`}
                />
                <ul className="list">
                    <li className="list_item">
                        <div className="number">1.</div>
                        Проявление бережного отношения и чуткого подхода к кошкам при обследовании и лечении.
                    </li>
                    <li className="list_item">
                        <div className="number">2.</div>
                        Минимизация стресса при обследовании, лечении и проведении других необходимых процедур.
                    </li>
                    <li className="list_item">
                        <div className="number">3.</div>
                        Распознавание и адекватное реагирование на проявление кошкой признаков страха и тревоги.
                    </li>
                    <li className="list_item">
                        <div className="number">4.</div>
                        Инструктаж и предоставление рекомендаций владельцам о способах и методиках уменьшения стресса во время поездки в ветеринарную клинику и обратно.
                    </li>
                    <li className="list_item">
                        <div className="number">5.</div>
                        Избегание насильственного удержания кошки и скраффинга.
                    </li>
                    <li className="list_item">
                        <div className="number">6.</div>
                        Минимизация негативного воздействия запахов, яркого освещения и шума на кошку в ветеринарной клинике.
                    </li>
                    <li className="list_item">
                        <div className="number">7.</div>
                        Ветеринарная клиника должна назначить, по крайней мере, одного ветеринарного врача или фельдшера,
                        который должен обеспечить соблюдение всех необходимых критериев и к которому за дополнительной информацией могут обращаться сотрудники клиники и владельцы кошек.
                    </li>
                </ul>
            </div>
        )
    }
}