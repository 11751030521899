import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from "../../../image/lessons/lesson11/step20-1.png";


export default class step20 extends React.Component {

    render() {
        return (
            <div className="step20">
                <div className="flex">
                    <TextBlock
                        text={` В пожилом возрасте кошкам нужен не только хорошо усваиваемый, но и вкусный корм, который они будут есть с удовольствием. С возрастом кошки становятся более разборчивы и могут перестать есть свой обычный рацион.
                        
                        Существуют специальные корма для пожилых кошек. Например, сухой и влажный корм Whiskas® для кошек старше 7 лет или Perfect Fit™ для стерилизованных кошек старше 7 лет.`}
                    />
                    <img src={img1} alt="" className="flex_img"/>
                </div>
                <div className="head">
                    Свойства корма для пожилых кошек:
                </div>
                <div className="advantages">
                    <div className="advantages_el">
                        омега-6 и цинк <b>для здоровья кожи и шерсти</b>
                    </div>
                    <div className="advantages_el">
                        глюкозамин и омега-3 <b>для здоровья суставов</b>
                    </div>
                    <div className="advantages_el">
                        баланс белков и жиров <b>для жизненной энергии</b>
                    </div>
                    <div className="advantages_el">
                        витамин Е <b>для поддержания иммунитета</b>
                    </div>
                    <div className="advantages_el">
                        специальная формула вкуса <b>для здорового аппетита</b>
                    </div>
                    <div className="advantages_el">
                        высокоусвояемые ингредиенты <b>для здорового пищеварения</b>
                    </div>
                </div>
            </div>
        )
    }
}
