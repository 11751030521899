import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import List from "../../../Components/LessonAndTestComponents/List";
import PopupInStep from "../../../Components/LessonAndTestComponents/PopupInStep";

import img1 from '../../../image/lessons/lesson11/step35-1.png'


export default class step35 extends React.Component {

    state = {
        windowWidth: window.innerWidth,
        flexActive: 0,
        flexs: [0],
        showPopup: false
    };

    //Данные попапа
    imgDivData = {
        1: {
            head: 'Порода',
            text: `Что касается <b>кошек</b>, то метисы примерно вдвое более склонны к избыточному весу, чем чистопородные животные.`,
            class: 'first'
        },
        2: {
            head: 'Генетика',
            text: `Генетические факторы обуславливают предрасположенность животного к избыточному весу, причем на это <b>влияют различные гены.</b>
            
            Животные одной и той же породы могут обладать <b>индивидуальными</b> особенностями. Эти не зависимые от породы вариации указывают на существование комплекса генетических элементов, который предопределяет способность животного к сохранению нормального веса.`,
            class: 'second'
        },
        3: {
            head: 'Возраст',
            text: `Исследования показывают, что <b>кошки</b> особенно <b>подвержены риску увелечения веса в среднем возрасте</b>. Каждая третья кошка в возрасте 6-8 лет страдает избыточным весом.`,
            class: 'third'
        },
        4: {
            head: 'Пол и репродуктивный статус',
            text: `У <b>кошек стерилизация</b> представляет собой основной фактор риска развития ожирения <b>независимо от пола животного.</b>`,
            class: 'fourth'
        },
        5: {
            head: 'Влияние владельца',
            text: `По данным исследований, владельцы животных, страдающих избыточным весом, <b>сами склонны к нему и отказываются признать</b> наличие у животного этой проблемы.

            Образ жизни владельца является важным фактором, определяющим возможность поддержания у животного нормального веса: когда владелец большую часть времени находится дома, для животного возникается повышенный риск набора избыточного веса, причиной которого является <b>недостаточная физическая активность владельца.</b>`,
            class: 'fifth'
        },
        6: {
            head: 'Диетологические факторы',
            text: `На возможность сохранения нормального веса влияет <b>режим кормления</b> питомца, а также <b>тип корма.</b>
            
            Кормление кошек без ограничения приводит к перееданию и вызывает увеличение веса животных. Деление дневного рациона <b>на несколько порций</b> не приведет к такому результату.
            
            Основным фактором ожирения животных является <b>подкармливание едой со стола</b>. Большое количество лакомств также способствует увеличению веса.`,
            class: 'sixth'
        },
        7: {
            head: 'Физическая активность',
            text: `Регулярные <b>физические нагрузки</b> являются основным фактором профилактики ожирения.
            
            Однако большинство владельцев кошек не делают попыток инициировать игру со своими животными, которая удовлетворила бы потребность последних в движении.
            
            Домашние кошки, ведущие менее подвижный образ жизни, более склонны к увеличению веса, чем кошки, имеющие доступ на улицу. Однако последних также следует стимулировать к движению, чтобы помочь им поддерживать нормальный вес.`,
            class: 'seventh'
        },
    }

    //Отлов события ресайз
    handlerResize = () => {
        this.setState({windowWidth: window.innerWidth})
    }

    componentDidMount() {
        window.addEventListener('resize', this.handlerResize)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handlerResize);
    }

    changeFlex = flexActive => {
        const {flexs} = this.state
        if (flexs.indexOf(flexActive) === -1) {
            flexs.push(flexActive)
        }

        this.setState({flexActive, flexs, showPopup: true})
        if (flexs.length === 7) {
            this.props.showNextBtn()
        }
    }

    nextPopup = (flexActive) => {
        const {flexs, showPopup} = this.state
        if (flexs.indexOf(flexActive) === -1) {
            flexs.push(flexActive)
        }

        if (!showPopup) {
            this.setState({
                flexActive: flexActive,
                showPopup: true,
            })
        } else if (flexActive >= 7) {
            this.setState({showPopup: false, flexActive: 0})
        } else {
            this.setState({
                flexActive: flexActive +1,
                showPopup: true,
            })
        }

        if (flexs.length === 8) {
            this.props.showNextBtn()
        }
    }

    closeImgDiv = () => {this.setState({flexActive: 0})}


    render() {
        const {flexActive, windowWidth, flexs} = this.state
        return (
            <div className="step35">
                <TextBlock
                    text={`Избыток веса обусловлен потреблением животным калорий в большем количестве, чем ему требуется. Тем не менее, есть немало факторов, которые оказывают влияние на способность животного сохранять нормальный вес.`}
                />
                <InfoBlock
                    text={`Нажмите на кнопки, чтобы узнать подробнее о причинах ожирения.`}
                />
                <div className="wrp">
                    <div className={'flex '}>
                        <div className={'flex_el ' + (flexs.indexOf(1) >= 0 && 'active')} onClick={() => {
                            this.changeFlex(1)
                        }}>Порода
                        </div>
                        <div className={'flex_el ' + (flexs.indexOf(2) >= 0 && 'active')} onClick={() => {
                            this.changeFlex(2)
                        }}>Генетика
                        </div>
                        <div className={'flex_el ' + (flexs.indexOf(3) >= 0 && 'active')} onClick={() => {
                            this.changeFlex(3)
                        }}>Возраст
                        </div>
                        <div className={'flex_el ' + (flexs.indexOf(4) >= 0 && 'active')} onClick={() => {
                            this.changeFlex(4)
                        }}>Пол и репродуктивный статус
                        </div>
                        <div className={'flex_el ' + (flexs.indexOf(5) >= 0 && 'active')} onClick={() => {
                            this.changeFlex(5)
                        }}>Влияние владельца
                        </div>
                        <div className={'flex_el ' + (flexs.indexOf(6) >= 0 && 'active')} onClick={() => {
                            this.changeFlex(6)
                        }}>Диетологические факторы
                        </div>
                        <div className={'flex_el ' + (flexs.indexOf(7) >= 0 && 'active')} onClick={() => {
                            this.changeFlex(7)
                        }}>Физическая активность
                        </div>
                    </div>
                    {windowWidth >= 768 ?
                        <div className="box">
                            {flexActive === 0 &&
                            <div className="wrp_right">
                                <img className="wrp_img" src={img1} alt=""/>
                            </div>
                            }
                            {flexActive !== 0 &&
                            <div className={'imgDiv ' + this.imgDivData[flexActive].class}>
                                <TextBlock text={this.imgDivData[flexActive].text}/>
                                {this.imgDivData[flexActive].list &&
                                <List ListData={this.imgDivData[flexActive].list}/>
                                }
                                {this.imgDivData[flexActive].text2 &&
                                <TextBlock text={this.imgDivData[flexActive].text2}/>
                                }
                            </div>
                            }
                        </div>
                        :
                        <div className="box">
                            {flexActive > 0 &&
                            <PopupInStep
                                closePopup={this.closeImgDiv}
                                changePoint={() => this.nextPopup(flexActive)}
                            >
                                <div className="PopupInStep_head">
                                    {this.imgDivData[flexActive].head}
                                </div>
                                <TextBlock
                                    text={this.imgDivData[flexActive].text}
                                />
                            </PopupInStep>
                            }
                        </div>
                    }
                </div>
            </div>
        )
    }
}
