import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import PlanInFirstStep from '../../../Components/LessonAndTestComponents/PlanInFirstStep';

export default class step1 extends React.Component {

    planData = [
        `Основные требования к ветеринарным клиникам для получения знака «Здесь комфортно кошкам»`,
        `Тест`,
    ]

    render() {
        return (
            <div className="step1">
                <hr className="string"/>
                <div className="head">План урока</div>
                <TextBlock
                    text={`В этом уроке мы рассмотрим и закрепим полученные знания на тему:`}
                />
                <PlanInFirstStep
                    planData={this.planData}
                    type={'violet'}
                />
            </div>
        )
    }
}