import React, {useEffect, useState} from 'react';
import './style.sass';

import Api from '../../Service/Api'
import Inputs from "../../Components/Inputs";
import InputFileNew from "../../Components/InputFileNew";
import Checkbox from "../../Components/Checkbox";
import TextBlock from '../../Components/LessonAndTestComponents/TextBlock';

const Accreditation = () => {
    const THISApi = new Api();
    const [validate, setValidate] = useState('')
    const [fileOne, setfileOne] = useState(false)

    const [next, setNext] = useState(false)
    const [comleted, setCompleted] = useState(false)
    const [stepZero, setStepZero] = useState(false)

    const [inputs, setInputs] = useState({
        info_board_file_one: '',
        info_board_file_one_extension: '',
    })



    const getData = async () => {
        await THISApi.getUserData().then(user => {
                THISApi.getClinics(user.user.city)
                    .then(clinic => (clinic.map(clinic => {
                            if (clinic.id === user.user.clinic_id) {
                                setInputs({
                                    ...inputs,
                                    clinic_city: user.user.city,
                                    contact_surname: user.user.last_name,
                                    contact_name: user.user.first_name,
                                    contact_middle_name: user.user.middle_name,
                                    contact_phone: user.user.phone.slice(1),
                                    clinic_name: clinic.name,
                                    clinic_address: clinic.address,
                                })
                            }
                        }))
                    )
            })
    }

    useEffect(() => {
        getData()
    }, [])

    const completed = () => {
        setCompleted(true)
        setTimeout(function () {
            document.location.href = "/";
        }, 5000)
    }

    const stepOne = () => {
        setStepZero(!stepZero)
    }

    const validateStepOne = () => {
        setValidate(true)
        for (let key in inputs) {
            if (inputs[key].length <= 0) {
                return;
            }
            if (key === 'contact_position') {
                setNext(!next)
                setValidate(false)
            }
        }
    }

    const validateStepTwo = () => {
        setValidate(true)
        for (let key in inputs) {
            if (inputs[key].length <= 0) {
                return;
            }
        }
        setValidate(false)
        sendForm()
    }
    const sendForm = () => {
        setNext(!next)
        THISApi.updateAccreditationTest(inputs)
            .then((res) => {
                 console.log('some')
            }).catch(err => console.log(err))
    }

    const updateState = (type, value) => {
        setInputs({...inputs, [type]: value})
    }

    const updateStateFileOne = (value, ext, name) => {
        setInputs({...inputs, info_board_file_one: value, info_board_file_one_extension: ext})
        setfileOne(name)
    }



    return (
        <div className="Accreditation">
            <div className="Accreditation_content">
                <div className="Accreditation__wrp">
                        <div>
                            {!comleted ?
                                <form action="#" className="Accreditation__form">
                                    <div className="Accreditation__name">
                                        Заявка на аккредитацию <br/>
                                        Клиники комфортной для кошек
                                    </div>
                                    <div className="Accreditation__description">
                                        Для получения аккредитации клиники, <br/>
                                        пожалуйста заполните форму из двух шагов.
                                    </div>
                                    <div className="step1">
                                        <div className="Accreditation__flex">
                                            <div className={next ? 'commonInfo accInfo active' : 'commonInfo accInfo'}>
                                                <p className="info__icon">
                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                         xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M9.86957 0.000713581C4.34823 0.0659263 -0.0644992 4.60908 0.000713581 10.1304C0.0659263 15.6518 4.60908 20.0645 10.1304 19.9993C15.6518 19.9341 20.0645 15.3909 19.9993 9.86957C19.9341 4.34823 15.3909 -0.0644991 9.86957 0.000713581ZM10.9782 3.32656C11.9999 3.32656 12.3042 3.91348 12.3042 4.58734C12.3042 5.43511 11.6303 6.21766 10.4782 6.21766C9.52177 6.21766 9.06528 5.73943 9.08702 4.93514C9.06528 4.26128 9.63046 3.32656 10.9782 3.32656ZM8.36968 16.2604C7.67408 16.2604 7.17412 15.8474 7.65234 13.978L8.45663 10.6956C8.58706 10.1739 8.6088 9.95652 8.45663 9.95652C8.23926 9.95652 7.34801 10.3261 6.80458 10.6739L6.45677 10.1087C8.15231 8.69574 10.087 7.86972 10.913 7.86972C11.6086 7.86972 11.7173 8.69574 11.3695 9.95652L10.4565 13.4128C10.3043 14.0214 10.3695 14.2388 10.5217 14.2388C10.7391 14.2388 11.4129 13.978 12.0868 13.4563L12.4781 13.978C10.8478 15.6083 9.06528 16.2604 8.36968 16.2604Z"
                                                            fill="#C6C6C6"/>
                                                    </svg>
                                                </p>
                                                <p className="topInfo">
                                                    Общая информация
                                                </p>
                                            </div>
                                            <div className={next ? 'requirements accInfo active' : "requirements accInfo"}>
                                                <p className="info__icon">
                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                         xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M9.86957 0.000713581C4.34823 0.0659263 -0.0644992 4.60908 0.000713581 10.1304C0.0659263 15.6518 4.60908 20.0645 10.1304 19.9993C15.6518 19.9341 20.0645 15.3909 19.9993 9.86957C19.9341 4.34823 15.3909 -0.0644991 9.86957 0.000713581ZM10.9782 3.32656C11.9999 3.32656 12.3042 3.91348 12.3042 4.58734C12.3042 5.43511 11.6303 6.21766 10.4782 6.21766C9.52177 6.21766 9.06528 5.73943 9.08702 4.93514C9.06528 4.26128 9.63046 3.32656 10.9782 3.32656ZM8.36968 16.2604C7.67408 16.2604 7.17412 15.8474 7.65234 13.978L8.45663 10.6956C8.58706 10.1739 8.6088 9.95652 8.45663 9.95652C8.23926 9.95652 7.34801 10.3261 6.80458 10.6739L6.45677 10.1087C8.15231 8.69574 10.087 7.86972 10.913 7.86972C11.6086 7.86972 11.7173 8.69574 11.3695 9.95652L10.4565 13.4128C10.3043 14.0214 10.3695 14.2388 10.5217 14.2388C10.7391 14.2388 11.4129 13.978 12.0868 13.4563L12.4781 13.978C10.8478 15.6083 9.06528 16.2604 8.36968 16.2604Z"
                                                            fill="#C6C6C6"/>
                                                    </svg>
                                                </p>
                                                <p className="topInfo">
                                                    Соответствия требованиям<br/>
                                                    Cat Friendly клиники
                                                </p>
                                            </div>
                                        </div>
                                                <div className="step2__inputs">
                                                    <div className="Accreditation__description-new">
                                                        Подтвердите соответствие требованиям Cat Friendly клиники <br/>
                                                        и заполните данные ниже:
                                                    </div>

                                                    <div className="inputsBlock">
                                                        <div className="inputsBlock__title__small small-star">Фото информационной доски</div>
                                                        <div className="inputFull inputFull-file">
                                                            <InputFileNew
                                                                id={'info_board_file_one'}
                                                                name={'info_board_file_one'}
                                                                placeholder={fileOne ? fileOne : 'Добавить еще фото'}
                                                                updateFile={updateStateFileOne}
                                                                key={'info_board_file_one'}
                                                                className={validate && inputs.info_board_file_one.length<= 0 && 'error'}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                    </div>



                                    <div className="button_container">
                                        <button
                                            type="button"
                                            className="btn btn_step btn_default btn_violet fright"
                                            onClick={() => validateStepTwo()}
                                        >
                                            {'Отправить'}
                                        </button>
                                    </div>
                                </form>
                                :
                                <div className="complet">
                                    <svg width="48" height="48" viewBox="0 0 48 48" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clip-rule="evenodd"
                                              d="M32.6861 17.2725C33.0879 17.6514 33.1064 18.2843 32.7275 18.6861L21.4104 30.6861C21.2215 30.8864 20.9583 31 20.6829 31C20.4076 31 20.1444 30.8864 19.9554 30.6861L15.2725 25.7206C14.8936 25.3188 14.9121 24.6859 15.3139 24.307C15.7157 23.9281 16.3486 23.9466 16.7275 24.3484L20.6829 28.5425L31.2725 17.3139C31.6514 16.9121 32.2843 16.8936 32.6861 17.2725Z"
                                              fill="#4DD06A"/>
                                        <rect x="1" y="1" width="46" height="46" rx="23" stroke="#4DD06A" stroke-width="2"/>
                                    </svg>
                                    <div className="complet__title">
                                        Спасибо!<br/>
                                        Мы приняли Вашу заявку.
                                    </div>
                                    <div className="complet__desc">
                                        Ваша заявка будет рассмотрена в течение месяца.
                                    </div>
                                    <div className="complet__mess">
                                        Через 5 секунд вы перейдете обратно на <a href="/">портал</a>
                                    </div>

                                </div>
                            }
                        </div>

                </div>
            </div>
        </div>
    )
}
export default Accreditation