import React from 'react';
import Api from '../../Service/Api'
import PanelTest from "../../Components/PanelTest";
import CourseStat from "../../Components/CourseStat";

import {withRouter} from 'react-router-dom';
// import PanelStudy from "../../Components/PanelStudy";



class MyTesting extends React.Component {

    Api = new Api();

    state = {
        courses: {},
        topics: []
    }

    componentDidMount() {
        this.Api.getTestCourses()
            .then(this.getTestsSuccess)
            .catch(res => console.log(res))

        this.Api.getTopics()
            .then((res) => {
                if (res !== undefined) {
                    this.setState({topics: res})
                }
            })
    }

    getTestsSuccess = (courses) => {
        this.setState({courses})
    }

    _renderTest = () => {
        const {courses, topics} = this.state;
        if (courses !== undefined && Object.values(courses).length) {
            return (
                Object.values(courses).map(course => {
                    let courseAttr = course.course.attributes
                    let testsAttr = course.course.tests
                    let pointTotal = course.course['all-points']
                    let testTopic = []
                    let testOutTopic = []
                    // eslint-disable-next-line
                    testsAttr.map(item => {
                        if (item.topic === 'Без темы') {
                            testOutTopic.push(item.topic)
                        }
                        testTopic.push(item.topic)
                    })

                    console.log(666, course.course)
                    return (
                        <div key={courseAttr.created_at}>
                            <div className="content_head">
                                {courseAttr.name}
                            </div>
                            {topics.map((item, index) => {
                                return (
                                    <div
                                        key={index}
                                        className="content_topic"
                                    >
                                        {testTopic.indexOf(item) >= 0 && testOutTopic.length !== testTopic.length &&
                                        <div className="content_topic_head">
                                            {item}
                                        </div>
                                        }
                                        <div className="studyPanels">
                                            {testsAttr !== undefined && Object.values(testsAttr).length &&
                                            // eslint-disable-next-line
                                            Object.values(testsAttr).map(test => {
                                                // pointTotal = pointTotal + test.attributes.points;
                                                let lesson = {};
                                                lesson.attributes = {};
                                                lesson.attributes.id = test.attributes.lesson_id;
                                                lesson.MainHeader_header = test.attributes.name;
                                                lesson.MainHeader_subheader = test.attributes.description;
                                                lesson.plan = test.lessonPlan;
                                                if (test.topic === item) {
                                                    return (
                                                        <PanelTest
                                                            key={test.attributes.id}
                                                            active={test.active}
                                                            name={test.attributes.name}
                                                            text={test.attributes.description}
                                                            steps={test.steps}
                                                            userSteps={test.userSteps}
                                                            id={test.attributes.id}
                                                            balls={test.attributes.points}
                                                            finished={test.finished}
                                                            lesson={lesson}
                                                            history={this.props.history}
                                                        />
                                                    )
                                                }
                                            })
                                            }

                                        </div>
                                    </div>
                                )
                            })}

                            <CourseStat courses={course.course} pointTotal={pointTotal}/>
                        </div>
                    )
                })
            )
        } else {
            return undefined;
        }
    }


    render () {
        return (
            <div>
                {this._renderTest()}
            </div>

        )
    }
}

export default withRouter(MyTesting);
