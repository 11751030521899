import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import Popup from "../../../Components/Popup";

import img1 from '../../../image/lessons/lesson6/step5-1.png'

export default class step5 extends React.Component {

    state = {
        windowWidth: window.innerWidth,
        pointActive: 0,
        showPopup: false,
        points: [],
    };

    //Отлов события ресайз
    handlerResize = () => {
        this.setState({windowWidth: window.innerWidth})
    }

    componentDidMount() {
        window.addEventListener('resize', this.handlerResize)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handlerResize);
    }

    showPopup = (pointActive) => {
        const {points, showPopup, windowWidth} = this.state;
        if (points.indexOf(pointActive) === -1) {
            points.push(pointActive)
        }

        if (windowWidth < 768) {
            if (!showPopup) {
                this.setState({
                    pointActive: pointActive,
                    showPopup: true,
                })
            } else if (pointActive >= 1) {
                this.setState({showPopup: false, pointActive: 0})
            } else {
                this.setState({
                    pointActive: pointActive +1,
                    showPopup: true,
                })
            }
        } else {
            this.setState({points: points, pointActive: pointActive})
        }
        if (points.length === 1) {
            this.props.showNextBtn();
        }
    }

    closePopup = () => {
        this.setState({showPopup: false})
    }

    render() {
        const {showPopup, pointActive} = this.state
        return (
            <div className="step5">
                <TextBlock
                    text={`У кошек нет такого же ежедневного цикла сна и бодрствования, как у людей и у многих других животных. Вместо этого они спят и часто просыпаются в течение дня и ночи. Это происходит потому, что кошки в условиях дикой природы охотятся примерно на 20 мелких животных каждый день. Кошки должны иметь возможность отдыхать между каждой охотой, чтобы они были готовы быстро наброситься по мере приближения добычи. Это объясняет, почему наши кошки так много спят днем, когда мы бодрствуем, и вместо этого проводят так много времени, бодрствуя ночью, когда нам нужно спать!`}
                />
                <InfoBlock
                    text={`Кликните на изображение, что бы узнать больше`}
                />
                <div className="box">
                    <div className="box_img">
                        <img
                            src={img1} className="imgCenter" alt=""
                            onClick={() => {this.showPopup(1)}}
                        />
                        <div className={'block ' + (pointActive === 1 && 'active')}>
                            Мы можем прийти к выводу, что кошки являются ночными созданиями, но это только кажется на первый взгляд, поскольку их цикл сна и бодрствования разительно отличается от нашего цикла. Добавление в распорядок дня и повседневную жизнь вашей кошки ежедневных игр может уменьшить возникновение такой ночной активности.
                        </div>
                    </div>
                </div>
                {showPopup &&
                <Popup
                    closePopup={this.closePopup}
                >
                    <div className={'info'}>
                        <TextBlock
                            text={`Мы можем прийти к выводу, что кошки являются ночными созданиями, но это только кажется на первый взгляд, поскольку их цикл сна и бодрствования разительно отличается от нашего цикла. Добавление в распорядок дня и повседневную жизнь вашей кошки ежедневных игр может уменьшить возникновение такой ночной активности.`}
                        />
                    </div>
                    <button
                        className="btn btn_violet btn_center"
                        onClick={() => {this.showPopup(pointActive)}}
                    >
                        Продолжить
                    </button>
                </Popup>
                }
            </div>
        )
    }
}
