import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson31/step10-1.png'
import img2 from '../../../image/lessons/lesson31/step10-2.png'
import img3 from '../../../image/lessons/lesson31/step10-3.png'
import img4 from '../../../image/lessons/lesson31/step10-4.png'
import img5 from '../../../image/lessons/lesson31/step10-5.png'
import img6 from '../../../image/lessons/lesson31/step10-6.png'
import img7 from '../../../image/lessons/lesson31/step10-7.png'
import img8 from '../../../image/lessons/lesson31/step10-8.png'


export default class step10 extends React.Component {

    render() {
        return (
            <div className="step10">
                <div className="head">
                    Классификация по кормовой форме
                </div>
                <TextBlock
                    text={`Вы уже знаете, что по кормовой форме готовые рационы делятся на сухие и влажные. Повторите, какими полезными свойствами обладают разные типы кормов.`}
                />
                <div className="table">
                    <h3 className="table_head">
                        Особенности состава
                    </h3>
                    <div className="flex flex--top">
                        <div className="flex_el">
                            <div className="flex_el_dry">Сухой корм</div>
                            <div className="flex_el_item">
                                <p className="flex_el_icon">
                                    <img src={img3} alt=""/>
                                </p>
                                <p className="flex_el_text">
                                    Содержит около 90% сухого вещества и до 10% влаги.
                                </p>
                            </div>
                        </div>
                        <div className="flex_el">
                            <div className="flex_el_wet">Влажный корм</div>
                            <div className="flex_el_item">
                                <p className="flex_el_icon">
                                    <img src={img4} alt=""/>
                                </p>
                                <p className="flex_el_text">
                                    Содержит около 20% сухого вещества и около 80% влаги.
                                </p>
                            </div>
                        </div>
                    </div>
                    <h3 className="table_head">
                        Преимущества
                    </h3>
                    <div className="flex flex--bottom">
                        <div className="flex_el">
                            <div className="flex_el_dry">Сухой корм</div>
                            <div className="flex_el_item">
                                <p className="flex_el_icon">
                                    <img src={img5} alt=""/>
                                </p>
                                <p className="flex_el_text">
                                    Способствует профилактике заболеваний ротовой полости за счёт механической чистки зубов.
                                </p>
                            </div>
                            <div className="flex_el_item">
                                <p className="flex_el_icon">
                                    <img src={img6} alt=""/>
                                </p>
                                <p className="flex_el_text">
                                    Способствует правильному пищеварению за счёт большего содержания клетчатки (в том числе диетической) в составе.
                                </p>
                            </div>
                        </div>
                        <div className="flex_el">
                            <div className="flex_el_wet">Влажный корм</div>
                            <div className="flex_el_item">
                                <p className="flex_el_icon">
                                    <img src={img7} alt=""/>
                                </p>
                                <p className="flex_el_text">
                                    Способствует снижению риска развития заболеваний мочевыделительной системы за счёт высокого содержания влаги, а значит, образования мочи большего объема и снижения её концентрации.
                                </p>
                            </div>
                            <div className="flex_el_item">
                                <p className="flex_el_icon">
                                    <img src={img8} alt=""/>
                                </p>
                                <p className="flex_el_text">
                                    Способствует профилактике лишнего веса за счет низкой калорийности.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="table_dry">Сухой корм</div>
                    <div className="table_wet">Влажный корм</div>
                </div>
                <div className="advice">
                    <p className="advice_text">
                        Ежедневное сочетание влажных и сухих рационов позволяет объединить характерные преимущества каждого из них.
                    </p>
                    <div className="advice_feed">
                        <img className="advice_feed_big" src={img1} alt=""/>
                        <span className="advice_plus">+</span>
                        <img className="advice_feed_small" src={img2} alt=""/>
                    </div>
                </div>
            </div>
        )
    }
}
