import React from 'react'
import './style.sass'

export default class List extends React.Component {

    state = {
        data: this.props.data
    }

    render() {
        // Пример испльзования lesson 7, step 16
        const {data} = this.state
        return (
            <ol className="ListNumeral">
                {data.map((item, index) => {
                    return (
                        <li
                            key={index}
                            className="ListNumeral_item"
                        >
                            <span className="ListNumeral_item_number">{(index + 1) + '.'}</span>
                            <span
                                className="ListNumeral_item_text"
                                dangerouslySetInnerHTML={{ __html: item}}
                            />
                        </li>
                    )
                })}
            </ol>
        )
    }
}
