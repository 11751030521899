import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from "../../../image/lessons/lesson7/step2-1.png";

export default class step2 extends React.Component {

    render() {
        return (
            <div className="step2">
                <div className="info">
                    Важно понимать, что кошки не реагируют на силу, но откликаются на похвалу!
                </div>
                <TextBlock
                    text={`Наказание, которое последует лишь через несколько секунд после совершения проступка, не отучит кошку от повторения нежелательного действия, но может спровоцировать у нее недоверие и страх перед человеком/владельцем.
                    
                    Если кошку застали во время совершения проступка, то необходимо переключить ее внимание на любую другую активность, например, бросить какой-то предмет, который может ее отвлечь, например мячик (только НЕ бросайте его в кошку!).`}
                />
                <img src={img1} alt="" className="imgCenter"/>
            </div>
        )
    }
}
