import React from 'react'
// import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from "../../../image/lessons/lesson7/step8-1.png";

export default class step8 extends React.Component {

    render() {
        return (
            <div className="step8">
                <div className="listNum">
                    <span className="listNum_number">2.</span>
                    Лучшим показателем благополучия кошки является ее здоровье:
                    <div className="letterList">
                        <span className="letterList_letter">a.</span>
                        Кошка должна быть активной. К элементам активности кошки относится лазание и отдых на насесте.
                    </div>
                    <div className="letterList">
                        <span className="letterList_letter">b.</span>
                        Кошка взаимодействует с окружающими людьми.
                    </div>
                    <div className="letterList">
                        <span className="letterList_letter">c.</span>
                        Домашние кошки обычно едят маленькими порциями и пьют в течение всего дня.
                    </div>
                    <div className="letterList">
                        <span className="letterList_letter">d.</span>
                        Большинство кошек используют лоток от 2 до 4 раз в день.
                    </div>
                    <div className="letterList">
                        <span className="letterList_letter">e.</span>
                        Увеличение или уменьшение веса кошки на 10% это важный показатель.
                    </div>
                    <div className="letterList">
                        <span className="letterList_letter">f.</span>
                        Обычно кошки ухаживают за всем телом 2-3 раза в день.
                    </div>
                    <div className="letterList">
                        <span className="letterList_letter">g.</span>
                        Большинство кошек любят спать на виду в любимых местах для отдыха.
                    </div>
                    <div className="letterList">
                        <span className="letterList_letter">h.</span>
                        Кошки могут издавать разные звуки; одни молчаливы, другие, наоборот, разговорчивы. Зная привычки конкретной кошки, гораздо проще контролировать любые изменения в ее состоянии.
                    </div>
                </div>
                <img src={img1} alt="" className="imgCenter"/>
            </div>
        )
    }
}
