import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson6/step11-1.png'

export default class step11 extends React.Component {

    render() {
        return (
            <div className="step11">
                <div className="head">
                    Кошачий насест и его роль для кошки
                </div>
                <img src={img1} alt="" className="imgCenter"/>
                <TextBlock
                    text={`<b>Кошки нуждаются в том, чтобы их окружали предметы или вещи, позволяющие им забираться достаточно высоко в вашем доме.</b> Кошки любят лазить, поэтому специальные перекладины или насесты на возвышенности обеспечат им безопасное и уединенное место, с которого они смогут наблюдать за происходящим сверху. Такие перекладины позволят кошке лежать, сидеть, спать или смотреть на улицу, находясь при этом на возвышенности и имея отличный угол обзора. Наличие таких перекладин является обязательным условием для кошек.`}
                />
            </div>
        )
    }
}
