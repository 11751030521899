import React from 'react'
import VideoBlock from "../../../Components/LessonAndTestComponents/VideoBlock";
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";


export default class step48 extends React.Component{

    render() {
        return (
            <div className="step48">
                <TextBlock
                    text={`Вы можете посмотреть обучающий видеоролик:`}
                />
                <VideoBlock
                    src={'https://www.youtube.com/embed/UpSH7Icz_2I'}
                    title={'https://www.youtube.com/watch?v=UpSH7Icz_2I&feature=youtu.be'}
                />
                <TextBlock
                    text={`Мягкие пластиковые или резиновые колпачки, которые приклеиваются на коготь, выпускаются под разными брендами и доступны в различных размерах и цветах.`}
                />
            </div>
        )
    }
}
