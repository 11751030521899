import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

export default class step12 extends React.Component {


    render() {
        return (
            <div className="step12">
                <div className="head">
                    Классификация по вкусу
                </div>
                <TextBlock
                    text={`По вкусу корма для животных делятся на четыре большие группы. Корма бывают как с каким-либо одним вкусом, так и с сочетанием нескольких вкусов. Не забывайте, что кошки склонны к разнообразию вкусов, поэтому время от времени рекомендуется покупать питомцам корма с различными вкусами.`}
                />
                <div className="flex">
                    <div className="flex_el">
                        <div className="flex_el_wrp">
                            <h3 className="flex_el_head">
                                вкусы с птицей
                            </h3>
                            <p className="flex_el_text">
                                курица, индейка, утка
                            </p>
                            <div className="flex_el_box">
                                <span className="flex_el_icon">
                                    <svg width="33" height="28" viewBox="0 0 33 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M18.1564 21.1118C17.9779 19.4331 17.165 17.6884 15.7573 16.2807C12.9155 13.4389 10.2653 11.9849 7.62179 13.1811C4.49578 14.5954 5.16989 21.852 8.00511 24.6938C9.61768 26.3064 11.673 27.1061 13.5698 27.0995L24.7983 27.0664C26.1531 27.0664 27.118 26.009 28.1027 25.0838C29.2527 23.9999 31.4072 22.2155 31.4072 20.788C31.4072 15.4942 23.8136 11.2051 18.5199 11.2051C16.709 11.2051 15.6714 11.7074 14.2241 12.5863" stroke="#4147DA" stroke-width="1.8" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M8.94363 12.5206C8.94363 12.5206 7.83333 11.2847 7.2187 10.214C6.60408 9.1368 7.05348 7.18057 7.09314 6.4602C7.13279 5.73983 4.72054 5.31686 4.4694 5.66052C4.08609 6.17601 4.48262 6.7576 3.82173 7.21361C2.97579 7.8018 2.6057 7.02856 1.67384 7.40527C0.741989 7.78858 0.761815 10.6767 1.82585 10.6833C2.88988 10.6833 4.62141 11.8993 4.97829 12.5272C5.33517 13.1484 6.30007 14.5098 6.30007 14.5098" stroke="#4147DA" stroke-width="1.8" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M18.7908 9.36116C17.2443 7.73537 16.134 7.45779 14.3099 7.94024C12.9353 8.30373 12.1687 9.55281 11.9043 11.4694" stroke="#4147DA" stroke-width="1.8" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M15.532 7.56389C15.532 7.56389 14.7588 6.34785 14.3887 5.3433C14.0186 4.33875 14.6861 2.72618 14.8249 2.11816C14.9637 1.51014 12.9612 0.796381 12.6968 1.05413C12.2937 1.44405 12.6439 1.9992 12.0161 2.2966C11.2098 2.67331 10.9058 1.95294 10.0533 2.1446C9.20071 2.33625 8.80417 4.80798 9.7162 4.96659C10.6282 5.1186 11.9302 6.40733 12.1417 6.99552C12.3531 7.58371 13.0074 8.71383 13.0074 8.71383" stroke="#4147DA" stroke-width="1.8" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="flex_el">
                        <div className="flex_el_wrp">
                            <h3 className="flex_el_head">
                                сочетания вкусов
                            </h3>
                            <p className="flex_el_text">
                                курица с овощами, говядина и кролик, телятина и индейка
                            </p>
                            <div className="flex_el_box">
                                <span className="flex_el_icon">
                                    <svg width="29" height="32" viewBox="0 0 29 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M18.4952 24.1941C19.5064 24.5576 20.7885 24.9541 21.7005 25.1921C22.0971 24.9938 24.8464 24.0355 25.8113 23.6588C26.1747 23.3217 27.0802 22.1652 27.3181 21.5241C27.4172 21.2003 27.404 20.8698 27.3181 20.3279C27.1661 19.4027 25.3949 14.142 24.2119 13.2366C23.5312 12.7211 18.4754 10.5269 17.0809 10.289C15.845 6.38315 13.5319 2.51695 10.7826 1.367C9.24276 0.719329 8.71405 1.03656 8.54883 1.49257" stroke="#4147DA" stroke-width="1.8" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M21.6988 25.1934C21.7847 27.8171 20.4166 29.6345 18.1895 30.2888" stroke="#4147DA" stroke-width="1.8" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M14.806 10.8899C14.4491 9.41616 11.2768 5.03446 8.92406 3.75895C6.5713 2.48343 4.80672 2.76761 4.21853 3.48798C3.34616 4.55201 6.2012 10.4934 7.3181 11.7293C8.435 12.9651 9.75017 14.2803 10.1864 14.6372C10.1071 15.3113 9.63121 16.871 9.26772 17.4261C7.11323 16.0845 3.64356 15.7144 1 16.7454" stroke="#4147DA" stroke-width="1.8" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </span>
                                <span className="flex_el_icon">
                                    <svg width="30" height="32" viewBox="0 0 30 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.91516 10.5908C9.22784 5.85225 5.7846 2.93773 3.30627 2.66016C1.81927 4.8477 1.70692 9.18974 5.28894 11.9126" stroke="#4147DA" stroke-width="1.8" stroke-miterlimit="10"/>
                                        <path d="M2.64356 8.60742H0" stroke="#4147DA" stroke-width="1.8" stroke-miterlimit="10"/>
                                        <path d="M10.5747 6.62622C11.3612 5.4895 12.2071 4.35277 15.2009 3.98267C18.6442 3.55309 20.6797 3.40109 25.1143 2C24.8631 4.4519 20.9639 6.26934 17.8445 7.28711C15.7429 7.96783 16.1262 9.10456 17.1836 9.93067C21.215 11.8737 20.5674 13.1955 20.4881 15.2178C21.3803 16.7841 24.6913 19.3351 26.4361 21.1658C27.5397 22.3223 28.6963 23.2013 28.1412 24.2984C26.8392 26.8825 25.1672 29.7573 23.7925 29.7573C22.8606 29.7573 19.3579 29.6582 17.8445 28.4356C13.7668 29.7573 6.39129 28.9312 4.63332 27.9069C4.11122 28.1977 1.32227 30.4182 1.32227 30.4182" stroke="#4147DA" stroke-width="1.8" stroke-miterlimit="10"/>
                                    </svg>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="flex_el">
                        <div className="flex_el_wrp">
                            <h3 className="flex_el_head">
                                рыбные вкусы
                            </h3>
                            <p className="flex_el_text">
                                лосось, форель, креветки
                            </p>
                            <div className="flex_el_box">
                                <span className="flex_el_icon">
                                    <svg width="32" height="22" viewBox="0 0 32 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M23.992 6.39286C22.0953 4.83977 17.9383 1.48906 16.643 1C15.2088 2.13012 14.733 4.81333 14.6074 5.99632" stroke="#4147DA" stroke-width="1.8" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M14.6072 5.99718C13.9066 6.11614 12.7897 6.25493 12.3932 6.38711C11.9438 6.13597 9.70339 5.25038 8.99624 5.24377C8.43448 5.98397 8.34857 6.75721 8.36179 7.86089C8.30231 7.89394 7.74716 8.36317 6.42538 8.13847C5.79754 7.44453 4.05279 5.51474 3.23329 5.10498C2.41378 4.69523 1.28366 4.91993 1.01931 5.17107C0.748342 5.42882 3.41834 10.478 3.4646 11.4561C3.40512 12.725 2.08995 16.1749 2.37413 16.4458C2.6517 16.7102 3.89418 16.8093 4.50219 16.2079C6.10815 14.6152 6.1412 14.1856 6.61043 13.5379C7.13253 12.8043 7.64803 12.2492 8.21639 12.5995C8.21639 12.5995 11.2102 15.1042 14.1446 16.1022" stroke="#4147DA" stroke-width="1.8" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M19.2804 5.73828C20.7211 5.79776 22.3403 5.89689 23.9925 6.38595C29.729 8.09105 31.0971 9.70362 30.998 10.5496C30.6741 13.3319 25.9223 16.3984 21.9438 17.0131C20.0404 18.9627 18.8376 19.4848 14.707 20.3373C15.6323 16.5636 17.5753 14.0391 18.6922 13.0675" stroke="#4147DA" stroke-width="1.8" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="flex_el">
                        <div className="flex_el_wrp">
                            <h3 className="flex_el_head">
                                мясные вкусы
                            </h3>
                            <p className="flex_el_text">
                                говядина, телятина, ягненок, кролик
                            </p>
                            <div className="flex_el_box">
                                <span className="flex_el_icon">
                                    <svg width="33" height="24" viewBox="0 0 33 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M29.6833 4.24069C28.5928 3.24275 26.815 2.85944 24.3433 2.22498C20.8406 1.33278 9.96893 0.546323 6.12256 1.30635C2.27618 2.06637 0.637175 3.71859 1.06675 5.21881C1.49633 6.72564 4.04736 8.21264 4.99904 8.90657C6.3803 9.91112 9.14943 14.0879 11.8525 15.0462C14.0929 15.8393 17.133 16.3812 21.779 15.0595C23.9996 14.3986 25.3677 13.4799 27.2578 12.33C28.9629 11.299 30.9191 9.42207 31.309 7.7368C31.699 6.05153 30.7737 5.24525 29.6833 4.24069Z" stroke="#4147DA" stroke-width="1.8" stroke-miterlimit="10"/>
                                        <path d="M1.00195 5.14648C1.00195 5.14648 1.00195 10.4865 1.00195 11.7554C1.00195 13.0243 4.01561 14.8219 4.96729 15.5158C6.34855 16.5204 9.15072 20.6972 11.8538 21.6555C14.0942 22.4486 17.1343 22.9905 21.7803 21.6687C24.0009 21.0078 25.4152 20.0892 27.3053 18.9392C29.0104 17.9083 31.4029 15.7471 31.4029 14.3989C31.4029 13.0507 31.4029 7.79004 31.4029 7.79004" stroke="#4147DA" stroke-width="1.8" stroke-miterlimit="10"/>
                                        <path d="M10.915 7.79077C12.375 7.79077 13.5586 7.05105 13.5586 6.13855C13.5586 5.22605 12.375 4.48633 10.915 4.48633C9.45505 4.48633 8.27148 5.22605 8.27148 6.13855C8.27148 7.05105 9.45505 7.79077 10.915 7.79077Z" stroke="#4147DA" stroke-width="1.8" stroke-miterlimit="10"/>
                                    </svg>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
