import React from 'react'
import './style.sass'

export default class InputFile extends React.Component {

    state = {
        fileName: this.props.placeholder,
        className: '',
        fileValue: '',
    }

    addsFileName = (name, value, context) => {
        let inputFile = value;
        let reader  = new FileReader();
        let fileName = name
        let fileExt = fileName.split('.').pop();
        reader.readAsDataURL(inputFile)
        reader.onloadend = reloadState
        function reloadState () {
            context.setState({fileName: fileName, className: 'inputFile__name--bold', fileValue: reader.result})
            context.props.updateFile(reader.result, fileExt, fileName)
        }
    }

    render() {
        console.log(this.state)
        const {fileName, className} = this.state
        return (
            <div className={'inputFile'}>
                <span className="inputFile__star">&#42;</span>
                <input
                    className="visually-hidden"
                    type="file"
                    name={this.props.name}
                    id={this.props.id}
                    onChange={(e) => {this.addsFileName(e.target.files[0].name, e.target.files[0], this)}}
                    multiple={'multiple'}
                />
                <label
                    className="inputFile__label"
                    htmlFor={this.props.id}
                >
                    <span className={'inputFile__name ' + className}>{fileName}</span>
                    <span className="inputFile__icon">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.8413 5.82715C14.5802 5.56749 14.1581 5.56865 13.8984 5.82969L5.88428 13.8872C4.84454 14.9269 3.15585 14.9269 2.11417 13.8858C1.07327 12.8444 1.07327 11.1557 2.11429 10.1147L10.3657 1.81991C11.0148 1.17081 12.0702 1.17081 12.7212 1.82132C13.372 2.47215 13.372 3.52739 12.721 4.1784L5.88553 11.0139C5.88509 11.0143 5.88472 11.0148 5.88428 11.0152C5.62387 11.2742 5.20302 11.2739 4.94313 11.014C4.68282 10.7537 4.68282 10.3317 4.94313 10.0714L8.24258 6.7713C8.5029 6.51092 8.50287 6.08878 8.24249 5.82847C7.9821 5.56815 7.55997 5.56818 7.29965 5.82856L4.00024 9.12863C3.21926 9.90961 3.21926 11.1758 4.0003 11.9568C4.78132 12.7378 6.04747 12.7378 6.82852 11.9568C6.82942 11.9559 6.83014 11.9549 6.83102 11.954L13.6638 5.12117C14.8356 3.94946 14.8356 2.04998 13.6638 0.878268C12.492 -0.292756 10.5926 -0.292756 9.4216 0.878268L1.17021 9.17307C-0.390258 10.7335 -0.390258 13.2662 1.17134 14.8286C2.73396 16.3904 5.2667 16.3904 6.82839 14.8287L14.8438 6.76999C15.1035 6.50892 15.1023 6.08681 14.8413 5.82715Z" fill="#C6C6C6"/>
                        </svg>
                    </span>
                </label>
            </div>
        )
    }
}
