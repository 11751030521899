import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

export default class step5 extends React.Component {

    render() {
        return (
            <div className="step5">
                <TextBlock
                    text={`<b>Следующие советы по уходу за больным животным в домашних условиях помогут владельцу кошки успешно продолжить лечение, начатое ветеринарным врачом, после выписки кошки из ветеринарной клиники:</b>`}
                />
                <ul className="list">
                    <li className="list_item">В условиях домашнего ухода найдите тихое, знакомое кошке личное пространство, например, небольшой вольер или отгороженную часть комнаты с хорошим освещением, где владелец может легко получить доступ к своей кошке. Небольшое пространство позволяет внимательно следить   за кошкой и обеспечивает ей чувство безопасности.</li>
                    <li className="list_item">Установите для кошки <b>режим приема</b> пероральных лекарств.</li>
                    <li className="list_item"><b>Раковина в ванной комнате</b>, выстланная мягким полотенцем или полотенцем с густым ворсом, может послужить в качестве закрытого, безопасного места для введения лекарств кошке и приема медицинских препаратов.</li>
                    <li className="list_item">Перед приемом лекарств <b>постарайтесь поднять настроение своей кошке</b> (например, дайте ей лакомства, расчешите ее или поласкайте).</li>
                    <li className="list_item">За исключением случаев, когда лекарства необходимо вводить вместе с пищей (например, фосфат-связывающие препараты), <b>не используйте пищу в качестве вспомогательного средства для приема лекарств</b>, особенно если это вызывает отвращение и способствует уменьшению потребления пищи кошкой.</li>
                    <li className="list_item">Принуждение кошки к приему лекарства является стрессом как для владельца, так и для самой кошки. <b>Не следует насильно вытаскивать кошку из укрытия или прерывать прием пищи</b>, процесс вычесывания шерсти или туалет с целью введения лекарств.</li>
                    <li className="list_item">Приветствуйте задаваемые вопросы <b>и поощряйте желание владельцев позвонить в ветеринарную клинику в случае возникновения каких-либо проблем с домашним уходом за животным</b>, включая прием лекарств. Предложите альтернативные способы приема лекарств (например, различные лекарственные препараты) для достижения цели лечения.</li>
                    <li className="list_item"><b>Объясните и покажите владельцу, как ведет себя здоровая кошка</b>, укажите признаки хорошего самочувствия, которые сигнализируют о полном выздоровлении. Кошки, которые чувствуют себя хорошо, как правило, спят чаще всего в свернутом положении. Они ухаживают за собой, следуют нормальному распорядку дня, взаимодействуют со своим владельцем, регулярно едят и ходят в туалет.</li>
                </ul>
            </div>
        )
    }
}
