import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson30/step12-1.png'


export default class step12 extends React.Component {

    render() {
        return (
            <div className="step12">
                <div className="flex">
                    <TextBlock
                        text={`Ингредиенты, используемые для производства промышленных кормов, тщательно отбираются и соответствуют необходимым параметрам, гарантируя безопасное потребление. 

                    В совокупности <b>они удовлетворяют энергетические потребности животных и обеспечивают их незаменимыми питательными веществами.</b>
                    
                    Правильная интерпретация информации на упаковках позволяет лучше понимать свойства корма и давать полезные рекомендации владельцам, помогая им подбирать наиболее подходящий корм.`}
                    />
                    <img className="flex_img" src={img1} alt=""/>
                </div>
            </div>
        )
    }
}
