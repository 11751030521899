import React from 'react'
import VideoBlock from "../../../Components/LessonAndTestComponents/VideoBlock";
import Lecturer from "../../../Components/LessonAndTestComponents/Lecturer";

import img1 from '../../../image/Lecturers/sereda.png'
import img2 from '../../../image/Lecturers/sereda-icon.png'

export default class step2 extends React.Component {

    render() {
        return (
            <div className="step2">
                <Lecturer
                    img={img1}
                    icon={img2}
                    surname={'Середа'}
                    name={'Илья Владимирович'}
                    text={'Кандидат ветеринарных наук, главный врач и заведующий хирургическим отделением ветеринарной клиники "СПУТНИК", г.Москва'}
                />

                <VideoBlock
                    src={'https://www.youtube.com/embed/jX8GXr6TKhE'}
                    title={'https://www.youtube.com/watch?v=jX8GXr6TKhE&feature=youtu.be'}
                />
            </div>
        )
    }
}
