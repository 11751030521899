import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import PlanInFirstStep from '../../../Components/LessonAndTestComponents/PlanInFirstStep';
import Lecturer from '../../../Components/LessonAndTestComponents/Lecturer';

import img1 from '../../../image/Lecturers/pozharskaya.png'
import img2 from '../../../image/Lecturers/pozharskaya-icon.png'

export default class step1 extends React.Component {

    planData = [
        `Видеолекция: Кожные проявления системных заболеваний у кошек`,
        `Тест`,
    ]

    render() {
        return (
            <div className="step1">
                <Lecturer
                    img={img1}
                    icon={img2}
                    surname={'Пожарская'}
                    name={'Дарья Сергеевна'}
                    text={'Ветеринарный врач дерматолог-эндокринолог, научный руководитель отделения дерматологии сети в/к «Свой Доктор», ИВЦ МВА, г. Москва.'}
                />

                <hr className="string"/>

                <div className="head">План урока</div>

                <TextBlock
                    text={`В этом уроке вы узнаете:`}
                />

                <PlanInFirstStep
                    planData={this.planData}
                    type={'violet'}
                />

            </div>
        )
    }
}
