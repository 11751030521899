import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import OnClickTest from '../../../Components/LessonAndTestComponents/OnClickTest';
import PopupInStep from '../../../Components/LessonAndTestComponents/PopupInStep';

// import img1 from '../../../image/lessons/lesson6/step3-1.png'

export default class step19 extends React.Component {

    state = {
        answersData: [
            `Да`,
            `Нет`,
        ],
        popupActive: 0,
        popupShow: false,
        points: [],
    }

    showPopup = popupActive => {
        const {points} = this.state;
        if (points.indexOf(popupActive) === -1) {
            points.push(popupActive)
        }

        if (points.length === 4) {
            this.setState({popupActive: 0, popupShow: false})
        }

        this.setState({
            popupShow: true,
            popupActive: popupActive,
            points: points
        })

        if (popupActive > 3) {
            this.setState({popupActive: 0, popupShow: false})
        }

        /*if (points.length === 3) {
            this.props.showNextBtn();
        }*/
    }

    closePopup = () => {
        this.setState({popupShow: false})
    }

    showNextBtn = () => {
        this.props.showNextBtn()
    }

    popupWrp = {
        1: `Соответствие продукта его задачам означает, что он способен удовлетворить потребности животного в питании, а владельца — в удобстве и цене.`,
        2: `Соответствие продукта его спецификациям означает, что состав продукта полностью соответствует информации, указанной на упаковке.`,
        3: `Отсутствие видимых и скрытых дефектов означает, что упаковка не вскрыта и герметична, хорошо пропечатана и читаема.`,
    }

    render() {
        const {popupShow, popupActive} = this.state
        return (
            <div className="step19">
                <TextBlock
                    text={`Теперь вы знаете, по каким критериям отличаются корма разных классов.

                    Давайте разберемся, зависит ли качество корма от класса?`}
                />
                <InfoBlock
                    text={`Какие утверждения являются верными? Выберите и нажмите «Подтвердить».`}
                />
                <OnClickTest
                    ansewrsData={this.state.answersData}
                    type={'line'}
                    rightAnswers={[2]}
                    showNextBtn={this.showNextBtn}
                    correctText={`Если продукт соответствует всем трем критериям, то он является качественным.
                                Таким образом, связь между классом корма и его качеством отсутствует!
                                Корм любой ценовой категории может являться качественным.`}
                >
                    <div className={'info_children'}>
                        <div className="children">
                            <h3 className="children_head">
                                Качество корма
                            </h3>
                            <div className="children_el">
                                <p className="children_el_text">Соответствие продукта его задачам</p>
                                <div
                                    className="point"
                                    onClick={() => {this.showPopup(1)}}
                                >
                                    ?
                                </div>
                            </div>
                            <div className="children_el">
                                <p className="children_el_text">Соответствие продукта его спецификациям</p>
                                <div
                                    className="point"
                                    onClick={() => {this.showPopup(2)}}
                                >
                                    ?
                                </div>
                            </div>
                            <div className="children_el">
                                <p className="flex_el_text">Отсутствие видимых и скрытых дефектов</p>
                                <div
                                    className="point"
                                    onClick={() => {this.showPopup(3)}}
                                >
                                    ?
                                </div>
                            </div>
                        </div>
                    </div>
                </OnClickTest>
                {popupShow &&
                <PopupInStep
                    closePopup={this.closePopup}
                    changePoint={() => this.showPopup(popupActive + 1)}
                >
                    <div className="PopupInStep_text">
                        {this.popupWrp[popupActive]}
                    </div>
                </PopupInStep>
                }
            </div>
        )
    }
}
