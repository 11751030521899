import React from 'react'
import OnClickCircleBlock from "../../../Components/LessonAndTestComponents/OnClickCircleBlock";
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";

import img1 from "../../../image/lessons/lesson8/step7-1.png";
import img2 from "../../../image/lessons/lesson8/step7-2.png";
import img3 from "../../../image/lessons/lesson8/step7-3.png";

export default class step7 extends React.Component {

    state = {
        flexData: [img1, img2, img3],
    }

    showNextBtn = () => {
        this.props.showNextBtn()
    }

    render() {
        const {flexData} = this.state
        return (
            <div className="step7">
                <div className="head">
                    Концепции кошачьего поведения
                </div>
                <OnClickCircleBlock
                    flexData={flexData}
                    showNextBtn={this.showNextBtn}
                    type={'img'}
                >
                    <div className="info_wrap">
                        <TextBlock
                            text={`Кошки не обладают врожденной толерантностью по отношению к незнакомым кошкам при первой встрече.
                            
                            Так как кошки являются хищниками и прирожденными охотниками, у кошек часто возникает чувство страха или незащищенности в незнакомой обстановке или при контакте с незнакомыми людьми.
                            
                            Такое поведение может быть скорее скрытым, чем явным.`}
                        />
                    </div>
                    <div className="info_wrap">
                        <TextBlock
                            text={`Кошки оставляют тактильную связь с другими кошками для членов своей кошачьей социальной группы или колонии.
                            
                            Кошки демонстрируют принадлежность и поддерживают запах колонии путем «аллоруббинга» (трения друг о друга; часто такое поведение неверно интерпретируется, когда оно направлено на человека как просьба о пище или ласке) и «аллогруминга» (когда кошки лижут друг друга, как правило, по шерсти на голове и шее).`}
                        />
                    </div>
                    <div className="info_wrap">
                        <TextBlock
                            text={`Большинство кошек предпочитают голову и шею для физического прикосновения.
                            
                            Кошки могут испытывать расстройство и даже стать агрессивными, когда люди пытаются погладить их в других областях (например, слишком сильное поглаживание по шерсти и перекатывание кожи при прикосновении к спине).`}
                        />
                    </div>
                </OnClickCircleBlock>
            </div>
        )
    }
}
