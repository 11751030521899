import React from 'react'
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";

import img1 from '../../../image/lessons/lesson10/step17-1.png'

export default class step17 extends React.Component {

    render() {
        return (
            <div className="step17">
                <TextBlock
                    text={`Металлические клетки издают очень громкий и резкий звук при закрывании дверцы. Такие клетки необходимо закрывать плавно и тихо.`}
                />
                <img className="imgCenter" src={img1} alt=""/>
            </div>
        )
    }
}
