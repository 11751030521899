import React from 'react'
// import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import PopupInStep from '../../../Components/LessonAndTestComponents/PopupInStep';
import List from '../../../Components/LessonAndTestComponents/List';

import img1 from "../../../image/lessons/lesson10/step2-1.png";
import img2 from "../../../image/lessons/lesson10/step2-2.png";
import img2xs from "../../../image/lessons/lesson10/step2-2xs.png";

export default class step2 extends React.Component {

    state = {
        pointActive: 0,
        showPopup: false,
        points: [],
    };

    showPopup = (pointActive) => {
        const {points, showPopup} = this.state;
        if (points.indexOf(pointActive) === -1) {
            points.push(pointActive)
        }
        if (!showPopup) {
            this.setState({
                pointActive: pointActive,
                showPopup: true,
            })
        } else if (pointActive >= 5) {
            this.setState({showPopup: false, pointActive: 0})
        } else {
            this.setState({
                pointActive: pointActive +1,
                showPopup: true,
            })
        }
        if (points.length === 5) {
            this.props.showNextBtn();
        }
    }

    closePopup = () => {
        this.setState({showPopup: false})
    }

    popupData = {
        0: {
            head: '',
            listData: '',
            id: 0,
        },
        1: {
            head: 'Приемная',
            listData: [
                `Оптимальный выбор кошачьей переноски и акклиматизация уменьшат стресс.`,
                `Обеспечьте наличие поверхности, расположенной на возвышенности (стол, стул, полка, подоконник), для кошачьей переноски .`,
                `Рассмотрите возможность использования перегородок или растений для создания отдельных зон для кошек и собак в приемной.`,
                `Рассмотрите возможность незамедлительного помещения кошек в комнату для осмотра при наличии такой возможности.`,
                `Используйте успокаивающие синтетические феромоны, распыленные в окружающей среде.`,
            ],
            img: img2,
            id: 1,
        },
        2: {
            head: 'Процедурная комната<br>(комната для осмотра)',
            listData: [
                `Поддерживайте воздух в комнате и медицинскую кушетку в тепле, используйте нескользящую поверхность для комфортного осмотра кошки.`,
                `Отвлеките и вознаградите кошку лакомствами или игрой.`,
                `Предоставьте рекомендации владельцам для надлежащего взаимодействия с кошкой.`,
                `Установите характер кошки и скорректируйте свое поведение в соответствии с ее потребностями.`,
                `Убедитесь, что все необходимые принадлежности и оборудование имеются в наличии в процедурной комнате, избегайте ненужного движения в комнату и из нее.`,
                `Используйте успокаивающие синтетические феромоны, распыленные в окружающей среде.`,
            ],
            id: 2,
        },
        3: {
            head: 'Диагностика и лечение',
            listData: [
                `Выберите самое тихое, наименее отвлекающее место для процедур – это может быть комната для осмотра или зона терапии.`,
                `Избегайте контакта с другими животными в обычных больничных помещениях, таких как отделение радиологии или процедурный кабинет.`,
            ],
            id: 3,
        },
        4: {
            head: 'Отделение хирургии',
            listData: [
                `Сделайте все возможное, чтобы минимизировать предоперационный стресс; например, изолируйте кошку до окончания процедуры ввода наркоза, покажите кошке какой-либо знакомый предмет, создайте успокаивающую среду, минимизируйте уровень шума в предоперационной зоне (исключите разговоры, звуки пылесоса, радио и т. д.).`,
                `Предоперационное управление температурой помещения и тела, а также контроль боли и гидратации являются обязательными.`,
                `Также необходимо тщательное наблюдение за проходящей лечение кошкой (большинство смертей, связанных с введением анестезии, происходят во время восстановления после наркоза).`,
            ],
            id: 4,
        },
        5: {
            head: 'Госпитализация',
            listData: [
                `Клетка должна быть достаточно большой по размеру, чтобы в ней поместилось место для укрытия, а также должна быть устроена таким образом, чтобы кошачий лоток располагался вдали от еды, подстилки и воды.`,
                `При возможности обеспечить наличие знакомой подстилки, игрушек, еды и кошачьего лотка из дома.`,
                `Использование успокаивающих синтетических феромонов в клетке будет способствовать более частому уходу за шерстью и лучшему потреблению пищи.`,
                `Идеально разделить кошачьи и собачьи отделения в ветеринарной клинике. При наличии соответствующей возможности, другие кошки и собаки должны быть вне поля зрения.`,
                `Важно учитывать микроклимат каждой клетки, исходя из потребностей «кошачьего» пациента. Больные и пожилые кошки, а также кошки, проходящие лечение с использованием успокоительных средств, имеющих седативный эффект, и котята нуждаются в более высокой температуре окружающей среды.`,
                `Необходимо рассмотреть вопрос о снижении уровня освещенности, чтобы стимулировать отдых и оказать успокаивающее воздействие на встревоженных кошек.`,
                `Для восприимчивых кошек важно обеспечить позитивное взаимодействие (уход за шерстью, игра или тисканье), которое не связано с медицинским лечением.`,
            ],
            id: 5,
        },
    }

    render() {
        const {showPopup, pointActive} = this.state
        return (
            <div className="step2">
                <div className="head">
                    Общие советы:
                </div>
                <ul className="list">
                    <li className="list_item">
                        Сведите к минимуму уровень шума
                    </li>
                    <li className="list_item">
                        Изолируйте кошек от собак
                    </li>
                    <li className="list_item">
                        Сведите к минимуму любые движения
                    </li>
                    <li className="list_item">
                        Расположите кошачью переноску выше уровня пола
                    </li>
                    <li className="list_item">
                        Позвольте кошке спрятаться, когда представляется такая возможность
                    </li>
                </ul>
                <InfoBlock
                    text={`Нажмите на активные элементы, чтобы узнать об источниках стресса для кошек в ветеринарных клиниках.`}
                />
                <div className="flex">
                    <div className="flex_el">
                        <ul className="flex_list">
                            <li className="flex_item">
                                <span className="flex_number">1</span>
                                Приемная
                            </li>
                            <li className="flex_item">
                                <span className="flex_number">2</span>
                                Процедурная комната
                                <br/>(комната для осмотра)
                            </li>
                            <li className="flex_item">
                                <span className="flex_number">3</span>
                                Диагностика и лечение
                            </li>
                            <li className="flex_item">
                                <span className="flex_number">4</span>
                                Отделение хирургии
                            </li>
                            <li className="flex_item">
                                <span className="flex_number">5</span>
                                Госпитализация
                            </li>
                        </ul>
                    </div>
                    <div className="flex_el">
                        <div
                            className={'flex_point'}
                            onClick={() => {this.showPopup(1)}}
                        >
                            1
                        </div>
                        <div
                            className={'flex_point'}
                            onClick={() => {this.showPopup(2)}}
                        >
                            2
                        </div>
                        <div
                            className={'flex_point'}
                            onClick={() => {this.showPopup(3)}}
                        >
                            3
                        </div>
                        <div
                            className={'flex_point'}
                            onClick={() => {this.showPopup(4)}}
                        >
                            4
                        </div>
                        <div
                            className={'flex_point'}
                            onClick={() => {this.showPopup(5)}}
                        >
                            5
                        </div>
                        <img src={img1} alt="" className="flex_img"/>
                    </div>
                </div>
                {showPopup &&
                    <PopupInStep
                        closePopup={this.closePopup}
                        changePoint={() => {this.showPopup(pointActive)}}
                        head={this.popupData[pointActive].head}
                    >
                        <List
                            key={this.popupData[pointActive].id}
                            ListData={this.popupData[pointActive].listData}
                        />
                        {pointActive === 1 &&
                        <picture>
                            <source srcSet={img2xs} media='(max-width: 767px)'/>
                            <img className="imgCenter" src={this.popupData[pointActive].img} alt=""/>
                        </picture>
                        }
                    </PopupInStep>
                }
            </div>
        )
    }
}
