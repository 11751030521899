import React from 'react'

export default class step5 extends React.Component {

    render() {
        return (
            <div className="step5">
                <ul className="list">
                    <li className="list_item">
                        <div className="number">1.</div>
                        Следует избегать прямого контакта между собаками и кошками, а также визуального контакта между собаками и кошками в комнате ожидания.
                    </li>
                    <li className="list_item">
                        <div className="number">2.</div>
                        Зона ожидания должна быть разделена заградительными перегородками, предотвращающими визуальный контакт.
                    </li>
                    <li className="list_item">
                        <div className="number">3.</div>
                        В клинике должны иметься простые и понятные ориентиры для владельцев, где они могут разместиться с питомцем.
                    </li>
                    <li className="list_item">
                        <div className="number">4.</div>
                        Настоятельно рекомендуется, чтобы кошачья переноска была размещена выше уровня пола
                        в приемном покое клиники (специальные столики, тумбочки или дополнительное место рядом
                        с владельцем).
                    </li>
                    <li className="list_item">
                        <div className="number">5.</div>
                        В доступе должны быть чистые покрывала, полотенца и пр., которыми можно укрыть переноску с кошкой.
                    </li>
                </ul>
            </div>
        )
    }
}