import React from 'react'
import Resume from '../../../Components/LessonAndTestComponents/Resume'

import img1 from '../../../image/lessons/lesson11/step36-1.png'


export default class step36 extends React.Component{

    state = {
        resumeData: [
            `В России количество кошек, имеющих лишний вес, <b>больше в крупных городах</b>, чем в небольших населенных пунктах.`,
            `<b>Ожирение у кошек</b> ведет к серьезным проблемам со здоровьем.`,
            `<b>Контроль калорийности рациона и регулярная физическая активность</b> – важнейшие аспекты поддержания нормального веса питомца.`,
        ]
    }

    render() {
        return (
            <div className="step36">
                <Resume
                    title={'Выводы'}
                    resumeData={this.state.resumeData}
                    img={img1}
                />
            </div>
        )
    }
}
