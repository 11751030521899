import React from 'react'
import './style.sass'


export default class Cookies extends React.Component{
    render() {
        return (
            <div className="Cookies">
                <div className="Cookies_text">
                    Мы собираем cookies, продолжая использовать сайт, вы соглашаетесь на их использование
                    (<a href="/cookies.pdf" className="Cookies_link" target="_blank" rel="noopener noreferrer">здесь</a> вы можете найти подробную информацию о cookies). Также на сайте используются системы
                    отслеживания интернет-трафика, способствующие улучшению работы сайта и настройке рекламных кампаний.
                    При нажатии на кнопку «Принимаю», вы разрешаете сбор данных, соглашаетесь на их использование в
                    рекламных и маркетинговых целях.
                </div>
                <div className="Cookies_buttons">
                    <div className="Cookies_box">
                        <button
                            type="button"
                            className="btn btn_cookies-white"
                            onClick={this.props.cookiesYes}
                        >
                            Принять
                        </button>
                        <button
                            type="button"
                            className="btn btn_cookies-trans"
                            onClick={this.props.closeCookiesPopup}
                        >
                            Отказаться
                        </button>
                    </div>
                    <button
                        type="button"
                        className="btn-close"
                        onClick={this.props.closeCookiesPopup}
                    />
                </div>
            </div>
        )
    }
}
