import React from 'react'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock'
import Answer from './dnd/Answer';
import Questions from './dnd/Questions';

export default class step29 extends React.Component {

    state = {
        // Ресайз
        windowWidth: window.innerWidth,

        // Работа десктопной версии
        answers: ['1', '2', '3', '4', '5', '6'], //Массив правильных ответов
        correct: '',
        answerCorrect: [],
        answersSelectedDesktop: ['?', '?', '?', '?', '?', '?'], // Массив выбираемых значений

        //Работа мобильной версии
        answerActive: 0,
        questionActive: 0,
        answersMobile: [],
        questionsMobile: [],
        answersSelected: ['?', '?', '?', '?', '?', '?', '?', '?'],
        correctMobile: '',
        answerCorrectMobile: [],
        showPopup: false
    }

    //Отлов события ресайз
    handlerResize = () => {
        this.setState({windowWidth: window.innerWidth})
    }

    componentDidMount() {
        window.addEventListener('resize', this.handlerResize)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handlerResize);
    }

    //Работа десктопной версии
    setAnswer = (answer_el, question_el) => {
        const {answersSelectedDesktop} = this.state

        answersSelectedDesktop.splice(answer_el - 1, 1, question_el)
        this.setState({answersSelectedDesktop})
    };

    checkAnswer = () => {
        const {answerCorrect, answersSelectedDesktop, answers} = this.state
        // eslint-disable-next-line
        answersSelectedDesktop.map((answer, index) => {
            if (index < 2) {
                if (answer === answers[0] || answer === answers[1]) {
                    if (answer === '?') {
                        answerCorrect.push('clean')
                    } else {
                        answerCorrect.push('correct')
                    }
                } else {
                    answerCorrect.push('inCorrect')
                }
            } else if (index < 6) {
                if (answer === answers[2] || answer === answers[3] || answer === answers[4] || answer === answers[5]) {
                    answerCorrect.push('correct')
                } else {
                    answerCorrect.push('inCorrect')
                }
            }
        })
        this.setState({
            answerCorrect: answerCorrect,
        })
        if (/*answersSelectedDesktop.indexOf('?') === -1 &&*/ answerCorrect.indexOf('inCorrect') === -1) {
            this.setState({correct: '1'})
        } else {
            this.setState({correct: '0'})
        }
        this.props.showNextBtn()
    };

    _renderTestDesktop = () => {
        const {answerCorrect, correct, answersSelectedDesktop} = this.state
        return (
            <div className="test_desktop">
                <div className="flex">
                    <div className="answers">
                        <div className="answers_head">
                            Открытый конфликт
                        </div>
                        <div className="answers_item">
                            <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'1'} text={'?'} count={'?'} answerCorrect={answerCorrect[0]}/>
                        </div>
                        <div className="answers_item">
                            <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'2'} text={'1'} count={'?'} answerCorrect={answerCorrect[1]}/>
                        </div>
                        {/*<div className="answers_item">*/}
                        {/*    <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'3'} text={'2'} count={'?'} answerCorrect={answerCorrect[2]}/>*/}
                        {/*</div>*/}
                        {/*<div className="answers_item">*/}
                        {/*    <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'4'} text={'3'} count={'?'} answerCorrect={answerCorrect[3]}/>*/}
                        {/*</div>*/}
                    </div>
                    <div className="answers">
                        <div className="answers_head">
                            Скрытый конфликт
                        </div>
                        <div className="answers_item">
                            <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'3'} text={'?'} count={'?'} answerCorrect={answerCorrect[2]}/>
                        </div>
                        <div className="answers_item">
                            <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'4'} text={'?'} count={'?'} answerCorrect={answerCorrect[3]}/>
                        </div>
                        <div className="answers_item">
                            <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'5'} text={'?'} count={'?'} answerCorrect={answerCorrect[4]}/>
                        </div>
                        <div className="answers_item">
                            <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'6'} text={'?'} count={'?'} answerCorrect={answerCorrect[5]}/>
                        </div>
                    </div>
                </div>
                <div className="questions">
                    <div className={'question_el ' + (answersSelectedDesktop.indexOf('1') >= 0 && 'hidden')}>
                        <Questions
                            targetKey="1"
                            question_el={'1'}
                            label={'Кошки могут преследовать друг друга, шипеть и поворачиваться боком, выпрямляя лапы, распушая хвост и поднимая шерсть дыбом, чтобы визуально казаться больше.'}
                            text={'Кошки могут преследовать друг друга, шипеть и поворачиваться боком, выпрямляя лапы, распушая хвост и поднимая шерсть дыбом, чтобы визуально казаться больше.'}
                        />
                    </div>
                    <div className={'question_el ' + (answersSelectedDesktop.indexOf('2') >= 0 && 'hidden')}>
                        <Questions
                            targetKey="1"
                            question_el={'2'}
                            label={'Если ни один из участников конфликта не отступит, визуальные угрозы могут перерасти в царапанье, борьбу и укусы.'}
                            text={'Если ни один из участников конфликта не отступит, визуальные угрозы могут перерасти в царапанье, борьбу и укусы.'}
                        />
                    </div>
                    <div className={'question_el ' + (answersSelectedDesktop.indexOf('3') >= 0 && 'hidden')}>
                        <Questions
                            targetKey="1"
                            question_el={'3'}
                            label={'Кошка, создающая конфликт, отказывает другим кошкам в доступе к ресурсам, уверенно смотрит на других кошек и опускает голову и шею, одновременно поднимая заднюю часть туловища, когда она приближается к менее уверенным кошкам.'}
                            text={'Кошка, создающая конфликт, отказывает другим кошкам в доступе к ресурсам, уверенно смотрит на других кошек и опускает голову и шею, одновременно поднимая заднюю часть туловища, когда она приближается к менее уверенным кошкам.'}
                        />
                    </div>
                    <div className={'question_el ' + (answersSelectedDesktop.indexOf('4') >= 0 && 'hidden')}>
                        <Questions
                            targetKey="1"
                            question_el={'4'}
                            label={'Шерсть вдоль спины, на хвосте и основании хвоста такой кошки может стоять дыбом, хотя и не до такой степени, как у кошек, вовлеченных в открытый конфликт.'}
                            text={'Шерсть вдоль спины, на хвосте и основании хвоста такой кошки может стоять дыбом, хотя и не до такой степени, как у кошек, вовлеченных в открытый конфликт.'}
                        />
                    </div>
                    <div className={'question_el ' + (answersSelectedDesktop.indexOf('5') >= 0 && 'hidden')}>
                        <Questions
                            targetKey="1"
                            question_el={'5'}
                            label={'Кошка может издавать несильное рычание.'}
                            text={'Кошка может издавать несильное рычание.'}
                        />
                    </div>
                    <div className={'question_el ' + (answersSelectedDesktop.indexOf('6') >= 0 && 'hidden')}>
                        <Questions
                            targetKey="1"
                            question_el={'6'}
                            label={'Кошка, создающая конфликт, может просто приблизиться или посмотреть на другую кошку, чтобы та оставила ценный ресурс, например, еду или кошачий лоток.'}
                            text={'Кошка, создающая конфликт, может просто приблизиться или посмотреть на другую кошку, чтобы та оставила ценный ресурс, например, еду или кошачий лоток.'}
                        />
                    </div>
                </div>
                {correct !== '' ?
                    <div className={'info ' + (correct === '0' && 'error')}>
                        <div className="info_head">
                            {correct === '1' ? 'Все верно!' : 'К сожалению, ваш ответ не верен.'}
                        </div>
                        <div className="infoText">
                            {correct === '1'
                                ?
                                'Вы правильно расставили признаки скрытого и открытого конфликтов в соответствующие столбцы.'
                                :
                                ''
                            }
                        </div>
                    </div>
                    :
                    <button className="btn btn_violet" onClick={() => this.checkAnswer()}>
                        ПРОВЕРИТЬ
                    </button>
                }
            </div>
        )
    }

    //Работа мобильной версии
    questions = [
        `Кошки могут преследовать друг друга, шипеть и поворачиваться боком, выпрямляя лапы, распушая хвост и поднимая шерсть дыбом, чтобы визуально казаться больше.`,
        `Если ни один из участников конфликта не отступит, визуальные угрозы могут перерасти в царапанье, борьбу и укусы.`,
        `Кошка, создающая конфликт, отказывает другим кошкам в доступе к ресурсам, уверенно смотрит на других кошек и опускает голову и шею, одновременно поднимая заднюю часть туловища, когда она приближается к менее уверенным кошкам.`,
        `Шерсть вдоль спины, на хвосте и основании хвоста такой кошки может стоять дыбом, хотя и не до такой степени, как у кошек, вовлеченных в открытый конфликт.`,
        `Кошка может издавать несильное рычание.`,
        `Кошка, создающая конфликт, может просто приблизиться или посмотреть на другую кошку, чтобы та оставила ценный ресурс, например, еду или кошачий лоток.`
    ]

    selectAnswer = (answerActive) => {
        document.body.scrollIntoView( true);
        document.body.style.overflow = "hidden";
        this.setState({
            showPopup: true,
            answerActive: answerActive
        })
    }

    selectQuestion = (questionActive) => {
        const {answerActive, answersMobile, questionsMobile, answersSelected} = this.state

        if (answersMobile.indexOf(answerActive) === -1) {
            answersMobile.push(answerActive)
        }
        this.setState({answersMobile})

        if (questionsMobile.indexOf(questionsMobile) === -1) {
            questionsMobile.push(questionActive)
        }
        this.setState({questionsMobile})

        answersSelected.splice(answerActive - 1, 1, this.questions[questionActive - 1])

        document.body.style.overflow = "";
        this.setState({
            showPopup: false,
            questionActive: questionActive,
            answersSelected: answersSelected
        })
    }

    checkAnswerMobile = () => {
        const {answerCorrectMobile, answersSelected} = this.state
        // eslint-disable-next-line
        answersSelected.map((answer, index) => {
            if (index < 2) {
                if (answer === this.questions[0] || answer === this.questions[1]) {
                    if (answer === '?') {
                        answerCorrectMobile.push('clean')
                    } else {
                        answerCorrectMobile.push('correct')
                    }
                } else {
                    answerCorrectMobile.push('inCorrect')
                }
            } else if (index < 6) {
                if (answer === this.questions[2] || answer === this.questions[3]|| answer === this.questions[4] || answer === this.questions[5]) {
                    answerCorrectMobile.push('correct')
                } else {
                    answerCorrectMobile.push('inCorrect')
                }
            }
        })
        this.setState({
            answerCorrect: answerCorrectMobile,
        })
        if (answerCorrectMobile.indexOf('inCorrect') === -1) {
            this.setState({correctMobile: '1'})
        } else {
            this.setState({correctMobile: '0'})
        }
        this.props.showNextBtn()
    }

    closePopup = () => {
        document.body.style.overflow = "";
        this.setState({showPopup: false})
    }

    _renderTestMobile = () => {
        const {showPopup, answersMobile, questionsMobile, answersSelected, correctMobile, answerCorrectMobile} = this.state
        return (
            <div className="test_mobile">
                {correctMobile !== '' ?
                    <div className={'info ' + (correctMobile === '0' && 'error')}>
                        <div className="info_head">
                            {correctMobile === '1' ? 'Все верно!' : 'К сожалению, ваш ответ не верен.'}
                        </div>
                        <div className="infoText">
                            {correctMobile === '1'
                                ?
                                `Вы правильно расположили основные элементы лицевой стороны упаковки. Далее ознакомьтесь с этикеткой на задней стороне.`
                                :
                                'Попробуйте еще раз расположить основные элементы лицевой стороны упаковки.'
                            }
                        </div>
                    </div>
                    :
                    <button className="btn btn_violet" onClick={() => this.checkAnswerMobile()}>
                        ПРОВЕРИТЬ
                    </button>
                }
                <div className="answers">
                    <div className="answers_box">
                        <div className="answers_head">
                            Открытый конфликт
                        </div>
                        {this.questions.slice(0, 2).map((item, index) => {
                            return (
                                <div
                                    key={index}
                                    className={'answers_item ' + (answersMobile.indexOf(index + 1) >=0 ? 'selected ' : '') + answerCorrectMobile[index]}
                                    onClick={() => {this.selectAnswer(index + 1)}}
                                >
                                    {answersSelected[index]}
                                </div>
                            )
                        })

                        }
                    </div>
                    <div className="answers_box">
                        <div className="answers_head">
                            Скрытый конфликт
                        </div>
                        {this.questions.slice(2).map((item, index) => {
                            return (
                                <div
                                    key={index}
                                    className={'answers_item ' + (answersMobile.indexOf(index + 3) >=0 ? 'selected ' : '') + answerCorrectMobile[index + 2]}
                                    onClick={() => {this.selectAnswer(index + 3)}}
                                >
                                    {answersSelected[index + 2]}
                                </div>
                            )
                        })

                        }
                    </div>
                </div>
                {showPopup &&
                <div className="popup">
                    <div className="popup_wrp">
                        <div
                            className="popup_closed"
                            onClick={() => {this.closePopup()}}
                        />
                        <div className="popup_flex">
                            <div className="questions">
                                {/*eslint-disable-next-line*/}
                                {this.questions.map((item, index) => {
                                    if (item !== '?') {
                                        return (
                                            <div
                                                key={index}
                                                className={'questions_item ' + (questionsMobile.indexOf(index + 1) >=0 ? 'selected ' : '')}
                                                onClick={() => {this.selectQuestion(index + 1)}}
                                            >
                                                {item}
                                            </div>
                                        )
                                    }
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                }
            </div>
        )
    }

    render() {
        const {windowWidth} = this.state
        return (
            <div className="step29">
                <TextBlock
                    text={`Признаки конфликта между кошками могут быть открытыми или скрытными.`}
                />
                <InfoBlock
                    text={`Расставьте признаки скрытого и открытого конфликтов в соответствующие столбцы.`}
                />
                {windowWidth >= 768 ?
                    <div className="test">
                        {this._renderTestDesktop()}
                    </div>
                    :
                    <div className="test">
                        {this._renderTestMobile()}
                    </div>
                }
            </div>
        )
    }
}
