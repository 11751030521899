import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import DefinitionBlock from '../../../Components/LessonAndTestComponents/DefinitionBlock';

import img1 from '../../../image/lessons/lesson11/step8-1.png'

export default class step8 extends React.Component {

    render() {
        return (
            <div className="step8">
                <DefinitionBlock
                    text={`<span class="text_violet">Лактация</span> &mdash; самый энергозатратный период жизни кошки. Она должна достаточно есть, чтобы обеспечить энергией не только себя, но и своих котят.`}
                />
                <div className="head">
                    Энергетические потребности кошки во время лактации зависят от возраста и количества котят.
                </div>
                <div className="flex">
                    <TextBlock
                        text={` Первые четыре недели жизни котята полностью зависят от материнского молока, поэтому энергетические потребности кормящей кошки определяются в зависимости от размера помета и возраста котят.
                        
                        В пиковый период лактации потребности кошки могут в четыре раза превысить стандартную норму взрослой кошки.`}
                    />
                    <img src={img1} className="flex_img" alt=""/>
                </div>
            </div>
        )
    }
}
