import React from 'react'
import VideoBlock from "../../../Components/LessonAndTestComponents/VideoBlock";
import Lecturer from "../../../Components/LessonAndTestComponents/Lecturer";
import img1 from "../../../image/Lecturers/antanasova.png";
import img2 from "../../../image/Lecturers/antanasova-icon.png";

export default class step2 extends React.Component {

    render() {
        return (
            <div className="step2">
                <Lecturer
                    img={img1}
                    icon={img2}
                    surname={'Атанасова'}
                    name={'Снежана Георгиевна'}
                    text={'Президент Национальной ассоциации медицины кошек, ветеринарный врач, анестезиолог-реаниматолог, научный руководитель ветеринарной клиники «Клиника Кошек», г. Москва.'}
                />

                <hr className="string"/>
                <VideoBlock
                    src={'https://www.youtube.com/embed/rIsihCdmcgc'}
                    title={'https://www.youtube.com/watch?v=rIsihCdmcgc&feature=youtu.be'}
                />
                <p>*По состоянию на 2022 год ветеринарный аналог Тразодона зарегистрирован и доступен к покупке владельцами без рецепта.</p>
            </div>
        )
    }
}
