import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';

import img1 from '../../../image/lessons/lesson2/step7-1.png'

export default class step7 extends React.Component {

    state = {
        pointActive: 0,
        showPopup: false,
        points: [],
    };



    showPopup = (pointActive) => {
        const {points} = this.state;
        if (points.indexOf(pointActive) === -1) {
            points.push(pointActive)
        }

        this.setState({points: points, pointActive: pointActive === this.state.pointActive ? 0 : pointActive})

        if (points.length === 4) {
            this.props.showNextBtn();
        }
    }

    closePopup = () => {
        this.setState({showPopup: false})
    }

    popupData = {
        0: {
            text: '',
        },
        1: {
            text: `<b>Грибовидные сосочки</b> – разбросаны по всей поверхности языка среди нитевидных, несут рецепторы солёного вкуса.`,
        },
        2: {
            text: `<b>Листовидные сосочки</b> располагаются в параллельных складках у корня языка, данный тип сосочков несёт рецепторы кислого вкуса.`,
        },
        3: {
            text: `<b>Валиковидные сосочки</b> погружены в поверхность языка и несколько отделены углублением от остальных, содержат рецепторы кислого и горького вкуса.`,
        },
        4: {
            text: `<b>Нитевидные сосочки</b> – выполняют механическую функцию. Кошки используют свой язык, чтобы удалить перья или шерсть с добычи, отделить мясо от костей. Кроме того, он выполняет роль щётки.`,
        },
    }

    render() {
        const {pointActive, points} = this.state
        return (
            <div className="step7">
                <TextBlock
                    text={`<b>Вкус</b> — одно из самых сложных чувств. Вкусовые ощущения принято разделять на четыре основных: сладкое, солёное, кислое, горькое, в последнее время выделяют также вкус глютамата натрия (umami) и вкус воды.
                    
                    Вкусовые рецепторы разных видов группируются во вкусовые почки, локализованные в эпителии вкусовых сосочков на поверхности языка.`}
                />
                <InfoBlock
                    text={`Нажмите на ключевые элементы (знаки вопроса), чтобы больше узнать о том, как работает вкус кошки.`}
                />
                <div className="box">
                    <div className="box_img">
                        <div
                            className={'box_point ' + (points.indexOf(1) >= 0 && 'active ') + (pointActive === 1 && ' index')}
                            onClick={() => {this.showPopup(1)}}
                        >?</div>
                        <div
                            className={'box_point ' + (points.indexOf(2) >= 0 && 'active ') + (pointActive === 2 && ' index')}
                            onClick={() => {this.showPopup(2)}}
                        >?</div>
                        <div
                            className={'box_point ' + (points.indexOf(3) >= 0 && 'active ') + (pointActive === 3 && ' index')}
                            onClick={() => {this.showPopup(3)}}
                        >?</div>
                        <div
                            className={'box_point ' + (points.indexOf(4) >= 0 && 'active ') + (pointActive === 4 && ' index')}
                            onClick={() => {this.showPopup(4)}}
                        >?</div>
                        <div className={'block red ' + (pointActive === 1 && 'active')}>
                            <TextBlock
                                text={this.popupData[1].text}
                            />
                        </div>
                        <div className={'block green ' + (pointActive === 2 && 'active')}>
                            <TextBlock
                                text={this.popupData[2].text}
                            />
                        </div>
                        <div className={'block ' + (pointActive === 3 && 'active')}>
                            <TextBlock
                                text={this.popupData[3].text}
                            />
                        </div>
                        <div className={'block ' + (pointActive === 4 && 'active')}>
                            <TextBlock
                                text={this.popupData[4].text}
                            />
                        </div>
                        <img src={img1} className="imgCenter" alt=""/>
                    </div>
                </div>
            </div>
        )
    }
}
