import React from 'react'
// import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from "../../../image/lessons/lesson13/step12-1.png";

export default class step12 extends React.Component {

    render() {
        return (
            <div className="step12">
                <div className="text-top">
                    <p>
                        Появление в доме ребенка – это беспокойное и захватывающее время для всех членов семьи.
                        Прежде чем ребенок родится, можно помочь облегчить период адаптации для  кошки – например, дать ей привыкнуть к новым для нее звукам и запахам, связанным с младенцами. Владельцу можно начать использовать небольшое количество детского лосьона, детского масла и детской присыпки на своей собственной коже, чтобы кошка привыкла к запаху и ассоциировала его с владельцем. Воспроизведение магнитофонной записи крика младенца поможет кошке привыкнуть к звуку, который вскоре станет привычным в доме. Вот некоторые советы, как подготовить кошку к появлению в доме ребенка:
                    </p>
                    <ul>
                        <li>
                            Пригласить друга с ребенком в гости, чтобы кошка привыкла к виду, запаху и звукам ребенка.
                        </li>
                        <li>
                            При подготовке детской комнаты выделить достаточно времени, чтобы кошка могла привыкнуть к новой комнате и предметам в ней.
                        </li>
                        <li>
                            Создать для кошки необходимое убежище, пока делается ремонт в детской комнате.
                        </li>
                        <li>
                            Когда в  доме появится ребенок, постараться, чтобы распорядок дня кошки не изменился и был в максимальной степени обычным. Увеличить количество игр, ласки и ухода, которые кошка получает от членов семьи, тем самым  уменьшить ее тревогу. Кошка будет с любопытством относиться к ребенку. Пусть она увидит и понюхает ребенка под присмотром. Кошка может изъявить желание быть рядом. Пусть кошка посидит рядом, или можно попросить кого-нибудь предложить ей угощение или поиграть с ней, пока владелец занят ребенком.
                        </li>
                        <li>
                            Постараться найти время на ежедневные игры с кошкой.
                        </li>

                    </ul>
                </div>

                <div className="infoImage">
                    <img src={img1} alt=""/>
                </div>

            </div>
        )
    }
}
