import React from 'react'
// import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from "../../../image/lessons/lesson13/step31-1.png";

export default class step31 extends React.Component {

    render() {
        return (
            <div className="step13">
                <div className="text-top">
                    <ul>
                        <li>Если кошка нуждается в госпитализации, следует навещать ее так часто, как это позволяет распорядок ветеринарной клиники.
                        </li>
                        <li>Для кошки следует взять в клинику знакомые игрушки, знакомый плед, предмет одежды владельца, миски, еду, наполнитель и желательно лоток из дома
                        </li>

                    </ul>
                </div>

                <div className="infoImage">
                    <img src={img1} alt=""/>
                </div>

            </div>
        )
    }
}
