import React from 'react'
import Resume from '../../../Components/LessonAndTestComponents/Resume'
// import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson11/step26-1.png'


export default class step26 extends React.Component{

    state = {
        resumeData: [
            `Рассчитать <b>суточный объем</b> рациона, <b>количество дней</b>, на которые хватит одной упаковки, а также <b>стоимость одной порции</b> можно с помощью специальных формул.`,
            `Чтобы поддерживать здоровый вес питомца, нужно кормить его полноценным и сбалансированным кормом, удовлетворяющим его <b>потребностям в энергии и питательных веществах.</b>`,
            `Размер порции рассчитывается, исходя из <b>желаемого веса</b> питомца и <b>энергетической ценности</b> корма.`,
        ]
    }

    render() {
        return (
            <div className="step26">
                <Resume
                    title={'Выводы'}
                    resumeData={this.state.resumeData}
                    img={img1}
                />
            </div>
        )
    }
}
