import React from 'react'

export default class step4 extends React.Component {

    render() {
        return (
            <div className="step4">
                <ul className="list">
                    <li className="list_item">
                        Проинструктируйте персонал приемной о предоставлении владельцам кошек информации об оптимальной конструкции кошачьей переноски и транспортировке кошки.
                    </li>
                    <li className="list_item">
                        Обеспечьте наличие поверхности, расположенной на возвышенности (стол, стул, полка, подоконник), для кошачьей переноски, чтобы она не касалась пола.
                    </li>
                    <li className="list_item">
                        Изолируйте собак от кошек или сведите к минимуму время пребывания в приемной, чтобы избежать столкновений между «собачьими» и «кошачьими» пациентами.
                    </li>
                </ul>
            </div>
        )
    }
}
