import React from 'react'
import VideoBlock from "../../../Components/LessonAndTestComponents/VideoBlock";
import Lecturer from "../../../Components/LessonAndTestComponents/Lecturer";

import img1 from "../../../image/Lecturers/seregina.png";
import img2 from "../../../image/Lecturers/seregina-icon.png";

export default class step2 extends React.Component {

    render() {
        return (
            <div className="step2">
                <Lecturer
                    img={img1}
                    icon={img2}
                    surname={'Сережина'}
                    name={'Людмила Абрамовна'}
                    text={'Ветеринарный врач клиники экспериментальной терапии ФГБУ НМИЦ онкологии им. Н.Н.Блохина, ведущий терапевт, руководитель отделения терапии клиники "Биоконтроль", г. Москва.'}
                />

                <hr className="string"/>

                <VideoBlock
                    src={'https://www.youtube.com/embed/xjwKV-Jh4Gs'}
                    title={'https://www.youtube.com/watch?v=xjwKV-Jh4Gs&feature=youtu.be'}
                />
            </div>
        )
    }
}
