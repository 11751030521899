import React from 'react'
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";

import img1 from '../../../image/lessons/lesson10/step16-1.png'

export default class step16 extends React.Component {

    render() {
        return (
            <div className="step16">
                <TextBlock
                    text={`Чтобы кошка чувствовала себя в безопасности, клетки в стационаре можно полностью или частично завешивать плотной, но пропускающей воздух тканью. Это особенно важно для беспокойных и агрессивных кошек. Уверенные в себе и любопытные кошки будут чувствовать себя комфортно, имея возможность наблюдать за всем происходящим.`}
                />
                <img className="imgCenter" src={img1} alt=""/>
            </div>
        )
    }
}
