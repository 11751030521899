import React from 'react'
import {withRouter} from 'react-router-dom';
import Api from '../../../Service/Api'

import LessonPlan from "../../../Components/LessonAndTestComponents/LessonPlan";
import MainButton from "../../../Components/LessonAndTestComponents/MainButton";
import MainHeader from "../../../Components/LessonAndTestComponents/MainHeader";
import ModalAfterStep from "../../../Components/LessonAndTestComponents/ModalAfterStep";

import './style.sass'
import Step1 from './step1';
import Step2 from './step2';
import Step3 from './step3';
import Step4 from './step4';
import Step5 from './step5';
import Step6 from './step6';
import Step7 from './step7';
import Step8 from "./step8";
import Step9 from "./step9";
import Step10 from "./step10";
import Step11 from "./step11";

import img1 from '../../../image/lessons/lesson35/step7-1.png'

class Lesson35 extends React.Component {
    Api = new Api();

    state = {
        lessonStepsActive: 1, // Пункт меню справа
        stepActive: 1, // Текуший шаг урока
        MainHeader_header: 'Урок 7', // Первая линия заголовка шага
        MainHeader_subheader: 'Уход за больной кошкой. Часть 3. Практические советы по уходу за больными кошками для ветеринарной бригады и владельца. ', // Вторая линия заголовка шага
        MainHeader_class: 'bigHeader', // Большой или маленький Заголовок
        MainHeader_text: '', //Текст под заголовком
        typeMainBtn: 'take a lesson', // тип кнопок (take a lesson - Пройти урок, '' - стандартно медлу щагами)
        showNextBtn: true, // Показывать кнопку продолжить?
        showPrevBtn: true, // Показывать кнопку вернуться?
        showPopap: false, // Показ попапа на шаге
        modalImg: '', // Картинка на верху модалки
        modalImg2: '', // Картинка в теле модалки
        modalHead: '', // шапка в модалке
        modalText: '', // текст в модалке
        arrayStepsPlan: [], //массив шагов плана урока (lessonStepsActive)
    }

    componentDidMount() {
        const {arrayStepsPlan} = this.state
        const {lesson} = this.props;
        let stepActive = lesson.steps
        stepActive = stepActive === lesson.attributes.steps ? 1 : stepActive + 1
        this.stepActiveStateUpdate(stepActive)
        this.stepActiveStateUpdate(1)
        if (lesson.steps === lesson.attributes.steps) {
            // eslint-disable-next-line
            Object.values(this.settingStepsLesson).map((item) => {
                arrayStepsPlan.push(item.state.lessonStepsActive)
                this.setState({arrayStepsPlan: arrayStepsPlan})
            })
        }
    }

    // Переключалка шагов
    _renderStep = () => {
        const {stepActive} = this.state;
        const {lesson} = this.props;

        if (this.settingStepsLesson[stepActive] !== undefined) {
            return this.settingStepsLesson[stepActive].view;
        } else {
            lesson.plan = lesson.lessonPlan;
            lesson.MainHeader_header = 'Тест: Урок 7';
            lesson.MainHeader_subheader = 'Уход за больной кошкой. Часть 3. Практические советы по уходу за больными кошками для ветеринарных врачей и владельца. ';
            this.props.history.push('/test', lesson);

        }
    }

    // Действие по кнопке продолжить
    showNextStep = () => {
        const {lesson} = this.props;
        let {stepActive} = this.state;
        this.Api.setLessonStats(lesson.attributes.id, stepActive)
        stepActive++;
        this.setStateBtnSteps(stepActive)
    }
    // Действие по кнопке вернутся
    showPrevStep = () => {
        let {stepActive} = this.state;
        stepActive--;
        this.setStateBtnSteps(stepActive)
    }
    // установка стайтов для смены шага
    setStateBtnSteps = stepActive => {
        this.setState({
            stepActive,
            showPopap: false
        });
        this.stepActiveStateUpdate(stepActive)
    }
    // показать попап на шаге.
    showPopap = () => {
        this.setState({
            showPopap: true
        });
    }

    closePopap = () => {
        this.setState({
            showPopap: false,
        })
    }

    // Состояния заголовков, кнопок, оглавления на текшем шаге
    stepActiveStateUpdate = stepActive => {
        this.setState({stepActive}, () => {
            if (this.settingStepsLesson[stepActive] !== undefined) {
                return this.setState({
                    MainHeader_header: this.settingStepsLesson[stepActive].state['MainHeader_header'],
                    MainHeader_subheader: this.settingStepsLesson[stepActive].state['MainHeader_subheader'],
                    MainHeader_class: this.settingStepsLesson[stepActive].state['MainHeader_class'],
                    MainHeader_text: this.settingStepsLesson[stepActive].state['MainHeader_text'],
                    typeMainBtn: this.settingStepsLesson[stepActive].state['typeMainBtn'],
                    showNextBtn: this.settingStepsLesson[stepActive].state['showNextBtn'],
                    lessonStepsActive: this.settingStepsLesson[stepActive].state['lessonStepsActive'],
                    modalImg: this.settingStepsLesson[stepActive].state['modalImg'],
                    modalImg2: this.settingStepsLesson[stepActive].state['modalImg2'],
                    modalHead: this.settingStepsLesson[stepActive].state['modalHead'],
                    modalText: this.settingStepsLesson[stepActive].state['modalText'],
                });
            }
        })
    }

    // Вызвать чтобы показать кнопку продолжить
    showNextBtn = () => {
        this.setState({
            showNextBtn: true
        })
    };

    render() {
        const {
            lessonStepsActive,
            typeMainBtn,
            showNextBtn,
            MainHeader_header,
            MainHeader_subheader,
            MainHeader_class,
            MainHeader_text,
            modalImg,
            modalImg2,
            modalHead,
            modalText,
            showPopap
        } = this.state;
        return (

            <div className="lesson">
                <div className="content_lesson">
                    <div className="step lesson35">
                        {/* Основной заголовок шага */}
                        <MainHeader
                            header={MainHeader_header}
                            subheader={MainHeader_subheader}
                            headerClass={MainHeader_class}
                            text={MainHeader_text}
                        />
                        {/* Вывод Шага урока */}
                        {this._renderStep()}
                    </div>
                    {/* Вывод Кнопок урока */}
                    <MainButton
                        type={typeMainBtn}
                        showPopap={this.showPopap}
                        showNextStep={this.showNextStep}
                        showPrevStep={this.showPrevStep}
                        showNextBtn={showNextBtn}
                        customBtnText={'Давайте узнаем'}
                        customClass={'les35'}
                    />
                </div>
                {/* Модальное окно после шага */}
                {showPopap &&
                <ModalAfterStep
                    closePopap={this.closePopap}
                    modalImg={modalImg}
                    modalImg2={modalImg2}
                    modalHead={modalHead}
                    modalText={modalText}
                    className={'flex-column'}
                    showNextStep={this.showNextStep}
                />
                }
                {/* Вывод Оглавление урока */}
                <LessonPlan
                    lessonImg={this.props.lesson.attributes.image} // Картинка урока
                    lessonNumeral={parseInt(this.props.lesson.attributes.name.match(/\d+/), 10)} //Номер урока для планшета
                    lessonName={'Уход за больной кошкой. Часть 3.'} // Название урока
                    lessonSteps={this.props.lesson.lessonPlan} // Шаги урока
                    lessonStepsActive={lessonStepsActive - 1} // Активный шаг урока
                    onClick={this.setStateBtnSteps}
                    arrayStepsPlan={this.state.arrayStepsPlan}
                />
            </div>

        )
    }

    settingStepsLesson = {
        1: {
            view: <Step1/>,
            state: {
                typeMainBtn: 'take a lesson',
                MainHeader_header: 'Урок 7',
                MainHeader_subheader: `Уход за больной кошкой. Часть 3.
                Практические советы по уходу за больными кошками для ветеринарных врачей и владельца.`,
                MainHeader_class: 'bigHeader',
                lessonStepsActive: 1,
                MainHeader_text: ''
            }
        },
        2: {
            view: <Step2 showNextBtn={this.showNextBtn}/>,
            state: {
                MainHeader_header: 'Практические советы по уходу за больными кошками для ветеринарных врачей и владельцев',
                MainHeader_subheader: '',
                MainHeader_class: '',
                typeMainBtn: 'next step',
                showNextBtn: true,
                lessonStepsActive: 2
            }
        },
        3: {
            view: <Step3 showNextBtn={this.showNextBtn}/>,
            state: {
                MainHeader_header: 'Обязанности ветеринарных врачей при общении с владельцами',
                MainHeader_subheader: '',
                MainHeader_class: '',
                typeMainBtn: 'next step',
                showNextBtn: true,
                lessonStepsActive: 2
            }
        },
        4: {
            view: <Step4 showNextBtn={this.showNextBtn}/>,
            state: {
                MainHeader_header: 'Обязанности ветеринарных врачей при общении с владельцами',
                MainHeader_subheader: '',
                MainHeader_class: '',
                typeMainBtn: 'next step',
                showNextBtn: false,
                lessonStepsActive: 2,
            }
        },
        5: {
            view: <Step5 showNextBtn={this.showNextBtn}/>,
            state: {
                MainHeader_header: 'Роль владельца в уходе за больной кошкой в домашних условиях',
                MainHeader_subheader: '',
                MainHeader_class: '',
                typeMainBtn: 'next step',
                showNextBtn: true,
                lessonStepsActive: 3,
            }
        },
        6: {
            view: <Step6 showNextBtn={this.showNextBtn}/>,
            state: {
                MainHeader_header: 'Домашний уход за больными кошками',
                MainHeader_subheader: '',
                MainHeader_class: '',
                typeMainBtn: 'next step',
                showNextBtn: true,
                lessonStepsActive: 3,
            }
        },
        7: {
            view: <Step7 showNextBtn={this.showNextBtn}/>,
            state: {
                MainHeader_header: 'Избегайте вещей, которые раздражают кошек',
                MainHeader_subheader: '',
                MainHeader_class: '',
                typeMainBtn: 'show popap',
                showNextBtn: true,
                lessonStepsActive: 3,
                modalHead: `Важно`,
                modalImg2: img1,
                modalText: `Защитные воротники из пластика могут причинять кошке сильный дискомфорт и усиливать стресс. Можно попробовать использовать мягкий воротник, если в нем есть необходимость. Боксы/клетки для временного пребывания должны быть подходящего размера, даже если кошка будет находиться там всего пару часов.`
            }
        },
        8: {
            view: <Step8 showNextBtn={this.showNextBtn}/>,
            state: {
                MainHeader_header: 'Пошаговый план реализации рекомендаций',
                MainHeader_subheader: '',
                MainHeader_class: '',
                typeMainBtn: 'next step',
                showNextBtn: false,
                lessonStepsActive: 4,
            }
        },
        9: {
            view: <Step9 showNextBtn={this.showNextBtn}/>,
            state: {
                MainHeader_header: 'Использование плана действий и комплексных мероприятий',
                MainHeader_subheader: '',
                MainHeader_class: '',
                typeMainBtn: 'next step',
                showNextBtn: true,
                lessonStepsActive: 4,
            }
        },
        10: {
            view: <Step10 showNextBtn={this.showNextBtn}/>,
            state: {
                MainHeader_header: 'Рекомендации по составлению плана действий с учетом SMART-критериев',
                MainHeader_subheader: '',
                MainHeader_class: '',
                typeMainBtn: 'next step',
                showNextBtn: true,
                lessonStepsActive: 4,
            }
        },
        11: {
            view: <Step11 showNextBtn={this.showNextBtn}/>,
            state: {
                MainHeader_header: 'Выводы',
                MainHeader_subheader: '',
                MainHeader_class: '',
                typeMainBtn: 'next step',
                showNextBtn: true,
                lessonStepsActive: 5,
            }
        },
    }

}

export default withRouter(Lesson35);
