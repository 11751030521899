import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import OnClickCircleBlock from "../../../Components/LessonAndTestComponents/OnClickCircleBlock";

import img1 from "../../../image/lessons/lesson8/step6-1.png";
import img2 from "../../../image/lessons/lesson8/step6-2.png";
import img3 from "../../../image/lessons/lesson8/step6-3.png";

export default class step6 extends React.Component {

    state = {
        flexData: [img1, img2, img3],
    }

    showNextBtn = () => {
        this.props.showNextBtn()
    }

    render() {
        const {flexData} = this.state
        return (
            <div className="step6">
                <div className="head">
                    Концепции кошачьего поведения
                </div>
                <OnClickCircleBlock
                    flexData={flexData}
                    showNextBtn={this.showNextBtn}
                    type={'img'}
                >
                    <div className="info_wrap">
                        <TextBlock
                            text={`Исторически сложилось так, что люди держали кошек за их способность охотиться и убивать грызунов, а не за другие черты их характера, поэтому люди не сильно изменяли врожденное поведение кошки путем генетического отбора.
                            
                            <b>Кошки нуждаются в удовлетворении их охотничьего инстинкта</b>. Охота также является одним из компонентов игры у кошек. Возможно, их можно будет отвлечь в ветеринарной клинике с помощью контактной игрушки, например, игрушечной мышки на веревочке.`}
                        />
                    </div>
                    <div className="info_wrap">
                        <TextBlock
                            text={`Кошки являются одиночными охотниками и избегают драк и столкновений с другими кошками, когда это возможно. Избежать драки получается, дистанцируясь от других кошек. Кошки часто реагируют на прямую конфронтацию тем, что избегают оппонента или прячутся, причем драка происходит только в крайнем случае.
                            
                            Если вы позволите кошкам почувствовать себя в укрытии, пока они находятся в ветеринарной клинике, используя для этого такие предметы, как полотенца или кошачью переноску, такой подход может облегчить процесс лечения.`}
                        />
                    </div>
                    <div className="info_wrap">
                        <TextBlock
                            text={`Социализация и привыкание к человеческому обращению, <b>начиная с 2-7-недельного возраста</b>, улучшают взаимоотношения между кошками и людьми.
                            
                            Тем не менее, человеческое возбуждение, грубое обращение, внезапные или беспорядочные движения либо громкие голоса могут вызвать у кошки неожиданную, внезапную боязливую или вовсе агрессивную реакцию.`}
                        />
                    </div>
                </OnClickCircleBlock>
            </div>
        )
    }
}
