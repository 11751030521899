import React from 'react'
import './style.sass'
// import Slider from "react-slick";
// import SwipeBlock from '../SwipeBlock';
// import List from "../List";

export default class PopupInStep extends React.Component {

    componentDidMount() {
        document.body.scrollIntoView( true);
        document.body.style.overflow = "hidden";
    }

    componentWillUnmount() {
        document.body.style.overflow = "";
    }

    stopClosePopup = (e) => {
        e.stopPropagation()
    }

    render() {
        // Пример испльзования lesson 7, step 16.
        return (
            <div className="PopupInStep" onClick={() => {this.props.closePopup()}}>
                <div className="popup" onClick={(e) => {this.stopClosePopup(e)}}>
                    <div
                        className="popup_closed"
                        onClick={() => {this.props.closePopup()}}
                    />
                    {this.props.imgHead &&
                    <div className="popup_imgHead">
                        <picture>
                            <source media="(max-width: 767px)" srcSet={this.props.imgHeadMobile}/>
                            <img className="popup_imgHeadBg" src={this.props.imgHead} alt=""/>
                        </picture>
                    </div>
                    }
                    {this.props.head &&
                        <div className="popup_head"
                             dangerouslySetInnerHTML={{ __html: this.props.head}}
                        />
                    }
                    <div className="popup_content">
                        {this.props.children}
                        <button
                            className="btn btn_violet btn_center"
                            onClick={() => {this.props.changePoint()}}
                        >
                            {this.props.popupBtnText ? this.props.popupBtnText : 'продолжить'}
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}
