import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

export default class step11 extends React.Component {

    render() {
        return (
            <div className="step11">
                <TextBlock
                    text={`Основные рекомендации:`}
                />
                <ul className="list">
                    <li className="list_item">Одной кошке необходим минимум один лоток.</li>
                    <li className="list_item">Лотки нужно чистить ежедневно.</li>
                    <li className="list_item">Кошки ходят в туалет не только чтобы удовлетворить естественные потребности, но и пометить территорию. Домашние кошки считают квартиру своей территорией.</li>
                    <li className="list_item">Цель – обеспечить кошке положительный опыт при использовании кошачьего лотка.</li>
                </ul>
                <TextBlock
                    text={`Кошки очень чистоплотны. Большинство кошек избегают использования грязного кошачьего лотка в пользу более чистого места, например, ковра. Поэтому <b>необходимо ежедневно менять наполнитель в кошачьем лотке и еженедельно мыть его мягким моющим средством для посуды.</b>`}
                />
            </div>
        )
    }
}
