import React from 'react'
import VideoBlock from "../../../Components/LessonAndTestComponents/VideoBlock";
import Lecturer from "../../../Components/LessonAndTestComponents/Lecturer";

import img1 from '../../../image/Lecturers/vorontsov.png'
import img2 from '../../../image/Lecturers/vorontsov-icon.png'

export default class step2 extends React.Component {

    render() {
        return (
            <div className="step2">
                <Lecturer
                    img={img1}
                    icon={img2}
                    surname={'Воронцов'}
                    name={'Александр Алексеевич'}
                    text={'Ветеринарный врач-хирург, кандидат ветеринарных наук. Главный врач "Ветеринарного центра хирургии и онкологии доктора Воронцова", г. Москва'}
                />

                <VideoBlock
                    src={'https://www.youtube.com/embed/RULQJseJulY'}
                    title={'https://youtu.be/RULQJseJulY'}
                />
            </div>
        )
    }
}
