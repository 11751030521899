import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from "../../../image/lessons/lesson6/step18-1.png";
import img2 from "../../../image/lessons/lesson6/step18-2.png";


export default class step18 extends React.Component {

    render() {
        return (
            <div className="step18">
                <div className="flex">
                    <TextBlock
                        text={`<b>Кошки устанавливают свои домашние охотничьи угодья</b>, помечая их соответствующим запахом. Самцы физически защищают свою территорию от других самцов, а самки обычно делят или совмещают свой участок обитания с другими самками.
                        
                        <b>Размер участка обитания или территории может достигать до 6 км<sup>2</sup></b> (примерно 20 городских кварталов). Поскольку кошки в условиях дикой природы охотятся на мелкую добычу, они, как правило, являются охотниками-одиночками, поэтому каждая кошка получает достаточно пищи. Если пищевых ресурсов достаточно для питания, кошки могут жить небольшими <b>группами по 2-25 особей</b>, состоящими в основном из самок.`}
                    />
                    <img src={img1} alt="" className="flex_img"/>
                </div>
                <img src={img2} alt="" className="imgCenter"/>
            </div>
        )
    }
}
