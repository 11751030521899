import React from 'react'
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";


export default class step37 extends React.Component{

    render() {
        return (
            <div className="step37">
                <TextBlock
                    text={`Наиболее распространенной причиной возникновения конфликтов между домашними кошками является конкуренция за ресурсы, например, за территорию, пищу, воду, солнечные зоны или внимание со стороны людей.
                    
                    Конфликт может наступить, только когда сама кошка поймет, что она хочет контролировать окружающую территорию или поведение своих соседей по дому.
                    
                    Открытый конфликт чаще всего возникает, когда в доме появляется новая кошка, либо когда кошки, знавшие друг друга с детства, в возрасте от 2 до 5 лет достигают социальной зрелости. Восприятие кошачьих потребностей в ресурсах может расширяться с возрастом социальной зрелости.`}
                />
            </div>
        )
    }
}
