import React from 'react'

export default class step5 extends React.Component {

    render() {
        return (
            <div className="step5">
                <ul className="list">
                    <li className="list_item">
                        Обеспечьте наличие теплой комнаты без посторонних звуков с удобной, нескользящей поверхностью для осмотра.
                    </li>
                    <li className="list_item">
                        Обеспечьте постоянное наличие всех необходимых принадлежностей и оборудования, чтобы избежать ненужного движения по комнате осмотра, а также прерывания осмотра.
                    </li>
                    <li className="list_item">
                        Оцените характер и темперамент кошки во время осмотра и соответствующим образом скорректируйте свой подход.
                    </li>
                    <li className="list_item">
                        Предложите рекомендации о том, как владелец кошки может надлежащим образом взаимодействовать с пациентом во время обследования.
                    </li>
                </ul>
            </div>
        )
    }
}
