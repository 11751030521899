import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';

import img1 from '../../../image/lessons/lesson34/step7-1.png';
import img2 from '../../../image/lessons/lesson34/step8-1.png';

export default class step8 extends React.Component {
    state = {
        flexActive: 0,
        flexs: [1],
        flexHidden: false,
    };

    changeFlex = flexActive => {
        const {flexs} = this.state
        if (flexs.indexOf(flexActive) === -1) {
            flexs.push(flexActive)
        }
        this.setState({flexActive, flexs, flexHidden: true})
        if (flexs.length === 4) {
            this.props.showNextBtn()
        }
    }

    render() {
        const {flexActive, flexHidden} = this.state
        return (
            <div className="step8">
                <InfoBlock
                    text={`Нажмите на каждое из пяти слагаемых здоровья и узнайте, благодаря каким особенностям состава корма PERFECT FIT™ они достигаются.`}
                    hidden={flexHidden === true ? 'hidden' : ''}
                />
                <div className="box">
                    <div className="img-box">
                        <img src={img1} alt="" className="img_active"/>
                        <div className={'flex ' + (flexHidden === true && 'hidden')}>
                            <div className={'flex_el ' + (flexActive === 1 && 'active')} onClick={() => {
                                this.changeFlex(1)
                            }}/>
                            <div className={'flex_el ' + (flexActive === 2 && 'active')} onClick={() => {
                                this.changeFlex(2)
                            }}/>
                            <div className={'flex_el ' + (flexActive === 3 && 'active')} onClick={() => {
                                this.changeFlex(3)
                            }}/>
                            <div className={'flex_el ' + (flexActive === 4 && 'active')} onClick={() => {
                                this.changeFlex(4)
                            }}/>
                            <div className={'flex_el ' + (flexActive === 5 && 'active')} onClick={() => {
                                this.changeFlex(5)
                            }}/>
                        </div>
                    </div>
                    {flexActive === 0 &&
                    <img className="imgBg" src={img2} alt=""/>
                    }
                    {flexActive !== 0 &&
                    <div className={'imgDiv ' + (flexActive === 2 ? 'imgDiv--2' : flexActive === 3 ? 'imgDiv--3': flexActive === 4 ? 'imgDiv--4' : '')}>
                        {flexActive === 1 &&
                        <div className="imgDiv_wrap">
                            <div className="imgDiv_head pink">Поддержание иммунитета</div>
                            <TextBlock
                                text={`Содержит витамин Е и цинк для поддержания иммунной системы.`}
                            />
                        </div>
                        }
                        {flexActive === 2 &&
                        <div className="imgDiv_wrap">
                            <div className="imgDiv_head orange">Здоровье мочевыводящей системы</div>
                            <TextBlock
                                text={`Баланс минералов, помогающий поддержанию здоровья мочевыводящей системы.`}
                            />
                        </div>
                        }
                        {flexActive === 3 &&
                        <div className="imgDiv_wrap">
                            <div className="imgDiv_head green">Здоровое пищеварение</div>
                            <TextBlock
                                text={`Содержит высококачественные белки и рис для легкого усвоения и оптимального пищеварения.`}
                            />
                        </div>
                        }
                        {flexActive === 4 &&
                        <div className="imgDiv_wrap">
                            <div className="imgDiv_head yellow">Здоровье кожи и шерсти</div>
                            <TextBlock
                                text={`Способствует поддержанию здоровья кожи и шерсти, благодаря содержанию цинка и подсолнечного масла – натурального источника ОМЕГА-6 жирных кислот.`}
                            />
                        </div>
                        }
                        {flexActive === 5 &&
                        <div className="imgDiv_wrap">
                            <div className="imgDiv_head blue">Оптимальное поддержание веса</div>
                            <TextBlock
                                text={`Способствует сохранению оптимального веса и хорошей физической формы благодаря балансу всех необходимых питательных веществ при соблюдении рекомендаций по кормлению.`}
                            />
                        </div>
                        }
                    </div>
                    }
                </div>
            </div>
        )
    }
}
