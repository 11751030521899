import React from 'react'
import './style.sass'


export default class Specialty extends React.Component {

    state = {
        specialty: 1
    }

    changeSpecialty = specialty => {
        this.setState({
            specialty: specialty
        })
        this.props.chooseSpecialty(specialty)
    }

    render() {
        return (
            <div className="Specialty">
                {/*<div className="Specialty_text">*/}
                {/*    <span className="Specialty_star">*</span>Ваша специальность:*/}
                {/*</div>*/}
                <div className="Specialty_buttons">
                    <div
                        className={'btn btn_default ' + (this.state.specialty === 1 && 'active')}
                        onClick={() => {this.changeSpecialty(1)}}
                    >
                        Врач
                    </div>
                    <div
                        className={'btn btn_default ' + (this.state.specialty === 2 && 'active')}
                        onClick={() => {this.changeSpecialty(2)}}
                    >
                        Студент
                    </div>
                </div>
            </div>
        )
    }
}
