import React from 'react'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import Slider from "react-slick";
import img1 from '../../../image/lessons/lesson11/step7-1.png';
import img2 from '../../../image/lessons/lesson11/step7-2.png';
import img3 from '../../../image/lessons/lesson11/step7-3.png';

export default class step7 extends React.Component {

    changeSlide = (next) => {
        if(next === 2){
            this.props.showNextBtn();
        }
    }

    slider = {
        1: {
            head: `Частота кормления`,
            text: `Количество корма, употребляемого кошкой, должно постепенно увеличиваться уже с первого дня беременности.
            
            Перед родами и некоторое время после можно предоставить кошке свободный доступ к корму, так как в период лактации потребности в питательных веществах и энергии возрастают ещё больше, а значит, кошке требуется большее количество корма.`,
            img: img1,
        },
        2: {
            head: `Усвояемость корма`,
            text: `В первую очередь, пища должна быть хорошо усваиваемой. Это даст кошке необходимое количество энергии, и при этом желудок не будет сильно растягиваться от большого объема пищи.
            
            Если кошка вынашивает большой помет, для желудка остается очень мало места в организме, и кошка физически не может достаточно есть.`,
            img: img2
        },
        3: {
            head: `Энергетическая ценность корма`,
            text: `Чтобы обеспечить беременную кошку необходимым уровнем питательных веществ и энергии, и учесть её физиологические особенности на данном этапе, <b>рекомендуется использовать в кормлении беременных и лактирующих кошек корм для котят</b>.

            Например, сухой и влажный корм Whiskas<sup>®</sup> для котят с 1 до 12 месяцев.
            
            Корм для котят имеет <b>высокую энергетическую плотность</b>, вкусовую <b>привлекательность</b> и <b>хорошо усваивается</b>. Именно эти качества корма необходимы кошкам во время беременности.`,
            img: img3
        },
    }

    render() {

        let settings = {
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            adaptiveHeight: true,
            beforeChange: (current, next) => {
                this.changeSlide(next)
            },
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        adaptiveHeight: true,
                    }
                },
            ]
        };
        return (
            <div className="step7">
                <TextBlock
                    text={`Поскольку кошки привыкли есть небольшими порциями, но часто, а во время беременности их потребности возрастают, им нужен особый режим кормления.`}
                />
                <InfoBlock
                    text={`Изучите рекомендации по кормлению беременной кошки. Используйте стрелки для перемещения по шагам.`}
                />

                <div className={"slider slider_noArrow show"}>
                    <Slider {...settings}>
                        {Object.values(this.slider).map(item => {
                            return (
                                <div
                                    key={item.img}
                                    className="slider_el"

                                >
                                    <div className="imgMobile">
                                        <img src={item.img} alt=""/>
                                    </div>
                                    <div className="slider_flex">
                                        <div className="slider_flex_box">
                                            <div className="head">
                                                {item.head}
                                            </div>
                                            <TextBlock text={item.text}/>
                                        </div>
                                        <img src={item.img} alt=""/>
                                        {item.popup &&
                                        <span className="slider_popup" onClick={this.openPopup}>?</span>
                                        }
                                    </div>
                                    {item.text2 &&
                                    <TextBlock text={item.text2}/>
                                    }
                                </div>
                            )
                        })}
                    </Slider>
                </div>
            </div>
        )
    }
}
