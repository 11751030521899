import React from 'react'
import './style.sass'

export default class VideoSectionModal extends React.Component{
    render() {
        return (
            <div className="Overlay" onClick={this.props.onClick}>
            </div>
        )
    }
}