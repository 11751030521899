import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson6/step14-1.png'

export default class step14 extends React.Component {

    render() {
        return (
            <div className="step14">
                <div className="head">
                    Кошачий насест и его роль для кошки
                </div>
                <img src={img1} alt="" className="imgCenter"/>
                <TextBlock
                    text={`<b>Насесты бывают самых разных форм и размеров</b>. Вы можете приобрести специальный насест, который крепится к подоконнику, или вы можете сами построить такой насест для вашей кошки. Если вы расположите более одной перекладины на стене или подоконнике над окном, это позволит вашей кошке смотреть на улицу под разными углами.`}
                />
            </div>
        )
    }
}
