import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import PopupInStep from "../../../Components/LessonAndTestComponents/PopupInStep";
import OnClickBlock from '../../../Components/LessonAndTestComponents/OnClickBlock';
import List from "../../../Components/LessonAndTestComponents/List";
import Answer from "../Lesson33/dnd/Answer";
import Questions from "../Lesson33/dnd/Questions";

import img1 from "../../../image/lessons/lesson33/step12-1.png";
import img2 from "../../../image/lessons/lesson33/step12-2.png";
import img3 from "../../../image/lessons/lesson33/step12-3.png";
import img4 from "../../../image/lessons/lesson33/step12-4.png";
// import img5 from "../../../image/lessons/lesson33/step12-5.png";
import img6 from "../../../image/lessons/lesson33/step12-6.png";
import img7 from "../../../image/lessons/lesson33/step12-7.png";
import img8 from "../../../image/lessons/lesson33/step12-8.png";
import img9 from "../../../image/lessons/lesson33/step12-9.png";
import img10 from "../../../image/lessons/lesson33/step12-10.png";
import img11 from "../../../image/lessons/lesson33/step12-11.png";
import img12 from "../../../image/lessons/lesson33/step12-12.png";
import img13 from "../../../image/lessons/lesson33/step12-13.png";
import img14 from "../../../image/lessons/lesson33/step12-14.png";
import img15 from "../../../image/lessons/lesson33/step12-15.png";
import img16 from "../../../image/lessons/lesson33/step12-16.png";
import img17 from "../../../image/lessons/lesson33/step12-17.png";
import img18 from "../../../image/lessons/lesson33/step12-18.png";
import img19 from "../../../image/lessons/lesson33/step12-19.png";
import img20 from "../../../image/lessons/lesson33/step12-20.png";
import img21 from "../../../image/lessons/lesson33/step12-21.png";
import img22 from "../../../image/lessons/lesson33/step12-22.png";
import img23 from "../../../image/lessons/lesson33/step12-23.png";
import img24 from "../../../image/lessons/lesson33/step12-24.png";
import img25 from "../../../image/lessons/lesson33/step12-25.png";
import img26 from "../../../image/lessons/lesson33/step12-26.png";
import img27 from "../../../image/lessons/lesson33/step12-27.png";
import img28 from "../../../image/lessons/lesson33/step12-28.png";
import img29 from "../../../image/lessons/lesson33/step12-29.png";
import img30 from "../../../image/lessons/lesson33/step12-30.png";


export default class step12 extends React.Component {

    state = {
        // Ресайз
        windowWidth: window.innerWidth,

        //OnClickBlock
        flexData: [
            'Обеспечение оптимального баланса питательных веществ',
            'Поддержка здоровья',
            'Поддержка разнообразия вкусов и текстур'
        ],
        flexHidden: false,

        // Popup
        popupActive: 0,
        showPopup: false,
        points: [],

        // Работа десктопной версии теста
        answers: [],
        questions: [],
        correct: '',
        answerCorrect: [],

        // Работа мобильной версии теста
        answerActive: 0,
        questionActive: 0,
        answersMobile: [],
        questionsMobile: [],
        answersSelected: ['?', '?', '?', '?', '?', '?', '?'],
        correctMobile: '',
        answerCorrectMobile: [],
        showQuestion: false,
    }

    //Отлов события ресайз
    handlerResize = () => {
        this.setState({windowWidth: window.innerWidth})
    }

    componentDidMount() {
        window.addEventListener('resize', this.handlerResize)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handlerResize);
    }

    //OnClickBlock
    hideElement = () => {
        this.setState({flexHidden: !this.state.flexHidden})
    }

    showNextBtn = () => {
        const {questions, points} = this.state
        if (questions.length === 7 && points.length === 12) {
            this.props.showNextBtn()
        }
    }

    //Popup
    showPopup = (popupActive) => {
        const {points, showPopup} = this.state;
        if (points.indexOf(popupActive) === -1) {
            points.push(popupActive)
        }

        if (!showPopup) {
            this.setState({
                popupActive: popupActive,
                showPopup: true,
                points: points,
            })
        } else if (popupActive >= 12) {
            this.setState({showPopup: false, popupActive: 0})
        } else {
            this.setState({
                popupActive: popupActive +1,
                showPopup: true,
                points: points,
            })
        }
    }

    closePopup = () => {
        this.setState({showPopup: false})
    }

    popupData = {
        1: {
            head: `Высокое содержание белка*`,
            list: [
                `Кошки <b>нуждаются в высоком уровне</b> общего количества <b>белка</b> в рационе для удовлетворения своих потребностей;`,
                `<b>не способны регулировать</b> активность ферментов, контролирующих процессы распада белка, прежде всего при потреблении низкопротеинового рациона;`,
                `норма потребления <b>не менее 15г/мДж.</b>`,
            ],
            id: 1
        },
        2: {
            head: `Витамин А`,
            list: [
                `не могут превращать бета-каротин в витамин А;`,
                `<b>должны получать активные формы ретинола с рационом.</b>`,
            ],
            id: 2
        },
        3: {
            head: `Аргинин`,
            list: [
                `<b>без аргинина нарушается утилизация аммиака и образование мочевины</b>, что приводит к развитию интоксикации и гибели животного;`,
                `<b>Должны получать аргинин с рационом.</b>`,
            ],
            id: 3
        },
        4: {
            head: `Ниацин`,
            list: [
                `способны синтезировать очень ограниченное количество ниацина из триптофана из-за активного вовлечения последнего в другие биотрансформации;`,
                `<b>должны получать ниацин с рационом.</b>`,
            ],
            id: 4
        },
        5: {
            head: `Таурин`,
            list: [
                `<b>не способны синтезировать таурин</b> для удовлетворения своих потребностей из серосодержащих аминокислот;`,
                `<b>должны получать таурин с рационом.</b>`,
            ],
            id: 5
        },
        6: {
            head: `Ограниченное количество углеводов`,
            list: [
                `обладают ограниченной способностью переваривать углеводы вследствие низкой активности ферментов в тонком кишечнике;`,
                `не способны переваривать углеводы в количестве, превышающем 5 г*<br/>
                <small>* не способны эффективно усваивать углеводы, не прошедшие предварительную термическую обработку;</small>`,
                `необходимо ограничивать содержание клетчатки в рационе до <b>6 г/мДж.</b>`,
            ],
            id: 6
        },
        7: {
            head: `Арахидоновая кислота`,
            list: [
                `<b>не способны синтезировать</b> в процессе трансформации жирных кислот арахидоновую кислоту из линолевой кислоты;`,
                `<b>должны получать арахидоновую кислоту с рационом.</b>`,
            ],
            id: 7
        },
        8: {
            head: `Разнообразие в питании – репертуар вкусов и текстур`,
            description: `WHISKAS® Вкусные подушечки с нежным паштетом Аппетитный обед с <span class="text_blue">лососем</span>, <span class="text_brown">курицей</span> и <span class="text_brown">индейкой</span>, <span class="text_pink">говядиной</span>.
            
            <b>Содержит:</b>`,
            tastes: false,
            list: [
                `Оптимальное сочетание питательных веществ и энергии для поддержания обмена веществ;`,
                `Витамин Е и цинк для иммунитета;`,
                `Омега-6 и цинк для здоровья кожи и шерсти;`,
                `Витамин А и таурин для хорошего зрения;`,
                `Высокоусваиваемые ингредиенты и клетчатка для пищеварения.`,
            ],
            view: img11,
            logos: false,
            id: 8
        },
        9: {
            head: `Разнообразие в питании – репертуар вкусов и текстур`,
            description: `Оптимальный баланс питательных веществ для полноценной жизни.
            <b>Все необходимое для поддержания здоровья Вашей любимицы:
            
            Паштет WHISKAS®:</b>`,
            tastes: img15,
            list: [
                `Говядина и печень;`,
                `Индейка и кролик;`,
                `Курица и индейка;`,
                `Утка.`,
            ],
            view: img16,
            logos: img14,
            id: 9
        },
        10: {
            head: `Разнообразие в питании – репертуар вкусов и текстур`,
            description: `Оптимальный баланс питательных веществ для полноценной жизни.
            <b>Все необходимое для поддержания здоровья Вашей любимицы:
            
            Желе WHISKAS® с</b>`,
            tastes: img17,
            list: [
                `Говядиной и ягненком;`,
                `Говядиной и кроликом;`,
                `Курицей;`,
                `Курицей и индейкой;`,
                `Лососем;`,
            ],
            view: img18,
            logos: img14,
            id: 10
        },
        11: {
            head: `Разнообразие в питании – репертуар вкусов и текстур`,
            description: `<b>WHISKAS® Аппетитный Микс</b> — это коллекция сбалансированных блюд, приготовленных по особым рецептам. В каждом из них три основных ингредиента: разные виды мяса или морепродуктов смешиваются в нежнейшем соусе или желе, чтобы вы воплощали мечты ваших любимцев.`,
            tastes: img19,
            list: [
                `Говядиной и ягненком;`,
                `Кроликом и овощами;`,
                `Курицей;`,
                `Индейкой и овощами;`,
                `Лососем;`,
            ],
            view: false,
            logos: img20,
            id: 11
        },
        12: {
            head: `Разнообразие в питании – репертуар вкусов и текстур`,
            description: `Оптимальный баланс питательных веществ для полноценной жизни.
            <b>Все необходимое для поддержания здоровья Вашей любимицы:
            
            Рагу WHISKAS® с:</b>`,
            tastes: img12,
            list: [
                `Говядиной и ягненком;`,
                `Телятиной;`,
                `Индейкой и кроликом;`,
                `Курицей;`,
                `Форелью и лососем;`,
                `Треской и лососем.`,
            ],
            view: img13,
            logos: false,
            id: 12
        },
        13: {
            head: `Разнообразие в питании – репертуар вкусов и текстур`,
            description: `<b>WHISKAS® Мясная и рыбная коллекция</b> — это палитра сбалансированных блюд из волокнистых мясных кусочков, приготовленных в нежном желе или соусе. Специальная технология помогает сохранить их сочность, а благодаря высокому содержанию белка, новинку оценят даже самые взыскательные кошки.`,
            tastes: img29,
            list: [
                `Говядиной и ягненком;`,
                `Кроликом и овощами;`,
                `Курицей;`,
                `Индейкой и овощами;`,
                `Лососем;`,
            ],
            view: false,
            logos: img30,
            id: 13
        },
    }

    //Работа десктопной версии теста
    setAnswer = (answer_el, question_el) => {
        const {answers} = this.state

        if (answers.indexOf(question_el) === -1) {
            let answer = []
            answers[answer_el] = question_el
            answers.push(answer)
        }
        this.setState({answers})

        let questions = [];
        // eslint-disable-next-line
        answers.map(answer => {

            if (answer.length) {
                questions.push(answer)
            }

        });
        this.setState({questions})
        if (questions.length === 7) {
            this.checkAnswer()
        }
    };

    checkAnswer = () => {
        const {answerCorrect, questions} = this.state
        // eslint-disable-next-line
        questions.map((answer, index) => {
            if (answer === String(index + 1)) {
                answerCorrect.push('correct')
            } else {
                answerCorrect.push('inCorrect')
            }
        })
        this.setState({
            answerCorrect: answerCorrect,
        })
        if (questions.length === 7 && answerCorrect.indexOf('inCorrect') === -1) {
            this.setState({correct: '1'})
        } else {
            this.setState({correct: '0'})
        }
    };

    _renderTestDesktop = () => {
        const {answerCorrect, correct, questions} = this.state
        return (
            <div className="test_desktop">
                {correct !== '' &&
                <div className={'info ' + (correct === '0' && 'error')}>
                    <div className="info_head">
                        {correct === '1' ? 'все верно!' : 'ЕСТЬ ОШИБКИ...'}
                    </div>
                </div>
                }
                <div className="flex">
                    <div className="flex_el">
                        <div className="answers">
                            <div className="property">
                                <img src={img3} alt="" className="property_img"/>
                            </div>
                        </div>
                    </div>
                    <div className="flex_el">
                        <div className="answers">
                            <div className="answers_item">
                                <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'1'} text={'?'} count={'?'} answerCorrect={answerCorrect[0]}/>
                            </div>
                            <div className="answers_item">
                                <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'2'} text={'1'} count={'?'} answerCorrect={answerCorrect[1]}/>
                            </div>
                            <div className="answers_item">
                                <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'3'} text={'2'} count={'?'} answerCorrect={answerCorrect[2]}/>
                            </div>
                            <div className="answers_item">
                                <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'4'} text={'3'} count={'?'} answerCorrect={answerCorrect[3]}/>
                            </div>
                            <div className="answers_item">
                                <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'5'} text={'?'} count={'?'} answerCorrect={answerCorrect[4]}/>
                            </div>
                            <div className="answers_item">
                                <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'6'} text={'?'} count={'?'} answerCorrect={answerCorrect[5]}/>
                            </div>
                            <div className="answers_item">
                                <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'7'} text={'?'} count={'?'} answerCorrect={answerCorrect[6]}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="questions">
                    <div className={'question_el ' + (questions.indexOf('1') >= 0 && 'hidden')}>
                        <Questions targetKey="1" question_el={'1'} label={'Поддержание обмена веществ'} text={'Поддержание обмена веществ'}/>
                    </div>
                    <div className={'question_el ' + (questions.indexOf('2') >= 0 && 'hidden')}>
                        <Questions targetKey="1" question_el={'2'} label={'Иммунитет'} text={'Иммунитет'}/>
                    </div>
                    <div className={'question_el ' + (questions.indexOf('3') >= 0 && 'hidden')}>
                        <Questions targetKey="1" question_el={'3'} label={'Здоровье кожи и шерсти'} text={'Здоровье кожи и шерсти'}/>
                    </div>
                    <div className={'question_el ' + (questions.indexOf('4') >= 0 && 'hidden')}>
                        <Questions targetKey="1" question_el={'4'} label={'Здоровье костей'} text={'Здоровье костей'}/>
                    </div>
                    <div className={'question_el ' + (questions.indexOf('5') >= 0 && 'hidden')}>
                        <Questions targetKey="1" question_el={'5'} label={'Хорошее зрение'} text={'Хорошее зрение'}/>
                    </div>
                    <div className={'question_el ' + (questions.indexOf('6') >= 0 && 'hidden')}>
                        <Questions targetKey="1" question_el={'6'} label={'Здоровое пищеварение'} text={'Здоровое пищеварение'}/>
                    </div>
                    <div className={'question_el ' + (questions.indexOf('7') >= 0 && 'hidden')}>
                        <Questions targetKey="1" question_el={'7'} label={'Удаление зубного налета'} text={'Удаление зубного налета'}/>
                    </div>
                </div>
            </div>
        )
    }

    //Работа мобильной версии теста
    questions = [
        `Поддержание обмена веществ`,
        `Иммунитет`,
        `Здоровье кожи и шерсти`,
        `Здоровые кости`,
        `Хорошее зрение`,
        `Здоровое пищеварение`,
        `Удаление зубного налета`,
    ]

    selectAnswer = (answerActive) => {
        document.body.scrollIntoView( true);
        document.body.style.overflow = "hidden";
        this.setState({
            showQuestion: true,
            answerActive: answerActive
        })
    }

    selectQuestion = (questionActive) => {
        const {answerActive, answersMobile, questionsMobile, answersSelected} = this.state

        if (answersMobile.indexOf(answerActive) === -1) {
            answersMobile.push(answerActive)
        }
        this.setState({answersMobile})

        if (questionsMobile.indexOf(questionsMobile) === -1) {
            questionsMobile.push(questionActive)
        }
        this.setState({questionsMobile})

        answersSelected.splice(answerActive - 1, 1, this.questions[questionActive - 1])

        document.body.style.overflow = "";
        this.setState({
            showQuestion: false,
            questionActive: questionActive,
            answersSelected: answersSelected
        })
    }

    checkAnswerMobile = () => {
        const {answerCorrectMobile, answersSelected} = this.state
        // eslint-disable-next-line
        answersSelected.map((answer, index) => {
            if (answer === this.questions[index]) {
                answerCorrectMobile.push('correct')
            } else {
                answerCorrectMobile.push('inCorrect')
            }
        })
        this.setState({
            answerCorrect: answerCorrectMobile,
        })
        if (answerCorrectMobile.indexOf('?') === -1 && answerCorrectMobile.indexOf('inCorrect') === -1) {
            this.setState({correctMobile: '1'})
        } else {
            this.setState({correctMobile: '0'})
        }
        this.props.showNextBtn()
    }

    closePopupQuestion = () => {
        document.body.style.overflow = "";
        this.setState({showQuestion: false})
    }

    _renderTestMobile = () => {
        const {showQuestion, answersMobile, questionsMobile, answersSelected, correctMobile, answerCorrectMobile} = this.state
        return (
            <div className="test_mobile">
                {correctMobile !== '' ?
                    <div className={'info ' + (correctMobile === '0' && 'error')}>
                        <div className="info_head">
                            {correctMobile === '1' ? 'все верно!' : 'К сожалению, ваш ответ не верен.'}
                        </div>
                    </div>
                    :
                    <button className="btn btn_violet" onClick={() => this.checkAnswerMobile()}>
                        Ответ
                    </button>
                }
                <div className="answers">
                    <div className="answers_box">
                        <div className="property property--violet">
                            Оптимальное соотношение питательных веществ
                            <img src={img21} alt="" className="property_img"/>
                        </div>
                        <div
                            className={'answers_item ' + (answersMobile.indexOf(1) >=0 ? 'selected ' : '') + answerCorrectMobile[0]}
                            onClick={() => {this.selectAnswer(1)}}
                        >
                            {answersSelected[0]}
                        </div>
                    </div>
                    <div className="answers_box">
                        <div className="property property--yellow">
                            Витамин е и цинк
                            <img src={img22} alt="" className="property_img"/>
                        </div>
                        <div
                            className={'answers_item ' + (answersMobile.indexOf(2) >=0 ? 'selected ' : '') + answerCorrectMobile[1]}
                            onClick={() => {this.selectAnswer(2)}}
                        >
                            {answersSelected[1]}
                        </div>
                    </div>
                    <div className="answers_box">
                        <div className="property property--turquoise">
                            Баланс кальция и фосфора
                            <img src={img23} alt="" className="property_img"/>
                        </div>
                        <div
                            className={'answers_item ' + (answersMobile.indexOf(3) >=0 ? 'selected ' : '') + answerCorrectMobile[2]}
                            onClick={() => {this.selectAnswer(3)}}
                        >
                            {answersSelected[2]}
                        </div>
                    </div>
                    <div className="answers_box">
                        <div className="property property--orange">
                            Омега-6 жирные кислоты и цинк
                            <img src={img24} alt="" className="property_img"/>
                        </div>
                        <div
                            className={'answers_item ' + (answersMobile.indexOf(4) >=0 ? 'selected ' : '') + answerCorrectMobile[3]}
                            onClick={() => {this.selectAnswer(4)}}
                        >
                            {answersSelected[3]}
                        </div>
                        <div className="answers_box">
                            <div className="property property--blue">
                                Витамин А и таурин
                                <img src={img25} alt="" className="property_img"/>
                            </div>
                            <div
                                className={'answers_item ' + (answersMobile.indexOf(5) >=0 ? 'selected ' : '') + answerCorrectMobile[4]}
                                onClick={() => {this.selectAnswer(5)}}
                            >
                                {answersSelected[4]}
                            </div>
                        </div>
                        <div className="answers_box">
                            <div className="property property--green">
                                Высокоусвояемые ингредиенты и клетчатка
                                <img src={img26} alt="" className="property_img"/>
                            </div>
                            <div
                                className={'answers_item ' + (answersMobile.indexOf(6) >=0 ? 'selected ' : '') + answerCorrectMobile[5]}
                                onClick={() => {this.selectAnswer(6)}}
                            >
                                {answersSelected[5]}
                            </div>
                        </div>
                        <div className="answers_box">
                            <div className="property property--violet">
                                Сухая текстура корма
                                <img src={img27} alt="" className="property_img"/>
                            </div>
                            <div
                                className={'answers_item ' + (answersMobile.indexOf(7) >=0 ? 'selected ' : '') + answerCorrectMobile[6]}
                                onClick={() => {this.selectAnswer(7)}}
                            >
                                {answersSelected[6]}
                            </div>
                        </div>
                    </div>
                    {/*{this.questions.map((item, index) => {
                        return (
                            <div
                                key={index}
                                className={'answers_item ' + (answersMobile.indexOf(index + 1) >=0 ? 'selected ' : '') + answerCorrectMobile[index]}
                                onClick={() => {this.selectAnswer(index + 1)}}
                            >
                                {answersSelected[index]}
                            </div>
                        )
                    })

                    }*/}
                </div>
                {showQuestion &&
                <div className="popup">
                    <div className="popup_wrp">
                        <div
                            className="popup_closed"
                            onClick={() => {this.closePopupQuestion()}}
                        />
                        <div className="popup_flex">
                            <div className="questions">
                                {this.questions.map((item, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className={'questions_item ' + (questionsMobile.indexOf(index + 1) >=0 ? 'selected ' : '')}
                                            onClick={() => {this.selectQuestion(index + 1)}}
                                        >
                                            {item}
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                }
            </div>
        )
    }

    render() {
        const {windowWidth, flexData, showPopup, popupActive} = this.state
        return (
            <div className="step12">
                <InfoBlock
                    text={`Нажмите на ключевые потребности взрослых кошек в питании и узнайте больше о каждой из них, а также Вы узнаете решения, которые предлагают рационы WHISKAS®.`}
                />
                <div className="header">
                    <div className="header_text">
                        Ключевые потребности взрослых кошек в питании
                    </div>
                    <img src={img1} alt="" className="header_img"/>
                </div>
                <OnClickBlock
                    flexData={flexData}
                    hideElement={this.hideElement}
                    showNextBtn={this.showNextBtn}
                >
                    <div className="imgDiv_wrap">
                        <div className="features">
                            <span className="features_pink">7</span> ключевых особенностей рациона кошек как плотоядных
                        </div>
                        <InfoBlock
                            text={`Нажмите на ключевые особенности рациона кошек и узнайте почему они им необходимы.`}
                        />
                        <div className="substances">
                            <div className="substances_el">
                                <div className="substances_el_block" onClick={() => {this.showPopup(1)}}>
                                    Высокое содержание белка*
                                </div>
                                <div className="substances_el_block" onClick={() => {this.showPopup(2)}}>
                                    Витамин А*
                                </div>
                                <div className="substances_el_block" onClick={() => {this.showPopup(3)}}>
                                    Аргинин*
                                </div>
                                <div className="substances_el_block" onClick={() => {this.showPopup(4)}}>
                                    Ниацин
                                </div>
                                <div className="substances_el_block" onClick={() => {this.showPopup(5)}}>
                                    Таурин*
                                </div>
                                <div className="substances_el_block" onClick={() => {this.showPopup(6)}}>
                                    Ограниченное количество углеводов
                                </div>
                                <div className="substances_el_block" onClick={() => {this.showPopup(7)}}>
                                    Арахидоновая кислота*
                                </div>
                            </div>
                            <div className="substances_el">
                                <img src={img2} alt="" className="substances_el_img"/>
                            </div>
                        </div>
                        {/*<div className="note">*/}
                        {/*    *Источник - только продукты животного происхождения.*/}
                        {/*</div>*/}
                    </div>
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`Рационы WHISKAS® способствуют улучшению здоровья кошек по следующим показателям:`}
                        />
                        <InfoBlock
                            text={`Узнайте, благодаря чему достигаются те или иные улучшения, сопоставив их со свойствами рационов WHISKAS®.`}
                        />
                        {windowWidth >= 768 ?
                            <div className="test">
                                {this._renderTestDesktop()}
                            </div>
                            :
                            <div className="test">
                                {this._renderTestMobile()}
                            </div>
                        }
                    </div>
                    <div className="imgDiv_wrap">
                        <div className="imgDiv_wrap_head">
                            Разнообразие в питании – репертуар вкусов и текстур
                        </div>
                        <img className="imgDiv_wrap_img imgCenter" src={img4} alt=""/>
                        <TextBlock
                            text={`Рационы WHISKAS® для взрослых кошек представлены во всех текстурах и вкусах.`}
                        />
                        <InfoBlock
                            text={`Узнайте подробнее о линейках рационов Вискас, нажав на иконки с кормом ниже.`}
                        />
                        <div className="tastes">
                            <div className="tastes_el" onClick={() => {this.showPopup(8)}}>
                                <img className="tastes_img" src={img6} alt=""/>
                                <p className="tastes_text">
                                    Сухой корм
                                </p>
                            </div>
                            <div className="tastes_el" onClick={() => {this.showPopup(9)}}>
                                <img className="tastes_img" src={img7} alt=""/>
                                <p className="tastes_text">
                                    Паштет
                                </p>
                            </div>
                            <div className="tastes_el" onClick={() => {this.showPopup(10)}}>
                                <img className="tastes_img" src={img8} alt=""/>
                                <p className="tastes_text">
                                    Желе
                                </p>
                            </div>
                            <div className="tastes_el" onClick={() => {this.showPopup(11)}}>
                                <img className="tastes_img" src={img9} alt=""/>
                                <p className="tastes_text">
                                    Аппетитный микс
                                </p>
                            </div>
                            <div className="tastes_el" onClick={() => {this.showPopup(12)}}>
                                <img className="tastes_img" src={img10} alt=""/>
                                <p className="tastes_text">
                                    Рагу<br/>
                                    <span className="tastes_text_description">(кусочки в соусе)</span>
                                </p>
                            </div>
                            <div className="tastes_el" onClick={() => {this.showPopup(13)}}>
                                <img className="tastes_img" src={img28} alt=""/>
                                <p className="tastes_text">
                                    Мясная коллекция
                                </p>
                            </div>
                        </div>
                    </div>
                </OnClickBlock>
                {showPopup &&
                <PopupInStep
                    closePopup={this.closePopup}
                    head={this.popupData[popupActive].head}
                    changePoint={() => {this.showPopup(popupActive)}}
                >
                    {popupActive < 8
                        ?
                        <div className="popup_box">
                            <List
                                ListData={this.popupData[popupActive].list}
                                key={this.popupData[popupActive].id}
                            />
                        </div>
                        :
                        <div className="popup_box">
                            <TextBlock
                                text={this.popupData[popupActive].description}
                            />
                            {this.popupData[popupActive].tastes &&
                            <img src={this.popupData[popupActive].tastes} alt="" className="popup_tastes"/>
                            }
                            {popupActive === 11 || popupActive === 13
                                ?
                            <TextBlock
                                text={`Оптимальный баланс питательных веществ для полноценной жизни. Все необходимое для поддержания здоровья Вашей любимицы:`}
                            />
                                :
                            <div className="popup_flex">
                                <List
                                    ListData={this.popupData[popupActive].list}
                                    key={this.popupData[popupActive].id}
                                />
                                <img src={this.popupData[popupActive].view} alt="" className="popup_img"/>
                            </div>
                            }
                            {this.popupData[popupActive].logos &&
                            <img src={this.popupData[popupActive].logos} alt="" className="popup_tastes"/>
                            }
                        </div>
                    }
                </PopupInStep>
                }
            </div>
        )
    }
}
