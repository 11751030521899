import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from "../../../image/lessons/lesson6/step25-1.png";


export default class step25 extends React.Component {

    render() {
        return (
            <div className="step25">
                <div className="head">
                    Мурлыканье
                </div>
                <TextBlock
                    text={`Удивительно, но до сих пор ученые так и не раскрыли механизм кошачьего урчания. Как оно происходит и для чего доподлинно неизвестно, существуют лишь предположения. Кошачье мурлыканье отличается по громкости, интенсивности и характеру воспроизводимых кошкой звуков. Интересно, что котята в возрасте двух дней уже умеют мурчать.`}
                />
                <img src={img1} alt="" className="imgCenter"/>
            </div>
        )
    }
}
