import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';

import img1 from "../../../image/lessons/lesson6/step24-1.png";
import img2 from "../../../image/lessons/lesson6/step24-2.png";
import img3 from "../../../image/lessons/lesson6/step24-3.png";
import img4 from "../../../image/lessons/lesson6/step24-4.png";


export default class step24 extends React.Component {

    render() {
        return (
            <div className="step24">
                <div className="flex">
                    <TextBlock
                        text={`<b>Собаки</b> в дикой природе представляют для кошек наибольшую опасность. Тем не менее, это не означает, что кошки и собаки не могут дружно жить вместе и сосуществовать в вашем доме!
                        
                        Кошки также могут бояться других кошек, людей (если они не социализированы должным образом) и громких звуков, таких как гром, звук проезжающих автомобилей или работающей газонокосилки.`}
                    />
                    <img src={img1} alt="" className="flex_img"/>
                </div>
                <TextBlock
                    text={`Пугливые кошки обычно демонстрируют такие признаки тревоги, как <b>расширенные зрачки, приплюснутые уши, приплюснутое или согнутое тело и учащенное дыхание</b>. При дальнейшем проявлении угрозы они могут начать <b>шипеть или рычать, выгибать спину, вспучивать шерсть дыбом и даже нападать.</b>`}
                />
                <InfoBlock
                    text={`Ознакомьтесь с изображениями, отображающими эмоции испуганной кошки.`}
                />
                <div className="cat-emotion">
                    <img src={img2} alt="" className="cat-emotion_img"/>
                    <img src={img3} alt="" className="cat-emotion_img"/>
                    <img src={img4} alt="" className="cat-emotion_img"/>
                </div>
            </div>
        )
    }
}
