import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

export default class step5 extends React.Component {

    render() {
        return (
            <div className="step5">
                <div className="head">
                    Важно:
                </div>
                <TextBlock
                    text={`Обеспечение чувства безопасности и защищенности является конечной целью ведения «кошачьих» пациентов в ветеринарной клинике. Когда кошки не чувствуют себя в безопасности, они демонстрируют поведение, связанное со страхом или агрессией. Как вызванная страхом ломка, так и агрессия делают лечение трудным или невозможным. Кроме того, физиологические последствия стресса ухудшают восстановление после болезни или травмы.`}
                />
            </div>
        )
    }
}
