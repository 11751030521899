import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from "../../../image/lessons/lesson8/step16-1.jpg";

export default class step16 extends React.Component {

    render() {
        return (
            <div className="step16">
                <div className="head">
                    Кошачьи переноски
                </div>
                <TextBlock
                    text={`Кошачьи переноски обеспечивают безопасность и дают кошке ощущение этой безопасности. Они должны быть прочными, надежными и устойчивыми для транспортировки кошки, легкими для переноски владельцем и обладать тихими дверцами и креплениями, чтобы открытие кошачьей переноски не испугало кошку.
                    
                    Конструкция должна позволять легко извлекать кошку.
                    
                    Переноски с верхней дверцей или съемной верхней частью – идеальный выбор.`}
                />
                <img src={img1} alt="" className="imgCenter"/>
            </div>
        )
    }
}