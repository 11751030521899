import React from 'react'
// import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";

import img1 from '../../../image/lessons/lesson7/step44-1.png'


export default class step44 extends React.Component{

    render() {
        return (
            <div className="step44">
                <ul className="list">
                    <li className="list_item">
                        Кошки царапаются, чтобы размять мышцы, сбросить старые кутикулы, заострить когти и оставить запаховые метки. Даже кошки с удаленными когтями сохраняют инстинкт царапаться.
                    </li>
                    <li className="list_item">
                        Наличие когтеточек позволяет кошке царапаться, сохраняя при этом в целости мебель и ковры!
                    </li>
                </ul>
                <img src={img1} alt="" className="imgCenter"/>
            </div>
        )
    }
}
