import React from 'react'
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";

import img1 from '../../../image/lessons/lesson7/step38-1.png'


export default class step38 extends React.Component{

    render() {
        return (
            <div className="step38">
                <TextBlock
                    text={`Устранение конфликтной ситуации между кошками включает в себя предоставление отдельного набора ресурсов для каждой кошки в уединенных местах.
                    
                    Когда конфликтующие кошки не могут находиться под непосредственным наблюдением владельца, их необходимо разделить и предоставить жертве конфликта убежище.
                    
                    Конфликт также можно минимизировать, подвергнув кошку процедуре стерилизации или кастрации. Необходимо коротко стричь когти.`}
                />
                <img src={img1} alt="" className="imgCenter"/>
            </div>
        )
    }
}
