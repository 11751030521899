import React from 'react'
// import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from "../../../image/lessons/lesson13/step33-1.png";

export default class step33 extends React.Component {

    render() {
        return (
            <div className="step13">
                <div className="text-top">
                    <p>
                        Возможно, придется путешествовать вместе с кошкой, например, совершать короткие поездки в
                        ветеринарную клинику или на семейный отдых. Путешествие может стать пугающим для кошки, поэтому
                        необходимо придерживаться соответствующих рекомендаций, которые помогут уменьшить стресс и
                        тревогу кошки во время поездки или путешествия.
                    </p>
                    <ul>
                        <li>
                            Путешествие с кошкой не должно стать для кошки неприятным опытом.

                        </li>
                        <li>
                            Необходимо заранее подготовить все документы и позаботиться о необходимых медикаментах.
                        </li>
                        <li>
                            Во время путешествия всегда лучше, чтобы кошка находилась в переноске.
                            Требования к переноскам для самолетов следует уточнять у авиакомпаний заранее.
                        </li>
                        <li>
                            Следует заранее выбирать по возможности авиакомпании, перевозящие кошек в салоне самолета, а
                            не в багажном отделении.
                        </li>

                    </ul>
                </div>

                <div className="infoImage">
                    <img src={img1} alt=""/>
                </div>

            </div>
        )
    }
}
