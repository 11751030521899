import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from "../../../image/lessons/lesson7/step6-1.png";

export default class step6 extends React.Component {

    render() {
        return (
            <div className="step6">
                <TextBlock
                    text={`Кошки предпочитают чувствовать, что они «контролируют» свое окружение, им нравится самостоятельно принимать решение о выборе соответствующих изменений на их территории.
                    
                    Поэтому привнося что-то новое в ее жизненный уклад важно позволить кошке самой решить, нравятся ли ей такие изменения.
                    
                    Обязательно регулярно посещайте своего ветеринарного врача.`}
                />
                <img src={img1} alt="" className="imgCenter"/>
            </div>
        )
    }
}
