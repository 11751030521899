import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from "../../../image/lessons/lesson6/step28-1.png";


export default class step28 extends React.Component {

    render() {
        return (
            <div className="step27">
                <div className="head">
                    Шипение и ворчание
                </div>
                <TextBlock
                    text={`<b>Сложно не заметить и не понять, почему шипит кошка</b>. Обычно сразу видно, что она напугана. Ворчат кошки, как правило, тоже по этой причине. Кроме того, питомец в страхе может издавать короткие взвизги. Нередко таким образом проявляется реакция на других животных.`}
                />
                <img src={img1} alt="" className="imgCenter"/>
            </div>
        )
    }
}
