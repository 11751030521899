import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock'

export default class step24 extends React.Component {


    render() {
        return (
            <div className="step24">
                <div className="flex">
                    <TextBlock
                        text={`<b>Управление визуальными и слуховыми сигналами</b>`}
                    />
                    <ul className="list">
                        <li className="list_item">
                            Сведите к минимуму визуальные сигналы, которые могут беспокоить кошку.
                        </li>
                        <li className="list_item">
                            Держите других животных подальше от кошки.
                        </li>
                        <li className="list_item">
                            По возможности обеспечьте отдельный вестибюль и приемный покой для владельцев кошек
                        </li>
                        <li className="list_item">
                            Накройте кошачьи переноски одеялом или полотенцем. Избегайте резкого освещения.
                        </li>
                        <li className="list_item">
                            Обеспечьте спокойную обстановку и говорите тихо. Минимизируйте шум, который может напугать кошку.
                        </li>
                        <li className="list_item">
                            Используйте успокаивающую фоновую музыку и акустические демпферы.
                        </li>
                    </ul>
                </div>
            </div>
        )
    }
}
