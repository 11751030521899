import React from 'react'
import VideoBlock from "../../../Components/LessonAndTestComponents/VideoBlock";
import Lecturer from "../../../Components/LessonAndTestComponents/Lecturer";

import img1 from '../../../image/Lecturers/sengmani.png'
import img2 from '../../../image/Lecturers/sengmani-icon.png'
import img3 from '../../../image/Lecturers/lisickaya.png'
import img4 from '../../../image/Lecturers/pozharskaya-icon.png'
import img5 from '../../../image/Lecturers/antanasova.png'
import img6 from '../../../image/Lecturers/antanasova-icon.png'

export default class step2 extends React.Component {

    render() {
        return (
            <div className="step2">
                <Lecturer
                    img={img1}
                    icon={img2}
                    surname={'Cенгмани'}
                    name={'Марина Шамировна '}
                    text={'Ветеринарный врач, хирург, эндоскопист, ВОНЦ «Биоконтроль»,  г.Москва'}
                />

                <Lecturer
                    img={img3}
                    surname={'Лисицкая'}
                    name={'Ксения Валерьевна'}
                    text={'К.б.н., морфолог, патолог ветеринарной лаборатории Лабоклин Россия'}
                />

                <Lecturer
                    img={img5}
                    icon={img6}
                    surname={'Атанасова'}
                    name={'Снежана Георгиевна'}
                    text={'Президент Национальной ассоциации медицины кошек, ветеринарный врач, анестезиолог-реаниматолог, научный руководитель в/к «Клиника Кошек», г. Москва.'}
                />
                <hr className="string"/>

                <VideoBlock
                    src={'https://www.youtube.com/embed/keTU717drhg'}
                    title={'https://www.youtube.com/watch?v=keTU717drhg&feature=youtu.be'}
                />
            </div>
        )
    }
}
