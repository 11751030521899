import React from 'react'
// import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";

import img1 from '../../../image/lessons/lesson7/step41-1.png'


export default class step41 extends React.Component{

    render() {
        return (
            <div className="step41">
                <div className="head">
                    Зачем играть с кошкой?
                </div>
                <ul className="list">
                    <li className="list_item">
                        Игры способствуют установлению и развитию связи, доверительных отношений с кошкой.
                    </li>
                    <li className="list_item">
                        У кошки улучшается состояние и самочувствие, снижается риск и частота ожирения.
                    </li>
                    <li className="list_item">
                        Повышается активность кошки в течение дня и уменьшается риск возбуждения и деструктивного поведения в вечернее и ночное время суток.
                    </li>
                    <li className="list_item">
                        Незаменимой чертой игр с вашей кошкой является сам факт того, что вы играете с ней в контролируемой среде, что снижает вероятность укусов или нападений на вас или на других людей во время игры.
                    </li>
                </ul>
                <img src={img1} alt="" className="imgCenter"/>
            </div>
        )
    }
}
