import React from 'react'
import InfoBlock from "../../../Components/LessonAndTestComponents/InfoBlock";
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";
import PopupInStep from "../../../Components/LessonAndTestComponents/PopupInStep";

import img1_1 from "../../../image/lessons/lesson34/step9-1_1.png";
import img1_2 from "../../../image/lessons/lesson34/step9-1_2.png";
import img2_1 from "../../../image/lessons/lesson34/step9-2_1.png";
import img2_2 from "../../../image/lessons/lesson34/step9-2_2.png";
import img3_1 from "../../../image/lessons/lesson34/step9-3_1.png";
import img3_2 from "../../../image/lessons/lesson34/step9-3_2.png";
import img4_1 from "../../../image/lessons/lesson34/step9-4_1.png";
import img4_2 from "../../../image/lessons/lesson34/step9-4_2.png";
import img5_1 from "../../../image/lessons/lesson34/step9-5_1.png";
import img5_2 from "../../../image/lessons/lesson34/step9-5_2.png";
import img6_1 from "../../../image/lessons/lesson34/step9-6_1.png";
import img6_2 from "../../../image/lessons/lesson34/step9-6_2.png";
import img7_1 from "../../../image/lessons/lesson34/step9-7_1.png";
import img7_2 from "../../../image/lessons/lesson34/step9-7_2.png";
import img8_1 from "../../../image/lessons/lesson34/step9-8_1.png";
import img8_2 from "../../../image/lessons/lesson34/step9-8_2.png";
import img_popup1_1 from "../../../image/lessons/lesson34/step9-1-1.png";
import img_popup1_2 from "../../../image/lessons/lesson34/step9-1-2.png";
import img_popup1_3 from "../../../image/lessons/lesson34/step9-1-3.png";
import img_popup2_1 from "../../../image/lessons/lesson34/step9-2-1.png";
import img_popup2_2 from "../../../image/lessons/lesson34/step9-2-2.png";
import img_popup2_3 from "../../../image/lessons/lesson34/step9-2-3.png";
import img_popup3_1 from "../../../image/lessons/lesson34/step9-3-1.png";
import img_popup3_2 from "../../../image/lessons/lesson34/step9-3-2.png";
import img_popup3_3 from "../../../image/lessons/lesson34/step9-3-3.png";
import img_popup4_1 from "../../../image/lessons/lesson34/step9-4-1.png";
import img_popup4_2 from "../../../image/lessons/lesson34/step9-4-2.png";
import img_popup4_3 from "../../../image/lessons/lesson34/step9-4-3.png";
import img_popup5_1 from "../../../image/lessons/lesson34/step9-5-1.png";
import img_popup5_2 from "../../../image/lessons/lesson34/step9-5-2.png";
import img_popup5_3 from "../../../image/lessons/lesson34/step9-5-3.png";
import img_popup6_1 from "../../../image/lessons/lesson34/step9-6-1.png";
import img_popup6_2 from "../../../image/lessons/lesson34/step9-6-2.png";
import img_popup6_3 from "../../../image/lessons/lesson34/step9-6-3.png";
import img_popup7_1 from "../../../image/lessons/lesson34/step9-7-1.png";
import img_popup7_2 from "../../../image/lessons/lesson34/step9-7-2.png";
import img_popup7_3 from "../../../image/lessons/lesson34/step9-7-3.png";
import img_popup8_1 from "../../../image/lessons/lesson34/step9-8-1.png";
import img_popup8_2 from "../../../image/lessons/lesson34/step9-8-2.png";
import img_popup8_3 from "../../../image/lessons/lesson34/step9-8-3.png";

export default class step9 extends React.Component {

    state = {
        popupActive: 0,
        showPopup: false,
        points: [],
    }

    showPopup = (popupActive, e) => {
        const {points} = this.state;
        if (points.indexOf(popupActive) === -1) {
            points.push(popupActive)
        }

        this.setState({
            popupActive: popupActive,
            showPopup: true,
            points: points
        })

        if (points.length === 6) {
            this.props.showNextBtn();
        }
        e.target.closest('.flex_el_content').classList.add('active')
    }

    closePopup = () => {
        this.setState({showPopup: false})
    }

    popupData = {
        1: {
            img: img1_2,
            head: 'для котят от 1 до 12 месяцев',
            description: `<span class="text_color">PERFECT FIT™ для котят от 1 до 12 месяцев – влажный рацион<br/>PERFECT FIT™ для котят от 2 до 12 месяцев – сухой рацион
</span>`,
            properties1: 'Здоровый рост',
            properties2: 'здоровое развитие костей',
            properties3: 'ЗДОРОВОЕ РАЗВИТИЕ ЗРЕНИЯ И МОЗГА',
            icon1: img_popup1_1,
            icon2: img_popup1_2,
            icon3: img_popup1_3,
            text1: 'Богат белком высокого качества, содержит необходимые макро и микроэлементы для здорового роста котенка.',
            text2: 'Здоровые и крепкие кости.',
            text3: 'Содержит омега-3 жирную кислоту, входящую в состав молока кормящей кошки и способствующую развитию мозговой деятельности и зрения котят',
            class: 'info--violet',
        },
        2: {
            img: img2_2,
            head: 'ДЛЯ ВЗРОСЛЫХ КОШЕК (ГОВЯДИНА)',
            description: `<span class="text_color">PERFECT FIT™ для взрослых кошек</span>`,
            properties1: 'Здоровое сердце',
            properties2: 'ЖИЗНЕННАЯ СИЛА',
            properties3: 'ЗДОРОВЬЕ ЗУБОВ',
            icon1: img_popup2_1,
            icon2: img_popup2_2,
            icon3: img_popup2_3,
            text1: 'Содержит таурин, незаменимую для кошек аминокислоту, помогающую поддерживать сердце сильным и здоровым.',
            text2: 'Обогащен витаминами группы B и железом для поддержания жизненной силы',
            text3: 'Хрустящие гранулы способствуют удалению зубного налета, а специальный ингредиент (STPP) снижает образование зубного камня',
            class: 'info--pink',
        },
        3: {
            img: img3_2,
            head: 'ДЛЯ КОШЕК С ЧУВСТВИТЕЛЬНЫМ ПИЩЕВАРЕНИЕМ',
            description: `<span class="text_color">PERFECT FIT™ для кошек с чувствительным пищеварением– рацион, адаптированный специально для кошек с особенностями пищеварения</span>`,
            properties1: 'ЗДОРОВАЯ МИКРОФЛОРА КИШЕЧНИКА',
            properties2: 'БЕЗ ДОБАВЛЕНИЯ ПШЕНИЦЫ И СОИ',
            properties3: 'ВЫСОКАЯ УСВОЯЕМОСТЬ',
            icon1: img_popup3_1,
            icon2: img_popup3_2,
            icon3: img_popup3_3,
            text1: 'Содержит пребиотики, способствующие поддержанию здоровой микрофлоры кишечника',
            text2: 'Без добавления пшеницы и сои, которые могут быть причиной расстройств пищеварения у кошек с чувствительным пищеварением',
            text3: 'Содержит легко усваиваемые и высококачественные ингредиенты – индейку/лосось и рис',
            class: 'info--yellow',
        },
        4: {
            img: img4_2,
            head: 'ДЛЯ ДОМАШНИХ КОШЕК',
            description: `<span class="text_color">PERFECT FIT™ для домашних кошек оптимален для домашних кошек благодаря следующим особенностям</span>`,
            properties1: 'ПОДДЕРЖАНИЕ ЗДОРОВОГО ВЕСА',
            properties2: 'СПОСОБСТВУЕТ УМЕНЬШЕНИЮ НЕПРИЯТНОГО ЗАПАХА',
            properties3: 'ГЛАДКАЯ ШЕРСТЬ И ЗДОРОВАЯ КОЖА',
            icon1: img_popup4_1,
            icon2: img_popup4_2,
            icon3: img_popup4_3,
            text1: 'Специальный ингредиент L-карнитин способствует сжиганию жиров, помогая поддерживать здоровый вес',
            text2: 'Способствует уменьшению запаха кошачьего туалета благодаря содержанию растительного компонента юкки Шидигера',
            text3: 'Повышенное содержание цинка и растительного масла – натурального источника омега-6 жирных кислот для поддержания здоровья кожи и шерсти',
            class: 'info--blue',
        },
        5: {
            img: img5_2,
            head: 'ДЛЯ СТЕРИЛИЗОВАННЫХ КОШЕК',
            description: `<span class="text_color">В рационе PERFECT FIT™ для стерилизованных кошек учтены особые потребности в питании стерилизованных кошек и кастрированных котов</span>`,
            properties1: 'ЗДОРОВЬЕ МОЧЕВЫВОДЯЩЕЙ СИСТЕМЫ',
            properties2: 'ПОДДЕРЖАНИЕ ЗДОРОВОГО ВЕСА',
            properties3: 'ЗДОРОВЫЙ ОБМЕН ВЕЩЕСТВ',
            icon1: img_popup5_1,
            icon2: img_popup5_2,
            icon3: img_popup5_3,
            text1: 'Поддерживает здоровье мочевыводящей системы у стерилизованных кошек, подверженных повышенному риску развития мочекаменной болезни',
            text2: 'Специальная рецептура с пониженным содержанием жира позволяет снизить потребление калорий в каждом кормлении',
            text3: 'Поддерживает здоровый обмен веществ после стерилизации благодаря содержанию всех необходимых питательных веществ',
            class: 'info--green',
        },
        6: {
            img: img6_2,
            head: 'ДЛЯ СТЕРИЛИЗОВАННЫХ КОШЕК СТАРШЕ 7 ЛЕТ',
            description: `<span class="text_color">Потребности кошек существенно меняются в процессе старения. PERFECT FIT™ для стерилизованных кошек старше 7 лет специально разработан для поддержания здоровья кошек в преклонном возрасте</span>`,
            properties1: 'ЗДОРОВОЕ СЕРДЦЕ',
            properties2: 'ЗДОРОВЬЕ МОЧЕВЫВОДЯЩЕЙ СИСТЕМЫ',
            properties3: 'ПОДВИЖНОСТЬ СУСТАВОВ',
            icon1: img_popup6_1,
            icon2: img_popup6_2,
            icon3: img_popup6_3,
            text1: 'Содержит таурин, незаменимую для кошек аминокислоту, помогающую поддерживать сердце сильным и здоровым',
            text2: 'Баланс минералов, помогающий снижению риска формирования камней и поддержанию здоровья мочевыводящей системы',
            text3: 'Содержит натуральный источник глюкозамина и омега-3 жирных кислот, помогающих поддерживать подвижность суставов',
            class: 'info--light-green',
        },
        7: {
            img: img7_2,
            head: 'ДЛЯ КРАСИВОЙ ШЕРСТИ И ЗДОРОВОЙ КОЖИ',
            description: `<span class="text_color">PERFECT FIT™ для красивой шерсти и здоровой кожи – рацион, контролирующий образование волосяных комочков и способствующий сокращению выпадения шерсти</span>`,
            properties1: 'ЗДОРОВАЯ КОЖА И КРАСИВАЯ ШЕРСТЬ',
            properties2: 'КОНТРОЛЬ ОБРАЗОВАНИЯ ВОЛОСЯНЫХ КОМОЧКОВ ШЕРСТИ',
            properties3: 'СПОСОБСТВУЕТ СОКРАЩЕНИЮ ВЫПАДЕНИЯ ШЕРСТИ',
            icon1: img_popup7_1,
            icon2: img_popup7_2,
            icon3: img_popup7_3,
            text1: 'Повышенное содержание цинка и растительного масла - натурального источника омега-6 жирных кислот - для поддержания здоровья кожи и шерсти',
            text2: 'Содержит натуральные пищевые волокна, помогающие контролировать образование комочков шерсти в организме кошки',
            text3: 'Комплекс аминокислот, витаминов, минералов и баланс омега-6 и омега-3 жирных кислот способствует сокращению выпадения шерсти',
            class: 'info--light-blue',
        },
        8: {
            img: img8_2,
            head: 'ДЛЯ ПОДДЕРЖАНИЯ ЗДОРОВЬЯ ПОЧЕК',
            description: `<span class="text_color">PERFECT FIT™ для поддержания здоровья почек</span>`,
            properties1: 'ПОДДЕРЖАНИЕ ФУНКЦИИ ПОЧЕК',
            properties2: 'ЗДОРОВЬЕ МОЧЕВЫВОДЯЩЕЙ СИСТЕМЫ',
            properties3: 'ПОДДЕРЖАНИЕ ЖИЗНЕННОВАЖНЫХ ФУНКЦИЙ',
            icon1: img_popup8_1,
            icon2: img_popup8_2,
            icon3: img_popup8_3,
            text1: 'Ограниченный уровень фосфора, омега-3 жирные кислоты и калий способствуют поддержанию здоровья почек',
            text2: 'Баланс минералов, помогающий снижению риска формирования камней и поддержанию здоровья мочевыводящей системы',
            text3: 'Незаменимые витамины, минералы и аминокислоты для здоровья организма кошки',
            class: 'info--purple',
        },
    }

    render() {
        const {showPopup, popupActive} = this.state
        return (
            <div className="step9">
                <InfoBlock
                    text={`Кроме общей формулы «5 слагаемых здоровья» каждый рацион Perfect Fit™ для кошек обладает индивидуальными особенностями. Выберите рацион и познакомьтесь с ним.`}
                />
                <div className="topInfo">
                    Кормление функциональными кормами позволяет не только сбалансировать рацион питомца более, чем по 40 показателям, но и учесть индивидуальные особенности организма.
                </div>
                <div className="flex">
                    <div className="flex_el">
                        <div
                            className="flex_el_content violet"
                            onClick={(e) => {this.showPopup(1, e)}}
                        >
                            <div className="flex_el_text">
                                <div className="itemHead">Для котят</div>
                                <p className="itemText">Сбалансированный рацион для правильного роста и развития котенка</p>
                            </div>
                            <img src={img1_1} alt="" className="flex_el_img"/>
                        </div>
                    </div>
                    <div className="flex_el">
                        <div
                            className="flex_el_content pink"
                            onClick={(e) => {this.showPopup(2, e)}}
                        >
                            <div className="flex_el_text">
                                <div className="itemHead">для взрослых</div>
                                <p className="itemText">Рацион, поддерживающий жизненную силу, здоровое сердце и крепкие зубы</p>
                            </div>
                            <img src={img2_1} alt="" className="flex_el_img"/>
                        </div>
                    </div>
                    <div className="flex_el">
                        <div
                            className="flex_el_content yellow"
                            onClick={(e) => {this.showPopup(3, e)}}
                        >
                            <div className="flex_el_text">
                                <div className="itemHead">ДЛЯ КОШЕК С ЧУВСТВИТЕЛЬНЫМ ПИЩЕВАРЕНИЕМ</div>
                                <p className="itemText">Содержит легкоусвояемые ингредиенты и пребиотики для поддержания здоровой микрофлоры кишечника</p>
                            </div>
                            <img src={img3_1} alt="" className="flex_el_img"/>
                        </div>
                    </div>
                    <div className="flex_el">
                        <div
                            className="flex_el_content blue"
                            onClick={(e) => {this.showPopup(4, e)}}
                        >
                            <div className="flex_el_text">
                                <div className="itemHead">ДЛЯ ДОМАШНИХ КОШЕК</div>
                                <p className="itemText">Оптимальный рацион, поддерживающий здоровый вес за счет L-карнитина, а юкка Шидигера уменьшает запах кошачьего туалета.</p>
                            </div>
                            <img src={img4_1} alt="" className="flex_el_img"/>
                        </div>
                    </div>
                    <div className="flex_el">
                        <div
                            className="flex_el_content green"
                            onClick={(e) => {this.showPopup(5, e)}}
                        >
                            <div className="flex_el_text">
                                <div className="itemHead">ДЛЯ СТЕРИЛИЗОВАННЫХ КОШЕК</div>
                                <p className="itemText">Рацион, сбалансированный с учетом всех питательных потребностей стерилизованных кошек и кастрированных котов.</p>
                            </div>
                            <img src={img5_1} alt="" className="flex_el_img"/>
                        </div>
                    </div>
                    <div className="flex_el">
                        <div
                            className="flex_el_content light-green"
                            onClick={(e) => {this.showPopup(6, e)}}
                        >
                            <div className="flex_el_text">
                                <div className="itemHead">ДЛЯ СТЕРИЛИЗОВАННЫХ КОШЕК СТАРШЕ 7 ЛЕТ</div>
                                <p className="itemText">Рацион, составленный с заботой о мочевыделительной системе, здоровом сердце и суставах.</p>
                            </div>
                            <img src={img6_1} alt="" className="flex_el_img"/>
                        </div>
                    </div>
                    <div className="flex_el">
                        <div
                            className="flex_el_content light-blue"
                            onClick={(e) => {this.showPopup(7, e)}}
                        >
                            <div className="flex_el_text">
                                <div className="itemHead">ДЛЯ КРАСИВОЙ ШЕРСТИ И ЗДОРОВОЙ КОЖИ</div>
                                <p className="itemText">Рацион, способствующий уменьшению образования комочков шерсти и сокращению выпадения шерсти.</p>
                            </div>
                            <img src={img7_1} alt="" className="flex_el_img"/>
                        </div>
                    </div>
                    <div className="flex_el">
                        <div
                            className="flex_el_content purple"
                            onClick={(e) => {this.showPopup(8, e)}}
                        >
                            <div className="flex_el_text">
                                <div className="itemHead">ДЛЯ ПОДДЕРЖАНИЯ ЗДОРОВЬЯ ПОЧЕК</div>
                                <p className="itemText">Поддержание здоровья почек и мочевыделительной системы.</p>
                            </div>
                            <img src={img8_1} alt="" className="flex_el_img"/>
                        </div>
                    </div>
                </div>
                {showPopup &&
                    <PopupInStep
                        closePopup={this.closePopup}
                    >
                        <div className={'info ' + this.popupData[popupActive].class}>
                            <div className="info_head">
                                {this.popupData[popupActive].head}
                            </div>
                            <div className="info_product">
                                <img src={this.popupData[popupActive].img} alt="" className="info_product_img"/>
                                <div className="info_product_frame">
                                    <TextBlock
                                        text={this.popupData[popupActive].description}
                                    />
                                </div>

                            </div>
                            <div className="info_description">
                                <div className="info_description_img">
                                    <img src={this.popupData[popupActive].icon1} alt="" className="info_description_img"/>
                                </div>
                                <div className="info_description_box">
                                    <div className="info_description_properties">
                                        {this.popupData[popupActive].properties1}
                                    </div>
                                    <div className="info_description_text">
                                        {this.popupData[popupActive].text1}
                                    </div>
                                </div>
                            </div>
                            <div className="info_description">
                                <div className="info_description_img">
                                    <img src={this.popupData[popupActive].icon2} alt="" className="info_description_img"/>
                                </div>
                                <div className="info_description_box">
                                    <div className="info_description_properties">
                                        {this.popupData[popupActive].properties2}
                                    </div>
                                    <div className="info_description_text">
                                        {this.popupData[popupActive].text2}
                                    </div>
                                </div>
                            </div>
                            <div className="info_description">
                                <div className="info_description_img">
                                    <img src={this.popupData[popupActive].icon3} alt="" className="info_description_img"/>
                                </div>
                                <div className="info_description_box">
                                    <div className="info_description_properties">
                                        {this.popupData[popupActive].properties3}
                                    </div>
                                    <div className="info_description_text">
                                        {this.popupData[popupActive].text3}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </PopupInStep>
                }
            </div>
        )
    }
}
