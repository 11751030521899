import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import PlanInFirstStep from "../../../Components/LessonAndTestComponents/PlanInFirstStep";
import Creator from "../../../Components/LessonAndTestComponents/Creator";
import img1 from "../../../image/lessons/lesson33/step1-1.svg";

export default class step1 extends React.Component {

    planData = [
        `Научная основа рационов WHISKAS®`,
        `Особенности питания для кошек на разных стадиях жизни`,
        `Преимущества сочетания сухих и влажных кормов`,
        `Правильное применение лакомств`,
    ]

    render() {
        return (
            <div className="step1">
                <Creator
                    img={img1}
                    name={'Отделом научной коммуникации компании «МАРС»'}
                    text={'Использованы материалы научно-исследовательского института Waltham. <a class="text_link" href="https://www.waltham.com" target="_blank" rel="noopener noreferrer">https://www.waltham.com</a>'}
                />
                <hr className="string"/>
                <div className="head">План урока</div>

                <TextBlock
                    text={`Вы изучите:`}
                />

                <PlanInFirstStep
                    planData={this.planData}
                    type={'violet'}
                />

            </div>
        )
    }
}
