import React from 'react'
import './style.sass'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

export default class Resume extends React.Component {

    state = {
        resumeData: this.props.resumeData
    }

    render() {
        const {resumeData} = this.state
        return (
            // Пример использования lesson 11, step 9
            <div className="Resume">
                {this.props.title &&
                <div
                    className={'Resume_title'}
                    dangerouslySetInnerHTML={{ __html: this.props.title }}
                />
                }
                {this.props.text &&
                <TextBlock
                    text={this.props.text}
                />
                }
                <div className="Resume_box">
                    <ul className="Resume_list">
                        {resumeData.map((item, index) => {
                            return (
                                <li
                                    key={index}
                                    className="Resume_item"
                                >
                                    <div className="Resume_number">{index + 1}</div>
                                    <div
                                        className="Resume_text"
                                        dangerouslySetInnerHTML={{ __html: item }}
                                    />
                                </li>
                            )
                        })}
                    </ul>
                    {this.props.img &&
                    <img className="Resume_img" src={this.props.img} alt=""/>
                    }
                </div>
            </div>
        )
    }
}
