import React from 'react'
// import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from "../../../image/lessons/lesson7/step20-1.png";


export default class step20 extends React.Component {

    render() {
        return (
            <div className="step20">
                <div className="head">
                    Рекомендации при тревоге и боязни разлуки:
                </div>
                <ul className="list">
                    <li className="list_item">
                        Обеспечить наличие в доме насестов для кошки, чтобы она могла смотреть на улицу.
                    </li>
                    <li className="list_item">
                        Приобрести для кошки большое количество разнообразных игрушек.
                    </li>
                </ul>
                <img src={img1} alt="" className={'imgCenter'}/>
            </div>
        )
    }
}
