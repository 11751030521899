import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import OnClickCircleBlock from "../../../Components/LessonAndTestComponents/OnClickCircleBlock";

import img1 from '../../../image/lessons/lesson9/step11-1.svg'
import img2 from '../../../image/lessons/lesson9/step11-2.svg'
import img3 from '../../../image/lessons/lesson9/step11-3.svg'

export default class step11 extends React.Component {

    state = {
        flexData: ['1', '2', '3']
    }

    showNextBtn = () => {
        this.props.showNextBtn()
    }

    render() {
        const {flexData} = this.state
        return (
            <div className="step11">
                <InfoBlock
                    text={`Нажмите на цифры, чтобы получить информацию.`}
                />
                <OnClickCircleBlock
                    flexData={flexData}
                    showNextBtn={this.showNextBtn}
                    type={'svg'}
                >
                    <div className="info_wrap">
                        <TextBlock
                            text={`Страх и тревога у кошек могут сначала проявляться в виде изменения положения ушей, глаз и выражения лица, положения тела, потливости подушечек лап и движения хвоста. Испуганные кошки могут попытаться убежать.`}
                        />
                        <img src={img1} alt="" className="info_img"/>
                    </div>
                    <div className="info_wrap">
                        <TextBlock
                            text={`Различные звуки (например, мяуканье, рычание, шипение, плевки) могут указывать на нарастающую стрессовую реакцию. После этого некоторые кошки могут демонстрировать откровенно агрессивное поведение, например, кусаются, царапаются или нападают.`}
                        />
                        <img src={img2} alt="" className="info_img"/>
                    </div>
                    <div className="info_wrap">
                        <TextBlock
                            text={`Другие реагируют на тревогу сильным страхом, перерастающим в оцепенение, в результате чего кошки становятся неподвижными, молчаливыми, а их глаза расширяются, или вовлечением в смещенное поведение, такое как беспорядочный уход за шерстью. Отличить поведение, связанное со страхом и тревогой, от поведения, связанного с болью, может быть довольно непросто .`}
                        />
                        <img src={img3} alt="" className="info_img"/>
                    </div>
                </OnClickCircleBlock>
            </div>
        )
    }
}
