import React from 'react'
import './style.sass'
import Element from './element.js'
import ElementMobile from './elementMobile.js'
import Newbie from '../../image/GuruPanel/01-Newbie.png'
import Specialist from '../../image/GuruPanel/02-Specialist.png'
import Professional from '../../image/GuruPanel/03-Professional.png'
import Master from '../../image/GuruPanel/04-Master.png'
import Weisedog from '../../image/GuruPanel/05-Weisedog.png'
import Smarty from '../../image/GuruPanel/06-Smarty.png'
import Guru from '../../image/GuruPanel/07-Guru.png'
import Empty from '../../image/GuruPanel/empty.png'
import Api from "../../Service/Api";
import Slider from "react-slick";

export default class GuruPanel extends React.Component {

    state = {
        windowWidth: window.innerWidth,
    }

    Api = new Api();

    //Отлов события ресайз
    handlerResize = () => {
        this.setState({windowWidth: window.innerWidth})
    }

    componentDidMount() {
        window.addEventListener('resize', this.handlerResize)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handlerResize);
    }

    _renderEl = () => {
        const {user} = this.props;
        const {windowWidth} = this.state;
        const userRank = user !== undefined ? user.rank : 0
        let elements = {
            0: {
                img: Newbie,
                name: 'новичок',
            },
            1: {
                img: Specialist,
                name: 'специалист',
            },
            2: {
                img: Professional,
                name: 'профессионал',
            },
            3: {
                img: Master,
                name: 'мастер',
            },
            4: {
                img: Weisedog,
                name: 'знаток',
            },
            5: {
                img: Smarty,
                name: 'мудрец',
            },
            6: {
                img: Guru,
                name: 'гуру',
            }
        }

        if (windowWidth > 500) {
            // eslint-disable-next-line
            return Object.values(elements).map((el, index) => {
                if (index >= 0) {
                    return (
                        <Element key={index} active={user !== undefined && user.rank >= index} img={el.img} name={el.name}/>
                    )
                }
            })
        } else {
            return (
                // <ElementMobile active={userRank} img={''} name={''}/>
                <ElementMobile active={userRank} img={elements[userRank].img} name={elements[userRank].name}/>
            )
        }

    }

    render() {
        const {user} = this.props
        const {windowWidth} = this.state;
        let settings = {
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: 7,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 6,
                    }
                },
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 5,
                    }
                },
                {
                    breakpoint: 900,
                    settings: {
                        slidesToShow: 4,
                    }
                },
                {
                    breakpoint: 700,
                    settings: {
                        slidesToShow: 3,
                    }
                },
                {
                    breakpoint: 500,
                    settings: {
                        slidesToShow: 2,
                    }
                },
                {
                    breakpoint: 420,
                    settings: {
                        slidesToShow: 1,
                        initialSlide: user !== undefined ? parseInt(user.rank) : 1,
                        centerMode: true
                    }
                },
            ]
        };
        return (
            <div className="guru">
                {windowWidth > 500
                    ?
                    <Slider {...settings}>
                        {this._renderEl()}
                    </Slider>
                    :
                    <div className="guru_wrap">
                        {this._renderEl()}
                    </div>
                }
            </div>
        )
    }
}
