import React from 'react'
import './style.sass'

export default props => {
    /*const swipeBlock = props.swipeBlock
    const infoBlockMobile = props.infoBlockMobile
    if (swipeBlock && window.innerWidth < 1024) {
        if (infoBlockMobile && window.innerWidth < 768) {
            return (
                <div className="InfoBlock">
                    <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M17.7665 0.999331C7.82737 1.11672 -0.116107 9.29498 0.00128454 19.2341C0.118676 29.1732 8.29694 37.1167 18.2361 36.9993C28.1752 36.8819 36.1187 28.7037 36.0013 18.7645C35.8839 8.82542 27.7056 0.88194 17.7665 0.999331ZM19.7622 6.98629C21.6013 6.98629 22.1491 8.04281 22.1491 9.25585C22.1491 10.7819 20.9361 12.1906 18.8622 12.1906C17.1404 12.1906 16.3187 11.3298 16.3578 9.88194C16.3187 8.6689 17.3361 6.98629 19.7622 6.98629ZM15.0665 30.2689C13.8143 30.2689 12.9143 29.5254 13.7752 26.1602L15.223 20.2515C15.4578 19.3124 15.4969 18.9211 15.223 18.9211C14.8317 18.9211 13.2274 19.5863 12.2491 20.2124L11.623 19.195C14.6752 16.6515 18.1578 15.1645 19.6448 15.1645C20.8969 15.1645 21.0926 16.6515 20.4665 18.9211L18.823 25.1428C18.5491 26.2385 18.6665 26.6298 18.9404 26.6298C19.3317 26.6298 20.5448 26.1602 21.7578 25.2211L22.4622 26.1602C19.5274 29.095 16.3187 30.2689 15.0665 30.2689Z"
                            fill="#99BFDE"/>
                    </svg>
                    <span className="swipeText" dangerouslySetInnerHTML={{ __html: props.textMobile }}/>
                </div>
            )
        }
        return (
            <div className="InfoBlock">
                <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M33.5139 28.1208C33.5158 26.1778 33.5179 24.7367 33.5191 23.5595C33.5233 20.3913 33.5248 19.2047 33.5167 15.7178C33.5142 14.5976 32.6791 13.6849 31.6554 13.6849H31.6005C30.5741 13.6849 29.7422 14.6012 29.7422 15.725V17.3154C29.7422 17.6566 29.4657 17.9331 29.1245 17.9331C28.7834 17.9331 28.5068 17.6566 28.5068 17.3154V14.378C28.5068 13.2545 27.6831 12.3403 26.6567 12.3403C25.6246 12.3403 24.8006 13.2545 24.8006 14.378V17.4916C24.8006 17.8327 24.524 18.1093 24.1828 18.1093C23.8417 18.1093 23.5651 17.8327 23.5651 17.4916V13.5235C23.5651 12.3997 22.7052 11.5229 21.6791 11.5229H21.6206C20.6205 11.5229 19.7817 12.3541 19.7817 13.4381V17.9054C19.7817 18.2465 19.5051 18.5231 19.1639 18.5231C18.8228 18.5231 18.5462 18.2465 18.5462 17.9054V13.5672C18.5462 13.5525 18.5447 13.538 18.5447 13.5226C18.5447 13.4873 18.5462 13.4523 18.5462 13.4173V3.27277C18.5462 2.14925 17.7005 1.23535 16.6747 1.23535C15.6486 1.23535 14.8032 2.14895 14.8026 3.27156L14.7908 20.0942C14.7905 20.3551 14.6264 20.5877 14.3809 20.6754C14.1354 20.7632 13.8612 20.6875 13.6956 20.486L11.6872 18.0408C11.1651 17.3863 10.3942 16.9782 9.55958 16.9143C8.70742 16.8575 8.22057 16.9966 7.31978 17.7283L6.95361 18.0294L13.901 31.372C14.9932 33.47 17.0572 34.7642 19.2876 34.7642H27.3381C30.74 34.7642 33.5103 31.7891 33.5139 28.1208Z" fill="#99BFDE"/>
                    <path d="M21.9436 6.11836H29.9494L28.514 7.55375C28.3542 7.70909 28.2902 7.93831 28.347 8.15367C28.4037 8.36932 28.572 8.53763 28.7876 8.59433C29.003 8.65073 29.2322 8.58679 29.3875 8.42693L31.8683 5.94644C32.1096 5.70515 32.1096 5.31395 31.8683 5.07296L29.3875 2.59247C29.1459 2.35359 28.7568 2.3548 28.5168 2.59488C28.2764 2.83527 28.2752 3.22436 28.514 3.46595L29.931 4.88294H21.9436C21.6025 4.88294 21.3259 5.15953 21.3259 5.50065C21.3259 5.84178 21.6025 6.11836 21.9436 6.11836Z" fill="#99BFDE"/>
                    <path d="M3.35353 6.11836H11.2882C11.6293 6.11836 11.9059 5.84178 11.9059 5.50065C11.9059 5.15953 11.6293 4.88294 11.2882 4.88294H3.37193L4.78892 3.46595C5.02781 3.22436 5.0266 2.83527 4.78621 2.59488C4.54612 2.3548 4.15704 2.35359 3.91544 2.59247L1.43465 5.07296C1.19366 5.31426 1.19366 5.70545 1.43465 5.94644L3.91544 8.42693C4.07108 8.58468 4.2994 8.64681 4.51355 8.58981C4.7277 8.5331 4.89479 8.36571 4.9518 8.15156C5.0088 7.93741 4.94667 7.70939 4.78892 7.55345L3.35353 6.11836Z" fill="#99BFDE"/>
                </svg>
                <span className="swipeText" dangerouslySetInnerHTML={{ __html: props.swipeText }}/>
            </div>
        )
    }*/
    return (
        <div className={"InfoBlock " + (props.danger !== undefined && "InfoBlock_danger") + props.className + props.hidden}>
            {props.danger === undefined ?
                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14.8044 0.00107037C6.52234 0.0988895 -0.0967487 6.91362 0.00107037 15.1956C0.0988895 23.4776 6.91362 30.0967 15.1956 29.9989C23.4777 29.9011 30.0967 23.0864 29.9989 14.8044C29.9011 6.52234 23.0864 -0.0967487 14.8044 0.00107037ZM16.4673 4.98984C17.9998 4.98984 18.4563 5.87022 18.4563 6.88101C18.4563 8.15266 17.4455 9.32649 15.7173 9.32649C14.2827 9.32649 13.5979 8.60915 13.6305 7.40271C13.5979 6.39192 14.4457 4.98984 16.4673 4.98984ZM12.5545 24.3906C11.5111 24.3906 10.7612 23.7711 11.4785 20.967L12.6849 16.0434C12.8806 15.2608 12.9132 14.9348 12.6849 14.9348C12.3589 14.9348 11.022 15.4891 10.2069 16.0108L9.68516 15.163C12.2285 13.0436 15.1304 11.8046 16.3695 11.8046C17.4129 11.8046 17.5759 13.0436 17.0542 14.9348L15.6847 20.1192C15.4565 21.0322 15.5543 21.3582 15.7826 21.3582C16.1086 21.3582 17.1194 20.967 18.1302 20.1844L18.7171 20.967C16.2716 23.4124 13.5979 24.3906 12.5545 24.3906Z" fill="#C6C6C6"/>
                </svg>
                :
                <svg width="36" height="32" viewBox="0 0 36 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M21.0051 1.72973C19.6695 -0.576574 16.3305 -0.576579 14.9949 1.72973L0.470073 26.8108C-0.865541 29.1171 0.803973 32 3.4752 32H32.5248C35.196 32 36.8655 29.1171 35.5299 26.8108L21.0051 1.72973ZM17.832 8.88887C16.8472 8.88887 16.0488 9.6848 16.0488 10.6666V21.3333C16.0488 22.3152 16.8472 23.1111 17.832 23.1111C18.8169 23.1111 19.6152 22.3152 19.6152 21.3333V10.6666C19.6152 9.6848 18.8169 8.88887 17.832 8.88887ZM17.832 28.4444C18.8169 28.4444 19.6152 27.6485 19.6152 26.6666C19.6152 25.6848 18.8169 24.8889 17.832 24.8889C16.8472 24.8889 16.0488 25.6848 16.0488 26.6666C16.0488 27.6485 16.8472 28.4444 17.832 28.4444Z" fill="#BD4E3B"/>
                </svg>

            }
            <span dangerouslySetInnerHTML={{ __html: props.text }}/>
        </div>
    )
}
