import React from 'react'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock'
// import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock'
import Answer from './dnd/Answer';
import Questions from './dnd/Questions';

import img1 from '../../../image/lessons/lesson34/step10-1.png'
import img1xs from '../../../image/lessons/lesson34/step10-1xs.png'
import img1xs_icon from '../../../image/lessons/lesson34/step10-1xs-icon.png'
import img2 from '../../../image/lessons/lesson34/step10-2.png'
import img2xs from '../../../image/lessons/lesson34/step10-2xs.png'
import img2xs_icon from '../../../image/lessons/lesson34/step10-2xs-icon.png'
import img3 from '../../../image/lessons/lesson34/step10-3.png'
import img3xs from '../../../image/lessons/lesson34/step10-3xs.png'
import img3xs_icon from '../../../image/lessons/lesson34/step10-3xs-icon.png'
import img4 from '../../../image/lessons/lesson34/step10-4.png'
import img4xs from '../../../image/lessons/lesson34/step10-4xs.png'
import img4xs_icon from '../../../image/lessons/lesson34/step10-4xs-icon.png'
import img5 from '../../../image/lessons/lesson34/step10-5.png'
import img5xs from '../../../image/lessons/lesson34/step10-5xs.png'
import img5xs_icon from '../../../image/lessons/lesson34/step10-5xs-icon.png'
import img6 from '../../../image/lessons/lesson34/step10-6.png'
import img6xs from '../../../image/lessons/lesson34/step10-6xs.png'
import img6xs_icon from '../../../image/lessons/lesson34/step10-6xs-icon.png'
import img7 from '../../../image/lessons/lesson34/step10-7.png'
import img7xs from '../../../image/lessons/lesson34/step10-7xs.png'
import img7xs_icon from '../../../image/lessons/lesson34/step10-7xs-icon.png'
import img8 from '../../../image/lessons/lesson34/step10-8.png'
import img8xs from '../../../image/lessons/lesson34/step10-8xs.png'
import img8xs_icon from '../../../image/lessons/lesson34/step10-8xs-icon.png'

export default class step10 extends React.Component {

    state = {
        // Ресайз
        windowWidth: window.innerWidth,

        // Работа десктопной версии
        answers: [],
        questions: [],
        correct: '',
        answerCorrect: [],

        //Работа мобильной версии
        answerActive: 0,
        questionActive: 0,
        answersMobile: [],
        questionsMobile: [],
        answersSelected: ['?', '?', '?', '?', '?', '?', '?', '?', '?', '?', '?', '?', '?', '?', '?', '?', '?', '?'],
        correctMobile: '',
        answerCorrectMobile: [],
        showPopup: false
    }

    //Отлов события ресайз
    handlerResize = () => {
        this.setState({windowWidth: window.innerWidth})
    }

    componentDidMount() {
        window.addEventListener('resize', this.handlerResize)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handlerResize);
    }

    //Работа десктопной версии
    questionsData = [
        `здоровый рост`,
        `здоровое развитие костей`,
        `здоровое развитие зрения и мозга`,
        `здоровое сердце`,
        `жизненная сила`,
        `здоровье зубов`,
        `здоровая микрофлора кишечника`,
        `без добавления пшеницы и сои`,
        `высокая усвояемость`,
        `поддержание здорового веса`,
        `способствует уменьшению неприятного запаха`,
        `гладкая шерсть и здоровая кожа`,
        `здоровье мочевыводящей системы`,
        `контроль веса`,
        `здоровый обмен веществ`,
        `здоровое сердце`,
        `здоровье мочевыводящей системы`,
        `подвижность суставов`,
        `здоровая кожа и красивая шерсть`,
        `контроль образования волосяных комочков шерсти`,
        `способствует сокращению выпадения шерсти`,
        `поддержание функции почек`,
        `здоровье мочевыводящей системы`,
        `поддержание жизненноважных функций`,
    ]
    setAnswer = (answer_el, question_el) => {
        const {answers} = this.state

        if (answers.indexOf(question_el) === -1) {
            let answer = []
            answers[answer_el] = question_el
            answers.push(answer)
        }
        this.setState({answers})

        let questions = [];
        // eslint-disable-next-line
        answers.map(answer => {

            if (answer.length) {
                questions.push(answer)
            }

        });
        this.setState({questions})
    };

    checkAnswer = () => {
        const {answerCorrect, questions} = this.state
        // eslint-disable-next-line
        questions.map((answer, index) => {
            if (answer === String(index + 1)) {
                answerCorrect.push('correct')
            } else if (index === 3 || index === 15) {
                if (answer === '4' || answer === '16') {
                    answerCorrect.push('correct')
                } else {
                    answerCorrect.push('inCorrect')
                }
            } else if (index === 12 || index === 16 || index === 22) {
                if (answer === '13' || answer === '17' || answer === '23') {
                    answerCorrect.push('correct')
                } else {
                    answerCorrect.push('inCorrect')
                }
            } else {
                answerCorrect.push('inCorrect')
            }
        })
        this.setState({
            answerCorrect: answerCorrect,
        })
        if (questions.length === 24 && answerCorrect.indexOf('inCorrect') === -1) {
            this.setState({correct: '1'})
        } else {
            this.setState({correct: '0'})
        }
        this.props.showNextBtn()
    };

    _renderTestDesktop = () => {
        const {answerCorrect, correct, questions} = this.state
        return (
            <div className="test_desktop">
                {correct !== '' ?
                    <div className={'info ' + (correct === '0' && 'error')}>
                        <div className="info_head">
                            {correct === '1' ? 'все верно!' : 'К сожалению, ваш ответ не верен.'}
                        </div>
                        <div className="infoText">
                            {correct === '1'
                                ?
                                'Вы верно определили индивидуальные особенности каждого рациона PERFECT FIT™ для кошек! Нажмите «Продолжить» и перейдите к следующему заданию.'
                                :
                                'Вы не верно определили индивидуальные особенности каждого рациона PERFECT FIT™ для кошек! Нажмите «Продолжить» и перейдите к следующему заданию.'
                            }
                        </div>
                    </div>
                    :
                    <button className="btn btn_violet" onClick={() => this.checkAnswer()}>
                        ПРОВЕРИТЬ
                    </button>
                }
                <div className="flex">
                    <div className="answers">
                        <div className="answers_type">
                            <img src={img1} alt="" className="answers_type_img"/>
                            <div className="answers_item">
                                <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'1'} text={'?'} count={'?'} answerCorrect={answerCorrect[0]}/>
                                <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'2'} text={'?'} count={'?'} answerCorrect={answerCorrect[1]}/>
                                <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'3'} text={'?'} count={'?'} answerCorrect={answerCorrect[2]}/>
                            </div>
                        </div>
                        <div className="answers_type">
                            <img src={img2} alt="" className="answers_type_img"/>
                            <div className="answers_item">
                                <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'4'} text={'?'} count={'?'} answerCorrect={answerCorrect[3]}/>
                                <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'5'} text={'?'} count={'?'} answerCorrect={answerCorrect[4]}/>
                                <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'6'} text={'?'} count={'?'} answerCorrect={answerCorrect[5]}/>
                            </div>
                        </div>
                        <div className="answers_type">
                            <img src={img3} alt="" className="answers_type_img"/>
                            <div className="answers_item">
                                <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'7'} text={'?'} count={'?'} answerCorrect={answerCorrect[6]}/>
                                <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'8'} text={'?'} count={'?'} answerCorrect={answerCorrect[7]}/>
                                <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'9'} text={'?'} count={'?'} answerCorrect={answerCorrect[8]}/>
                            </div>
                        </div>
                        <div className="answers_type">
                            <img src={img4} alt="" className="answers_type_img"/>
                            <div className="answers_item">
                                <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'10'} text={'?'} count={'?'} answerCorrect={answerCorrect[9]}/>
                                <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'11'} text={'?'} count={'?'} answerCorrect={answerCorrect[10]}/>
                                <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'12'} text={'?'} count={'?'} answerCorrect={answerCorrect[11]}/>
                            </div>
                        </div>
                        <div className="answers_type">
                            <img src={img5} alt="" className="answers_type_img"/>
                            <div className="answers_item">
                                <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'13'} text={'?'} count={'?'} answerCorrect={answerCorrect[12]}/>
                                <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'14'} text={'?'} count={'?'} answerCorrect={answerCorrect[13]}/>
                                <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'15'} text={'?'} count={'?'} answerCorrect={answerCorrect[14]}/>
                            </div>
                        </div>
                        <div className="answers_type">
                            <img src={img6} alt="" className="answers_type_img"/>
                            <div className="answers_item">
                                <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'16'} text={'?'} count={'?'} answerCorrect={answerCorrect[15]}/>
                                <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'17'} text={'?'} count={'?'} answerCorrect={answerCorrect[16]}/>
                                <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'18'} text={'?'} count={'?'} answerCorrect={answerCorrect[17]}/>
                            </div>
                        </div>
                        <div className="answers_type">
                            <img src={img7} alt="" className="answers_type_img"/>
                            <div className="answers_item">
                                <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'19'} text={'?'} count={'?'} answerCorrect={answerCorrect[18]}/>
                                <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'20'} text={'?'} count={'?'} answerCorrect={answerCorrect[19]}/>
                                <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'21'} text={'?'} count={'?'} answerCorrect={answerCorrect[20]}/>
                            </div>
                        </div>
                        <div className="answers_type">
                            <img src={img8} alt="" className="answers_type_img"/>
                            <div className="answers_item">
                                <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'22'} text={'?'} count={'?'} answerCorrect={answerCorrect[21]}/>
                                <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'23'} text={'?'} count={'?'} answerCorrect={answerCorrect[22]}/>
                                <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'24'} text={'?'} count={'?'} answerCorrect={answerCorrect[23]}/>
                            </div>
                        </div>
                    </div>
                    <div className="questions">
                        {this.questionsData.map((item, index) => {
                            return (
                                <div key={index} className={'question_el ' + (questions.indexOf(String(index + 1)) >= 0 && 'hidden')}>
                                    <Questions targetKey="1" question_el={String(index + 1)} label={item} text={item}/>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        )
    }

    //Работа мобильной версии
    questions = [
        `здоровый рост`,
        `здоровое развитие костей`,
        `здоровое развитие зрения и мозга`,
        `здоровое сердце`,
        `жизненная сила`,
        `здоровье зубов`,
        `здоровая микрофлора кишечника`,
        `без добавления пшеницы и сои`,
        `высокая усвояемость`,
        `поддержание здорового веса`,
        `способствует уменьшению неприятного запаха`,
        `гладкая шерсть и здоровая кожа`,
        `здоровье мочевыводящей системы`,
        `контроль веса`,
        `здоровый обмен веществ`,
        `здоровое сердце`,
        `здоровье мочевыводящей системы`,
        `подвижность суставов`,
        `здоровая кожа и красивая шерсть`,
        `контроль образования волосяных комочков шерсти`,
        `способствует сокращению выпадения шерсти`,
        `поддержание функции почек`,
        `здоровье мочевыводящей системы`,
        `поддержание жизненноважных функций`,
    ]

    selectAnswer = (answerActive) => {
        document.body.scrollIntoView( true);
        document.body.style.overflow = "hidden";
        this.setState({
            showPopup: true,
            answerActive: answerActive
        })
    }

    selectQuestion = (questionActive) => {
        const {answerActive, answersMobile, questionsMobile, answersSelected} = this.state

        if (answersMobile.indexOf(answerActive) === -1) {
            answersMobile.push(answerActive)
        }
        this.setState({answersMobile})

        if (questionsMobile.indexOf(questionsMobile) === -1) {
            questionsMobile.push(questionActive)
        }
        this.setState({questionsMobile})

        answersSelected.splice(answerActive - 1, 1, this.questions[questionActive - 1])

        document.body.style.overflow = "";
        this.setState({
            showPopup: false,
            questionActive: questionActive,
            answersSelected: answersSelected
        })
    }

    checkAnswerMobile = () => {
        const {answerCorrectMobile, answersSelected} = this.state
        // eslint-disable-next-line
        answersSelected.map((answer, index) => {
            if (answer === this.questions[index]) {
                answerCorrectMobile.push('correct')
            } else if (index === 3 || index === 15) {
                if (answer === this.questions[3] || answer === this.questions[15]) {
                    answerCorrectMobile.push('correct')
                } else {
                    answerCorrectMobile.push('inCorrect')
                }
            } else if (index === 12 || index === 16 || index === 22) {
                if (answer === this.questions[12] || answer === this.questions[16] || answer === this.questions[22]) {
                    answerCorrectMobile.push('correct')
                } else {
                    answerCorrectMobile.push('inCorrect')
                }
            } else {
                answerCorrectMobile.push('inCorrect')
            }
        })
        this.setState({
            answerCorrect: answerCorrectMobile,
        })
        if (answerCorrectMobile.indexOf('?') === -1 && answerCorrectMobile.indexOf('inCorrect') === -1) {
            this.setState({correctMobile: '1'})
        } else {
            this.setState({correctMobile: '0'})
        }
        this.props.showNextBtn()
    }

    closePopup = () => {
        document.body.style.overflow = "";
        this.setState({showPopup: false})
    }

    _renderTestMobile = () => {
        const {showPopup, answersMobile, questionsMobile, answersSelected, correctMobile, answerCorrectMobile} = this.state
        return (
            <div className="test_mobile">
                {correctMobile !== '' ?
                    <div className={'info ' + (correctMobile === '0' && 'error')}>
                        <div className="info_head">
                            {correctMobile === '1' ? 'все верно!' : 'К сожалению, ваш ответ не верен.'}
                        </div>
                        <div className="infoText">
                            {correctMobile === '1'
                                ?
                                `Вы верно определили индивидуальные особенности каждого рациона PERFECT FIT™ для кошек! 
                                
                                Нажмите «Продолжить» и перейдите к следующему заданию.`
                                :
                                'Вы неверно определили индивидуальные особенности каждого рациона PERFECT FIT™ для кошек! ' +

                                'Нажмите «Продолжить» и перейдите к следующему заданию.'
                            }
                        </div>
                    </div>
                    :
                    <button className="btn btn_violet" onClick={() => this.checkAnswerMobile()}>
                        ПРОВЕРИТЬ
                    </button>
                }
                <div className="answers">
                    <div className="answers_type">
                        <img src={img1xs} alt="" className="answers_type_img"/>
                        <div className="answers_flex">
                            <img src={img1xs_icon} alt="" className="answers_type_icon"/>
                            <div className="answers_flex_box">
                                <div
                                    className={'answers_item ' + (answersMobile.indexOf(1) >=0 ? 'selected ' : '') + answerCorrectMobile[0]}
                                    onClick={() => {this.selectAnswer(1)}}
                                >
                                    {answersSelected[0]}
                                </div>
                                <div
                                    className={'answers_item ' + (answersMobile.indexOf(2) >=0 ? 'selected ' : '') + answerCorrectMobile[1]}
                                    onClick={() => {this.selectAnswer(2)}}
                                >
                                    {answersSelected[1]}
                                </div>
                                <div
                                    className={'answers_item ' + (answersMobile.indexOf(3) >=0 ? 'selected ' : '') + answerCorrectMobile[2]}
                                    onClick={() => {this.selectAnswer(3)}}
                                >
                                    {answersSelected[2]}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="answers_type">
                        <img src={img2xs} alt="" className="answers_type_img"/>
                        <div className="answers_flex">
                            <img src={img2xs_icon} alt="" className="answers_type_icon"/>
                            <div className="answers_flex_box">
                                <div
                                    className={'answers_item ' + (answersMobile.indexOf(4) >=0 ? 'selected ' : '') + answerCorrectMobile[3]}
                                    onClick={() => {this.selectAnswer(4)}}
                                >
                                    {answersSelected[3]}
                                </div>
                                <div
                                    className={'answers_item ' + (answersMobile.indexOf(5) >=0 ? 'selected ' : '') + answerCorrectMobile[4]}
                                    onClick={() => {this.selectAnswer(5)}}
                                >
                                    {answersSelected[4]}
                                </div>
                                <div
                                    className={'answers_item ' + (answersMobile.indexOf(6) >=0 ? 'selected ' : '') + answerCorrectMobile[5]}
                                    onClick={() => {this.selectAnswer(6)}}
                                >
                                    {answersSelected[5]}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="answers_type">
                        <img src={img3xs} alt="" className="answers_type_img"/>
                        <div className="answers_flex">
                            <img src={img3xs_icon} alt="" className="answers_type_icon"/>
                            <div className="answers_flex_box">
                                <div
                                    className={'answers_item ' + (answersMobile.indexOf(7) >=0 ? 'selected ' : '') + answerCorrectMobile[6]}
                                    onClick={() => {this.selectAnswer(7)}}
                                >
                                    {answersSelected[6]}
                                </div>
                                <div
                                    className={'answers_item ' + (answersMobile.indexOf(8) >=0 ? 'selected ' : '') + answerCorrectMobile[7]}
                                    onClick={() => {this.selectAnswer(8)}}
                                >
                                    {answersSelected[7]}
                                </div>
                                <div
                                    className={'answers_item ' + (answersMobile.indexOf(9) >=0 ? 'selected ' : '') + answerCorrectMobile[8]}
                                    onClick={() => {this.selectAnswer(9)}}
                                >
                                    {answersSelected[8]}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="answers_type">
                        <img src={img4xs} alt="" className="answers_type_img"/>
                        <div className="answers_flex">
                            <img src={img4xs_icon} alt="" className="answers_type_icon"/>
                            <div className="answers_flex_box">
                                <div
                                    className={'answers_item ' + (answersMobile.indexOf(10) >=0 ? 'selected ' : '') + answerCorrectMobile[9]}
                                    onClick={() => {this.selectAnswer(10)}}
                                >
                                    {answersSelected[9]}
                                </div>
                                <div
                                    className={'answers_item ' + (answersMobile.indexOf(11) >=0 ? 'selected ' : '') + answerCorrectMobile[10]}
                                    onClick={() => {this.selectAnswer(11)}}
                                >
                                    {answersSelected[10]}
                                </div>
                                <div
                                    className={'answers_item ' + (answersMobile.indexOf(12) >=0 ? 'selected ' : '') + answerCorrectMobile[11]}
                                    onClick={() => {this.selectAnswer(12)}}
                                >
                                    {answersSelected[11]}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="answers_type">
                        <img src={img5xs} alt="" className="answers_type_img"/>
                        <div className="answers_flex">
                            <img src={img5xs_icon} alt="" className="answers_type_icon"/>
                            <div className="answers_flex_box">
                                <div
                                    className={'answers_item ' + (answersMobile.indexOf(13) >=0 ? 'selected ' : '') + answerCorrectMobile[12]}
                                    onClick={() => {this.selectAnswer(13)}}
                                >
                                    {answersSelected[12]}
                                </div>
                                <div
                                    className={'answers_item ' + (answersMobile.indexOf(14) >=0 ? 'selected ' : '') + answerCorrectMobile[13]}
                                    onClick={() => {this.selectAnswer(14)}}
                                >
                                    {answersSelected[13]}
                                </div>
                                <div
                                    className={'answers_item ' + (answersMobile.indexOf(15) >=0 ? 'selected ' : '') + answerCorrectMobile[14]}
                                    onClick={() => {this.selectAnswer(15)}}
                                >
                                    {answersSelected[14]}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="answers_type">
                        <img src={img6xs} alt="" className="answers_type_img"/>
                        <div className="answers_flex">
                            <img src={img6xs_icon} alt="" className="answers_type_icon"/>
                            <div className="answers_flex_box">
                                <div
                                    className={'answers_item ' + (answersMobile.indexOf(16) >=0 ? 'selected ' : '') + answerCorrectMobile[15]}
                                    onClick={() => {this.selectAnswer(16)}}
                                >
                                    {answersSelected[15]}
                                </div>
                                <div
                                    className={'answers_item ' + (answersMobile.indexOf(17) >=0 ? 'selected ' : '') + answerCorrectMobile[16]}
                                    onClick={() => {this.selectAnswer(17)}}
                                >
                                    {answersSelected[16]}
                                </div>
                                <div
                                    className={'answers_item ' + (answersMobile.indexOf(18) >=0 ? 'selected ' : '') + answerCorrectMobile[17]}
                                    onClick={() => {this.selectAnswer(18)}}
                                >
                                    {answersSelected[17]}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="answers_type">
                        <img src={img7xs} alt="" className="answers_type_img"/>
                        <div className="answers_flex">
                            <img src={img7xs_icon} alt="" className="answers_type_icon"/>
                            <div className="answers_flex_box">
                                <div
                                    className={'answers_item ' + (answersMobile.indexOf(19) >=0 ? 'selected ' : '') + answerCorrectMobile[18]}
                                    onClick={() => {this.selectAnswer(19)}}
                                >
                                    {answersSelected[18]}
                                </div>
                                <div
                                    className={'answers_item ' + (answersMobile.indexOf(20) >=0 ? 'selected ' : '') + answerCorrectMobile[19]}
                                    onClick={() => {this.selectAnswer(20)}}
                                >
                                    {answersSelected[19]}
                                </div>
                                <div
                                    className={'answers_item ' + (answersMobile.indexOf(21) >=0 ? 'selected ' : '') + answerCorrectMobile[20]}
                                    onClick={() => {this.selectAnswer(21)}}
                                >
                                    {answersSelected[20]}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="answers_type">
                        <img src={img8xs} alt="" className="answers_type_img"/>
                        <div className="answers_flex">
                            <img src={img8xs_icon} alt="" className="answers_type_icon"/>
                            <div className="answers_flex_box">
                                <div
                                    className={'answers_item ' + (answersMobile.indexOf(22) >=0 ? 'selected ' : '') + answerCorrectMobile[21]}
                                    onClick={() => {this.selectAnswer(22)}}
                                >
                                    {answersSelected[21]}
                                </div>
                                <div
                                    className={'answers_item ' + (answersMobile.indexOf(23) >=0 ? 'selected ' : '') + answerCorrectMobile[22]}
                                    onClick={() => {this.selectAnswer(23)}}
                                >
                                    {answersSelected[22]}
                                </div>
                                <div
                                    className={'answers_item ' + (answersMobile.indexOf(24) >=0 ? 'selected ' : '') + answerCorrectMobile[23]}
                                    onClick={() => {this.selectAnswer(24)}}
                                >
                                    {answersSelected[23]}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {showPopup &&
                <div className="popup">
                    <div className="popup_wrp">
                        <div
                            className="popup_closed"
                            onClick={() => {this.closePopup()}}
                        />
                        <div className="popup_flex">
                            <div className="questions">
                                {this.questions.map((item, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className={'questions_item ' + (questionsMobile.indexOf(index + 1) >=0 ? 'selected ' : '')}
                                            onClick={() => {this.selectQuestion(index + 1)}}
                                        >
                                            {item}
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                }
            </div>
        )
    }

    render() {
        const {windowWidth} = this.state
        return (
            <div className="step10">
                <InfoBlock
                    text={`Вы изучили индивидуальные особенности рационов Perfect Fit™ для кошек. 
                    Теперь Вам предстоит сопоставить рацион с его индивидуальными особенностями`}
                />
                {windowWidth >= 768 ?
                    <div className="test">
                        {this._renderTestDesktop()}
                    </div>
                    :
                    <div className="test">
                        {this._renderTestMobile()}
                    </div>
                }
            </div>
        )
    }
}
