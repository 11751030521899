import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import OnClickTest from "../../../Components/LessonAndTestComponents/OnClickTest";

import img1 from "../../../image/lessons/lesson7/step32-1.png";


export default class step32 extends React.Component {

    state = {
        answersData:
            [
                `Да`,
                `Нет`,
            ]
    }

    showNextBtn = () => {
        this.props.showNextBtn()
    }

    render() {
        return (
            <div className="step32">
                <TextBlock
                    text={`Определите, иллюстрируют ли следующие картинки конфликт между кошками:`}
                />
                <img src={img1} alt="" className="imgCenter"/>
                <OnClickTest
                    ansewrsData={this.state.answersData}
                    type={'block'}
                    rightAnswers={[2]}
                    showNextBtn={this.showNextBtn}
                    quantity={50}
                    correctText={`Данная картинка иллюстрирует дружелюбное поведение кошек: когда кошки ведут себя дружелюбно, они часто держат хвост прямо, направляют усы вперед и трутся подбородком или головой о других кошек или людей. Кошки делают это, когда приветствуют друг друга или когда уверенно исследуют что-то новое.`}
                />
            </div>
        )
    }
}
