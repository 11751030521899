import React from 'react'
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";

export default class step39 extends React.Component{

    render() {
        return (
            <div className="step39">
                <div className="head">
                    Обращение с кошкой в ветеринарной клинике
                </div>
                <TextBlock
                    text={`Существует много видов оборудования, позволяющего проводить эффективное обследование. Используйте его, когда более щадящие методы не дают необходимого эффекта.
                    Внимательно следите за реакцией кошки.
                    Дезинфицируйте материалы между использованием.
                    
                    <b>Вспомогательные средства:</b>`}
                />
                <ul className="list">
                    <li className="list_item">
                        Полотенца или намордники, закрывающие глаза, могут успокоить кошек.
                    </li>
                    <li className="list_item">
                        Перчатки могут в какой-то степени защитить руки и кисти ветеринарного врача.
                    </li>
                    <li className="list_item">
                        Сети следует использовать только в редких случаях, например, когда кошке трудно выбраться из клетки, или чтобы поймать вырвавшуюся кошку.
                    </li>
                </ul>
                <TextBlock
                    text={`Сумки для обследования могут обеспечить доступ к передним лапам - для венепункции и дорсальной области плеча - для подкожных инъекций.
                    В достаточно плотно прилегающем полотенце кошки чувствуют себя более комфортно.`}
                />
            </div>
        )
    }
}
