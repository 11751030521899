import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

export default class step4 extends React.Component {

    render() {
        return (
            <div className="step4">
                <div className="head">
                    Взаимодействие с владельцем
                </div>
                <TextBlock
                    text={`Большинство владельцев кошек могут легко определить, кто из ветеринарных врачей действительно вовлечен в процесс лечения и проявляет сочувствие к находящимся на лечении кошкам. Ветеринарные врачи, применяющие как накопленный опыт и знания, так и науку ветеринарного ухода, не только смогут обеспечить оптимальное ветеринарное обслуживание для кошки, но также заслужат доверие и признательность своих клиентов.
                    
                    <b>Вовлеченность владельца кошки в процесс является ключевым аспектом успешного ухода за кошками и требует специального обучения у клиентов, надлежащего руководства и соответствующей поддержки со стороны ветеринарных врачей.</b>`}
                />
            </div>
        )
    }
}
