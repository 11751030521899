import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import PlanInFirstStep from '../../../Components/LessonAndTestComponents/PlanInFirstStep';
import Lecturer from '../../../Components/LessonAndTestComponents/Lecturer';

import img1 from '../../../image/Lecturers/ilina.png'
import img2 from '../../../image/Lecturers/ilina-icon.png'

export default class step1 extends React.Component {

    planData = [
        `Видеолекция: Cat friendly с рождения. Хендлинг неонатов`,
        `Тест`,
    ]

    render() {
        return (
            <div className="step1">
                <Lecturer
                    img={img1}
                    icon={img2}
                    surname={'Ильина'}
                    name={'Юлия Алексеевна '}
                    text={'Руководитель Центра репродукции и неонатологии Европейского ветеринарного центра (EVC), ведущий врач-репродуктолог, врач УЗ-диагностики EVC , г.Москва'}
                />

                <hr className="string"/>

                <div className="head">План урока</div>

                <TextBlock
                    text={`В этом уроке вы узнаете:`}
                />

                <PlanInFirstStep
                    planData={this.planData}
                    type={'violet'}
                />

            </div>
        )
    }
}
