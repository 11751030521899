import React from 'react'
// import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from "../../../image/lessons/lesson13/step22-1.png";

export default class step22 extends React.Component {

    render() {
        return (
            <div className="step13">
                <div className="text-top">
                    <ul>
                        <li>
                            По мере смены времен года меняется и то, что кошка видит за окном. Птицы прилетают и улетают, насекомые жужжат и стрекочут в воздухе и траве, мелкие млекопитающие мечутся по территории участка, а листья опадают. Вид из окна – это все равно, что телевизор для кошки. Можно расположить необходимое количество кошачьих насестов рядом с несколькими окнами в доме с хорошим углом обзора, чтобы кошка могла «переключать каналы».
                        </li>
                        <li>
                            В зимнее время года, когда световой день короткий, можно помочь кошке скоротать время и развлечь ее играми в помещении. Установить аквариум для кошки, чтобы она могла смотреть на рыбок, когда ей скучно, или приобрести специальные видеоролики для кошек, в которых есть звуки резвящихся птиц и шорох мелких грызунов.
                        </li>

                    </ul>
                </div>

                <div className="infoImage">
                    <img src={img1} alt=""/>
                </div>

            </div>
        )
    }
}
