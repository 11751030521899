import React from 'react'
import SliderImgDes from '../../../Components/LessonAndTestComponents/SliderImgDes';

import img1 from '../../../image/lessons/lesson13/step10-1.png';
import img2 from '../../../image/lessons/lesson13/step10-2.png';
import img3 from '../../../image/lessons/lesson13/step10-3.png';

export default class step10 extends React.Component {

    showNextBtn = () => {
        this.props.showNextBtn()
    }

    sliderData = {
        1: {
            text: `<p class="step6-slideP">Когда владелец уезжает в отпуск, кошка либо оказывается дома одна, либо ее отдают на время в гостиницу. Пребывание кошки в гостинице означает не только, что она будет находиться в незнакомой обстановке и контактировать с ранее незнакомыми ей людьми и животными, но и то, что она будет отстранена от своего обычного распорядка дня.</p>
            `,
            img: img1,
        },
        2: {
            text: `    
            <p class="step6-slideP">Поиск сиделки для домашних животных (зооняни), которая может прийти домой и остаться с кошкой или посещать дом ежедневно, является хорошей альтернативой гостинице. Зооняню нужно  познакомить с кошкой за несколько недель до отпуска. Если это возможно, провести несколько встреч, в ходе которых зооняня сможет поиграть с кошкой, чтобы владелец, кошка и зооняня могли лучше узнать друг друга. </p>       
            <p class="step6-slideP">Игровое взаимодействие, включающее в себя вкусные лакомства, может помочь установить позитивные отношения между кошкой и зооняней. Другими словами, если кошка ассоциирует время, проводимое с зооняней, с игрой и угощениями, она будет очень рада ее видеть! </p>  
            <p class="step6-slideP">Также владелец должен убедиться, что зооняня знакома с ежедневным распорядком кошки, а также понимает ее характер и привычки. Можно составить список, который включает в себя расписание кошки.  </p>  
            `,
            img: img2
        },
        3: {
            text: `Создание убежища в доме будет полезно, чтобы обеспечить кошке безопасное, знакомое место, куда она сможет приходить, пока находится одна. Предмет одежды или одеяло с запахом владельца помогут успокоить кошку. Оставьте радио или телевизор включенными рядом с убежищем, чтобы обеспечить успокаивающий «белый шум» для кошки.`,
            img: img3
        },
    }

    render() {
        return (
            <div className="step10">
                <SliderImgDes
                    showNextBtn={this.showNextBtn}
                    sliderData={this.sliderData}
                />
            </div>
        )
    }
}
