import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock'

import img1 from '../../../image/lessons/lesson34/step7-1.png'

export default class step7 extends React.Component {

    render() {
        return (
            <div className="step7">
                <div className="head">
                    Формула «5 слагаемых здоровья»
                </div>
                <div className="flex">
                    <div className="flex_el">
                        <div className="flex_el_box-img">
                            <img src={img1} alt="" className="flex_el_img"/>
                        </div>
                    </div>
                    <div className="flex_el">
                        <div className="flex_el_head">
                            Система профессионального питания
                        </div>
                        <TextBlock
                            text={`PERFECT FIT™ — это система профессионального питания, которая создана специально для поддержания здоровья кошек, чтобы они оставались активными, игривыми и получали максимум от каждого дня на протяжении всей жизни. Благодаря специальной формуле <span class="text_violet">«5 слагаемых здоровья» PERFECT FIT™</span> заботится о важнейших составляющих хорошего самочувствия кошки и помогает ей всегда быть полной жизненных сил.`}
                        />
                    </div>
                </div>
            </div>
        )
    }
}
