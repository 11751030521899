import React from 'react'
// import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from "../../../image/lessons/lesson9/step2-1.png";

export default class step2 extends React.Component {

    render() {
        return (
            <div className="step2">
                <div className="flex">
                    <ul className="list">
                        <li className="list_item">
                            Сделать так, чтобы кошка чувствовала себя в безопасности в клинических условиях и дома после выписки
                        </li>
                        <li className="list_item">
                            Минимизировать стресс для больной кошки во время стационарного и домашнего лечения
                        </li>
                        <li className="list_item">
                            Способствовать успешному выздоровлению после болезни, операции или другого лечения, а также травмы
                        </li>
                    </ul>
                    <img src={img1} alt="" className="flex_img"/>
                </div>
            </div>
        )
    }
}
