import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson10/step14-1.png'


export default class step14 extends React.Component{

    render() {
        return (
            <div className="step14">
                <TextBlock
                    text={`<b>Сделайте все возможное, чтобы свести к минимуму воздействие предоперационного стресса на кошку:</b>`}
                />
                <ul className="list">
                    <li className="list_item">
                        Изолируйте кошку в тихом месте до окончания процедуры введения наркоза;
                    </li>
                    <li className="list_item">
                        Покажите кошке знакомый предмет, например, подстилку, взятую из дома, или любимую игрушку;
                    </li>
                    <li className="list_item">
                        Создайте успокаивающую среду путем минимизации уровня окружающего шума, сведению к минимуму посторонних разговоров либо эксплуатации оборудования и приборов;
                    </li>
                    <li className="list_item">
                        Используйте синтетический лицевой феромон (FFP) в клетке кошки и в процедурном кабинете.
                    </li>
                </ul>
                <TextBlock
                    text={`Поддерживайте надлежащую температуру в предоперационном помещении и теле кошки, а также обеспечьте необходимое обезболивание и гидратацию.
                    
                    Внимательно наблюдайте за животным во время выздоровления, поскольку именно в этот период происходит большинство смертей, связанных с введением анестезии.`}
                />
                <img src={img1} alt="" className="imgCenter"/>
            </div>
        )
    }
}
