import React from 'react'
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";
import noteInside from "../../../Page/NoteInside";


export default class step55 extends React.Component{

    render() {
        return (
            <div className="step55">
                <TextBlock
                    text={`Основные этапы:`}
                />
                <ul className="list">
                    <li className="list_item">
                        Снять верхнюю часть и дверцу переноски.
                    </li>
                    <li className="list_item">
                        Положить любимое одеяло кошки на дно переноски. Создать положительные ассоциации с ней.
                    </li>
                    <li className="list_item">
                        Как только кошка перестанет бояться, можно закрепить верхнюю часть переноски и позволить животному привыкнуть к нахождению в полуоткрытой переноске.
                    </li>
                    <li className="list_item">
                        После того, как кошка адаптировалась, можно присоединить к переноске дверцу.
                    </li>
                    <li className="list_item">
                        Если кошка неохотно пользуется переноской, можно распылить на нее специальные феромоны для кошек.
                    </li>
                </ul>
                {/*<TextBlock*/}
                {/*    text={`Для получения более подробной информации о способах приучения к переноске посетите <a class="text_link" href="noteInside" target="_blank" rel="noopener noreferrer">сайт</a>`}*/}
                {/*/>*/}
            </div>
        )
    }
}
