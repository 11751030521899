import React from 'react'
import './style.sass'


export default class DropDownPanel extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            open: false,
        };
    }
    render () {
        const {open} = this.state
        return (
            <div
                className={open ? 'DropDownPanel open' : 'DropDownPanel'}
                onClick={() => {
                    this.setState({
                        open: !open
                    })
                }}
            >
                <div className="number">{this.props.number}</div>
                <div className="DropDownPanel_wrp">
                    <div className="question">
                        {this.props.question}
                    </div>
                </div>
                <div className="arrow"></div>
                <div className="answer">
                    {this.props.answer}
                </div>
            </div>
        )
    }
}
