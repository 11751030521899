import React from 'react'
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";

import img1 from '../../../image/lessons/lesson32/step10-1.png'
import img2 from '../../../image/lessons/lesson32/step10-2.png'
import img3 from '../../../image/lessons/lesson32/step10-3.png'
import img4 from '../../../image/lessons/lesson32/step9-3.png'

export default class step10 extends React.Component {


    render() {
        return (
            <div className="step10">
                <TextBlock
                    text={`Если в сухом корме используется предварительно высушенное сырье, то во влажном корме — сырье в его естественном состоянии. Сырьевые источники влажного корма представлены в таблице ниже.`}
                />
                <div className="flex flex_header">
                    <div className="flex_el_header">
                        <div className="attention">
                            Тип сырья
                        </div>
                    </div>
                    <div className="flex_el_header">
                        <div className="attention">
                            Сырой материал
                        </div>
                    </div>
                    <div className="flex_el_header">
                        <div className="attention">
                            Функция
                        </div>
                    </div>
                </div>
                <div className="flex">
                    <div className="flex_el">
                        <h3 className="flex_head">
                            Тип сырья:
                        </h3>
                        <div className="flex_wrp">
                            <div className="flex_wrp_head">
                                субпродукты
                            </div>
                            <img className="img" src={img1} alt=""/>
                        </div>
                    </div>
                    <div className="flex_el">
                        <h3 className="flex_head">
                            Сырой материал:
                        </h3>
                        <div className="flex_wrp">
                            <TextBlock
                                text={`печень говяжья
                            печень куриная
                            селезенка
                            почки
                            сердце куриное`}
                            />
                        </div>
                    </div>
                    <div className="flex_el">
                        <h3 className="flex_head">
                            Функция:
                        </h3>
                        <div className="flex_wrp">
                            <TextBlock
                                text={`источник животного белка, незаменимых аминокислот, витамина А`}
                            />
                        </div>
                    </div>
                </div>
                {/*<div className="flex">*/}
                {/*    <div className="flex_el">*/}
                {/*        <h3 className="flex_head">*/}
                {/*            Тип сырья:*/}
                {/*        </h3>*/}
                {/*        <div className="flex_wrp">*/}
                {/*            <div className="flex_wrp_head">*/}
                {/*                мясной фарш*/}
                {/*            </div>*/}
                {/*            <img className="img" src={img2} alt=""/>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*    <div className="flex_el">*/}
                {/*        <h3 className="flex_head">*/}
                {/*            Сырой материал:*/}
                {/*        </h3>*/}
                {/*        <div className="flex_wrp">*/}
                {/*            <TextBlock*/}
                {/*                text={`фарш куриный*/}
                {/*            фарш индейки`}*/}
                {/*            />*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*    <div className="flex_el">*/}
                {/*        <h3 className="flex_head">*/}
                {/*            Функция:*/}
                {/*        </h3>*/}
                {/*        <div className="flex_wrp">*/}
                {/*            <TextBlock*/}
                {/*                text={`источник животного белка и жира, незаменимых аминокислот, источник энергии, улучшение вкуса, усвоение жирорастворимых витаминов`}*/}
                {/*            />*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div className="flex">
                    <div className="flex_el">
                        <h3 className="flex_head">
                            Тип сырья:
                        </h3>
                        <div className="flex_wrp">
                            <div className="flex_wrp_head">
                                мясо
                            </div>
                            <img className="img" src={img3} alt=""/>
                        </div>
                    </div>
                    <div className="flex_el">
                        <h3 className="flex_head">
                            Сырой материал:
                        </h3>
                        <div className="flex_wrp">
                            <TextBlock
                                text={`говядина,
                            ягненок,
                            кролик,
                            креветки,
                            тунец,
                            форель,
                            лосось,
                            утка`}
                            />
                        </div>
                    </div>
                    <div className="flex_el">
                        <h3 className="flex_head">
                            Функция:
                        </h3>
                        <div className="flex_wrp">
                            <TextBlock
                                text={`источник животного белка и жира, незаменимых аминокислот, витаминов`}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex">
                    <div className="flex_el">
                        <h3 className="flex_head">
                            Тип сырья:
                        </h3>
                        <div className="flex_wrp">
                            <div className="flex_wrp_head">
                                Жиры и масла
                            </div>
                            <img className="img" src={img4} alt=""/>
                        </div>
                    </div>
                    <div className="flex_el">
                        <h3 className="flex_head">
                            Сырой материал:
                        </h3>
                        <div className="flex_wrp">
                            <TextBlock
                                text={`подсолнечное масло,
                            оливковое масле,
                            рыбий жир,
                            животный жир`}
                            />
                        </div>
                    </div>
                    <div className="flex_el">
                        <h3 className="flex_head">
                            Функция:
                        </h3>
                        <div className="flex_wrp">
                            <TextBlock
                                text={`источник энергии, жирных кислот, жирорастворимых витаминов`}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
