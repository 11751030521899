import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson6/step7-1.png'

export default class step7 extends React.Component {


    render() {
        return (
            <div className="step7">
                <div className="head">
                    Почему важно играть с кошкой днем?
                </div>
                <TextBlock
                    text={`Отличительной особенностью игр является повышение активности вашей кошки в течение дня, в результате чего кошка начинает дольше спать и меньше беспокоить вас по ночам. Играя с вашей кошкой, вы помогаете ей выплеснуть накопившуюся лишнюю энергию, вследствие чего значительно уменьшается риск возбуждения в вечернее и ночное время суток, и у вашей кошки пропадает желание крушить окружающие предметы в вашей квартире, особенно в позднее время суток. Незаменимой чертой игр с вашей кошкой является сам факт того, что вы играете с ней в контролируемой среде, что впоследствии уменьшает вероятность того, что она, играя, захочет укусить либо напасть на вас или других людей.`}
                />
                <img src={img1} alt="" className="imgCenter"/>
            </div>
        )
    }
}
