import React from 'react'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock'
import Answer from './dnd/Answer';
import Questions from './dnd/Questions';

import img1 from '../../../image/lessons/lesson33/step21-1.png'
import img2 from '../../../image/lessons/lesson33/step21-2.png'
import img3 from '../../../image/lessons/lesson33/step21-3.png'
import img4 from '../../../image/lessons/lesson33/step21-4.png'

export default class step2 extends React.Component {

    state = {
        // Ресайз
        windowWidth: window.innerWidth,

        // Работа десктопной версии
        answers: [],
        questions: [],
        correct: '',
        answerCorrect: [],

        //Работа мобильной версии
        answerActive: 0,
        questionActive: 0,
        answersMobile: [],
        questionsMobile: [],
        answersSelected: ['?', '?', '?', '?'],
        correctMobile: '',
        answerCorrectMobile: [],
        showPopup: false
    }

    //Отлов события ресайз
    handlerResize = () => {
        this.setState({windowWidth: window.innerWidth})
    }

    componentDidMount() {
        window.addEventListener('resize', this.handlerResize)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handlerResize);
    }

    //Работа десктопной версии
    setAnswer = (answer_el, question_el) => {
        const {answers} = this.state

        if (answers.indexOf(question_el) === -1) {
            let answer = []
            answers[answer_el] = question_el
            answers.push(answer)
        }
        this.setState({answers})

        let questions = [];
        // eslint-disable-next-line
        answers.map(answer => {

            if (answer.length) {
                questions.push(answer)
            }

        });
        this.setState({questions})
    };

    checkAnswer = () => {
        const {answerCorrect, questions} = this.state
        // eslint-disable-next-line
        questions.map((answer, index) => {
            if (answer === String(index + 1)) {
                answerCorrect.push('correct')
            } else {
                answerCorrect.push('inCorrect')
            }
        })
        this.setState({
            answerCorrect: answerCorrect,
        })
        if (questions.length === 4 && answerCorrect.indexOf('inCorrect') === -1) {
            this.setState({correct: '1'})
        } else {
            this.setState({correct: '0'})
        }
        this.props.showNextBtn()
    };

    _renderTestDesktop = () => {
        const {answerCorrect, correct, questions} = this.state
        return (
            <div className="test_desktop">
                {correct !== '' ?
                    <div className={'info ' + (correct === '0' && 'error')}>
                        <div className="info_head">
                            {correct === '1' ? 'все верно!' : 'К сожалению, ваш ответ не верен.'}
                        </div>
                        <div className="info_flex">
                            <div className="info_flex_el">
                                <div className="info_flex_head">
                                    Cухие рационы
                                </div>
                                <TextBlock
                                    text={`Способствуют`}
                                />
                                <ul className="list">
                                    <li className="list_item">
                                        профилактике заболеваний ротовой полости за счет механической чистик зубов;
                                    </li>
                                    <li className="list_item">
                                        профилактике ЖКТ за счет высокого содержания клетчатки.
                                    </li>
                                </ul>
                            </div>
                            <div className="info_flex_el">
                                <div className="info_flex_head">
                                    Влажные рационы
                                </div>
                                <TextBlock
                                    text={`Способствуют`}
                                />
                                <ul className="list">
                                    <li className="list_item">
                                        профилактике ожирения за счет меньшей калорийности;
                                    </li>
                                    <li className="list_item">
                                        профилактике заболеваний мочевыделительной системы за счет высокого содержания жидкости.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    :
                    <button className="btn btn_violet" onClick={() => this.checkAnswer()}>
                        ПРОВЕРИТЬ
                    </button>
                }
                <div className="flex">
                    <div className="answers left">
                        <div className="answers_head">
                            Cухие рационы
                        </div>
                        <div className="answers_item">
                            <img src={img1} alt="" className="answers_item_img"/>
                            <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'1'} text={'?'} count={'?'} answerCorrect={answerCorrect[0]}/>
                        </div>
                        <div className="answers_item">
                            <img src={img2} alt="" className="answers_item_img"/>
                            <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'2'} text={'1'} count={'?'} answerCorrect={answerCorrect[1]}/>
                        </div>
                    </div>
                    <div className="answers right">
                        <div className="answers_head">
                            Влажные рационы
                        </div>
                        <div className="answers_item">
                            <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'3'} text={'2'} count={'?'} answerCorrect={answerCorrect[2]}/>
                            <img src={img3} alt="" className="answers_item_img"/>
                        </div>
                        <div className="answers_item">
                            <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'4'} text={'3'} count={'?'} answerCorrect={answerCorrect[3]}/>
                            <img src={img4} alt="" className="answers_item_img"/>
                        </div>
                    </div>
                </div>
                <div className="questions">
                    <div className={'question_el ' + (questions.indexOf('1') >= 0 && 'hidden')}>
                        <Questions targetKey="1" question_el={'1'} label={'Способствует профилактике заболеваний ротовой полости'} text={'Способствует профилактике заболеваний ротовой полости'}/>
                    </div>
                    <div className={'question_el ' + (questions.indexOf('2') >= 0 && 'hidden')}>
                        <Questions targetKey="1" question_el={'2'} label={'Способствует профилактике заболеваний желудочно-кишечного тракта'} text={'Способствует профилактике заболеваний желудочно-кишечного тракта'}/>
                    </div>
                    <div className={'question_el ' + (questions.indexOf('3') >= 0 && 'hidden')}>
                        <Questions targetKey="1" question_el={'3'} label={'Способствует профилактике ожирения'} text={'Способствует профилактике ожирения'}/>
                    </div>
                    <div className={'question_el ' + (questions.indexOf('4') >= 0 && 'hidden')}>
                        <Questions targetKey="1" question_el={'4'} label={'Способствует снижению риска развития заболеваний мочевыделительной системы'} text={'Способствует снижению риска развития заболеваний мочевыделительной системы'}/>
                    </div>
                </div>
            </div>
        )
    }

    //Работа мобильной версии
    questions = [
        `Способствует профилактике заболеваний ротовой полости`,
        `Способствует профилактике заболеваний желудочно-кишечного тракта`,
        `Способствует профилактике ожирения`,
        `Способствует снижению риска развития заболеваний мочевыделительной системы`,
    ]

    selectAnswer = (answerActive) => {
        document.body.scrollIntoView( true);
        document.body.style.overflow = "hidden";
        this.setState({
            showPopup: true,
            answerActive: answerActive
        })
    }

    selectQuestion = (questionActive) => {
        const {answerActive, answersMobile, questionsMobile, answersSelected} = this.state

        if (answersMobile.indexOf(answerActive) === -1) {
            answersMobile.push(answerActive)
        }
        this.setState({answersMobile})

        if (questionsMobile.indexOf(questionsMobile) === -1) {
            questionsMobile.push(questionActive)
        }
        this.setState({questionsMobile})

        answersSelected.splice(answerActive - 1, 1, this.questions[questionActive - 1])

        document.body.style.overflow = "";
        this.setState({
            showPopup: false,
            questionActive: questionActive,
            answersSelected: answersSelected
        })
    }

    checkAnswerMobile = () => {
        const {answerCorrectMobile, answersSelected} = this.state
        // eslint-disable-next-line
        answersSelected.map((answer, index) => {
            if (answer === this.questions[index]) {
                answerCorrectMobile.push('correct')
            } else {
                answerCorrectMobile.push('inCorrect')
            }
        })
        this.setState({
            answerCorrect: answerCorrectMobile,
        })
        if (answerCorrectMobile.indexOf('?') === -1 && answerCorrectMobile.indexOf('inCorrect') === -1) {
            this.setState({correctMobile: '1'})
        } else {
            this.setState({correctMobile: '0'})
        }
        this.props.showNextBtn()
    }

    closePopup = () => {
        document.body.style.overflow = "";
        this.setState({showPopup: false})
    }

    _renderTestMobile = () => {
        const {showPopup, answersMobile, questionsMobile, answersSelected, correctMobile, answerCorrectMobile} = this.state
        return (
            <div className="test_mobile">
                {correctMobile !== '' ?
                    <div className={'info ' + (correctMobile === '0' && 'error')}>
                        <div className="info_head">
                            {correctMobile === '1' ? 'все верно!' : 'К сожалению, ваш ответ не верен.'}
                        </div>
                        <div className="info_flex">
                            <div className="info_flex_el">
                                <div className="info_flex_head">
                                    Cухие рационы
                                </div>
                                <TextBlock
                                    text={`Способствуют`}
                                />
                                <ul className="list">
                                    <li className="list_item">
                                        профилактике заболеваний ротовой полости за счет механической чистик зубов;
                                    </li>
                                    <li className="list_item">
                                        профилактике ЖКТ за счет высокого содержания клетчатки.
                                    </li>
                                </ul>
                            </div>
                            <div className="info_flex_el">
                                <div className="info_flex_head">
                                    Влажные рационы
                                </div>
                                <TextBlock
                                    text={`Способствуют`}
                                />
                                <ul className="list">
                                    <li className="list_item">
                                        профилактике ожирения за счет меньшей калорийности;
                                    </li>
                                    <li className="list_item">
                                        профилактике заболеваний мочевыделительной системы за счет высокого содержания жидкости.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    :
                    <button className="btn btn_violet" onClick={() => this.checkAnswerMobile()}>
                        ПРОВЕРИТЬ
                    </button>
                }
                <div className="answers top">
                    <div className="answers_head">
                        Cухие рационы
                    </div>
                    <div className="answers_box">
                        <img src={img1} alt="" className="answers_item_img"/>
                        <div
                            className={'answers_item ' + (answersMobile.indexOf(1) >=0 ? 'selected ' : '') + answerCorrectMobile[0]}
                            onClick={() => {this.selectAnswer(1)}}
                        >
                            {answersSelected[0]}
                        </div>
                    </div>
                    <div className="answers_box">
                        <img src={img2} alt="" className="answers_item_img"/>
                        <div
                            className={'answers_item ' + (answersMobile.indexOf(2) >=0 ? 'selected ' : '') + answerCorrectMobile[1]}
                            onClick={() => {this.selectAnswer(2)}}
                        >
                            {answersSelected[1]}
                        </div>
                    </div>
                </div>
                <div className="answers bottom">
                    <div className="answers_head">
                        Влажные рационы
                    </div>
                    <div className="answers_box">
                        <div
                            className={'answers_item ' + (answersMobile.indexOf(3) >=0 ? 'selected ' : '') + answerCorrectMobile[2]}
                            onClick={() => {this.selectAnswer(3)}}
                        >
                            {answersSelected[2]}
                        </div>
                        <img src={img3} alt="" className="answers_item_img"/>
                    </div>
                    <div className="answers_box">
                        <div
                            className={'answers_item ' + (answersMobile.indexOf(4) >=0 ? 'selected ' : '') + answerCorrectMobile[3]}
                            onClick={() => {this.selectAnswer(4)}}
                        >
                            {answersSelected[3]}
                        </div>
                        <img src={img4} alt="" className="answers_item_img"/>
                    </div>
                </div>
                {showPopup &&
                <div className="popup">
                    <div className="popup_wrp">
                        <div
                            className="popup_closed"
                            onClick={() => {this.closePopup()}}
                        />
                        <div className="popup_flex">
                            <div className="questions">
                                {this.questions.map((item, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className={'questions_item ' + (questionsMobile.indexOf(index + 1) >=0 ? 'selected ' : '')}
                                            onClick={() => {this.selectQuestion(index + 1)}}
                                        >
                                            {item}
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                }
            </div>
        )
    }

    render() {
        const {windowWidth} = this.state
        return (
            <div className="step21">
                <div className="head">
                    Оптимальный режим кормления кошки на каждой стадии ее жизни
                </div>
                <TextBlock
                    text={`Сочетание влажного и сухого корма позволяет сбалансировать питательные вещества так, чтобы удовлетворить естественные предпочтения кошек.`}
                />
                <InfoBlock
                    text={`Определите преимущества сухого и влажного кормов.
                    Переместите правильные ответы в соответствующие пустые ячейки.`}
                />
                {windowWidth >= 768 ?
                    <div className="test">
                        {this._renderTestDesktop()}
                    </div>
                    :
                    <div className="test">
                        {this._renderTestMobile()}
                    </div>
                }
            </div>
        )
    }
}
