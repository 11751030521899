import React from 'react'
// import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from "../../../image/lessons/lesson8/step8-1.png";

export default class step8 extends React.Component {

    render() {
        return (
            <div className="step8">
                <div className="flex">
                    <ul className="list">
                        <li className="list_item">
                            <b>Страх – это реакция, которая позволяет избежать предполагаемой опасности.</b>
                        </li>
                        <li className="list_item">
                            Тревога – это реакция, возникающая в результате ожидания неблагоприятного события, основанного на ранее негативном, пугающем или болезненном опыте.
                        </li>
                    </ul>
                    <img src={img1} alt="" className="flex_img"/>
                </div>
            </div>
        )
    }
}
